import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isUndefined, noop } from "lodash";
import { useSelector } from "react-redux";
import useEmployee from "lib/api-endpoints/hooks/employee";
import { selectCompany } from "state/app-slice";
import { TabSection, TertiaryButton, TextLink } from "components";
import DateDisplay from "components/date-display/date-display";
import { LinkIcon } from "components/icons";
import DocumentTab from "./document-tab";
import TrainingTab from "./training-tab";
import OverviewTab from "./overview-tab";
import EmployeeEditDrawer from "./employee-edit-drawer";
import { SchemeJobRolesApi } from "lib/api-endpoints";

import styles from "./employee-profile.module.scss";

/**
 * Gets a display value from a given value being either the value or -.
 *
 * @param {string} value the value to display
 *
 * @returns {string} display value
 */
const getDisplayValue = (value) => {
    return value || "-";
};

/**
 * Appends valid address lines and postcode into an address display string
 *
 * @param {object} employee The current employee
 *
 * @returns {string} formatted address display string
 */
const getAddress = (employee) => {
    if (!employee || !employee?.address) {
        return "-";
    }

    const { line_1: line1, line_2: line2, postcode } = employee.address;

    return [line1, line2, postcode].filter((val) => !isUndefined(val)).join(", ");
};

/**
 * Gets the employee name
 *
 * @param {object} employee The current employee
 *
 * @returns {string} employee name
 */
const getName = (employee) => {
    if (!employee || !employee?.name) {
        return "-";
    }

    const [surname, forename] = employee?.name.split(", ");

    return `${forename} ${surname}`;
};

const getSchemes = (employee) => {
    if (!employee || !employee?.current_tronc_scheme?.length) {
        return "-";
    }

    return employee.current_tronc_scheme.map((scheme) => {
        return (
            <TextLink
                key={scheme.id}
                className={styles.schemeBadge}
                to={`/companies/${employee.company_id}/schemes`}
            >
                <div><LinkIcon /></div>
                <div className={styles.schemeText}>{scheme.name}</div>
            </TextLink>
        );
    });
};

/**
 * The Employee Profile Page
 *
 * @param {int} id the employee id
 * @param {func} setRefresh Re-gets the data for the employee page
 * @param {func} refresh Re-gets the data for the employee page
 *
 * @returns {React.Component} EmployeeProfile component
 */
const EmployeeProfile = ({ id, setRefresh, refresh }) => {
    const { app, user } = useSelector((state) => state);
    const { data: employee, loading } = useEmployee(id, refresh);
    const [employeeEditDrawer, setEmployeeEditDrawer] = useState(false);
    const [jobRoles, setJobRoles] = useState([]);

    useEffect(() => {
        const getJobRoles = async () => {
            const { data } = await SchemeJobRolesApi.getJobRoles(employee?.current_tronc_scheme[0]?.id).request;

            setJobRoles(data.data);
        };

        if (employee?.current_tronc_scheme?.length > 0) {
            getJobRoles();
        }

    }, [employee?.current_tronc_scheme]);

    /**
     * Finds current company and gets relevant permissions
     *
     * @returns {object} selected company and permissions
     */
    const company = selectCompany(app, employee?.company_id);

    /**
     * Closes drawer and triggers the get for the new employee data
     *
     * @returns {void}
     */
    const onDrawerClose = () => {
        setEmployeeEditDrawer(false);
        setRefresh();
    };

    return (
        <div className={styles.container}>
            <div className={styles.sidebar}>
                <h2 className={styles.name}>
                    {getName(employee)}
                </h2>
                <span className={styles.department}>
                    {employee?.payroll_ref}
                </span>
                <span className={styles.department}>
                    {employee?.department}
                </span>
                <dl className={styles.details}>
                    <div>
                        <dt>Start date</dt>
                        <dd>
                            {employee?.start_date
                                ? <DateDisplay data={employee.start_date} format={null} output="Do MMMM YYYY" />
                                : "-"
                            }
                        </dd>
                    </div>
                    <div>
                        <dt>Email</dt>
                        <dd className={styles.email}>{getDisplayValue(employee?.emails?.home)}</dd>
                    </div>
                    <div>
                        <dt>Mobile</dt>
                        <dd>{getDisplayValue(employee?.phone_numbers?.mobile)}</dd>
                    </div>
                    <div>
                        <dt>Address</dt>
                        <dd>{getAddress(employee)}</dd>
                    </div>
                    <div>
                        <dt>Tronc scheme</dt>
                        <dd>{getSchemes(employee)}</dd>
                    </div>
                </dl>
                {(company?.companyPermissions?.employees.canEdit || user.tronc_master) && (
                    <div className={styles.sidebarFooter}>
                        <TertiaryButton onClick={() => setEmployeeEditDrawer(true)}>
                            Edit
                        </TertiaryButton>
                    </div>
                )}
            </div>

            <EmployeeEditDrawer
                isOpen={employeeEditDrawer}
                onClose={onDrawerClose}
                employee={employee}
                jobRoles={jobRoles}
                loading={loading}
            />

            <div className={styles.content}>
                <TabSection
                    tabs={[
                        {
                            title: "Overview",
                            route: "overview",
                            content: <OverviewTab employee={employee} />,
                        },
                        {
                            title: "Documents",
                            route: "documents",
                            content: <DocumentTab employee={employee} />,
                        },
                        {
                            title: "Training",
                            route: "training",
                            content: <TrainingTab employee={employee} setRefresh={setRefresh} />,
                        },
                    ]}
                />
            </div>
        </div>
    );
};

EmployeeProfile.propTypes = {
    id: PropTypes.number.isRequired,
    setRefresh: PropTypes.func,
    refresh: PropTypes.bool,
};

EmployeeProfile.defaultProps = {
    setRefresh: noop,
    refresh: false,
};

export default EmployeeProfile;
