import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectPayroll } from "state/app-slice";
import Button from "components/forms/form-controls/button";
import { BatchesApi } from "lib/api-endpoints";
import batchPropTypes from "lib/props-schema/batch-schema";
import periodPropTypes from "lib/props-schema/period-schema";

/**
 * Renders the unapproved batch actions
 *
 * @param {object} batch The batch details
 * @param {object} period The period details
 * @param {function} onComplete Callback to be made when approval/query complete
 *
 * @returns {React.Component} The UnapprovedBatchActions component
 */
const UnapprovedBatchActions = ({ batch, period, onComplete }) => {
    const approve = () => {
        BatchesApi.approve(batch.id)
            .request
            .then(({ data }) => onComplete(data));
    };

    const query = () => {
        BatchesApi.query(batch.id)
            .request
            .then(({ data }) => onComplete(data));
    };

    const payroll = selectPayroll(
        useSelector((state) => state.app),
        period.payroll_id
    );

    const canApprove = (payroll.permissions.batches?.canApprove && period.status === 'checked')
        || period.status !== 'checked';

    return (
        <>
            <Button onClick={query}>Query</Button>
            {canApprove && <Button onClick={approve}>Approve</Button>}
        </>
    );
};

UnapprovedBatchActions.defaultProps = {
    onComplete: () => null,
};

UnapprovedBatchActions.propTypes = {
    onComplete: PropTypes.func,
    batch: batchPropTypes.isRequired,
    period: periodPropTypes.isRequired,
};

export default UnapprovedBatchActions;
