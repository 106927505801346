import { useEffect, useState } from "react";
import { TroncsPeriodsApi } from "lib/api-endpoints";

const initialState = {
    files: [],
    isLoaded: false,
};

const useTroncPeriodFiles = ({ periodIds, refresh }) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        setState(initialState);

        const { request, abortController } = TroncsPeriodsApi.getFiles({
            "tronc_period_ids": periodIds,
        });

        request.then(({ data: { data } }) => setState({ files: data, isLoaded: true }));

        return () => abortController?.abort();
    }, [periodIds, refresh]);

    return state;
};

export default useTroncPeriodFiles;
