import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { isNil, omitBy } from "lodash";
import { FormRow, FormRowLabel, DateInput } from "./form-controls";
import ErrorHelpText from "./error-help-text";

/**
 * Renders the text input row
 *
 * @param {object} props The text input props
 *
 * @return {React.Component} The text input row component
 */
const DateInputRow = forwardRef(({ label, rowTestId, topMargin, error, required, ...otherProps }, ref) => {
    const rowProps = omitBy({
        "data-testid": rowTestId,
        topMargin,
    }, isNil);

    return (
        <FormRow {...rowProps}>
            { label && <FormRowLabel label={label} required={required} /> }
            <DateInput
                ref={ref}
                aria-label={label}
                error={Boolean(error)}
                required={required}
                {...otherProps}
            />
            { error ? <ErrorHelpText text={error} /> : null }
        </FormRow>
    );
});

DateInputRow.propTypes = {
    error: PropTypes.string,
    rowTestId: PropTypes.string,
    topMargin: PropTypes.bool,
    label: PropTypes.string,
    required: PropTypes.bool,
};

DateInputRow.defaultProps = {
    error: "",
    rowTestId: null,
    topMargin: true,
    label: null,
    required: false,
};

export default DateInputRow;
