import { api } from "lib/api";

export default class BatchesApi {

    /**
     * Gets the details for a batch
     *
     * @param {int} id The batch id
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getBatch = (id) => api({ url: `/payroll_batches/${id}` });

    /**
     * Queries a batch
     *
     * @param {int} id The batch id
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static query = (id) => api({ url: `/payroll_batches/${id}/query`, type: 'post' });

    /**
     * Approves a batch
     *
     * @param {int} id The batch id
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static approve = (id) => api({ url: `/payroll_batches/${id}/approve`, type: 'post' });

}
