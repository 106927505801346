import React from "react";
import { isEmpty } from "lodash";
import { DateDisplay } from "components";
import announcementProps, { announcementDefaultProps } from "lib/props-schema/announcement-schema";

import styles from "./announcement.module.scss";

/**
 * Renders the announcement component
 *
 * @param {object} announcement The announcement
 *
 * @returns {React.Component} The Announcement Component
 */
const Announcement = ({ announcement }) => {
    const { subject, message, addedby } = announcement;
    const name = [addedby?.forename, addedby?.surname].filter((value) => !isEmpty(value)).join(" ");
    const start = <DateDisplay data={announcement.datestart} output="DD-MM-YYYY" />;
    const end = <DateDisplay data={announcement.dateend} output="DD-MM-YYYY" />;

    return (
        <div className={styles.container} data-testid="announcement">
            <h1 className={styles.subject} data-testid="subject">{subject}</h1>
            <div className={styles.added}>
                <div className={styles.paddedContainer} data-testid="visibility">
                    {name} made visible from {start} - {end}
                </div>
            </div>
            <div className={styles.message} data-testid="message">{message}</div>
        </div>
    );
};

Announcement.propTypes = {
    announcement: announcementProps,
};

Announcement.defaultProps = {
    announcement: announcementDefaultProps,
};

export default Announcement;
