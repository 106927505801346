import PropTypes from 'prop-types';

export const periodPropTypes = PropTypes.shape({
    "id": PropTypes.number,
    "payroll_id": PropTypes.number,
    "period_number": PropTypes.number,
    "year": PropTypes.number,
    "frequency": PropTypes.string,
    "period_name": PropTypes.string,
    "uploader": PropTypes.shape({
        "forename": PropTypes.string,
        "surname": PropTypes.string,
    }),
    "versions": PropTypes.arrayOf(PropTypes.number),
    "status": PropTypes.string,
});

export const periodDefaultProps = {};

export default periodPropTypes;
