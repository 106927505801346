import { api } from "lib/api";

export default class TroncFilesApi {

    /**
     * Gets the files
     *
     * @param {object} params The query parameters
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getFiles = (params = {}) => api({ url: "/tronc_files", config: { params } });

    /**
     * Gets the file
     *
     * @param {number} id The file id
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getFileData = (id) => api({ url: `/tronc_files/${id}/data`, config: { responseType: 'blob' }});

}

