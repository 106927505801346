import React, { useEffect, useState } from "react";
import MFAApi from "lib/api-endpoints/mfa-api";
import AuthenticatorCodeDropdown from "./authenticator-code-dropdown";
import LoadingMask from "components/loading-mask/loading-mask";

import styles from "./QR-code.module.scss";

/**
 * The QR code with authentication dropdown
 *
 * @return {React.Component} the qr code with authentication
 */
const QRcode = () => {
    const [QRCodeState, setQRCodeState] = useState({
        qrImage: "",
        code: "",
    });

    useEffect(() => {
        setInitialQRState();
    }, []);

    /**
     * Sets the initial state for the QR code
     *
     * @return {void}
     */
    const setInitialQRState = async () => {
        const { data } = await MFAApi.getQRCode().request;

        setQRCodeState({
            // eslint-disable-next-line camelcase
            qrImage: data.qr_image,
            code: (data.key.match(/.{1,3}/g)).join("-"),
        });
    };

    /**
     * Renders the QR code
     *
     * @return {ReactElement} QR code
     */
    const renderQRCode = () => {
        return (
            <div className={styles.QRcontainer}>
                <LoadingMask loading={!QRCodeState.qrImage}>
                    <img
                        src={`data:image/png;base64, ${QRCodeState.qrImage}`}
                        alt="The QR code to setup multi-factor authentication"
                    />
                </LoadingMask>
            </div>
        );
    };

    /**
     * Renders the QR component once loaded
     *
     * @return {ReactElement} QR code
     */
    return (
        <div data-testid="QR-code">
            {renderQRCode()}
            <AuthenticatorCodeDropdown code={QRCodeState.code} />
        </div>
    );
};

export default QRcode;
