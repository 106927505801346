import { api } from "lib/api";

export default class CompaniesApi {

    /**
     * Gets the companies request promise
     *
     * @param {object} params The query parameters
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getCompanies = (params) => api({ url: `/companies`, config: { params } });

    /**
     * Gets the single company request promise
     *
     * @param {int} id The company id
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getCompany = (id) => api({ url: `/companies/${id}` });

    /**
     * Gets the company user permissions request promise
     *
     * @param {int} id The company id
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getUserPermissions = (id) => api({ url: `/company/${id}/permissions` });

    /**
     * Creates a company
     *
     * @param {object} data The company data
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static addCompany = (data) => api({ type: "post", url: "/company/add", data });

    /**
     * Edits a company
     *
     * @param {int} id The company id
     * @param {object} data The company data
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static editCompany = (id, data) => api({ type: "patch", url: `/company/${id}/edit`, data });

    /**
     * Get company sites
     *
     * @param {object} params The query parameters
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getSites = (params) => api({ type: "get", url: "/sites", config: { params } });

    /**
     * Create company sites
     *
     * @param {object} data The site information
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static addSite = (data) => api({ type: "post", url: "/sites", data });

    /**
     * Edit company site
     *
     * @param {number} id The site id
     * @param {object} data The site information
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static editSite = (id, data) => api({ type: "patch", url: `/sites/${id}`, data });

    /**
     * Get company site schemes
     *
     * @param {object} params The query parameters
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getSchemes = (params) => api({ type: "get", url: "/tronc_schemes", config: { params } });

    /**
     * Gets a company training types
     *
     * @param {int} id The company id
     * @param {object} data The company data
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getCompanyTraining = (id, data) => api({ type: "get", url: `/company/${id}/training_types`, data });

}
