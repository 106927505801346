import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getCompanies, getUpdates, loaded } from "state/app-slice";
import { getUser } from "state/user-slice";
import { LoadingMask, Card } from "components/";
import { routePaths } from "routes";
import UserApi from "lib/api-endpoints/user-api";
import LoginBodyContent from "./loginBodyContent";
import SMSBodyContent from "./smsBodyContent";
import MFABodyContent from "./mfaBodyContent";
import { TipologyLogo } from "components/icons";

import styles from "./login.module.scss";

/**
 * Renders login component
 *
 * @returns {React.Component} Login Component
 */
const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [state, setState] = useState({
        errorMessage: null,
        loading: false,
        apiResponse: "",
    });

    const { register, handleSubmit } = useForm();

    const loadInitialData = async () => {
        await dispatch(getUser());

        await Promise.all([
            dispatch(getCompanies()),
            dispatch(getUpdates()),
        ]);

        setState({
            ...state,
            loading: false,
        });
        dispatch(loaded());
        navigate(routePaths.dashboard, { replace: true });
    };

    const handleOnSubmit = async (data) => {
        setState({
            ...state,
            loading: true,
            errorMessage: null,
        });

        try {
            const { data: response } = await UserApi.login({
                ...data,
                type: "employer",
                platform: "tipology",
            }).request;

            if (response.incomplete) {
                setState({
                    ...state,
                    errorMessage: response.incomplete,
                    loading: false,
                    apiResponse: response.incomplete,
                });

                return;
            }

            [
                {
                    key: "userId",
                    value: response.user_id,
                },
                {
                    key: "apiToken",
                    value: response.token,
                },
                {
                    key: "deviceToken",
                    value: response.device_token,
                },
            ].forEach(({ key, value }) => sessionStorage.setItem(key, value));

            await loadInitialData();
        } catch (error) {
            setState({
                ...state,
                errorMessage: error?.response?.data.message ?? "Failed to login",
                loading: false,
            });
        }

    };

    const renderBodyContent = () => {
        switch (state.apiResponse) {
        case "Totp code is required for this account":
            return (
                <MFABodyContent
                    handleSubmit={handleSubmit(handleOnSubmit)}
                    register={register}
                />
            );
        case "SMS code login is required for this account":
            return (
                <SMSBodyContent
                    handleSubmit={handleSubmit(handleOnSubmit)}
                    register={register}
                />
            );
        default:
            return (
                <LoginBodyContent
                    handleSubmit={handleSubmit(handleOnSubmit)}
                    register={register}
                />
            );
        }
    };

    return (
        <>
            <div className={styles.headerBar}>
                <TipologyLogo className={styles.logo} />
            </div>
            <div className={styles.container}>
                <h1 className={styles.title}>
                    Login to Tipology
                </h1>
                <Card>
                    <LoadingMask loading={state.loading}>
                        {(state.errorMessage)
                            ? (
                                <span className={styles.errorMessage}>
                                    {state.errorMessage}
                                </span>
                            )
                            : null
                        }
                        {renderBodyContent()}

                    </LoadingMask>
                </Card>
            </div>
        </>
    );
};

export default Login;
