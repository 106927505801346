import { noop } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import FormPageControls from './form-page-controls';
import LoadingMask from 'components/loading-mask/loading-mask';
import DrawerTitle from 'components/drawer-title/drawer-title';
import Stepper from './stepper/stepper';
import FormContainer from 'components/forms/form-container/form-container';
import FormContent from 'components/forms/form-container/form-content';

/**
 * Return Form Content for multi part forms in either modal or drawer form
 *
 * @param {object} props component props
 * @param {array} props.config steps config
 * @param {integer} props.currentFormPage current form page
 * @param {string} props.mode view mode modal/drawer etc
 * @param {boolean} props.stepper if stepper should be shown
 * @param {string} props.formTitle form heading
 * @param {string} props.className  custom styling class
 * @param {boolean} props.showForm if the form is visible
 *
 * @returns {React.Component} FormContent Component
 */
const FormController = ({
    config,
    currentFormPage,
    mode,
    stepper,
    formTitle,
    footerClassName,
    footerAlignment,
    className,
    showForm,
    nextPage,
    prevPage,
    handleOnClose,
    onSubmit,
    onError,
    isSubmitting,
    submitText,
    handleTransitionEnd,
    padding,
    headerClass,
    ...otherProps
}) => {
    /**
     * Gets the current drawer title
     *
     * @returns {string} drawer title
     */
    const getCurrentTitle = () => {
        const title = config[currentFormPage - 1].title;

        return title ?? formTitle;
    };

    /**
     * Gets the current
     *
     * @returns {React.Component} The current form component to be rendered
     */
    const getCurrentFormComponent = () => {
        const { component: CurrentComponent, props: configProps } = config[currentFormPage - 1];

        return <CurrentComponent {...configProps} />;
    };

    return (
        <FormContainer
            mode={mode}
            className={className}
            isOpen={showForm}
            isLoading={false}
            onClose={handleOnClose}
            onOutsideClick={handleOnClose}
            handleTransitionEnd={handleTransitionEnd}
            padding={padding}
            headerClass={headerClass}
            headerContent={(
                <DrawerTitle>
                    {getCurrentTitle()}
                </DrawerTitle>
            )}
            {...otherProps}
        >
            <form>
                <FormContent>
                    {stepper && <Stepper steps={config} currentPage={currentFormPage} />}
                    {getCurrentFormComponent()}
                    <LoadingMask loading={isSubmitting} />
                </FormContent>
                <div className={footerClassName}>
                    <FormPageControls
                        alignment={footerAlignment}
                        nextPage={nextPage}
                        prevPage={prevPage}
                        currentFormPage={currentFormPage}
                        config={config}
                        onSubmit={onSubmit}
                        onError={onError}
                        submitText={submitText}
                    />
                </div>
            </form>
        </FormContainer>
    );
};

FormController.propTypes = {
    className: PropTypes.string,
    config: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        stepNum: PropTypes.number.isRequired,
        stepTitle: PropTypes.string,
        props: PropTypes.object,
        component: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node,
            PropTypes.element,
            PropTypes.func,
        ]),
    })).isRequired,
    formTitle: PropTypes.string,
    footerClassName: PropTypes.string,
    footerAlignment: PropTypes.string,
    showForm: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func,
    onError: PropTypes.func,
    stepper: PropTypes.bool,
    mode: PropTypes.string,
    nextPage: PropTypes.func.isRequired,
    prevPage: PropTypes.func.isRequired,
    currentFormPage: PropTypes.number.isRequired,
    handleOnClose: PropTypes.func,
    isSubmitting: PropTypes.bool,
    submitText: PropTypes.string,
    handleTransitionEnd: PropTypes.func,
    headerClass: PropTypes.string,
    padding: PropTypes.bool,
};

FormController.defaultProps = {
    className: "",
    formTitle: null,
    footerClassName: "",
    footerAlignment: "left",
    onSubmit: noop,
    onError: noop,
    stepper: true,
    mode: "modal",
    handleOnClose: noop,
    isSubmitting: false,
    submitText: "Submit",
    handleTransitionEnd: noop,
    headerClass: "",
    padding: false,
};

export default FormController;
