import React from 'react';
import PropTypes from 'prop-types';
import InlineFormRow from './inline-form-row';
import TroncSchemeTrainingInput from './tronc-scheme-training-input';
import { useFormContext } from 'react-hook-form';
import ToggleSwitchRow from 'components/forms/toggle-switch-row';

import styles from './tronc-schemes-training-form.module.scss';

/**
 * Renders Tronc schemes training roles form
 *
 * @returns {React.Component} Tronc schemes training roles form Component
 */
const TroncSchemeTrainingForm = ({ isEdit }) => {
    const { register, watch, getValues } = useFormContext();
    const formType = isEdit ? "Edit" : "Add";

    const {
        troncSchemeTrainingOptIn,
    } = getValues();

    watch();

    return (
        <div className={styles.container}>
            <div className={styles.formWrapper}>
                <InlineFormRow
                    heading={`${formType} training roles and tronc points`}
                >
                    <div>
                        <ToggleSwitchRow
                            {...register('troncSchemeTrainingOptIn')}
                            label="Use tronc scheme training points"
                            topMargin={false}
                        />
                        {
                            troncSchemeTrainingOptIn ? (
                                <div className={styles.cardContainer}>
                                    <div className={styles.cardContent}>
                                        <div className={styles.titleContainer}>
                                            <div className={styles.roleTitle}>
                                                Training roles
                                            </div>
                                            <div className={styles.pointsTitle}>
                                                Complete Points *
                                            </div>
                                            <div className={styles.pointsTitle}>
                                                Incomplete Points *
                                            </div>
                                        </div>
                                        <TroncSchemeTrainingInput />
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
                </InlineFormRow>
            </div>
        </div>
    );
};

TroncSchemeTrainingForm.propTypes = {
    isEdit: PropTypes.bool.isRequired,
};

export default TroncSchemeTrainingForm;
