import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./button.module.scss";

const Button = ({ children, className, hasPermission, ...otherProps }) => {
    return hasPermission ? (
        <button
            type="button"
            data-testid="button-component"
            className={classNames(styles.button, className)}
            {...otherProps}
        >
            {children}
        </button>
    ) : null;
};

Button.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    hasPermission: PropTypes.bool,
};

Button.defaultProps = {
    children: "",
    className: "",
    hasPermission: true,
};

export default Button;
