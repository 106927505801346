import { api } from "lib/api";
import { first, isArray } from "lodash";

export default class DocumentsApi {

    /**
     * Gets list of documents
     *
     * @param {object} documentType The type of document required
     * @param {object} params request params
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getDocuments = (documentType, params = {}) => api({
        url: `/documents/${documentType}`,
        config: { params },
    });

    /**
     * Creates a new company document with the given payload
     *
     * @param {object} payload the request payload
     * @param {array} payrolls array of payroll ids
     * @param {int} company company id
     * @param {string} description file description
     * @param {FileList} file file array containing the file to upload
     *
     * @returns {Promise} axios promise
     */
    static createDocument = ({ payrolls, company, description, file }) => {
        const data = new FormData();
        const payrollsArr = isArray(payrolls) ? payrolls : Array(payrolls);

        data.append('payrolls[]', payrollsArr);
        data.append('company', company);
        data.append('description', description);
        data.append('file', first(file));

        return api({ type: 'post', url: '/file/company', data });
    };

    /**
     * Deletes document with the given id from the given table
     *
     * @param {int} id document id
     * @param {string} documentType the document table
     *
     * @returns {Promise} axios promise
     */
    static deleteDocument = (id, documentType) => api({
        type: 'delete',
        url: `/file/${documentType}/${id}`,
    });

    /**
     * Download the given document/file
     *
     * @param {int} id document id
     * @param {string} documentType document table
     *
     * @returns {Promise} axios promise
     */
    static download = (id, documentType) => api({
        url: `/file/${documentType}/${id}/data`,
        config: { responseType: 'blob' },
    });

}
