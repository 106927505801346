import { store } from "state/store";

/**
 * Clears all local session data
 *
 * @return {void}
 */
const clearSessionData = () => {
    localStorage.clear();
    sessionStorage.clear();
    store.dispatch({ type: "RESET" });
    window.location.reload();
};

export { clearSessionData };
