import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { flatMap } from 'lodash';
import { SelectInputRow } from 'components/forms';
import InlineFormRow from './inline-form-row';
import TroncSchemeJobRolesInput from './tronc-scheme-job-roles-input';
import { PrimaryButton } from 'components';
import ToggleSwitchRow from 'components/forms/toggle-switch-row';

import styles from './tronc-schemes-job-roles-form.module.scss';

/**
 * Renders Tronc schemes job roles form
 *
 * @returns {React.Component} Tronc schemes job roles form Component
 */
const TroncSchemeJobRolesForm = ({ isEdit, companyJobRoles }) => {
    const { register, getValues, setValue, watch } = useFormContext({
        defaultValues: {},
    });

    const {
        fields,
        append,
        remove,
    } = useFieldArray({
        name: "schemeJobRoles",
    });

    const formType = isEdit ? "Edit" : "Add";

    const {
        jobRole,
        schemeJobRoles,
        troncSchemeJobRoleOptIn,
    } = getValues();

    watch();

    const [jobRoleOptions, setJobRoleOptions] = useState([]);

    const getJobRoleOptions = useCallback(() => {
        if (schemeJobRoles) {
            const filteredJobRoles = companyJobRoles.filter((companyRole) => (
                schemeJobRoles.every((schemeJobRole) => schemeJobRole.job_role_id !== companyRole.job_role_id)
            ));

            const options = (flatMap(filteredJobRoles, ((role) => {
                return ({
                    value: role.job_role_id,
                    display: role.job_title,
                });
            })).filter((roles) => roles.value !== ""));

            setJobRoleOptions(options);

            return;
        }

        setJobRoleOptions(companyJobRoles);
    }, [companyJobRoles, schemeJobRoles]);

    useEffect(() => {
        getJobRoleOptions();
    }, [getJobRoleOptions, schemeJobRoles]);

    const onAdd = () => {
        const pos = companyJobRoles.map((companyRole) => companyRole.job_role_id).indexOf(parseFloat(jobRole));
        const selectedCompanyJobrole = companyJobRoles[pos];

        append({
            "job_title": selectedCompanyJobrole.job_title,
            "job_role_id": selectedCompanyJobrole.job_role_id,
            points: "",
        });

        setValue('jobRole', '');
    };

    return (
        <div className={styles.container}>
            <div className={styles.formWrapper}>
                <InlineFormRow
                    heading={`${formType} job roles and tronc points`}
                >
                    <div>
                        <ToggleSwitchRow
                            {...register('troncSchemeJobRoleOptIn')}
                            label="Use tronc scheme job role points"
                            topMargin={false}
                        />
                        {
                            troncSchemeJobRoleOptIn ? (
                                <div className={styles.cardContainer}>
                                    <div className={styles.cardContent}>
                                        <div className={styles.titleContainer}>
                                            <div className={styles.roleTitle}>
                                                Job roles
                                            </div>
                                            <div className={styles.pointsTitle}>
                                                Points
                                            </div>
                                        </div>
                                        <TroncSchemeJobRolesInput
                                            fields={fields}
                                            getJobRoleOptions={getJobRoleOptions}
                                            register={register}
                                            remove={remove}
                                            schemeJobRoles={schemeJobRoles ?? schemeJobRoles}
                                        />
                                    </div>
                                    <div className={styles.selectContainer}>
                                        <SelectInputRow
                                            {...register("jobRole")}
                                            name="jobRole"
                                            data-testid="jobRole"
                                            className={styles.select}
                                            topMargin={false}
                                            options={jobRoleOptions}
                                            selected={jobRole}
                                        />
                                        <PrimaryButton
                                            disabled={!parseFloat(jobRole)}
                                            onClick={() => {
                                                onAdd();
                                            }}
                                        >
                                            Add
                                        </PrimaryButton>
                                    </div>
                                </div>

                            ) : null
                        }
                    </div>
                </InlineFormRow>
            </div>
        </div>
    );
};

TroncSchemeJobRolesForm.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    companyJobRoles: PropTypes.array,
};

TroncSchemeJobRolesForm.defaultProps = {
    companyJobRoles: null,
};

export default TroncSchemeJobRolesForm;
