import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, DrawerTitle } from 'components';
import EditUserForm from './edit-user-form';

const UserEditDrawer = ({ visible, onClose, isLoading, defaultValues }) => {
    return (
        <Drawer
            isOpen={visible}
            isLoading={isLoading}
            onClose={onClose}
            onOutsideClick={onClose}
            headerContent={<DrawerTitle>Edit User</DrawerTitle>}
            ariaLabel="user drawer"
        >
            <EditUserForm defaultValues={defaultValues} onCancel={onClose} />
        </Drawer>
    );
};

UserEditDrawer.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    defaultValues: PropTypes.object.isRequired,
};

UserEditDrawer.defaultProps = {
    visible: false,
};

export default UserEditDrawer;
