import PropTypes from 'prop-types';
import React from 'react';

import styles from './counter.module.scss';

/**
 * Renders a basic counter that takes a label and a value to show
 *
 * @param {object} props component props
 * @param {string} props.className styling class
 * @param {string} props.label label text
 * @param {integer} props.value the counter number
 *
 * @returns {React.Component} Counter component
 */
const Counter = ({ className, label, value }) => (
    <div data-testid="counter-container" className={className}>
        <span data-testid="counter-label" className={styles.label}>{label}</span>
        <span data-testid="counter-value" className={styles.value}>{value}</span>
    </div>
);

Counter.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

Counter.defaultProps = {
    className: "",
    label: "",
    value: "",
};

export default Counter;
