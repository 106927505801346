import React from 'react';

/**
 * Svg icon indicating element can be edited
 *
 * @param {*} props Props for the svg element
 * @returns {React.Component} Edit icon
 */
const Edit = (props) => {
    return (
        <svg viewBox="0 0 24 24" {...props}>
            <path d="M0 14.462v3.04a.5.5 0 00.5.5h3.04a.469.469 0 00.35-.15l10.92-10.91-3.75-3.75L.15
                14.102a.491.491 0 00-.15.36zm17.71-10.42a1 1 0 000-1.41L15.37.292a1
                1 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
            />
        </svg>
    );
};

export default Edit;
