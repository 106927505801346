import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./card.module.scss";

/**
 * Renders Card
 *
 * @param {any} children Any children to render
 * @param {string} className Additional class names
 * @param {boolean} padding Add padding to the card wrapper
 * @param {object} props Any other card properties
 *
 * @returns {React.Component} The Card
 */
const Card = ({ children, className, padding, ...props }) => {
    if (props.onClick !== null) {
        return (
            <button
                type="button"
                className={classNames(styles.actionCard, className)}
                onClick={props.onClick}
                {...props}
            >
                {children}
            </button>
        );
    }

    const classList = classNames({
        [styles.card]: true,
        [styles.padding]: padding,
        [className]: true,
    });

    return (
        <div className={classList} {...props}>{children}</div>
    );
};

Card.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    onClick: PropTypes.func,
    padding: PropTypes.bool,
};

Card.defaultProps = {
    onClick: null,
    children: null,
    className: null,
    padding: true,
};

export default Card;
