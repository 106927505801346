import Permissions from "./permissions";

export default class CompanyCrudPermissions extends Permissions {

    /**
     * @inheritDoc
     */
    register () {
        const viewCompany = this.permissions.includes("view_company");

        this.canView = this.troncmaster || viewCompany;
        this.canCreate = this.troncmaster;
        this.canEdit = this.troncmaster;
        this.canDelete = this.troncmaster;
    }

}
