import React from 'react';
import PropTypes from 'prop-types';
import Step from './step';

import styles from './stepper.module.scss';

/**
 * Renders the stepper component
 *
 * @returns {React.Component} Stepper Component
 */
const Stepper = ({ steps, currentPage }) => {
    return (
        <div className={styles.stepperContainer}>
            {steps.map((step) => (
                <Step key={step.stepNum} step={step} currentPage={currentPage} />
            ))}
        </div>
    );
};

Stepper.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.shape({
        stepNum: PropTypes.number.isRequired,
        stepTitle: PropTypes.string,
        component: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node,
            PropTypes.element,
            PropTypes.func,
        ]),
    })).isRequired,
    currentPage: PropTypes.number.isRequired,
};

export default Stepper;
