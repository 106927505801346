import React from 'react';
import PropTypes from "prop-types";
import { useForm, FormProvider } from "react-hook-form";
import { noop } from 'lodash';
import { Form, FormActions, TextInputRow } from "components/forms";
import FileInputRow from "components/forms/file-input-row";
import { useNotificationContext } from 'state/context';
import { FilesApi } from 'lib/api-endpoints';
import FormContent from 'components/forms/form-container/form-content';

import styles from './document-upload-form.module.scss';

/**
 * Renders the document upload form
 *
 * @returns {React.Component} The document upload form
 */
const DocumentUploadForm = ({ employee, onClose, reloadPage }) => {
    const { openNotification } = useNotificationContext();
    const methods = useForm({
        defaultValues: {
            files: {},
        },
    });

    const { register, handleSubmit, watch } = methods;
    watch();

    /**
     * Saves the files to the selected period
     *
     * @param {object} formData The form data
     *
     * @return {void}
     */
    const onSubmit = async (formData) => {
        await FilesApi.uploadFile({
            type: "personalcompany",
            file: formData.file,
            employee: employee.id,
            description: formData.description,
        }).request.then(() => {
            openNotification({
                message: `Document uploaded successfully`,
                type: "success",
            });
            onClose();
            reloadPage(true);
        });
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <FormContent>
                    <div className={styles.formContent}>
                        <FileInputRow
                            {...register("file")}
                            data-testid={"upload_file"}
                            buttonLabel={"Upload File"}
                        />
                        <TextInputRow
                            {...register("description")}
                            name={`description`}
                            label={`Description`}
                            data-testid={"description"}
                        />
                    </div>
                </FormContent>
                <FormActions onSubmit={handleSubmit(onSubmit)} onCancel={onClose} />
            </Form>
        </FormProvider>
    );
};

DocumentUploadForm.propTypes = {
    employee: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    reloadPage: PropTypes.func,
};

DocumentUploadForm.defaultProps = {
    onClose: noop,
    reloadPage: noop,
};

export default DocumentUploadForm;
