import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { isNil, omitBy } from "lodash";
import { FormRow, FormRowLabel, Select } from "./form-controls";
import ErrorHelpText from "./error-help-text";

/**
 * Renders the selection input row
 *
 * @param {object} props The selection properties
 * @param {string} props.label The label to render
 * @param {array} props.options An array of options to render
 *
 * @return {React.Component} The select input row
 */
const SelectInputRow = forwardRef(({ label, error, options, rowTestId, required, topMargin, ...otherProps }, ref) => {
    const rowProps = omitBy({
        "data-testid": rowTestId,
        topMargin,
    }, isNil);

    return (
        <FormRow {...rowProps}>
            <FormRowLabel label={label} required={required} />
            <Select
                ref={ref}
                required={required}
                error={Boolean(error)}
                options={options}
                {...otherProps}
            />
            { error ? <ErrorHelpText text={error} /> : null }
        </FormRow>
    );
});

SelectInputRow.defaultProps = {
    label: "",
    rowTestId: "",
    error: "",
    topMargin: true,
    required: false,
};

SelectInputRow.propTypes = {
    label: PropTypes.string,
    rowTestId: PropTypes.string,
    error: PropTypes.string,
    options: PropTypes.array.isRequired,
    topMargin: PropTypes.bool,
    required: PropTypes.bool,
};

export default SelectInputRow;
