import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import TaxYearsOptions from "./tax-years-options";
import PeriodOptions from "./period-options";

import styles from "./tax-year-period-options.module.scss";

/**
 * Renders the tax years period options
 *
 * @param {object} props The tax year periods props
 * @param {array} props.years The tax years
 * @param {array} props.periods The payrolls periods
 * @param {array} props.taxProps The tax year input properties
 * @param {array} props.periodProps The period input properties
 *
 * @returns {React.Component} The tax year period selections
 */
const TaxYearPeriodOptions = forwardRef(({ years, periods, taxProps, periodProps }, ref) => (
    <div className={styles.taxPeriods}>
        <TaxYearsOptions ref={ref} years={years} {...taxProps} />
        <PeriodOptions ref={ref} periods={periods} {...periodProps} />
    </div>
));

TaxYearPeriodOptions.propTypes = {
    years: PropTypes.array.isRequired,
    periods: PropTypes.array.isRequired,
    taxProps: PropTypes.object.isRequired,
    periodProps: PropTypes.object.isRequired,
};

export default TaxYearPeriodOptions;
