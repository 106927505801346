import React from 'react';
import PropTypes from 'prop-types';

import styles from './ticket-header-column.module.scss';

/**
 * Wrapper for the information columns for an individual ticket
 *
 * @param {array} config Objects describing the properties/values of the ticket
 * @returns {React.Component} The ticket info columns
 */
const TicketHeaderColumn = ({ config }) => {
    return (
        <div className={styles.ticketInfoCol}>
            {config.map((row) => {
                return (
                    <div key={row.property} className={styles.ticketInfoWrapper}>
                        <span className={styles.ticketProperty}>{row.property}: </span>
                        <span className={styles.ticketValue}>{row.value}</span>
                    </div>
                );
            })}
        </div>
    );
};

TicketHeaderColumn.propTypes = {
    config: PropTypes.arrayOf(PropTypes.shape({
        property: PropTypes.string,
        value: PropTypes.any,
    })),
};

TicketHeaderColumn.defaultProps = {
    config: [],
};

export default TicketHeaderColumn;
