import React from 'react';
import PropTypes from 'prop-types';

/**
 * Bell SVG icon
 *
 * @param {string} fill The colour of the bell
 * @param {object} props Props for the svg element
 *
 * @returns {React.Component} Bell icon
 */
const Bell = ({ fill }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height="25px" width="25px">
        <path
            d="M8 38v-3h4.2V19.7q0-4.2 2.475-7.475Q17.15 8.95 21.2 8.1V6.65q0-1.15.825-1.9T24 4q1.15 0
            1.975.75.825.75.825 1.9V8.1q4.05.85 6.55 4.125t2.5 7.475V35H40v3Zm16-14.75ZM24 44q-1.6 0-2.8-1.175Q20
            41.65 20 40h8q0 1.65-1.175 2.825Q25.65 44 24 44Zm-8.8-9h17.65V19.7q0-3.7-2.55-6.3-2.55-2.6-6.25-2.6t-6.275
            2.6Q15.2 16 15.2 19.7Z"
            fill={fill}
        />
    </svg>
);

Bell.propTypes = {
    fill: PropTypes.string,
};

Bell.defaultProps = {
    fill: "#858585",
};

export default Bell;
