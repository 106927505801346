import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isFunction, map, noop } from 'lodash';
import FocusTrap from 'focus-trap-react';
import Portal from 'utils/portal';
import { CloseButton, InformationIcon } from 'components';
import { SecondaryButton } from 'components/buttons';

import styles from './snackbar.module.scss';

/**
 * The button used for the fallbackFocus
 *
 * @returns {React.Component} The fallback focus
 */
const fallbackFocus = () => {
    return <button type="button" tabIndex={-1} />;
};

/**
 * Renders Snackbar styled based on the type
 *
 * @param {object} props component props
 * @param {array} props.actions array of snackbar actions
 * @param {object} props.attributes additional container attributes
 * @param {string} props.heading snackbar heading
 * @param {integer} props.id snackbar id
 * @param {string} props.message the snackbar text
 * @param {function} props.onClose close snackbar handler
 * @param {string} props.type snackbar information type
 *
 * @returns {React.Component} SnackBar Component
 */
const SnackBar = ({ actions, attributes, currentSnackbar, heading, id, message, onClose, type }) => {
    const classList = classNames([
        styles.confirmationContainer,
        styles[type],
    ]);

    const iconClass = classNames([
        styles.typeIcon,
        styles.iconClass,
        styles[type],
    ]);

    const confirmationClass = classNames([
        styles.confirmationInner,
        styles[type],
    ]);

    /**
     * Returns an array of action buttons to be shown on the snackbar
     *
     * @returns {array} returns an array of action button elements
     */
    const renderActions = () => {
        return map(actions, ({ id: actionId, text, handler, keepSnackbar }) => {

            /**
             * Runs onclick handler and then closes the snackbar if closeSnackbar is provided
             *
             * @param {Event} event click event object
             */
            const handleActionClick = (event) => {
                if (isFunction(handler)) {
                    handler();
                }

                if (!keepSnackbar) {
                    onClose(event);
                }
            };

            return (
                <SecondaryButton
                    className={styles.action}
                    onClick={handleActionClick}
                    data-testid={`snackbar-action-${text}`}
                    key={`snackbar-action-${actionId}`}
                >
                    { text }
                </SecondaryButton>
            );
        });
    };

    if (!currentSnackbar) {
        return null;
    }

    return (
        <FocusTrap
            active={currentSnackbar}
            focusTrapOptions={{fallbackFocus, initialFocus: false, delayInitialFocus: true}}
        >
            <Portal>
                <div key={`snackbar-${id}`} className={classList} {...attributes} role="alert" data-testid="snackbar">
                    <div className={confirmationClass}>
                        <InformationIcon type={type} className={iconClass} />
                        <div className={styles.message} data-testid="heading-message">
                            { heading && (<h3>{heading}</h3>) }
                            {message}
                        </div>
                        <div className={styles.actions}>
                            { renderActions() }
                            <CloseButton
                                tabIndex={0}
                                onClick={onClose}
                                data-testid="close-snackbar"
                                iconClass={iconClass}
                            />
                        </div>
                    </div>
                </div>
            </Portal>
        </FocusTrap>
    );
};

SnackBar.propTypes = {
    actions: PropTypes.array,
    attributes: PropTypes.object,
    currentSnackbar: PropTypes.bool,
    heading: PropTypes.string,
    id: PropTypes.number.isRequired,
    message: PropTypes.string,
    onClose: PropTypes.func,
    type: PropTypes.oneOf([
        "warn",
        "success",
        "error",
        "info",
        "none",
    ]),
};

SnackBar.defaultProps = {
    actions: [],
    attributes: {},
    currentSnackbar: false,
    heading: null,
    message: "",
    onClose: noop,
    type: "none",
};

export default SnackBar;
