import { api } from "lib/api";

export default class TaxYearsApi {

    /**
     * Gets a list payrolls tax years the user has permission to see request promise
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getTaxYears = () => api({ url: "/tax_years" });

}
