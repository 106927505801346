import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { debounce, isEmpty, noop, isObject } from 'lodash';
import Counter from 'components/counter/counter';
import SearchInput from 'components/search-input/search-input';
import Pagination from 'components/pagination/pagination';

import styles from './table-input.module.scss';

/**
 * Renders table input based on the given config
 *
 * @param {object} props component props
 * @param {React.Component} props.config additional column config
 * @param {string} props.countLabel label for the selected counter
 * @param {array} props.data the data to render
 * @param {array} props.headers table headers
 * @param {boolean} props.searchable should the table have a search bar
 * @param {array} props.selected selected elements
 * @param {boolean} props.showSelectedCount shows selected counter in header
 * @param {function} props.onSearch function called when search term changes
 * @param {string} props.tableClassName custom table class
 * @param {string} props.headerClassName custom table class for headers
 * @param {string} props.rowClassName custom table class for rows
 * @param {string} props.gridTemplateCols custom grid width values
 * @param {string} props.bodyScroll allows body to scroll
 *
 * @returns {React.Component} TableInput form input
 */
const TableInput = ({
    config: Config,
    countLabel,
    data,
    headers,
    onSearch,
    searchable,
    selected,
    selectedCount,
    showSelectedCount,
    tableClassName,
    pagination,
    headerClassName,
    rowClassName,
    gridTemplateCols,
    bodyScroll,
}) => {

    /**
     * Debounces prop onSearch call when typing in the search
     */
    const callOnSearch = debounce((value) => {
        onSearch(value);
    }, 300);

    /**
     * Search Box onChange Handler
     *
     * @param {object} e onChange event
     */
    const handleSearch = (e) => {
        callOnSearch(e?.target?.value);
    };

    /**
     * Returns search box if searchable is true
     *
     * @returns {HTMLElement} null or search input element
     */
    const renderSearchBox = () => {
        if (!searchable) {
            return null;
        }

        return (
            <div data-testid="table-input-search" className={styles.search}>
                <SearchInput onChange={handleSearch} />
            </div>
        );
    };

    /**
     * Returns Counter with given label and count props if showSelectedCount is true
     *
     * @returns {React.Element} null or Counter component
     */
    const renderSelectedCount = () => {
        if (!showSelectedCount) {
            return null;
        }

        return <Counter className={styles.counter} label={countLabel} value={selectedCount} />;
    };

    /**
     * Returns provided config component with required props if config is provided
     *
     * @param {integer} id the rowData id
     *
     * @returns {React.Element} null or given config component as a rendered element
     */
    const renderAdditionalRowConfig = (id) => {
        if (!Config) {
            return null;
        }

        return (
            <Config
                key={`config-${id}`}
                value={id}
                cellClass={styles.dataCell}
                headers={headers}
            />
        );
    };

    /**
     * Gets array of header elements based on headers prop
     *
     * @returns {array} table header cells
     */
    const getHeaders = () => headers.map((header) => {
        const headerName = isObject(header) ? header.name : header;

        return (
            <div
                data-testid="table-input-header-cell"
                className={styles.headerCell}
                key={`header-cell-${headerName}`}
            >
                {headerName}
            </div>
        );
    });

    /**
     * Gets table rows with config if one is provided
     *
     * @returns {array} table row cells
     */
    const getRows = () => {
        return data.map((rowData) => {

            let content = headers.map((header) => {
                const headerName = isObject(header) ? header.system_name : header;

                if (rowData[headerName]) {
                    return (
                        <div
                            data-testid="table-input-data-cell"
                            key={`data-cell-${rowData.id}-${rowData[headerName]}`}
                            className={styles.dataCell}
                        >
                            {rowData[headerName]}
                        </div>
                    );
                }

                return null;
            });

            return [
                ...content,
                renderAdditionalRowConfig(rowData.id),
            ];
        });
    };

    return (
        <div className={classNames(styles.container, tableClassName)}>
            <div className={styles.header}>
                {renderSearchBox()}
                {renderSelectedCount()}
                <div
                    className={classNames(styles.table, headerClassName)}
                    style={{ gridTemplateColumns: gridTemplateCols }}
                >
                    {getHeaders()}
                </div>
            </div>
            <div className={classNames({[styles.scrollable]: bodyScroll})}>
                <div
                    className={classNames(styles.table, rowClassName)}
                    style={{ gridTemplateColumns: gridTemplateCols }}
                >
                    {getRows()}
                </div>
            </div>
            {!isEmpty(pagination)
                ? (
                    <Pagination
                        currentPage={pagination?.currentPage}
                        totalPages={pagination?.totalPages}
                        onPageChange={pagination?.onChange}
                    />
                ) : null
            }
        </div>
    );
};

TableInput.propTypes = {
    config: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
        PropTypes.object,
        PropTypes.oneOf([null]),
    ]),
    countLabel: PropTypes.string,
    data: PropTypes.array.isRequired,
    headers: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string).isRequired,
        PropTypes.arrayOf(PropTypes.shape({
            "system_name": PropTypes.string.isRequired,
            "name": PropTypes.string.isRequired,
        }).isRequired).isRequired,
    ]).isRequired,
    onSearch: PropTypes.func,
    searchable: PropTypes.bool,
    selected: PropTypes.object,
    selectedCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    showSelectedCount: PropTypes.bool,
    tableClassName: PropTypes.string,
    pagination: PropTypes.object,
    headerClassName: PropTypes.string,
    rowClassName: PropTypes.string,
    gridTemplateCols: PropTypes.string,
    bodyScroll: PropTypes.bool,
};

TableInput.defaultProps = {
    config: null,
    countLabel: "",
    onSearch: noop,
    searchable: false,
    selected: {},
    selectedCount: 0,
    showSelectedCount: false,
    tableClassName: "",
    pagination: {},
    headerClassName: "",
    rowClassName: "",
    gridTemplateCols: "",
    bodyScroll: true,
};

export default TableInput;
