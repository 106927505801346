import { createSlice } from "@reduxjs/toolkit";
import { TicketsApi } from "lib/api-endpoints";

const initialState = {
    ticket: {},
    isLoading: false,
};

const ticketsSlice = createSlice({
    name: "tickets",
    reducers: {
        setTicket: (state, { payload }) => {
            state.ticket = payload;
        },
        setLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
    },
    initialState,
});

export { ticketsSlice };
export const { setTicket, setLoading } = ticketsSlice.actions;

export default ticketsSlice.reducer;

/**
 * Gets the requested ticket
 *
 * @param {number} id The id of the ticket
 *
 * @returns {Promise} The ticket dispatcher promise
 */
export const getTicket = (id) => async (dispatch) => {
    dispatch(setLoading(true));
    const { data } = await TicketsApi.getTicket(id).request;
    dispatch(setTicket(data));
    dispatch(setLoading(false));
};

/**
 * Clears the current ticket state
 *
 * @returns {Promise} The ticket dispatcher promise
 */
export const clearTicket = () => (dispatch) => {
    dispatch(setTicket({}));
};
