import Permissions from "./permissions";

export default class PayrollBatchPermissions extends Permissions {

    /**
     * @inheritDoc
     */
    register () {
        this.canApprove = this.permissions.includes("dual_approve");
    }

}
