import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { find, groupBy } from 'lodash';
import { useNotificationContext } from 'state/context';
import { UsersApi, useUserDetails } from 'lib/api-endpoints';
import { LayoutContainer, LoadingMask, Page, PrimaryButton } from 'components';
import UserEditDrawer from './user-edit-drawer';
import UserDetailsContent from './user-details-content';

import styles from './user-details.module.scss';

/**
 * The full name of the user
 *
 * @param {object} userDetails The user information
 *
 * @returns {string} The users full name
 */
const getFullName = (userDetails) => {
    if (userDetails?.forename || userDetails?.surname) {
        return `${userDetails.forename} ${userDetails.surname}`;
    }

    return "";
};

/**
 * Displays the user details including payrolls grouped by frequency
 *
 * @returns {React.Component} Returns the user details page
 */
const UserDetails = () => {
    const { id } = useParams();
    const { payrolls } = useSelector((state) => state.app);
    const { data: userDetails, isLoaded } = useUserDetails(id);
    const [drawerRendered, setDrawerRendered] = useState(false);
    const [disabling, setDisabling] = useState(false);
    const navigate = useNavigate();
    const { openNotification } = useNotificationContext();

    /**
     * Payrolls keyed by payroll frequency
     *
     * @returns {object} Payrolls + permission info for the user
     */
    const groupedPayrolls = useCallback(() => {
        if (userDetails?.permissions) {
            return groupBy(userDetails.permissions, (userPerms) => {
                const matchedPayroll = find(payrolls, (p) => {
                    return p.id === userPerms.payroll_id;
                });

                return matchedPayroll?.frequency;
            });
        }

        return {};
    }, [userDetails.permissions, payrolls]);

    const handleDisableUser = async () => {
        setDisabling(true);
        await UsersApi.disableUser(id).request;
        setDisabling(false);
        navigate("/user-management");
        openNotification({
            message: "User disabled",
            type: "success",
        });
    };

    return (
        <Page
            title={getFullName(userDetails)}
            titleAction={<PrimaryButton onClick={handleDisableUser} disabled={disabling}>Disable User</PrimaryButton>}
        >
            <LayoutContainer className={styles.containerPadding}>
                <LoadingMask loading={!(isLoaded && !disabling)} containerClasses={styles.loadingMask}>
                    <UserDetailsContent
                        userDetails={userDetails}
                        groupedPayrolls={groupedPayrolls()}
                        setDrawerRendered={setDrawerRendered}
                        getFullName={getFullName}
                    />
                </LoadingMask>
                <UserEditDrawer
                    visible={drawerRendered}
                    onClose={() => setDrawerRendered(false)}
                    isLoading={!isLoaded}
                    defaultValues={userDetails}
                />
            </LayoutContainer>
        </Page>
    );
};

export default UserDetails;
