import React from "react";
import FilesCompanyList from "pages/files/components/files-company-list";
import FileNavigationPage from "./components/file-navigation-page";

/**
 * Renders the files
 *
 * @return {React.Component} Renders the Companies page
 */
const Companies = () => (
    <FileNavigationPage title="Files">
        <FilesCompanyList />
    </FileNavigationPage>
);

export default Companies;
