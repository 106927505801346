import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { noop, filter } from 'lodash';
import { useParams } from "react-router-dom";
import { useForm, useFormState } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getTicket } from "state/tickets-slice";
import { TicketsApi } from "lib/api-endpoints";
import { Form } from 'components/forms';
import TertiaryButton from 'components/buttons/tertiary-button';
import { LoadingSpinner } from 'components';
import AttachmentList from './attachment-list';
import AttachmentInput from './attachment-input';

import styles from "./message-response.module.scss";

/**
 * Renders the message response section
 *
 * @param {object} props The form properties
 *
 * @return {React.Component} The form component
 */
const MessageResponse = ({ setBodyToEnd, expandResponse, setExpandResponse }) => {
    const { register, handleSubmit, control, reset } = useForm({ mode: "onChange" });
    const { isValid } = useFormState({ control });
    const { id } = useParams();
    const dispatch = useDispatch();
    const [isLoaded, setIsLoaded] = useState(true);
    const [fileList, setFileList] = useState([]);

    const inputClasses = classNames(styles.responseInput, {
        [styles.focusInput]: expandResponse,
    });

    /**
     * The submit handler for the message response box
     *
     * @param {Object} data The data to be submitted from the form
     */
    const onSubmit = (data) => {
        setIsLoaded(false);
        const payload = {
            ...data,
            attachments: fileList,
        };

        const { request } = TicketsApi.addComment(id, payload);

        request.then(async () => {
            await dispatch(getTicket(id));
            setIsLoaded(true);
            reset();
        }).catch((error) => {
            // toast informing user
            setIsLoaded(true);
        }).finally(() => {
            setFileList([]);
        });
    };

    const handleAttachmentRemove = (file) => {
        const filteredFiles = filter(fileList, (f) => f.name !== file.name);
        setFileList(filteredFiles);
    };

    return (
        <div className={styles.formBottomContainer}>
            <Form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                <div className={styles.messageResponseContainer}>
                    <div className={styles.messageContainer}>
                        <textarea
                            {...register("message", { required: true })}
                            required={true}
                            placeholder="Add a response"
                            className={inputClasses}
                            disabled={!isLoaded}
                            onFocus={() => setExpandResponse(true)}
                            onBlur={() => setExpandResponse(false)}
                            onTransitionEnd={setBodyToEnd}
                        />
                    </div>
                    <div className={styles.attachmentContainer}>
                        <AttachmentList fileList={fileList} onRemove={(file) => handleAttachmentRemove(file)} />
                        <div className={styles.buttonGroup}>
                            {!isLoaded && <LoadingSpinner className={styles.loadingSpinner} />}
                            <TertiaryButton
                                type="submit"
                                className={styles.replyButton}
                                disabled={(!isLoaded || !isValid)}
                            >
                                Reply
                            </TertiaryButton>
                            <AttachmentInput onChange={(files) => setFileList(files)} />
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
};

MessageResponse.propTypes = {
    setBodyToEnd: PropTypes.func,
    expandResponse: PropTypes.bool,
    setExpandResponse: PropTypes.func,
};

MessageResponse.defaultProps = {
    setBodyToEnd: noop,
    expandResponse: false,
    setExpandResponse: noop,
};

export default MessageResponse;
