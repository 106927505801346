import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class Portal extends React.Component {

    static propTypes = {
        children: PropTypes.any,
        target: PropTypes.any,
    };

    static defaultProps = {
        children: null,
        target: document.body,
    };

    /**
     * Renders the toast areas to the DOM
     *
     * @return {ReactElement} The toast areas
     */
    render () {
        return ReactDOM.createPortal(
            this.props.children,
            this.props.target,
        );
    }

}

export default Portal;
