import { api } from "lib/api";

export default class TroncSchemesApi {

    /**
     * Gets the tronc schemes list axios promise
     *
     * @param {object} params The query parameters
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getTroncSchemes = (params) => api({ url: "/tronc_schemes", config: { params } });

    /**
     * Gets the tronc scheme axios promise
     *
     * @param {string|number} id The tronc scheme id
     * @param {object} params The query parameters
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getTroncScheme = (id, params) => api({ url: `/tronc_schemes/${id}`, config: { params } });

    /**
     * Gets the tronc schemes list axios promise
     *
     * @param {object} params The query parameters
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getTroncSchemesPeriods = (params) => api({
        url: `/tronc_periods`,
        config: { params },
    });

    /**
     * Gets tronc scheme frequencies for a given site
     *
     * @param {string|number} siteId site id
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getTroncSchemeFrequencies = (siteId) => api({ url: `/sites/${siteId}/tronc_scheme_frequencies`});

    /**
     * Gets tronc scheme paydate options
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getTroncPaydates = () => api({ url: '/troncs_paydate_options' });

    /**
     * Post a tronc scheme
     *
     * @param {object} data tronc scheme payload
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static postTroncScheme = (data) => api({ type: "post", url: "/tronc_schemes", data });

    /**
     * Patch a tronc scheme
     *
     * @param {number}id the tronc scheme id
     * @param {object} data tronc scheme payload
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static patchTroncScheme = (id, data) => api({ type: "patch", url: `/tronc_schemes/${id}`, data });

    /**
     * Sets the scheme training request promise
     *
     * @param {int} id The scheme ids
     * @param {object} data tronc scheme payload
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
    */
    static setSchemeTraining = (id, data) => api({
        url: `/tronc_schemes/${id}/training_points`,
        type: 'put',
        data,
    });

    /**
     * Get an employees tronc points
     *
     * @param {number} id the tronc scheme id
     * @param {number} employeeId the employee id
     * @param {object} data tronc scheme payload
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getEmployeeTroncPoints = (id, employeeId) => api({
        type: "get",
        url: `/tronc_schemes/${id}/employees/${employeeId}/tronc_points`,
    });

    /**
     * Patch an employees tronc points
     *
     * @param {number} id the tronc scheme id
     * @param {number} employeeId the employee id
     * @param {object} data tronc scheme payload
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
    */
    static patchEmployeeTroncPoints = (id, employeeId, data) => api({
        type: "patch",
        url: `/tronc_schemes/${id}/employees/${employeeId}/tronc_points`,
        data,
    });

    /**
     * Sets the scheme length of service request promise
     *
     * @param {int} id The scheme ids
     * @param {object} data tronc scheme payload
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
    */
    static addLengthOfServicePoints = (id, data) => api({
        url: `/tronc_schemes/${id}/length_of_service_points`,
        type: "put",
        data,
    });

}
