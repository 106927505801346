import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ErrorHelpText from './error-help-text';
import { Checkbox, FormRow, FormRowLabel } from './form-controls';

import styles from './checkbox-input-row.module.scss';

/**
 * Checkbox Input Row that renders a label and checkbox text if provided
 *
 * @param {object} props component props
 * @param {string} props.error error message
 * @param {string} props.label row label
 * @param {string} props.text checkbox text
 * @param {object} props.otherProps additional checkbox props
 * @param {object} ref forwarded checkbox ref
 */
const CheckBoxInputRow = forwardRef(({ error, label, text, ...otherProps }, ref) => {
    return (
        <FormRow>
            { label ? <FormRowLabel data-testid="checkbox-row-label" label={label} /> : null }
            <div className={styles.checkboxContainer}>
                <Checkbox ref={ref} className={styles.checkbox} error={Boolean(error)} {...otherProps} />
                { text ? <div data-testid="checkbox-text">{ text }</div> : null }
                { error ? <ErrorHelpText text={error} /> : null }
            </div>
        </FormRow>
    );
});

CheckBoxInputRow.defaultProps = {
    error: "",
    label: null,
    text: null,
};

CheckBoxInputRow.propTypes = {
    error: PropTypes.string,
    label: PropTypes.string,
    text: PropTypes.string,
};

export default CheckBoxInputRow;
