import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { FloatingMenu } from "components/";

import styles from "./more-actions-button.module.scss";

const defaultDots = () => {
    return (
        <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 4C0.89543 4 -4.82823e-08 3.10457 0 2C4.82823e-08 0.89543 0.895431 -4.82823e-08
            2 0C3.10457 4.82823e-08 4 0.895431 4 2C4 3.10457 3.10457 4 2 4Z" fill="#9CA3AF"
            />
            <path d="M2 10C0.89543 10 -4.82823e-08 9.10457 0 8C4.82823e-08 6.89543 0.895431 6 2 6C3.10457
            6 4 6.89543 4 8C4 9.10457 3.10457 10 2 10Z" fill="#9CA3AF"
            />
            <path d="M2 16C0.89543 16 -4.82823e-08 15.1046 0 14C4.82823e-08 12.8954 0.895431 12 2
            12C3.10457 12 4 12.8954 4 14C4 15.1046 3.10457 16 2 16Z" fill="#9CA3AF"
            />
        </svg>

    );
};

const boldDots = () => {
    return (
        <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill="#666666"
                d="M2 16C1.45 16 0.979334 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14C0 13.45
                0.196 12.979 0.588 12.587C0.979334 12.1957 1.45 12 2 12C2.55 12 3.021 12.1957 3.413
                12.587C3.80433 12.979 4 13.45 4 14C4 14.55 3.80433 15.021 3.413 15.413C3.021 15.8043
                2.55 16 2 16ZM2 10C1.45 10 0.979334 9.804 0.588 9.412C0.196 9.02067 0 8.55 0 8C0 7.45
                0.196 6.979 0.588 6.587C0.979334 6.19567 1.45 6 2 6C2.55 6 3.021 6.19567 3.413
                6.587C3.80433 6.979 4 7.45 4 8C4 8.55 3.80433 9.02067 3.413 9.412C3.021 9.804 2.55 10
                2 10ZM2 4C1.45 4 0.979334 3.804 0.588 3.412C0.196 3.02067 0 2.55 0 2C0 1.45 0.196
                0.979333 0.588 0.588C0.979334 0.196 1.45 0 2 0C2.55 0 3.021 0.196 3.413 0.588C3.80433
                0.979333 4 1.45 4 2C4 2.55 3.80433 3.02067 3.413 3.412C3.021 3.804 2.55 4 2 4Z"
            />
        </svg>
    );
};

const MoreActionsButton = ({ actions, className, boldBorderless, horizontalDots, alignRight }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleClick = () => {
        setMenuOpen(true);
    };

    const handleClose = () => {
        setMenuOpen(false);
    };

    /**
     * Checks if there is at least one action to render
     *
     * @return {bool} Is there an action
     */
    const hasPermissionsToRender = !isEmpty(actions.filter((action) => action?.hasPermission ?? true));

    return (
        <div className={classNames(styles.wrapper, {
            [styles.alignRight]: alignRight,
            [styles.boldBorderlessWrapper]: boldBorderless,
        })}
        >
            {hasPermissionsToRender && (
                <button
                    type="button"
                    onClick={handleClick}
                    className={classNames([styles.button, className, {
                        [styles.boldBorderless]: boldBorderless,
                        [styles.horizontalDots]: horizontalDots,
                    }])}
                    ref={anchorRef}
                    data-testid="more-actions-button"
                    aria-label="more actions button"
                >
                    {boldBorderless ? boldDots() : defaultDots()}
                </button>
            )}
            {(menuOpen)
                && (
                    <FloatingMenu
                        actions={actions}
                        anchorRef={anchorRef}
                        onAttemptClose={handleClose}
                    />
                )
            }
        </div>
    );
};

MoreActionsButton.propTypes = {
    className: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        handler: PropTypes.func.isRequired,
    })).isRequired,
    boldBorderless: PropTypes.bool,
    horizontalDots: PropTypes.bool,
    alignRight: PropTypes.bool,
};

MoreActionsButton.defaultProps = {
    className: "",
    boldBorderless: false,
    horizontalDots: false,
    alignRight: false,
};

export default MoreActionsButton;
