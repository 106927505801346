import React from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton } from 'components';

import styles from './user-details.module.scss';

/**
 * The user details page content
 *
 * @param {object} userDetails The user information
 * @param {array} groupedPayrolls Payrolls keyed by frequency
 * @param {func} setDrawerRendered Function to open the edit drawer
 * @param {func} getFullName Memoised full name of the user
 *
 * @returns {React.Component} The user details page content
 */
const UserDetailsContent = ({ userDetails, groupedPayrolls, setDrawerRendered, getFullName }) => {
    return (
        <div className={styles.userDetails}>
            <div className={styles.detailRow}>
                <div>Name</div>
                <div>{getFullName(userDetails)}</div>
            </div>
            <div className={styles.detailRow}>
                <div>Email</div>
                <div>{userDetails?.email ? userDetails.email : '-'}</div>
            </div>
            <div className={styles.detailRow}>
                <div>Mobile No.</div>
                <div>{userDetails?.mobile_number ? userDetails.mobile_number : '-'}</div>
            </div>
            <div className={styles.payrollCategories}>
                {Object.keys(groupedPayrolls).map((key) => (
                    <div key={key} className={styles.frequencyGroup}>
                        <div>{key}</div>
                        <div className={styles.frequencyPayrollsContainer}>
                            {groupedPayrolls[key].map(({ payroll_id: payrollId, payroll_name: payrollName }) => {
                                return (
                                    <div
                                        key={payrollId}
                                        className={styles.payrollBadge}
                                    >
                                        {payrollName}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>
            <PrimaryButton className={styles.editUserButton} onClick={() => setDrawerRendered(true)}>
                Edit User
            </PrimaryButton>
        </div>);
};

UserDetailsContent.propTypes = {
    userDetails: PropTypes.object.isRequired,
    groupedPayrolls: PropTypes.object.isRequired,
    setDrawerRendered: PropTypes.func.isRequired,
    getFullName: PropTypes.func.isRequired,
};

export default UserDetailsContent;
