import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { groupBy, upperFirst } from "lodash";
import moment from 'moment';
import ProfileSection from "./profile-section";
import TabHeader from "./tab-header";
import { Select } from "components/forms/form-controls";
import { Table, SecondaryButton } from "components/";
import { EmployeesApi, FilesApi } from "lib/api-endpoints";
import { handleFile } from "lib/downloaders/stream-file-handler";
import { useSnackbarContext, useNotificationContext } from 'state/context';
import DocumentUploadDrawer from "components/document-upload/document-upload-drawer";

import styles from "./document-tab.module.scss";

const initialState = {
    docTypes: {
        all: [],
        personal: [],
        company: [],
    },
    selectedType: 'all',
    isLoaded: false,
};

/**
 * Handles the deletion of a file
 *
 * @param {object} file the file
 * @param {func} setReload sets the reload
 * @param {func} openNotification opens the notification
 *
 * @returns {void}
 */
const handleFileDeletion = async (file, setReload, openNotification) => {
    await FilesApi.deleteFile(file).request;

    openNotification({
        message: `Document deleted successfully`,
        type: "success",
    });

    setReload(true);
};

/**
 * Handles the downloading of files
 *
 * @param {object} file the file
 * @param {func} openNotification opens the notification
 *
 * @returns {void}
 */
const handleFileDownload = async (file, openNotification) => {
    await handleFile(FilesApi.downloadUrl(file));

    openNotification({
        message: `Document downloaded successfully`,
        type: "success",
    });
};

/**
 * Adds a snackbar to warn when downloading docs
 *
 * @param {object} file the file to download
 * @param {func} openNotification opens the notification
 * @param {func} openSnackbar opens the snackbar
 */
const handleDownloadSnackbar = (file, openNotification, openSnackbar) => {
    openSnackbar({
        message: `This file may contain sensitive information. Because
        this file may contain personal data about this employee,
        you should keep it secure and take precautions when storing,
        sharing or uploading it to any other services.`,
        actions: [
            { text: 'Yes', handler: () => handleFileDownload(file, openNotification), id: 1 },
            { text: 'No', id: 2 },
        ],
        type: "warn",
    });
};

/**
 * Gets the formatted document list
 *
 * @param {array} selectedDocuments An array of documents to format
 * @param {func} setReload sets the reload
 * @param {func} openNotification opens the notification
 * @param {func} openSnackbar opens the snackbar
 *
 * @returns {array} A list of document details formatted for table display
 */
const getFormattedDocuments = (selectedDocuments, setReload, openNotification, openSnackbar) => (
    selectedDocuments.map((file) => {
        const { id, filename, datetime_added: dateAdded, description } = file;

        return {
            id,
            description,
            filename,
            dateCreated: moment(dateAdded).format('DD MMM YYYY HH:mm'),
            actions: {
                component: (
                    <div className={styles.buttonContainer}>
                        <SecondaryButton onClick={() => handleDownloadSnackbar(file, openNotification, openSnackbar)}>
                            Download
                        </SecondaryButton>
                        <SecondaryButton
                            className={styles.button}
                            onClick={() => openSnackbar({
                                type: "warn",
                                message: `Are you sure you wish to delete this document? Once deleted,
                                    the document will not be recoverable.`,
                                actions: [
                                    {
                                        id: 1,
                                        text: 'Yes',
                                        handler: () => handleFileDeletion(
                                            file,
                                            setReload,
                                            openNotification
                                        ),
                                    },
                                    { id: 2, text: 'No' },
                                ],
                            })}
                        >
                            Delete
                        </SecondaryButton>
                    </div>
                ),
            },
        };
    })
);

const headings = {
    filename: "Name",
    description: "Description",
    dateCreated: "Date/Time",
    actions: "",
};

/**
 * A component representing the DocumentTab content
 *
 * @param {object} props component props
 *
 * @returns {React.Component} DocumentTab component
 */
const DocumentTab = ({ employee }) => {
    const [documents, setDocuments] = useState(initialState);
    const [reload, setReload] = useState(false);
    const { selectedType, docTypes, isLoaded } = documents;
    const { openNotification } = useNotificationContext();
    const { openSnackbar } = useSnackbarContext();
    const reloadPage = (reload === true);

    useEffect(() => {
        const getDocuments = async () => {
            const { data } = await EmployeesApi.getDocuments(employee.id, { "generate_access_token": true }).request;

            const {
                personal = [],
                personalcompany = [],
                company = [],
            } = groupBy(data, 'type');

            const personalAndPersonalCompany = [...personal, ...personalcompany];

            setDocuments({
                ...initialState,
                docTypes: {
                    all: [...personalAndPersonalCompany, ...company],
                    personal: personalAndPersonalCompany,
                    company,
                },
                isLoaded: true,
            });
            setReload(false);
        };

        if (employee?.id) {
            getDocuments();
        }
    }, [employee, reloadPage]);

    const selectedDocuments = docTypes[selectedType] ?? [];

    const options = Object.keys(docTypes).map((type) => (
        {
            value: type,
            display: upperFirst(type),
        }
    ));

    return (
        <>
            <TabHeader title="Documents">
                <Select
                    data-testid="document-options"
                    aria-label="Document options"
                    value={selectedType}
                    onChange={(event) => {
                        setDocuments({
                            ...documents,
                            selectedType: event.target.value,
                        });
                    }}
                    options={options}
                />
                <DocumentUploadDrawer employee={employee} reloadPage={setReload} />
            </TabHeader>
            <ProfileSection>
                <Table
                    data-testid="employee-documents"
                    noDataText="No Documents"
                    data={getFormattedDocuments(
                        selectedDocuments,
                        setReload,
                        openNotification,
                        openSnackbar
                    )}
                    headings={headings}
                    dataLoaded={isLoaded}
                    skeletonConfig={{
                        rowCount: 6,
                        rowConfig: [
                            { key: 'employee-documents' },
                        ],
                    }}
                />
            </ProfileSection>
        </>
    );
};

DocumentTab.propTypes = {
    employee: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }),
};

DocumentTab.defaultProps = {
    employee: {},
};

export default DocumentTab;
