import React from "react";

const Employees = (props) => (
    <svg
        width="21"
        height="21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.281 13.531a4.375 4.375 0 0 1 3.094-1.281h7a4.375 4.375 0 0 1 4.375
                4.375v1.75a.875.875 0 0 1-1.75 0v-1.75A2.625 2.625 0 0 0 11.375 14h-7a2.625
                2.625 0 0 0-2.625 2.625v1.75a.875.875 0 0 1-1.75 0v-1.75c0-1.16.46-2.273
                1.281-3.094ZM7.875 3.5a2.625 2.625 0 1 0 0 5.25 2.625 2.625 0 0 0 0-5.25ZM3.5
                6.125a4.375 4.375 0 1 1 8.75 0 4.375 4.375 0 0 1-8.75 0ZM16.653 13.02a.875.875 0 0 1
                1.066-.628A4.376 4.376 0 0 1 21 16.623v1.751a.875.875 0 0 1-1.75 0v-1.75a2.626 2.626
                0 0 0-1.969-2.539.875.875 0 0 1-.628-1.066ZM13.152 2.522a.875.875 0 0 1 1.065-.63
                4.375 4.375 0 0 1 0 8.476.875.875 0 0 1-.434-1.696 2.625 2.625 0 0 0 0-5.086.875.875 0
                0 1-.63-1.064Z"
            fill="#fff"
        />
    </svg>

);

export default Employees;
