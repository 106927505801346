import React, { useState } from "react";
import PropTypes from "prop-types";
import ImportEmployeesDrawer from "./import-employee-drawer";
import EmployeeList from "components/employees/EmployeeList";
import companyPropTypes from "lib/props-schema/company-schema";

/**
 * Renders the employees tab for a selected company
 *
 * @param {object} company The selected company
 * @param {function} setDrawer Handle the drawer state
 * @param {boolean} isDrawerOpen Determines if drawer is open or closed
 * @param {boolean} canImport The current user can import employees
 *
 * @returns {React.Component} Employees Tab
 */
const Employees = ({ company, setDrawer, isDrawerOpen, canImport }) => {
    const [refresh, setRefreshEmployees] = useState(false);

    return (
        <>
            {canImport && (
                <ImportEmployeesDrawer
                    isDrawerOpen={isDrawerOpen}
                    setDrawer={setDrawer}
                    company={company}
                    onComplete={() => setRefreshEmployees(!refresh)}
                />
            )}
            <EmployeeList company={company} refresh={refresh} />
        </>
    );
};

Employees.propTypes = {
    setDrawer: PropTypes.func.isRequired,
    isDrawerOpen: PropTypes.bool.isRequired,
    company: companyPropTypes.isRequired,
    canImport: PropTypes.bool.isRequired,
};

export default Employees;
