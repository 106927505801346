import React from "react";
import PropTypes from "prop-types";
import { NavLink as RouterNavLink } from "react-router-dom";
import classNames from "classnames";

import styles from "./nav-link.module.scss";

const NavLink = ({ icon, to, children, ...props }) => (
    <RouterNavLink
        to={to}
        className={({ isActive }) => classNames({
            [styles.link]: true,
            [styles.active]: isActive,
        })}
        {...props}
    >
        <div className={styles.icon}>
            {icon}
        </div>
        <span>{children}</span>
    </RouterNavLink>
);

NavLink.propTypes = {
    icon: PropTypes.element.isRequired,
    to: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
};

export default NavLink;
