import { noop } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import FormController from './form-controller';

/**
 * Renders multipart form component
 *
 * @returns {React.Component} Multipart Form Component
 */
const MultipartForm = ({
    className,
    footerClassName,
    setShowForm,
    defaultValues,
    onClose,
    customErrors,
    clearCustomErrors,
    setClearCustomErrors,
    allowSuccess,
    setAllowSuccess,
    onSubmit,
    ...otherProps
}) => {
    const [currentFormPage, setCurrentFormPage] = useState(1);
    const methods = useForm({ mode: "onChange", defaultValues });

    const {
        trigger,
        reset,
        setError,
        clearErrors,
        formState: { isSubmitSuccessful, isSubmitting },
    } = methods;

    /**
     * Manually sets errors on form to a user set field, clears all errors including
     * custom when triggered, used for custom error handling
     *
     * @returns {void}
     */
    useEffect(() => {
        customErrors.forEach((err) => setError(err.type, err));

        if (clearCustomErrors) {
            clearErrors();
            reset();
            setClearCustomErrors(false);
        }
    }, [customErrors, setError, clearCustomErrors, clearErrors, setClearCustomErrors, reset]);

    /**
     * Closes and fully resets form when submission successful, optional allowSuccess prop
     * prevents form submitting early during async submit
     *
     * @returns {void}
     */
    useEffect(() => {
        if (isSubmitSuccessful && allowSuccess) {
            setShowForm(false);
            clearErrors();
            reset();
            setAllowSuccess(false);
        }
    }, [isSubmitSuccessful, allowSuccess, setShowForm, reset, isSubmitting, clearErrors, setAllowSuccess]);

    /**
     * Increments current form step if the step passes validation checks
     *
     * @returns {void}
     */
    const nextPage = async () => {
        const pageValid = await trigger();

        if (pageValid) {
            setCurrentFormPage(currentFormPage + 1);
        }
    };

    /**
     * Handles the onSubmit and resets the drawer
     *
     * @param {object} data The form data
     */
    const handleOnSubmit = async (data) => {
        await onSubmit(data);
        setCurrentFormPage(1);
    };

    /**
     * Decrements current form step
     *
     * @returns {void}
     */
    const prevPage = () => setCurrentFormPage(currentFormPage - 1);

    /**
     * Handler for closing and reseting form to empty state
     */
    const handleOnClose = () => {
        reset();
        setShowForm(false);
        setCurrentFormPage(1);
        onClose();
    };

    return (
        <FormProvider {...methods}>
            <FormController
                {...otherProps}
                className={className}
                footerClassName={footerClassName}
                handleOnClose={handleOnClose}
                onSubmit={handleOnSubmit}
                prevPage={prevPage}
                nextPage={nextPage}
                isSubmitting={isSubmitting}
                currentFormPage={currentFormPage}
            />
        </FormProvider>
    );
};

MultipartForm.propTypes = {
    className: PropTypes.string,
    defaultValues: PropTypes.object,
    footerClassName: PropTypes.string,
    config: PropTypes.arrayOf(PropTypes.shape({
        stepNum: PropTypes.number.isRequired,
        stepTitle: PropTypes.string,
        component: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node,
            PropTypes.element,
            PropTypes.func,
        ]),
    })).isRequired,
    formTitle: PropTypes.string,
    showForm: PropTypes.bool.isRequired,
    setShowForm: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    onError: PropTypes.func,
    stepper: PropTypes.bool,
    mode: PropTypes.string,
    customErrors: PropTypes.arrayOf(PropTypes.object),
    clearCustomErrors: PropTypes.bool,
    setClearCustomErrors: PropTypes.func,
    allowSuccess: PropTypes.bool,
    setAllowSuccess: PropTypes.func,
};

MultipartForm.defaultProps = {
    className: "",
    defaultValues: {},
    footerClassName: "",
    formTitle: null,
    onSubmit: noop,
    onError: noop,
    onClose: noop,
    stepper: true,
    mode: "modal",
    customErrors: [],
    clearCustomErrors: false,
    setClearCustomErrors: noop,
    allowSuccess: true,
    setAllowSuccess: noop,
};

export default MultipartForm;
