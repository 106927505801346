import React from 'react';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from "prop-types";
import { noop } from "lodash";
import { getSites } from 'state/company-slice';
import { CompaniesApi } from 'lib/api-endpoints';
import { useNotificationContext } from 'state/context';
import { Drawer, DrawerTitle } from 'components';
import { Form, FormActions } from 'components/forms';
import CompanySiteFormContent from './company-site-form-content';
import FormContent from 'components/forms/form-container/form-content';

/**
 * Renders a drawer to create a new site
 *
 * @param {number} companyId The id of the sites parent company
 * @param {boolean} isOpen Is the form drawer open
 * @param {function} setIsOpen sets the state of the drawer
 * @param {boolean} isLoaded Whether the app sites are loaded
 * @param {function} onCancel function to execute on create site cancellation
 *
 * @returns {React.Component} The create company drawer
 */
const NewSiteDrawer = ({ isOpen, setIsOpen, isLoading, onCancel, companyId }) => {
    const dispatch = useDispatch();
    const { openNotification } = useNotificationContext();
    const methods = useForm({ mode: "onChange" });

    const { handleSubmit, reset } = methods;

    const createSite = ({
        siteName,
        address1,
        address2,
        address3,
        town,
        postcode,
    }) => {
        const siteData = {
            "company_id": companyId,
            name: siteName,
            "address_line_1": address1,
            "address_line_2": address2,
            "address_line_3": address3,
            "address_post_town": town,
            "address_post_code": postcode,
        };

        return CompaniesApi.addSite(siteData);
    };

    /**
     * Submit method to create a site, resets and updates site data when complete
     *
     * @param {object} data The form data
     */
    const onSubmit = (data) => {
        createSite(data)
            .request
            .then(() => {
                openNotification({
                    message: "Site successfully created",
                    type: "success",
                });
                setIsOpen(false);
                reset();
                dispatch(getSites([companyId]));
            });
    };

    if (!isOpen) {
        return null;
    }

    return (
        <Drawer
            isOpen
            isLoading={isLoading}
            onClose={onCancel}
            onOutsideClick={onCancel}
            headerContent={<DrawerTitle>Add a Site</DrawerTitle>}
            padding={false}
            ariaLabel="new site drawer"
        >
            <FormProvider {...methods}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormContent>
                        <CompanySiteFormContent />
                    </FormContent>
                    <FormActions
                        onSubmit={handleSubmit(onSubmit)}
                        submitText="Add Site"
                        onCancel={onCancel}
                    />
                </Form>
            </FormProvider>
        </Drawer>
    );
};

NewSiteDrawer.propTypes = {
    companyId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    onCancel: PropTypes.func,
};

NewSiteDrawer.defaultProps = {
    isOpen: false,
    setIsOpen: noop,
    onCancel: noop,
};

export default NewSiteDrawer;
