import PropTypes from 'prop-types';

export const troncFileProps = {
    "id": PropTypes.number.isRequired,
    "created_at": PropTypes.string.isRequired,
    "description": PropTypes.string.isRequired,
    "file_scanner_scan_status": PropTypes.string.isRequired,
    "file_scanner_scan_time": PropTypes.string.isRequired,
    "file_scanner_service_id": PropTypes.number,
    "filename": PropTypes.string.isRequired,
    "mime_type": PropTypes.string.isRequired,
    "size": PropTypes.number.isRequired,
    "tronc_period_id": PropTypes.number.isRequired,
    "updated_at": PropTypes.string.isRequired,
    "uploader": PropTypes.shape({
        "forename": PropTypes.string,
        "surname": PropTypes.string,
    }),
};

export const troncFilePropTypes = PropTypes.arrayOf(PropTypes.shape(troncFileProps));
export const troncFileDefaultProps = {};
export default troncFilePropTypes;
