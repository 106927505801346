import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { resetCrumbs, setCrumbs, selectCompany } from "state/app-slice";
import useTroncSchemesList from 'lib/api-endpoints/hooks/tronc-schemes-list';
import { Card, CardContent, DateDisplay } from 'components';

import styles from './scheme.module.scss';

/**
 * Formats a given date string and displays it.
 *
 * @param {string} date date string
 *
 * @returns {string|React.Element} dash or DateDisplay element
 */
const getDate = (date) => {
    if (isEmpty(date) || !date) {
        return "-";
    }

    return (
        <div className={styles.subHeading}>
            <DateDisplay data={date} output="DD-MM-YY" />
        </div>
    );
};

/**
 * Renders the company tabs and content
 *
 * @returns {React.Component} The company page
 */
const OverView = () => {
    const dispatch = useDispatch();
    const { app } = useSelector((state) => state);
    const { scheme, id } = useParams();

    /**
     * Finds current company and gets relevant permissions
     *
     * @returns {object} selected company and permissions
     */
    const {...selectedCompany} = useMemo(() => selectCompany(app, id), [app, id]);

    const { schemes } = useTroncSchemesList({
        companyIds: [selectedCompany.id],
        getCurrentPeriod: true,
    });

    /**
     * Resets the breadcrumbs when leaving the company page.
     */
    useEffect(() => {
        if (selectedCompany?.name) {
            dispatch(setCrumbs({
                company: selectedCompany.name,
                end: true,
            }));
        }

        return () => dispatch(resetCrumbs());
    }, [dispatch, selectedCompany?.name]);

    const selectedScheme = schemes.find((singleScheme) => parseInt(singleScheme.id, 10) === parseInt(scheme, 10));

    return (
        <Card padding={false} className={styles.card}>
            <CardContent className={styles.cardContent}>
                <div className={styles.infoBlock}>
                    <div data-testid="submission-date">
                        <h3>Data Submission</h3>
                        {getDate(selectedScheme?.currentPeriod?.submission_date)}
                    </div>
                </div>
                <div className={styles.infoBlockMiddleColumn}>
                    <div data-testid="report-date">
                        <h3>Tronc Report Return</h3>
                        {getDate(selectedScheme?.currentPeriod?.report_return_date)}
                    </div>
                </div>
                <div className={styles.infoBlock}>
                    <div data-testid="pay-date">
                        <h3>Pay Date</h3>
                        {getDate(selectedScheme?.currentPeriod?.actual_pay_date)}
                    </div>
                </div>
                <div className={styles.infoBlock}>
                    <div data-testid="submission-date">
                        <h3>Divisions</h3>
                        <div className={styles.subHeading}>
                            {selectedScheme ? selectedScheme.divisions.length : "-"}
                        </div>
                    </div>
                </div>
                <div className={styles.infoBlockMiddleColumn}>
                    <div data-testid="report-date">
                        <h3>Departments</h3>
                        <div className={styles.subHeading}>-</div>
                    </div>
                </div>
                <div className={styles.infoBlock}>
                    <div data-testid="pay-date">
                        <h3>Job roles</h3>
                        <div className={styles.subHeading}>-</div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default OverView;
