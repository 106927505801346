import PropTypes from 'prop-types';

export const batchPropTypes = PropTypes.shape({
    "id": PropTypes.number,
    "employees_paid": PropTypes.number,
    "gross_pay": PropTypes.string,
    "net_pay": PropTypes.string,
    "pay_date": PropTypes.string,
    "requires_checking": PropTypes.bool,
    "status": PropTypes.string,
    "total_pay": PropTypes.string,
    "version": PropTypes.number,
});

export const batchDefaultProps = {};

export default batchPropTypes;
