import Permissions from "./permissions";

export default class CompanyTroncSchemesPermissions extends Permissions {

    /**
     * @inheritDoc
     */
    register () {
        const { permissions, troncmaster } = this;
        const viewTroncSchemes = troncmaster || permissions.includes("view_tronc_schemes");
        const editTroncSchemes = troncmaster || permissions.includes("edit_tronc_schemes");
        const createTroncSchemes = troncmaster || permissions.includes("create_tronc_schemes");
        const deleteTroncSchemes = troncmaster || permissions.includes("delete_tronc_schemes");

        this.canView = viewTroncSchemes;
        this.canEdit = editTroncSchemes;
        this.canCreate = createTroncSchemes;
        this.canDelete = deleteTroncSchemes;
    }

}
