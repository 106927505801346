import { each } from "lodash";
import { useState, useEffect } from "react";
import { DocumentsApi } from "lib/api-endpoints";

const initialState = {
    data: {},
    isLoaded: false,
};

const paramMap = {
    start: "start_date",
    end: "end_date",
    uploader: "uploader_id",
};

/**
 * Gets a list of documents and returns the data, meta information and loading status
 *
 * @param {string} documentType type of document to get
 * @param {int} page page number
 * @param {boolean} refresh refreshes the data if toggled
 * @param {object} filters request filter params
 *
 * @returns {object} document data and isLoaded
 */
const useDocumentsList = ({
    type,
    page = 1,
    refresh = false,
    filters = {
        start: '',
        end: '',
        uploader: '',
    },
}) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        setState(initialState);

        const params = { page };

        each(paramMap, (paramName, filterName) => {
            if (filters[filterName]) {
                params[paramName] = filters[filterName];
            }
        });

        const { request, abortController } = DocumentsApi.getDocuments(type, params);

        request.then(({
            data,
            last_page: lastPage,
            per_page: perPage,
            current_page: currentPage,
        }) => setState({
            data,
            isLoaded: true,
            meta: {
                lastPage,
                perPage,
                currentPage,
            },
        }));

        return () => abortController?.abort();
    }, [type, refresh, page, filters.start, filters.end, filters.uploader, filters]);

    return state;
};

export default useDocumentsList;
