import { api } from "lib/api";

export default class SecurityApi {

    /**
     * Gets the change password request promise
     *
     * @param {object} data The data for the call
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static changePassword = (data) => api({ type: 'patch', url: `/security/password`, data });

    /**
     * Gets the change username request promise
     *
     * @param {object} data The data for the call
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static changeUsername = (data) => api({ type: 'patch', url: `/security/username`, data });

    /**
     * Gets the change email request promise
     *
     * @param {object} data The data for the call
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static changeEmail = (data) => api({ type: 'patch', url: `/security/email`, data });

    /**
     * Gets the change mobile number request promise
     *
     * @param {object} data The data for the call
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static changeMobileNumber = (data) => api({ type: 'patch', url: `/security/mobile_number`, data });

    /**
     * Gets the security questions request promise
     *
     * @param {object} data The data for the call
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getSecurityQuestions = () => api({ type: 'get', url: `/security/questions` });

    /**
     * Gets the security questions list
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getSecurityQuestionsList = () => api({ type: 'get', url: `/security/questions_list` });

    /**
     * Gets the change security questions request promise
     *
     * @param {object} data The data for the call
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static changeSecurityQuestions = (data) => api({
        type: 'put',
        url: `/security/questions`,
        data,
    });

}
