import React from "react";
import PropTypes from "prop-types";

const ThinArrowDropDown = (props) => (
    <svg data-testid="thin-arrow-dropdown-icon" viewBox="0 0 24 24" {...props} >
        <path d="M16.62 2.99a1.249 1.249 0 00-1.77 0L6.54 11.3a1 1 0 000 1.41
            l8.31 8.31a1.252 1.252 0 101.77-1.77L9.38 12l7.25-7.25a1.2
            46 1.246 0 00-.01-1.76z"
        />
    </svg>
);

ThinArrowDropDown.propTypes = {
    fill: PropTypes.string,
};

ThinArrowDropDown.defaultProps = {
    fill: '#111827',
};

export default ThinArrowDropDown;

