import React from 'react';
import PropTypes from "prop-types";
import { Files } from "components/icons";

import styles from "pages/files/files.module.scss";

/**
 * PayrollName Component renders the payroll name display
 *
 * @param {string} companyName The payroll company name
 * @param {string} name The payroll name
 * @param {boolean} hasManyCompanies If the user belongs to many companies
 *
 * @returns {React.Component} PayrollName Component
 */
const PayrollName = ({ companyName, name, hasManyCompanies }) => {
    const displayName = (hasManyCompanies) ? `${companyName} - ${name}` : name;

    return (
        <>
            <Files />
            <span className={styles.name}>{displayName}</span>
        </>
    );
};

PayrollName.propTypes = {
    companyName: PropTypes.string,
    name: PropTypes.string.isRequired,
    hasManyCompanies: PropTypes.bool.isRequired,
};

PayrollName.defaultProps = {
    companyName: "",
};

export default PayrollName;
