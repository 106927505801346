import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { debounce, noop } from 'lodash';
import { SearchInput } from 'components';
import { DateRangeInputRow, Select } from 'components/forms';

import styles from './documents-filters.module.scss';

/**
 * Renders the Filters for the company documents table
 *
 * @param {object} props component props
 * @param {function} props.onChange filter onChange handler
 * @param {array} props.uploaders array of uploaders for the filter dropdown
 * @param {string|number} props.selectedUploader the value of the selected uploader in the filter
 *
 * @returns {React.Component} DocumentsFilters Component
 */
const DocumentsFilters = ({ onChange, uploaders, selectedUploader }) => {
    const [state, updateState] = useState({
        start: "",
        end: "",
        uploader: "",
        search: "",
    });

    /**
     * Filter change handler - sets a given property with the given value on the state
     *
     * @param {string} property the key of the property
     * @param {any} value the value to set
     */
    const handleOnChange = (property, value) => {
        updateState((s) => ({
            ...s,
            [property]: value,
        }));
    };

    /**
     * Formats uploader data into option data
     *
     * @returns {array} array of uploader options
     */
    const getUploaderOptions = () => uploaders.map(({ label: display, value }) => ({ display, value }));

    /**
     * Updates search state value via a debounced function
     */
    const handleSearchChange = debounce((value) => {
        handleOnChange("search", value);
    }, 200);

    /**
     * Call onChange prop callback after a filter value changes on the state
     */
    useEffect(() => {
        onChange(state);
    }, [state.start, state.end, state.uploader, state.search, onChange, state]);

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <SearchInput onChange={(event) => handleSearchChange("search", event.target.value)} />
            </div>
            <div className={styles.right}>
                <DateRangeInputRow
                    name="date-range"
                    start={{ value: state.start, onChange: (event) => handleOnChange("start", event.target.value)}}
                    end={{ value: state.end, onChange: (event) => handleOnChange("end", event.target.value)}}
                />
                <Select
                    options={getUploaderOptions()}
                    aria-label="uploader options"
                    placeholder="Uploader"
                    selected={selectedUploader}
                    onChange={(event) => handleOnChange("uploader", event.target.value)}
                />
            </div>
        </div>
    );
};

DocumentsFilters.defaultProps = {
    onChange: noop,
    uploaders: [],
    selectedUploader: "",
};

DocumentsFilters.propTypes = {
    onChange: PropTypes.func,
    uploaders: PropTypes.array,
    selectedUploader: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
    ]),
};

export default DocumentsFilters;
