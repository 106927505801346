import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FormRow, FormRowLabel, TextArea } from './form-controls';
import ErrorHelpText from './error-help-text';

/**
 * Renders Text Area Row
 *
 * @param {object} props component props
 * @param {string} props.label row label
 * @param {string} props.error error message
 * @param {object} props.otherProps additional text area props
 * @param {object} ref forwarded text area ref
 *
 * @return {React.Component} TextAreaInputRow Component
 */
const TextAreaInputRow = forwardRef(({ label, error, required, ...otherProps }, ref) => (
    <FormRow>
        <FormRowLabel label={label} required={required} />
        <TextArea ref={ref} error={Boolean(error)} required={required} {...otherProps} />
        { error ? <ErrorHelpText text={error} /> : null }
    </FormRow>
));

TextAreaInputRow.defaultProps = {
    error: "",
    label: null,
    required: false,
};

TextAreaInputRow.propTypes = {
    error: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
};

export default TextAreaInputRow;
