import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from "components/forms";

import styles from './secondary-button.module.scss';

/**
 * Button component with outline styles
 *
 * @param {any} children The content of the button
 * @param {object} props Component properties
 *
 * @returns {React.Component} The secondary button
 */
const SecondaryButton = ({ className, children, ...props }) => {
    return (
        <Button
            data-testid="secondary-button-component"
            className={classNames([styles.secondary, className])}
            {...props}
        >
            {children}
        </Button>
    );
};

SecondaryButton.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
};

SecondaryButton.defaultProps = {
    children: null,
    className: "",
};

export default SecondaryButton;
