import React from 'react';
import { TextInputRow } from 'components/forms';
import { useFormContext, useFieldArray } from 'react-hook-form';

import styles from './tronc-schemes-training-form.module.scss';

/**
 * Renders Tronc schemes training roles form
 *
 * @returns {React.Component} Tronc schemes training roles form Component
 */
const TroncSchemeTrainingInput = () => {
    const { register, getValues, watch } = useFormContext();

    const {
        fields,
    } = useFieldArray({
        name: "schemeTrainingRoles",
    });

    const {
        schemeTrainingRoles,
    } = getValues();

    watch();

    return (
        schemeTrainingRoles.length > 0 ? (
            fields.map((item, index) => {
                return (
                    <div className={styles.titleContainer} key={item.id}>
                        <div className={styles.roleRow}>{item.name}</div>
                        <input
                            type="hidden" value={item.name}
                            {...register(
                                `schemeTrainingRoles.${index}.training_id`,
                                { required: true }
                            )}
                        />
                        <div className={styles.pointsRow}>
                            <TextInputRow
                                className={styles.input}
                                name={`complete_points`}
                                data-testid={"complete_points"}
                                {...register(
                                    `schemeTrainingRoles.${index}.complete_points`,
                                    { required: true }
                                )}
                                defaultValue={item.complete_points}
                            />
                        </div>
                        <div className={styles.pointsRow}>
                            <TextInputRow
                                className={styles.input}
                                name={`incomplete_points`}
                                data-testid={"incomplete_points"}
                                {...register(
                                    `schemeTrainingRoles.${index}.incomplete_points`,
                                    { required: true }
                                )}
                                defaultValue={item.incomplete_points}
                            />
                        </div>
                    </div>
                );
            })) : <div className={styles.skeletonRow} />
    );
};

export default TroncSchemeTrainingInput;
