import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { noop } from 'lodash';
import { DateDisplay, Table } from 'components';
import useUsersList from 'lib/api-endpoints/hooks/users-list';

const headings = {
    name: "Name",
    email: "Email",
    lastlogin: "Last Login",
    actions: "Actions",
};

/**
 * Table showing users filtered from the user api call
 *
 * @param {bool} userActive Indicates if the api call should be for active, inactive or all users
 *
 * @returns {React.Component} The user management table
 */
const UserManagementTable = ({ userActive }) => {
    const params = useParams();
    const [userManagementPage, setUserManagementPage] = useState(1);
    const userData = useUsersList({ page: userManagementPage, active: userActive });
    const { data: userList, isLoaded, meta } = userData;

    const moreActions = [
        {
            label: "",
            handler: () => noop,
        },
    ];

    /**
     * Formats the user data
     *
     * @returns {array} Array of formatted user data for the table
     */
    const getFormattedData = () => {
        const currentSection = params?.tab ?? "all-users";
        return userList && userList.map(({ id, forename, surname, email, last_login: lastlogin }) => ({
            id,
            email,
            name: {
                text: `${forename} ${surname}`,
                link: `/user-management/${currentSection}/user-details/${id}`,
            },
            lastlogin: {
                component: lastlogin ? <DateDisplay data={lastlogin} output="DD-MM-YY" /> : "-",
            },
        }));
    };

    return (
        <Table
            headings={headings}
            data={getFormattedData()}
            data-testid="user-management-table"
            dataLoaded={isLoaded}
            skeletonConfig={{
                rowCount: 5,
                rowConfig: [
                    { key: 'user-management' },
                ],
            }}
            noDataText="No Users"
            actions={moreActions}
            pagination={{
                totalPages: meta?.last_page,
                currentPage: userManagementPage,
                onPageChange: (newPage) => setUserManagementPage(newPage),
            }}
        />

    );
};

UserManagementTable.propTypes = {
    userActive: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
    ]),
};

UserManagementTable.defaultProps = {
    userActive: null,
};

export default UserManagementTable;
