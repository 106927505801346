import PropTypes from 'prop-types';

export const announcementProps = PropTypes.shape({
    "id": PropTypes.number,
    "payrollid": PropTypes.number,
    "deptno": PropTypes.number,
    "dateadded": PropTypes.string,
    "addedby": PropTypes.shape({
        "id": PropTypes.number,
        "forename": PropTypes.string,
        "surname": PropTypes.string,
    }),
    "subject": PropTypes.string,
    "message": PropTypes.string,
    "datestart": PropTypes.string,
    "dateend": PropTypes.string,
    "hideonread": PropTypes.bool,
    "notice_read": PropTypes.array,
    "notice_type": PropTypes.shape({
        "id": PropTypes.number,
        "system_name": PropTypes.string,
        "friendly_name": PropTypes.string,
    }),
});

export const announcementDefaultProps = {};
export default announcementProps;
