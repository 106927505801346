import React from "react";
import TroncSchemesTable from "pages/files/components/tronc-schemes-table";
import FileNavigationPage from "./components/file-navigation-page";

/**
 * Renders the files
 *
 * @return {React.Component} Renders the Files page
 */
const Schemes = () => (
    <FileNavigationPage title="Schemes">
        <TroncSchemesTable />
    </FileNavigationPage>
);

export default Schemes;
