import React from "react";
import PropTypes from "prop-types";
import { FormRow, Button } from "components/forms/form-controls";
import { Form, TextInputRow } from "components/forms";

/**
 * Renders the SMS body content
 *
 * @param {props} props The props
 *
 * @returns {React.Component} The SMS body content
 */
const SMSBodyContent = ({handleSubmit, register}) => {
    return (
        <Form action="" method="post" onSubmit={handleSubmit}>
            <TextInputRow {...register("smscode")} label="Sms code" id="smscode" autoComplete="off" />
            <FormRow>
                <Button type="submit">Login</Button>
            </FormRow>
        </Form>
    );
};

SMSBodyContent.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    register: PropTypes.any.isRequired,
};

export default SMSBodyContent;
