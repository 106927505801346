import React from 'react';
import { useSelector } from "react-redux";
import { isEmpty } from 'lodash';

import styles from "./count-display.module.scss";

/**
 * Renders the count display
 *
 * @param {object} props The component props
 *
 * @return {React.Component} The new count icon
 */
const CountDisplay = (props) => {
    const { updates } = useSelector((state) => state.app);
    const count = isEmpty(updates) ? 0 : updates.length;

    if (!count) {
        return null;
    }

    return (
        <div
            {...props}
            className={styles.count}
        >
            {count}
        </div>
    );
};

export default CountDisplay;
