const CountryCodes = [
    {
        "value": "+93",
        "display": "Afghanistan +93",
    },
    {
        "value": "+355",
        "display": "Albania +355",
    },
    {
        "value": "+213",
        "display": "Algeria +213",
    },
    {
        "value": "+376",
        "display": "Andorra +376",
    },
    {
        "value": "+244",
        "display": "Angola +244",
    },
    {
        "value": "+1268",
        "display": "Antigua & Barbuda +1268",
    },
    {
        "value": "+54",
        "display": "Argentina +54",
    },
    {
        "value": "+374",
        "display": "Armenia +374",
    },
    {
        "value": "+297",
        "display": "Aruba +297",
    },
    {
        "value": "+61",
        "display": "Australia +61",
    },
    {
        "value": "+43",
        "display": "Austria +43",
    },
    {
        "value": "+994",
        "display": "Azerbaijan +994",
    },
    {
        "value": "+1242",
        "display": "Bahamas +1242",
    },
    {
        "value": "+973",
        "display": "Bahrain +973",
    },
    {
        "value": "+880",
        "display": "Bangladesh +880",
    },
    {
        "value": "+1246",
        "display": "Barbados +1246",
    },
    {
        "value": "+375",
        "display": "Belarus +375",
    },
    {
        "value": "+32",
        "display": "Belgium +32",
    },
    {
        "value": "+501",
        "display": "Belize +501",
    },
    {
        "value": "+229",
        "display": "Benin +229",
    },
    {
        "value": "+1441",
        "display": "Bermuda +1441",
    },
    {
        "value": "+975",
        "display": "Bhutan +975",
    },
    {
        "value": "+591",
        "display": "Bolivia +591",
    },
    {
        "value": "+387",
        "display": "Bosnia-Herzegovina +387",
    },
    {
        "value": "+267",
        "display": "Botswana +267",
    },
    {
        "value": "+55",
        "display": "Brazil +55",
    },
    {
        "value": "+673",
        "display": "Brunei +673",
    },
    {
        "value": "+359",
        "display": "Bulgaria +359",
    },
    {
        "value": "+226",
        "display": "Burkina Faso +226",
    },
    {
        "value": "+257",
        "display": "Burundi +257",
    },
    {
        "value": "+855",
        "display": "Cambodia +855",
    },
    {
        "value": "+237",
        "display": "Cameroon +237",
    },
    {
        "value": "+238",
        "display": "Cape Verde Islands +238",
    },
    {
        "value": "+1345",
        "display": "Cayman Islands +1345",
    },
    {
        "value": "+236",
        "display": "Central African Republic +236",
    },
    {
        "value": "+235",
        "display": "Chad +235",
    },
    {
        "value": "+56",
        "display": "Chile +56",
    },
    {
        "value": "+86",
        "display": "China +86",
    },
    {
        "value": "+57",
        "display": "Colombia +57",
    },
    {
        "value": "+242",
        "display": "Congo +242",
    },
    {
        "value": "+243",
        "display": "Congo, Democratic Republ +243",
    },
    {
        "value": "+506",
        "display": "Costa Rica +506",
    },
    {
        "value": "+385",
        "display": "Croatia +385",
    },
    {
        "value": "+53",
        "display": "Cuba +53",
    },
    {
        "value": "+357",
        "display": "Cyprus +357",
    },
    {
        "value": "+420",
        "display": "Czech Republic +420",
    },
    {
        "value": "+45",
        "display": "Denmark +45",
    },
    {
        "value": "+253",
        "display": "Djibouti +253",
    },
    {
        "value": "1767",
        "display": "Dominica +1767",
    },
    {
        "value": "1829",
        "display": "Dominican Republic +1829",
    },
    {
        "value": "+593",
        "display": "Ecuador +593",
    },
    {
        "value": "+20",
        "display": "Egypt +20",
    },
    {
        "value": "+503",
        "display": "El Salvador +503",
    },
    {
        "value": "+372",
        "display": "Estonia +372",
    },
    {
        "value": "+251",
        "display": "Ethiopia +251",
    },
    {
        "value": "+500",
        "display": "Falkland Islands +500",
    },
    {
        "value": "+298",
        "display": "Faroe Islands +298",
    },
    {
        "value": "+679",
        "display": "Fiji Islands +679",
    },
    {
        "value": "+358",
        "display": "Finland +358",
    },
    {
        "value": "+33",
        "display": "France +33",
    },
    {
        "value": "+594",
        "display": "French Guiana +594",
    },
    {
        "value": "+689",
        "display": "French Polynesia +689",
    },
    {
        "value": "+241",
        "display": "Gabon +241",
    },
    {
        "value": "+220",
        "display": "Gambia +220",
    },
    {
        "value": "+995",
        "display": "Georgia +995",
    },
    {
        "value": "+49",
        "display": "Germany +49",
    },
    {
        "value": "+233",
        "display": "Ghana +233",
    },
    {
        "value": "+350",
        "display": "Gibraltar +350",
    },
    {
        "value": "+30",
        "display": "Greece +30",
    },
    {
        "value": "+299",
        "display": "Greenland +299",
    },
    {
        "value": "1473",
        "display": "Grenada +1473",
    },
    {
        "value": "+590",
        "display": "Guadeloupe +590",
    },
    {
        "value": "+671",
        "display": "Guam +671",
    },
    {
        "value": "+502",
        "display": "Guatemala +502",
    },
    {
        "value": "+224",
        "display": "Guinea +224",
    },
    {
        "value": "+592",
        "display": "Guyana +592",
    },
    {
        "value": "+509",
        "display": "Haiti +509",
    },
    {
        "value": "+504",
        "display": "Honduras +504",
    },
    {
        "value": "+852",
        "display": "HongKong +852",
    },
    {
        "value": "+36",
        "display": "Hungary +36",
    },
    {
        "value": "+354",
        "display": "Iceland +354",
    },
    {
        "value": "+91",
        "display": "India +91",
    },
    {
        "value": "+62",
        "display": "Indonesia +62",
    },
    {
        "value": "+98",
        "display": "Iran +98",
    },
    {
        "value": "+964",
        "display": "Iraq +964",
    },
    {
        "value": "+353",
        "display": "Ireland +353",
    },
    {
        "value": "+972",
        "display": "Israel +972",
    },
    {
        "value": "+39",
        "display": "Italy +39",
    },
    {
        "value": "+225",
        "display": "Ivory Coast +225",
    },
    {
        "value": "1876",
        "display": "Jamaica +1876",
    },
    {
        "value": "+81",
        "display": "Japan +81",
    },
    {
        "value": "+962",
        "display": "Jordan +962",
    },
    {
        "value": "+254",
        "display": "Kenya +254",
    },
    {
        "value": "+82",
        "display": "Korea (South) +82",
    },
    {
        "value": "+965",
        "display": "Kuwait +965",
    },
    {
        "value": "+996",
        "display": "Kyrgyzstan +996",
    },
    {
        "value": "+856",
        "display": "Laos +856",
    },
    {
        "value": "+371",
        "display": "Latvia +371",
    },
    {
        "value": "+961",
        "display": "Lebanon +961",
    },
    {
        "value": "+266",
        "display": "Lesotho +266",
    },
    {
        "value": "+231",
        "display": "Liberia +231",
    },
    {
        "value": "+218",
        "display": "Libya +218",
    },
    {
        "value": "+423",
        "display": "Liechtenstein +423",
    },
    {
        "value": "+370",
        "display": "Lithuania +370",
    },
    {
        "value": "+352",
        "display": "Luxembourg +352",
    },
    {
        "value": "+853",
        "display": "Macau +853",
    },
    {
        "value": "+389",
        "display": "Macedonia +389",
    },
    {
        "value": "+261",
        "display": "Madagascar +261",
    },
    {
        "value": "+265",
        "display": "Malawi +265",
    },
    {
        "value": "+60",
        "display": "Malaysia +60",
    },
    {
        "value": "+960",
        "display": "Maldives +960",
    },
    {
        "value": "+223",
        "display": "Mali Republic +223",
    },
    {
        "value": "+356",
        "display": "Malta +356",
    },
    {
        "value": "+596",
        "display": "Martinique +596",
    },
    {
        "value": "+222",
        "display": "Mauritania +222",
    },
    {
        "value": "+230",
        "display": "Mauritius +230",
    },
    {
        "value": "+269",
        "display": "Mayotte Island +269",
    },
    {
        "value": "+52",
        "display": "Mexico +52",
    },
    {
        "value": "+373",
        "display": "Moldova +373",
    },
    {
        "value": "+377",
        "display": "Monaco (Kosovo) +377",
    },
    {
        "value": "+976",
        "display": "Mongolia +976",
    },
    {
        "value": "+382",
        "display": "Montenegro +382",
    },
    {
        "value": "1664",
        "display": "Montserrat +1664",
    },
    {
        "value": "+212",
        "display": "Morocco +212",
    },
    {
        "value": "+258",
        "display": "Mozambique +258",
    },
    {
        "value": "+264",
        "display": "Namibia +264",
    },
    {
        "value": "+977",
        "display": "Nepal +977",
    },
    {
        "value": "+31",
        "display": "Netherlands +31",
    },
    {
        "value": "+599",
        "display": "Netherlands Antilles +599",
    },
    {
        "value": "+687",
        "display": "New Caledonia +687",
    },
    {
        "value": "+64",
        "display": "New Zealand +64",
    },
    {
        "value": "+505",
        "display": "Nicaragua +505",
    },
    {
        "value": "+227",
        "display": "Niger +227",
    },
    {
        "value": "+234",
        "display": "Nigeria +234",
    },
    {
        "value": "+47",
        "display": "Norway +47",
    },
    {
        "value": "+968",
        "display": "Oman +968",
    },
    {
        "value": "+92",
        "display": "Pakistan +92",
    },
    {
        "value": "+970",
        "display": "Palestine +970",
    },
    {
        "value": "+507",
        "display": "Panama +507",
    },
    {
        "value": "+675",
        "display": "Papua New Guinea +675",
    },
    {
        "value": "+595",
        "display": "Paraguay +595",
    },
    {
        "value": "+51",
        "display": "Peru +51",
    },
    {
        "value": "+63",
        "display": "Philippines +63",
    },
    {
        "value": "+48",
        "display": "Poland +48",
    },
    {
        "value": "+351",
        "display": "Portugal +351",
    },
    {
        "value": "1787",
        "display": "Puerto Rico +1787",
    },
    {
        "value": "+974",
        "display": "Qatar +974",
    },
    {
        "value": "+262",
        "display": "Reunion +262",
    },
    {
        "value": "+40",
        "display": "Romania +40",
    },
    {
        "value": "+7",
        "display": "Russia & Kazakhstan +7",
    },
    {
        "value": "+250",
        "display": "Rwanda +250",
    },
    {
        "value": "1869",
        "display": "Saint Kitts and Nevis +1869",
    },
    {
        "value": "1758",
        "display": "Saint Lucia +1758",
    },
    {
        "value": "+685",
        "display": "Samoa +685",
    },
    {
        "value": "+378",
        "display": "San Marino +378",
    },
    {
        "value": "+966",
        "display": "Saudi Arabia +966",
    },
    {
        "value": "+221",
        "display": "Senegal +221",
    },
    {
        "value": "+381",
        "display": "Serbia +381",
    },
    {
        "value": "+248",
        "display": "Seychelles +248",
    },
    {
        "value": "+232",
        "display": "Sierra Leone +232",
    },
    {
        "value": "+65",
        "display": "Singapore +65",
    },
    {
        "value": "+421",
        "display": "Slovakia +421",
    },
    {
        "value": "+386",
        "display": "Slovenia +386",
    },
    {
        "value": "+252",
        "display": "Somalia +252",
    },
    {
        "value": "+27",
        "display": "South Africa +27",
    },
    {
        "value": "+34",
        "display": "Spain +34",
    },
    {
        "value": "+94",
        "display": "Sri Lanka +94",
    },
    {
        "value": "1784",
        "display": "St. Vincent and The Gren +1784",
    },
    {
        "value": "+249",
        "display": "Sudan +249",
    },
    {
        "value": "+597",
        "display": "Suriname +597",
    },
    {
        "value": "+268",
        "display": "Swaziland +268",
    },
    {
        "value": "+46",
        "display": "Sweden +46",
    },
    {
        "value": "+41",
        "display": "Switzerland +41",
    },
    {
        "value": "+963",
        "display": "Syria +963",
    },
    {
        "value": "+886",
        "display": "Taiwan +886",
    },
    {
        "value": "+992",
        "display": "Tajikistan +992",
    },
    {
        "value": "+255",
        "display": "Tanzania +255",
    },
    {
        "value": "+66",
        "display": "Thailand +66",
    },
    {
        "value": "+670",
        "display": "Timor-Leste +670",
    },
    {
        "value": "+228",
        "display": "Togo +228",
    },
    {
        "value": "1868",
        "display": "Trinidad and Tobago +1868",
    },
    {
        "value": "+216",
        "display": "Tunisia +216",
    },
    {
        "value": "+90",
        "display": "Turkey +90",
    },
    {
        "value": "+993",
        "display": "Turkmenistan +993",
    },
    {
        "value": "+256",
        "display": "Uganda +256",
    },
    {
        "value": "+380",
        "display": "Ukraine +380",
    },
    {
        "value": "+971",
        "display": "United Arab Emirates +971",
    },
    {
        "value": "+44",
        "display": "United Kingdom +44",
    },
    {
        "value": "+598",
        "display": "Uruguay +598",
    },
    {
        "value": "+1",
        "display": "USA & Canada +1",
    },
    {
        "value": "+998",
        "display": "Uzbekistan +998",
    },
    {
        "value": "+678",
        "display": "Vanuatu +678",
    },
    {
        "value": "+58",
        "display": "Venezuela +58",
    },
    {
        "value": "+84",
        "display": "Vietnam +84",
    },
    {
        "value": "+967",
        "display": "Yemen +967",
    },
    {
        "value": "+260",
        "display": "Zambia +260",
    },
    {
        "value": "+263",
        "display": "Zimbabwe +263",
    },
];

export default CountryCodes;
