import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';

import styles from "./layout-container.module.scss";

/**
 * Renders the header for the container
 *
 * @param {React.Component} headerContent The header components
 * @param {string} title The container title
 *
 * @returns {React.Component} The header component
 */
const renderHeader = (headerContent, title) => {
    if (headerContent || title) {
        return (
            <div className={styles.headerWrapper}>
                {(title)
                    ? <h2 data-testid={`container-title-${title}`} className={styles.title}>{title}</h2>
                    : null
                }

                {(headerContent)
                    ? <div className={styles.header}>{headerContent}</div>
                    : null
                }
            </div>
        );
    }

    return null;
};

/**
 * Stacks content with a header, scrollable body and footer
 *
 * @param {string} title The container title
 * @param {React.Component} headerContent The header components
 * @param {string} className The wrapper classNames
 * @param {React.Component} footerContent The footer components
 * @param {any} children The main elements
 *
 * @returns {React.Component} The container component
 */
const LayoutContainer = ({ title, headerContent, className, footerContent, children }) => {
    const containerClassList = classNames(styles.container, className);

    return (
        <div className={containerClassList}>
            {renderHeader(headerContent, title)}
            <div className={styles.scrollableContent}>
                {children}
            </div>
            {(footerContent)
                ? <div className={styles.footer}>{footerContent}</div>
                : null
            }
        </div>
    );
};

LayoutContainer.propTypes = {
    title: PropTypes.string,
    headerContent: PropTypes.element,
    className: PropTypes.string,
    footerContent: PropTypes.element,
    children: PropTypes.any,
};

LayoutContainer.defaultProps = {
    title: null,
    headerContent: null,
    className: "",
    footerContent: null,
    children: null,
};

export default LayoutContainer;
