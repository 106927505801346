import PropTypes from "prop-types";

export const companyProps = {
    "id": PropTypes.number.isRequired,
    "name": PropTypes.string.isRequired,
    "telephone_number": PropTypes.string,
    "fax_number": PropTypes.string,
    "epay_access_code": PropTypes.string.isRequired,
    "address": PropTypes.shape({
        "line_1": PropTypes.string,
        "post_town": PropTypes.string,
        "post_code": PropTypes.string,
    }),
};

export const companyPropTypes = PropTypes.shape(companyProps);
export const companyDefaultProps = {};
export default companyPropTypes;
