import React from 'react';
import PropTypes from 'prop-types';
import { Edit } from 'components/icons';
import { LoadingSpinner } from 'components';

import styles from './inline-edit.module.scss';

/**
 * Shows the various states of the inline edit component via icons
 *
 * @param {bool} showHoverIcon Should show the user they can click via icon
 * @param {bool} editing Is the inline edit showing as an input
 * @param {bool} isLoaded Tracks if the inline edit is submitting content
 *
 * @returns {React.Component} The inline edit icons
 */
const InlineEditIcons = ({ showHoverIcon, editing, isLoaded }) => (
    <>
        {(showHoverIcon && !editing) && <Edit className={styles.editIcon} data-testid="edit-icon" />}
        {!isLoaded && (
            <div className={styles.editIcon} data-testid="temp-loading-icon">
                <LoadingSpinner className={styles.spinner} />
            </div>
        )}
    </>
);

InlineEditIcons.propTypes = {
    showHoverIcon: PropTypes.bool.isRequired,
    editing: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
};

export default InlineEditIcons;
