import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { getFormErrors } from 'lib/helpers/validation-helpers';
import { emailRegEx, phoneRegEx } from 'lib/helpers/regex';
import { SelectInputRow, TextInputRow, DateInputRow } from 'components/forms';
import InlineFormRow from 'pages/company/schemes/components/inline-form-row';
import ToggleSwitchRow from 'components/forms/toggle-switch-row';

import styles from './edit-employee-form.module.scss';

/**
 * The form inputs to edit the employee details
 *
 * @returns {React.Component} The form inputs
 */
const EditEmployeeForm = ({ jobRolesOptions }) => {
    const { register, watch, formState: { errors }, getValues } = useFormContext();

    const {
        jobRole,
    } = getValues();

    watch();

    return (
        <div className={styles.formContent}>
            <InlineFormRow>
                <TextInputRow
                    {...register('currentTroncScheme')}
                    label="Tronc Scheme"
                    className={styles.currentTroncScheme}
                    disabled
                />
                <ToggleSwitchRow
                    {...register('troncSchemeOptOut')}
                    label="Opt out of Tronc Scheme"
                    topMargin={false}
                />
            </InlineFormRow>
            <InlineFormRow>
                <TextInputRow
                    {...register('forename', {
                        required: !isEmpty(watch("surname")),
                        deps: ['surname'],
                    })}
                    label={'Forename'}
                    topMargin={false}
                    className={styles.firstColumn}
                    error={getFormErrors(errors, 'forename')}
                />
                <TextInputRow
                    {...register('surname', {
                        required: !isEmpty(watch("forename")),
                        deps: ['forename'],
                    })}
                    label={'Surname'}
                    topMargin={false}
                    className={styles.secondColumn}
                    error={getFormErrors(errors, 'surname')}
                />
            </InlineFormRow>
            <InlineFormRow>
                <TextInputRow
                    {...register("mobileNumber", {
                        maxLength: 15,
                        pattern: phoneRegEx,
                    })}
                    name="mobileNumber"
                    data-testid="mobileNumber"
                    label="Mobile Number"
                    className={styles.firstColumn}
                    topMargin={false}
                    error={getFormErrors(
                        errors,
                        'mobileNumber',
                        { pattern: "Please enter a valid phone number" }
                    )}
                />
                <DateInputRow
                    {...register("startDate", {
                        maxLength: 15,
                    })}
                    name="startDate"
                    data-testid="startDate"
                    label="Start Date"
                    className={styles.secondColumn}
                    topMargin={false}
                />
            </InlineFormRow>
            <InlineFormRow>
                <TextInputRow
                    {...register('email', {
                        pattern: emailRegEx,
                    })}
                    label={'Email'}
                    topMargin={false}
                    className={styles.firstColumn}
                    error={getFormErrors(
                        errors,
                        'email',
                        { pattern: "Please enter a valid email address" }
                    )}
                />
                <SelectInputRow
                    {...register("jobRole")}
                    name="jobRole"
                    data-testid="jobRole"
                    label="Job Roles"
                    className={styles.firstColumn}
                    topMargin={false}
                    options={jobRolesOptions}
                    selected={jobRole}
                />
            </InlineFormRow>
        </div>
    );
};

EditEmployeeForm.propTypes = {
    jobRolesOptions: PropTypes.array,
};

EditEmployeeForm.defaultProps = {
    jobRolesOptions: [],
};

export default EditEmployeeForm;
