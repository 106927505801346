import { useState, useEffect } from "react";
import { AnnouncementsApi } from "lib/api-endpoints";

const initialState = {
    data: {
        data: [],
    },
    isLoaded: false,
};

/**
 * Gets the announcements hook
 *
 * @param {int} page The current page
 * @param {int} perPage The number of announcements per page
 * @param {boolean} refresh Trigger the call again without any other property changes
 * @param {string} sortBy The sort by properties
 *
 * @returns {object} The announcements state
 */
const useAnnouncements = ({
    page,
    perPage = 10,
    refresh = false,
    sortBy = "date_added:desc",
}) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        setState(initialState);

        const { request, abortController } = AnnouncementsApi.getList({
            "per_page": perPage,
            "sort_by": sortBy,
            page,
        });

        request.then(({ data }) => setState({ data, isLoaded: true }));

        return () => abortController?.abort();
    }, [page, perPage, refresh, sortBy]);

    return state;
};

export default useAnnouncements;
