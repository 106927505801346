import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { CompaniesApi } from 'lib/api-endpoints';
import { getCompanies } from 'state/app-slice';
import { useNotificationContext } from 'state/context';
import { Drawer, DrawerTitle } from 'components';
import { Form, FormActions } from 'components/forms';
import CompanyFormContent from 'pages/companies/company-forms/company-form-content';
import FormContent from 'components/forms/form-container/form-content';

/**
 * Renders a drawer to edit a compny
 *
 * @param {object} company Contains the company information
 * @param {boolean} isOpen Is the form drawer open
 * @param {func} setIsOpen sets the state of the drawer
 * @param {boolean} isLoaded Whether the app is loaded
 * @param {func} onCancel function to execute on create company cancellation
 *
 * @returns {React.Component} The create company drawer
 */
const AddEditCompanyDrawer = ({ isOpen, setIsOpen, isLoaded, onCancel, company }) => {
    const dispatch = useDispatch();
    const { openNotification } = useNotificationContext();
    const isEdit = !isEmpty(company);

    const formattedDefaults = () => {
        const {
            name: companyName,
            trading_as_name: tradingName,
            companies_house_number: companyNumber,
            address: { line_1: address1, line_2: address2, line_3: address3, post_town: town, post_code: postcode },
        } = company;

        return {
            companyName,
            tradingName,
            companyNumber,
            address1,
            address2,
            address3,
            town,
            postcode,
        };
    };

    const methods = useForm({
        mode: "onChange",
        defaultValues: isEmpty(company) ? null : formattedDefaults(),
    });

    const { handleSubmit, reset } = methods;

    /**
     * Submit method to create a company, resets and updates company data when complete
     *
     * @param {object} data The form data
     */
    const onSubmit = ({
        companyName,
        tradingName,
        companyNumber,
        address1,
        address2,
        address3,
        town,
        postcode,
    }) => {
        const companyData = {
            name: companyName,
            "trading_as_name": tradingName,
            "companies_house_number": companyNumber,
            address1,
            address2,
            address3,
            town,
            postcode,
            tipology: true,
        };

        if (isEdit) {
            CompaniesApi.editCompany(company.id, companyData)
                .request
                .then(() => onSuccess());
        } else {
            CompaniesApi.addCompany(companyData)
                .request
                .then(() => onSuccess());
        }
    };

    /**
     * Success method to nofify user, reset the form to new defaults and close drawer
     *
     * @param {bool} isEdit Is the form editing a company or creating
     */
    const onSuccess = () => {
        openNotification({
            message: isEdit ? "Company Saved" : "Company successfully created",
            type: "success",
        });

        setIsOpen(false);
        reset();
        dispatch(getCompanies());
    };

    /**
     * Sets up the form draw
     *
     * @returns {React.Component} The form content wrapper
     */
    const renderContent = () => {
        return (
            <Drawer
                isOpen={isOpen}
                isLoading={!isLoaded}
                onClose={onCancel}
                onOutsideClick={onCancel}
                padding={false}
                headerContent={
                    <DrawerTitle>{isEmpty(company) ? "Add a company" : "Edit Company"}</DrawerTitle>
                }
                ariaLabel="company drawer"
            >
                <FormProvider {...methods}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <FormContent>
                            <CompanyFormContent />
                        </FormContent>
                        <FormActions
                            onSubmit={handleSubmit(onSubmit)}
                            submitText={isEmpty(company) ? "Create" : "Save"}
                            onCancel={onCancel}
                        />
                    </Form>
                </FormProvider>
            </Drawer>
        );
    };

    return isOpen && renderContent();
};

AddEditCompanyDrawer.propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    isLoaded: PropTypes.bool,
    onCancel: PropTypes.func,
    company: PropTypes.object,
};

AddEditCompanyDrawer.defaultProps = {
    isOpen: false,
    setIsOpen: noop,
    isLoaded: false,
    onCancel: noop,
    company: null,
};

export default AddEditCompanyDrawer;
