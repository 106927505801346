import React, { useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { useParams } from "react-router-dom";
import { isEmpty, noop, parseInt } from "lodash";
import useUsersList from "lib/api-endpoints/hooks/users-list";
import { LayoutContainer, MoreActionsButton, Table, TextButton } from "components";
import AddEditUserDrawer from "./add-edit-user-drawer";

import styles from "./users-table.module.scss";

const USER_TABLE_HEADINGS = {
    name: "Name",
    phone: "Phone",
    email: "Email",
    actions: "",
};

/**
 * Renders the users in a table under a company
 *
 * @returns {React.Component} The company users component
 */
const UsersTable = ({setDrawer, isDrawerOpen}) => {
    const { id } = useParams();
    const [refresh, setRefresh] = useState(false);
    const query = useMemo(() => ({ companyId: [id], refresh }), [id, refresh]);
    const { data: users, isLoaded } = useUsersList(query);
    const [selectedUserId, setSelectedUserId] = useState(null);

    /**
     * Opens the drawer to edit the currently selected user
     *
     * @param {number} userId The current user Id
     *
     * @returns {void}
     */
    const editCompanyUser = (userId) => {
        setSelectedUserId(userId);
        setDrawer(true);
    };

    /**
     * Formats the users data for a company for display in a table
     *
     * @returns {array} The formatted users table data
     */
    const getFormattedUsers = () => users?.map(({
        id: userId,
        forename,
        surname,
        mobile_number: phone,
        mobile_country_code: countryCode,
        email,
    }) => {
        return {
            id: userId,
            name: {
                component: (
                    <TextButton>
                        {`${forename} ${surname}`}
                    </TextButton>
                ),
            },
            phone: isEmpty(phone) ? "-" : countryCode + phone,
            email: isEmpty(email) ? "-" : email,
            actions: {
                component: (
                    <MoreActionsButton
                        alignRight
                        actions={[
                            {
                                label: "Edit",
                                handler: () => editCompanyUser(userId),
                            },
                        ]}
                    />
                ),
            },
        };
    });

    return (
        <LayoutContainer
            className={styles.container}
        >
            <Table
                data-testid="company-users"
                noDataText="No Users"
                data={getFormattedUsers()}
                headings={USER_TABLE_HEADINGS}
                dataLoaded={isLoaded}
                skeletonConfig={{
                    rowCount: 5,
                    rowConfig: [
                        { key: 'company-users' },
                    ],
                }}
            />
            <AddEditUserDrawer
                isOpen={isDrawerOpen}
                isLoaded={isLoaded}
                companyId={parseInt(id)}
                userId={parseInt(selectedUserId)}
                refreshUsers={() => setRefresh(!refresh)}
                onSelected={(user) => setSelectedUserId(user.value.id)}
                onClose={() => {
                    setSelectedUserId(null);
                    setDrawer(false);
                }}
            />
        </LayoutContainer>
    );
};

UsersTable.propTypes = {
    setDrawer: PropTypes.func,
    isDrawerOpen: PropTypes.bool,
};

UsersTable.defaultProps = {
    setDrawer: noop,
    isDrawerOpen: false,
};

export default UsersTable;
