import React from "react";
import PropTypes from "prop-types";

import styles from "./card-header.module.scss";

/**
 * Renders Card Header
 *
 * @param {any} children Any children to render
 * @param {object} props Any other card properties
 * @param {string} title The card title
 *
 * @returns {React.Component} The Card Header
 */
const CardHeader = ({ children, title, ...props }) => (
    <div className={styles.header} {...props}>
        <div className={styles.title}>
            {title}
        </div>
        <div className={styles.items}>
            {children}
        </div>
    </div>
);

CardHeader.propTypes = {
    children: PropTypes.any,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.element,
        ]),
    ]),
};

CardHeader.defaultProps = {
    children: null,
    title: "",
};

export default CardHeader;
