import React from 'react';
import PropTypes from 'prop-types';

import styles from './no-data-card.module.scss';

/**
 * Renders a card for when there's no data on a page
 *
 * @param {object} props component props
 * @param {string} text card text
 *
 * @returns {React.Component} NoDataCard Component
 */
const NoDataCard = ({ text }) => (
    <div className={styles.noData}>{text}</div>
);

NoDataCard.propTypes = {
    text: PropTypes.string,
};

NoDataCard.defaultProps = {
    text: 'No Data',
};

export default NoDataCard;
