import axios from "axios";

// This is defined during the build process
/* eslint-disable no-undef */
const apiUrl = window?.ENV?.API_BASE_URL || process.env.API_BASE_URL;
/* eslint-enable */

const apiClient = axios.create({
    baseURL: apiUrl,
});

/**
 * Creates an abort controller and attaches the signal to the given api request
 *
 * @param {object} props The api props
 * @param {string} props.type The request type (getUri|request|get|delete|head|options|post|put|patch)
 * @param {string} props.url The request url
 * @param {object} props.config The requests additional configuration
 * @param {string|object} props.data The request payload
 * this will not show the dashboard
 *
 * @returns {object} An object container the current request and abort controller
 */
const api = ({ type = "get", url, config, data }) => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const requestConfig = {
        ...config,
        signal,
    };

    const requestParams = (['post', 'put', 'patch'].indexOf(type)) >= 0
        ? [data, requestConfig]
        : [requestConfig];

    const request = new Promise((resolve, reject) => {
        apiClient[type](url, ...requestParams)
            .then(resolve)
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    reject(error);
                }
            });
    });

    return {
        request,
        abortController,
    };
};

export { api, apiClient, apiUrl };
