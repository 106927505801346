import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { noop, debounce } from "lodash";

const usePaginatedData = ({ data, page, perPage, handleSearch = noop }) => {
    const [currentPage, setPage] = useState(page);
    const [pageData, setPageData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");

    const setSearch = React.useMemo(
        () => debounce((event) => {
            setSearchTerm(event.target.value);
            setPage(1);
        }, 100),
        []
    );

    useEffect(() => {
        const filteredData = searchTerm.length > 0
            ? handleSearch(data, searchTerm)
            : data;

        const pagesTotal = Math.ceil(filteredData.length / perPage);
        const dataStart = (perPage * (currentPage - 1));

        setPageData(filteredData.slice(dataStart, dataStart + perPage));
        setTotalPages(pagesTotal);
    }, [data, handleSearch, currentPage, perPage, setPageData, setTotalPages, searchTerm]);

    return {
        setPage,
        setSearch,
        data: pageData,
        meta: {
            totalPages,
            currentPage,
            perPage,
        },
    };
};

usePaginatedData.propTypes = {
    data: PropTypes.array.isRequired,
    perPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
};

export default usePaginatedData;
