import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./indicator.module.scss";

const statusDisplay = {
    "not started": "open",
    "unapproved": "for review",
};

/**
 * Renders Indicator
 *
 * @param {string} status The current status
 *
 * @returns {React.Component} The Indicator
 */
const Indicator = ({ status }) => {
    if (!status) {
        return null;
    }

    const activeStatus = statusDisplay[status] ?? status;

    return (
        <div className={styles.container} data-testid="indicator-container">
            {activeStatus}
            <span
                className={classNames(styles.indicator, styles[activeStatus.replace(/\s/g, "-")])}
                data-testid="indicator"
            />
        </div>
    );
};

Indicator.propTypes = {
    status: PropTypes.oneOf([
        'unapproved',
        'not started',
        'approved',
        'queried',
        'processing',
        'checked',
    ]),
};

Indicator.defaultProps = {
    status: null,
};

export default Indicator;
