import React from 'react';
import PropTypes from "prop-types";
import troncFilePropTypes, { troncFileDefaultProps } from "lib/props-schema/tronc-file-schema";
import { Table } from 'components';
import DateDisplay from "components/date-display/date-display";
import TableAction from "components/table/table-action";
import { streamFileHandler } from "lib/downloaders/stream-file-handler";
import { TroncFilesApi } from "lib/api-endpoints";

const headings = {
    "name": "NAME",
    "uploaded_by": 'UPLOADED BY',
    "date_updated": 'DATE UPDATED',
    "actions": "ACTIONS",
};

/**
 * Converts the given data array into data that's compatible with the table
 *
 * @param {array} data rest data
 * @returns {array} table data
 */
const getFormattedData = (data) => data.map(({ id, "updated_at": updatedAt, uploader, filename }) => ({
    name: filename,
    "uploaded_by": `${uploader?.forename} ${uploader?.surname}`,
    "date_updated": {
        component: (
            <DateDisplay
                data={updatedAt}
                format="YYYY-MM-DD HH:mm:ss"
                output="DD/MM/YYYY HH:mm"
            />
        ),
    },
    actions: {
        component: (
            <TableAction
                onClick={() => streamFileHandler(TroncFilesApi.getFileData(id).request, { filename })}
            >
                Download
            </TableAction>
        ),
    },
    id,
}));

/**
 * Payroll file list component
 *
 * @param {object} files The current paginated files data
 * @param {function} onPageChange Handles callback for page change
 * @param {boolean} isLoaded Is the data fetching
 *
 * @returns {React.Component} Payroll Companies Component
 */
const FileList = ({ files, onPageChange, isLoaded }) => {
    const { data, meta } = files;

    return (
        <Table
            headings={headings}
            data={getFormattedData(data)}
            noDataText="No Files"
            dataLoaded={isLoaded}
            skeletonConfig={{
                rowCount: 6,
                rowConfig: [
                    { key: 'tronc-files' },
                ],
            }}
            pagination={{
                totalPages: meta?.last_page,
                currentPage: meta?.current_page,
                onPageChange,
            }}
            data-testid="tronc-files"
        />
    );
};

FileList.propTypes = {
    files: PropTypes.shape({
        data: troncFilePropTypes,
        meta: PropTypes.shape({
            total: PropTypes.number,
            "current_page": PropTypes.number,
            "last_page": PropTypes.number,
        }),
    }),
    onPageChange: PropTypes.func,
    isLoaded: PropTypes.bool,
};

FileList.defaultProps = {
    files: {
        data: troncFileDefaultProps,
    },
    onPageChange: () => null,
    isLoaded: false,
};

export default FileList;
