import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './loading-mask.module.scss';

const LoadingSpinner = ({ className }) => {
    return (
        <div
            className={classNames(styles.spinner, className)}
            data-testid="loading-spinner"
            aria-label="Loading spinner"
        />
    );
};

LoadingSpinner.propTypes = {
    className: PropTypes.string,
};

LoadingSpinner.defaultProps = {
    className: "",
};

export default LoadingSpinner;

