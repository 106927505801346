import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ToggleSwitchRow from 'components/forms/toggle-switch-row';
import TOTPSetupDrawer from 'components/authentication/TOTP-setup-drawer';
import { getUser } from "state/user-slice";

import styles from './components.module.scss';

/**
 * Renders the authentification tab for my account
 *
 * @returns {React.Component} the authentification tab
 */
const Authentification = () => {
    const [drawerState, setDrawerState] = useState({ isOpen: false, isLoading: true });

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUser());
    }, [dispatch, drawerState.isOpen]);

    const user = useSelector((state) => state.user);
    const checkedValue = drawerState.isOpen ? !user.mfa_totp : user.mfa_totp;

    return (
        <>
            <div className={styles.container}>
                <ToggleSwitchRow
                    onChange={() => setDrawerState({ isOpen: true, isLoading: false })}
                    rightText="Multi-Factor Authentication"
                    checked={checkedValue}
                />
            </div>
            <TOTPSetupDrawer
                drawerState={drawerState}
                setDrawerState={setDrawerState}
            />
        </>
    );
};

export default Authentification;
