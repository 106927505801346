import React from "react";
import PropTypes from "prop-types";

import styles from "./page-content.module.scss";

/**
 * Wrapper for the page filters content
 *
 * @param {React.Component} children The children
 *
 * @returns {React.Component} The page filters container
 */
const PageFilters = ({ children }) => (
    <div className={styles.filters}>
        {children}
    </div>
);

PageFilters.propTypes = {
    children: PropTypes.any.isRequired,
};

export default PageFilters;
