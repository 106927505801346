import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './text-area.module.scss';

/**
 * Renders a text area input
 *
 * @param {object} props component props
 * @param {string} props.className styling class name
 * @param {boolean} props.resizable if the text area can be resized
 * @param {boolean} props.error error message
 * @param {object} props.otherProps other text area props
 * @param {object} ref a forwarded text area ref
 *
 * @return {React.Component} TextArea Component
*/
const TextArea = React.forwardRef(({ className, resizable, error, required, ...otherProps }, ref) => (
    <textarea
        ref={ref}
        aria-required={required}
        className={classNames(styles.input, className, {
            [styles.resizable]: resizable,
            [styles.error]: error,
        })}
        {...otherProps}
    />
));

TextArea.propTypes = {
    className: PropTypes.string,
    error: PropTypes.bool,
    resizable: PropTypes.bool,
    required: PropTypes.bool,
};

TextArea.defaultProps = {
    className: "",
    error: false,
    resizable: false,
    required: false,
};

export default TextArea;
