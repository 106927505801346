import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'components/forms/form-controls';
import { useFormContext } from 'react-hook-form';

import styles from './form-page-controls.module.scss';

/**
 *
 * @returns {React.Component} Form page controls
 */
const FormPageControls = ({
    alignment,
    config,
    currentFormPage,
    nextPage,
    onError,
    onSubmit,
    prevPage,
    submitText,
}) => {
    const isLastFormPage = currentFormPage === config.length;
    const { handleSubmit, formState: { isValid } } = useFormContext();

    const renderPreviousButton = () => {
        if (!prevPage) {
            return null;
        }

        return (
            <Button
                className={classNames(styles.formPageControl, styles.previous)}
                onClick={prevPage}
                disabled={currentFormPage === 1}
            >
                Previous
            </Button>
        );
    };

    if (isLastFormPage) {
        return (
            <div className={classNames(styles.pageControlWrapper, styles[alignment])}>
                {renderPreviousButton()}
                <Button
                    className={styles.formPageControl}
                    onClick={handleSubmit(onSubmit, onError)}
                    disabled={!isValid}
                >
                    {submitText}
                </Button>
            </div>
        );
    }

    return (
        <div className={classNames(styles.pageControlWrapper, styles[alignment])}>
            {renderPreviousButton()}
            <Button
                className={styles.formPageControl}
                onClick={nextPage}
                disabled={currentFormPage === config.length}
            >
                Next
            </Button>
        </div>
    );
};

FormPageControls.propTypes = {
    alignment: PropTypes.oneOf(['left', 'right']),
    config: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    currentFormPage: PropTypes.number.isRequired,
    nextPage: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    prevPage: PropTypes.func.isRequired,
    submitText: PropTypes.string,
};

FormPageControls.defaultProps = {
    alignment: "right",
    submitText: "Submit",
};

export default FormPageControls;
