import React from 'react';
import PropTypes from 'prop-types';

/**
 * Formats a given numeric value into currency format eg. £231.00
 *
 * @param {object} props component props
 * @param {number} value the number to be formatted
 * @param {string} currency the currency code
 * @param {string} locale the formatted locale
 *
 * @returns {string} input value as a currency string
 */
const Currency = ({ currency, locale, value }) => {
    // eslint-disable-next-line new-cap
    const formatter = Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
    });

    return (
        <span data-testid="formatted-currency">
            { formatter.format(value) }
        </span>
    );
};

Currency.propTypes = {
    currency: PropTypes.string,
    locale: PropTypes.string,
    value: PropTypes.number,
};

Currency.defaultProps = {
    currency: 'GBP',
    locale: 'en-UK',
    value: 0,
};

export default Currency;
