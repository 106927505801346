import React, { useState } from "react";
import PropTypes from "prop-types";
import { findIndex, first, last } from "lodash";
import SwitchNavigator from "./switch-navigator";
import { Button } from "components/forms";
import Searchable from "components/searchable/searchable";

import styles from "./switcher.module.scss";

/**
 * Renders the Switcher
 *
 * @param {function} onChange The on change event
 * @param {function} getContent The handler to get the switcher content to display
 * @param {string|number} value The current selected value
 * @param {object} props Other switcher props
 *
 * @returns {React.Component} Renders the Switcher
 */
const Switcher = ({ onChange, getContent, value, ...props }) => {
    const [visible, setVisible] = useState(false);
    const availableOptions = props.options;
    const firstOption = first(availableOptions);
    const lastOption = last(availableOptions);
    const isFirst = (firstOption?.value === value);
    const isLast = (lastOption?.value === value);

    const handleOnnChange = (option) => {
        onChange(option);
        setVisible(false);
    };

    const handleNavigationChange = (back) => {
        const currentIndex = findIndex(availableOptions, (item) => item.value === value);

        const newOptions = back
            ? availableOptions[currentIndex - 1]
            : availableOptions[currentIndex + 1];

        onChange(newOptions);
        setVisible(false);
    };

    return (
        <div className={styles.container}>
            <SwitchNavigator onClick={handleNavigationChange} disabled={isFirst} />
            <div className={styles.textContainer}>
                <Button className={styles.action} onClick={() => setVisible(true)}>
                    {getContent()}
                </Button>
            </div>
            <SwitchNavigator onClick={() => handleNavigationChange(false)} direction="right" disabled={isLast} />
            <Searchable
                {...props}
                onChange={handleOnnChange}
                visible={visible}
                onOutsideClick={() => setVisible(false)}
            />
        </div>
    );
};

Switcher.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        display: PropTypes.any,
    })),
    getContent: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

Switcher.defaultProps = {
    options: [],
    getContent: () => null,
    onChange: () => null,
    value: null,
};

export default Switcher;
