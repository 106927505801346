import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TextInputRow } from 'components/forms';

import styles from './company-form-content.module.scss';

const required = "Required field";

/**
 * Renders the form content for the company create/edit page
 *
 * @returns {React.Component} Renders the company form elements
 */
const CompanyFormContent = () => {
    const { register, formState } = useFormContext();
    const { errors } = formState;

    return (
        <div className={styles.createCompanyWrapper}>
            <TextInputRow
                {...register("companyName", {
                    required,
                })}
                required={true}
                name="companyName"
                data-testid="companyName"
                label="Company name"
                error={errors?.companyName?.message}
            />
            <TextInputRow
                {...register("companyNumber", {
                    required: "Require a valid company number with a maximum of 8 alphanumeric characters",
                    pattern: {
                        value: /^(([0-9]{2})|(SC)|(NI)|(OC)|(SO)|(NC))[0-9]{6}$/,
                        message: "Require a valid company number with a maximum of 8 alphanumeric characters",
                    },
                })}
                required={true}
                name="companyNumber"
                data-testid="companyNumber"
                label="Company No."
                error={errors?.companyNumber?.message}
            />
            <TextInputRow
                {...register("tradingName", { required })}
                required={true}
                name="tradingName"
                data-testid="tradingName"
                label="Trading as"
                error={errors?.tradingName?.message}
            />
            <TextInputRow
                {...register("address1", { required })}
                required={true}
                name="address1"
                data-testid="address1"
                label="Address line 1"
                error={errors?.address1?.message}
            />
            <TextInputRow
                {...register("address2")}
                name="address2"
                data-testid="address2"
                label="Address line 2"
            />
            <TextInputRow
                {...register("address3")}
                name="address3"
                data-testid="address3"
                label="Address line 3"
            />
            <TextInputRow
                {...register("town")}
                name="town"
                data-testid="town"
                label="Town"
            />
            <TextInputRow
                {...register("postcode", {
                    required,
                    pattern: {
                        value: /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i,
                        message: "Valid postcode required",
                    },
                })}
                required={true}
                name="postcode"
                data-testid="postcode"
                label="Postcode"
                error={errors?.postcode?.message}
            />
        </div>
    );
};

export default CompanyFormContent;
