import moment from 'moment';
import { emailRegEx, phoneRegEx, codeRegEx, ninoRegEx } from './regex';

const errorMessages = {
    required: "is a required field",
    maxLength: "exceeds the maximum length",
    minLength: "does not meet the minimum length",
    max: "exceeds the maximum value allowed",
    min: "is lower than the allowed value",
};

/**
 * Helper funtion to format the return values of the validation
 *
 * @param {string} message The message to send back
 *
 * @return {object} The information to return regarding validation
 */
const formatReturn = (message) => {
    return {
        isValid: (message.length < 1),
        message,
    };
};

/**
 * Checks if the date is valid
 *
 * @param {string} date The date value to validate
 * @param {?string} format The date format to validate
 *
 * @return {object} The information to return regarding validation
 */
const validateDate = (date, format = 'DD-MM-YYY') => {
    const momentDate = moment(date, format);

    if (!momentDate.isValid) {
        return formatReturn('Please enter a valid date');
    }

    return formatReturn('');
};

/**
 * Checks if the email is valid
 *
 * @param {string} email The email value to validate
 *
 * @return {object} The information to return regarding validation
 */
const validateEmail = (email) => {
    if (!emailRegEx.test(email)) {
        return formatReturn('Please use the format example@domain.com');
    }

    return formatReturn('');
};

/**
 * Checks if the phone number is valid
 *
 * @param {string} mobile The phone number value to validate
 *
 * @return {object} The information to return regarding validation
 */
const validateMobile = (mobile) => {
    if (!phoneRegEx.test(mobile)) {
        return formatReturn('Please enter a mobile number with at least 4 digits');
    }

    return formatReturn('');
};

/**
 * Checks if the NINO is valid
 *
 * @param {string} nino The NINO value to validate
 *
 * @return {object} The information to return regarding validation
 */
const validateNino = (nino) => {
    if (!ninoRegEx.test(nino)) {
        return formatReturn('Please enter a valid NI number');
    }

    return formatReturn('');
};

/**
 * Checks that a password has been entered, used when password needed to make security changes
 *
 * @param {string} password The password
 *
 * @return {object} The information to return regarding validation
 */
const validatePasswordEntered = (password) => {
    if (password.length < 1) {
        return formatReturn('Password is required to make changes to your account');
    }

    return formatReturn('');
};

/**
 * Checks the setup code entered is valid
 *
 * @param {string} code the set up code
 *
 * @return {object} The information to return regarding validation
 */
const validateSetupCode = (code) => {
    if (!codeRegEx.test(code) || code.length !== 6) {
        return formatReturn('Please enter a valid setup code');
    }

    return formatReturn('');
};

/**
 * Checks the array of security questions to check all questions are unique and all have answers
 *
 * @param {array} securityQuestions The list of security questions and answers
 *
 * @return {object} The information to return regarding validation
 */
const validateSecurityQuestions = (securityQuestions) => {
    const selectedQuestionIds = [];
    const validAnswers = [];

    securityQuestions.forEach((question) => {
        selectedQuestionIds.push(question.id);
        validAnswers.push(question.answer.length > 0);
    });

    const distinctQuestionIds = [...new Set(selectedQuestionIds)];
    const allAnswersValid = validAnswers.indexOf(false);

    if (distinctQuestionIds.length < 5) {
        return formatReturn('You must chose 5 different questions');
    }

    if (allAnswersValid >= 0) {
        return formatReturn('All questions must have an answer');
    }

    return formatReturn('');
};

/**
 * Checks the array of security questions to check all questions are unique and all have answers
 *
 * @param {object} questionAnswer The security question and answer pair
 *
 * @return {object} The information to return regarding validation
 */
const validateSecurityQuestionAnswer = (questionAnswer) => {
    if (questionAnswer.question.length < 1) {
        return formatReturn('Question is required');
    }

    if (questionAnswer.answer.length < 1) {
        return formatReturn('Answer is required');
    }

    return formatReturn('');
};

/**
 * Checks the new password has been entered and that it matches the entered confirmed value
 *
 * @param {array} passwords The new password and the confirmation value
 *
 * @return {object} The information to return regarding validation
 */
const validateNewPassword = (passwords) => {
    const newPassword = passwords[0] || '';
    const confirmPassword = passwords[1] || '';

    if (newPassword.length < 1) {
        return formatReturn('Please enter your new password');
    }

    if (newPassword !== confirmPassword) {
        return formatReturn('Passwords must match');
    }

    return formatReturn('');
};

/**
 * Checks the email address provided is valid and matches the confirmed value
 *
 * @param {array} emails The new email and confirmation value
 *
 * @return {object} The information to return regarding validation
 */
const validateNewEmail = (emails) => {
    const newEmail = emails[0] || '';
    const confirmEmail = emails[1] || '';

    if (!emailRegEx.test(newEmail)) {
        return formatReturn('Please use the format example@domain.com');
    }

    if (newEmail !== confirmEmail) {
        return formatReturn('Email addresses must match');
    }

    return formatReturn('');
};

/**
 * Checks the username provided is valid and matches the confirmed value
 *
 * @param {array} username The new username and confirmation value
 *
 * @return {object} The information to return regarding validation
 */
const validateUsernamesMatch = (username) => {
    const newUsername = username[0] || '';
    const confirmNewUsername = username[1] || '';

    if (newUsername !== confirmNewUsername) {
        return formatReturn('Usernames must match');
    }

    return formatReturn('');
};

/**
 * Checks the mobile number provided is valid and matches the confirmed value
 *
 * @param {array} mobile The new mobile number and confirmation value
 *
 * @return {object} The information to return regarding validation
 */
const validateMobilesMatch = (mobile) => {
    const newMobile = mobile[0] || '';
    const confirmNewMobile = mobile[1] || '';

    if (!phoneRegEx.test(newMobile)) {
        return formatReturn('Please enter a mobile number with at least 4 digits');
    }

    if (newMobile !== confirmNewMobile) {
        return formatReturn('Mobile numbers must match');
    }

    return formatReturn('');
};

/**
 * Define new validation types and relevant functions here
 */
const validTypes = {
    'date': validateDate,
    'email': validateEmail,
    'mobile': validateMobile,
    'nino': validateNino,
    'setupCode': validateSetupCode,
    'passwordEntered': validatePasswordEntered,
    'securityQuestions': validateSecurityQuestions,
    'securityQuestionAnswer': validateSecurityQuestionAnswer,
    'newPassword': validateNewPassword,
    'newEmail': validateNewEmail,
    'newUsername': validateUsernamesMatch,
    'newMobile': validateMobilesMatch,
};

/**
 * Checks validity of the value
 *
 * @param {mixed} value The value to validate
 * @param {string} type The type of validation to perform
 * @param {?string} format The format to use in validation if required (e.g. date)
 *
 * @return {object} The information to return regarding validation
 */
export const checkValidity = (value, type, format = null) => {
    if (!validTypes[type]) {
        return formatReturn('');
    }

    return validTypes[type](value, format);
};

/**
 * Gets generic error text from form state errors and a key
 *
 * @param {object} errors form state errors object
 * @param {string} key the key of the error you want the message for
 * @param {object} customErrors custom errors object
 *
 * @return {string} the error string
 */
export const getFormErrors = (errors = {}, key, customErrors = {}) => {
    const type = errors[key]?.type;
    const allErrors = { ...errorMessages, ...customErrors };

    return (allErrors[type] || "");
};
