import React from "react";

const RightArrow = () => (
    <svg
        width="16"
        height="11"
        viewBox="0 0 16 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden
    >
        <path
            d="M10.2929 0.655198C10.6834 0.264674 11.3166 0.264674 11.7071 0.655198L15.7071 4.6552C16.0976
                5.04572 16.0976 5.67889 15.7071 6.06941L11.7071 10.0694C11.3166 10.4599 10.6834 10.4599 10.2929
                10.0694C9.90238 9.67889 9.90238 9.04572 10.2929 8.6552L12.5858 6.3623H1C0.447716 6.3623 -2.41411e-08
                5.91459 0 5.3623C2.41411e-08 4.81002 0.447716 4.3623 1 4.3623H12.5858L10.2929 2.06941C9.90238
                1.67889 9.90238 1.04572 10.2929 0.655198Z"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="currentColor"
        />
    </svg>
);

export default RightArrow;
