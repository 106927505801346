import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { resetCrumbs, setCrumbs, selectCompany } from "state/app-slice";
import { Page, PageContent, TabSection } from 'components';
import { Button } from 'components/forms';
import { Sites, NewSiteDrawer } from './sites';
import CompanyAbout from './about/company-about';
import UsersTable from './users/users-table';
import Schemes from './schemes/schemes';
import Employees from "./employees/employees";
import JobRoles from './job-roles/job-roles';

import styles from './company.module.scss';

/**
 * Renders the company tabs and content
 *
 * @returns {React.Component} The company page
 */
const Company = () => {
    const dispatch = useDispatch();
    const { app, company: { isLoading } } = useSelector((state) => state);
    const { id } = useParams();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [schemeDrawerOpen, setSchemeDrawerOpen] = useState({
        editDrawer: false,
        addDrawer: false,
    });

    /**
     * Finds current company and gets relevant permissions
     *
     * @returns {object} selected company and permissions
     */
    const {
        companyPermissions: {
            company: companyPermissions,
            sites: sitesPermissions,
            troncSchemes: schemesPermissions,
            employees: employeePermissions,
        },
        ...selectedCompany
    } = useMemo(() => selectCompany(app, id), [app, id]);

    const getHeaderButton = (text, permissions) => (
        <Button
            disabled={isLoading}
            onClick={() => setDrawerOpen(true)}
            hasPermission={permissions.canCreate}
        >
            {text}
        </Button>
    );

    const getSchemeHeaderButton = (text, permissions) => (
        <Button
            disabled={isLoading}
            onClick={() => setSchemeDrawerOpen({
                editDrawer: false,
                addDrawer: true,
            })}
            hasPermission={permissions.canCreate}
        >
            {text}
        </Button>
    );

    /**
     * Resets the breadcrumbs when leaving the company page.
     */
    useEffect(() => {
        if (selectedCompany?.name) {
            dispatch(setCrumbs({
                company: selectedCompany.name,
                end: true,
            }));
        }

        return () => dispatch(resetCrumbs());
    }, [dispatch, selectedCompany?.name]);

    return (
        <Page title={selectedCompany.name} marginTop={false}>
            <PageContent>
                <TabSection
                    headerUnderline={false}
                    className={classNames(styles.alignLeft, styles.headerSpacing)}
                    tabs={[
                        {
                            title: "Sites",
                            route: "sites",
                            shouldRender: sitesPermissions.canView,
                            headerAction: getHeaderButton('Add Site', sitesPermissions),
                            content: (
                                <>
                                    <Sites setNewSiteDrawerOpen={setDrawerOpen} />
                                    <NewSiteDrawer
                                        companyId={Number(id)}
                                        isOpen={drawerOpen}
                                        setIsOpen={setDrawerOpen}
                                        isLoading={isLoading}
                                        onCancel={() => setDrawerOpen(false)}
                                    />
                                </>
                            ),
                        },
                        {
                            title: "Schemes",
                            route: "schemes",
                            headerAction: getSchemeHeaderButton('Add Scheme', schemesPermissions),
                            content: (
                                <Schemes
                                    isDrawerOpen={schemeDrawerOpen}
                                    setDrawer={setSchemeDrawerOpen}
                                    permissions={schemesPermissions}
                                />
                            ),
                            shouldRender: schemesPermissions.canView,
                        },
                        {
                            title: "Job Roles",
                            route: "job-roles",
                            content: (
                                <JobRoles company={selectedCompany} />
                            ),
                        },
                        {
                            title: "Employees",
                            route: "employees",
                            content: (
                                <Employees
                                    company={selectedCompany}
                                    setDrawer={setDrawerOpen}
                                    isDrawerOpen={drawerOpen}
                                    canImport={employeePermissions.canCreate}
                                />
                            ),
                            shouldRender: employeePermissions.canView,
                            headerAction: getHeaderButton('Import', employeePermissions.canCreate),
                        },
                        {
                            title: "About",
                            route: "about",
                            content: <CompanyAbout />,
                        },
                        {
                            title: "Users",
                            route: "users",
                            headerAction: getHeaderButton('Add User', employeePermissions.canCreate),
                            content: (
                                <UsersTable
                                    isDrawerOpen={drawerOpen}
                                    setDrawer={setDrawerOpen}
                                />
                            ),
                            shouldRender: companyPermissions?.troncmaster,
                        },
                    ]}
                />
            </PageContent>
        </Page>
    );
};

export default Company;
