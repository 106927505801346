import { useState, useEffect } from "react";
import PayrollsApi from "lib/api-endpoints/payrolls-api";

const initialState = {
    isLoading: true,
    payrolls: [],
};

const usePayrollList = ({ companyId }) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        setState(initialState);

        const params = {};

        if (companyId) {
            params.company_ids = [companyId]; // eslint-disable-line camelcase
        }

        const { request, abortController } = PayrollsApi.getPayrolls(params);

        request.then(({ data }) => setState({
            payrolls: data,
            isLoading: false,
        }));

        return () => abortController?.abort();
    }, [companyId]);

    return state;
};

export default usePayrollList;
