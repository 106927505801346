import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components';

/**
 * Renders a Modal with given children
 *
 * @param {object} props component props
 * @param {React.Element} props.children provided component children
 *
 * @returns {React.Component} Modal component with children
 */
const ModalForm = ({ children, ...otherProps }) => (
    <Modal {...otherProps}>
        {children}
    </Modal>
);

ModalForm.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
        PropTypes.array,
        PropTypes.element,
    ]),
};

ModalForm.defaultProps = {
    children: null,
};

export default ModalForm;
