import { api } from "lib/api";

export default class TicketsApi {

    /**
     * Gets the post request promise for adding a new ticket
     *
     * @param {object} data The ticket payload
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static addTicket = (data) => api({ type: 'post', url: "/tickets", data });

    /**
     * Gets the tickets list request promise
     *
     * @param {object} params The query parameters
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getTickets = (params) => api({ url: "/tickets", config: { params } });

    /**
     * Gets the single ticket request promise
     *
     * @param {int} id The ticket id
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getTicket = (id) => api({ url: `/tickets/${id}` });

    /**
     *  Gets the ticket visibility promise
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getTicketsVisibilityTypes = () => api({ url: "/tickets/visibility_types" });

    /**
     * Gets the edit request promise
     *
     * @param {int} id The ticket id
     * @param {object} data The data to edit
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static editTicket = (id, data) => api({ type: 'patch', url: `/tickets/${id}`, data });

    /**
     * Gets the post request promise for closing a ticket
     *
     * @param {int} id The ticket id
     * @param {object} data The close ticket payload
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static closeTicket = (id, data) => api({ type: 'post', url: `/tickets/${id}/close`, data });

    /**
     * Gets the post request promise for opening a ticket
     *
     * @param {int} id The ticket id
     * @param {object} data The open ticket payload
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static openTicket = (id, data) => api({ type: 'post', url: `/tickets/${id}/open`, data });

    /**
     * Gets the post request promise for adding a comment to a ticket
     *
     * @param {int} id The ticket id
     * @param {object} formData The ticket comments form data
     * @param {object} formData.attachments The attachments for the comment
     * @param {string} formData.message The comment message
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static addComment = (id, { attachments, message }) => {
        const data = new FormData();

        data.append('message', message);

        Array.from(attachments).forEach((fileItem) => {
            data.append('attachments[]', fileItem);
        });

        return api({ type: 'post', url: `/tickets/${id}/comments`, data });
    };

    /**
     * Gets the download attachment request promise
     *
     * @param {int} id The attachment id
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static downloadAttachment = (id) => api({
        url: `/ticket_attachments/${id}/download`,
        config: { responseType: 'blob' },
    });

}
