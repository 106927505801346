import React from 'react';
import PropTypes from 'prop-types';
import TextLink from 'components/text-link/text-link';
import { noop } from 'lodash';

/**
 * Renders a table cell for use in Row
 *
 * @param {object} props component props
 * @param {object} cellData the data to render in the cell
 *
 * @returns {React.Component} Cell Component
 */
const Cell = ({ cellData }) => {
    const { link, text, className, component, onClick } = cellData;
    const CustomComponent = component;
    let content;

    if (typeof cellData === "string") {
        content = cellData;
    } else if (link) {
        content = (
            <TextLink onClick={onClick} to={link}>
                {text}
            </TextLink>
        );
    } else if (component) {
        content = CustomComponent;
    } else {
        content = text;
    }

    return (
        <td className={className}>
            {content}
        </td>
    );
};

Cell.propTypes = {
    cellData: PropTypes.oneOfType([
        PropTypes.shape({
            link: PropTypes.string,
            text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            onClick: PropTypes.func,
            className: PropTypes.string,
            component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
        }),
        PropTypes.string,
    ]),
};

Cell.defaultProps = {
    cellData: {
        className: "",
        text: "",
        link: "",
        onClick: noop,
    },
};

export default Cell;
