import React from 'react';
import PropTypes from "prop-types";
import { Button } from "components/forms/form-controls";

import styles from "./table-action.module.scss";

/**
 * Renders a table cell for use in Row
 *
 * @param {object} props component props
 *
 * @returns {React.Component} Cell Component
 */
const TableAction = ({ children, onClick }) => {
    return (
        <Button className={styles.action} onClick={onClick}>{children}</Button>
    );
};

TableAction.propTypes = {
    children: PropTypes.any.isRequired,
    onClick: PropTypes.func,
};

TableAction.defaultProps = {
    onClick: () => null,
};

export default TableAction;
