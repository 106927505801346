import PropTypes from 'prop-types';
import React from 'react';
import { groupBy, map, noop} from 'lodash';
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Form, FormActions, SelectInputRow, TextInputRow } from 'components/forms';
import FileInputRow from 'components/forms/file-input-row';
import CheckBoxInputRow from 'components/forms/checkbox-input-row';
import DocumentsApi from 'lib/api-endpoints/documents-api';
import { getFormErrors } from 'lib/helpers/validation-helpers';

/**
 * Renders Form For Creating Documents
 *
 * @param {object} props component props
 * @param {function} props.onClose onClose handler
 * @param {function} props.onSuccess onSuccess handler
 * @param {function} props.onSubmit onSubmit handler
 *
 * @returns {React.Component} Create Document Form
 */
const CreateDocumentForm = ({ onClose, onSuccess, onSubmit }) => {
    const methods = useForm();
    const { companies, payrolls } = useSelector((state) => state.app);
    const { handleSubmit, register, getValues, watch, formState: { errors } } = methods;
    watch();

    const values = getValues();
    const payrollsByCompany = groupBy(payrolls, 'company_id');
    const selectedCompanyPayrolls = payrollsByCompany[values.company] || [];

    /**
     * Formats given data into display value pair options for select inputs
     *
     * @param {array} data data to format
     *
     * @returns {array} array of display value pair options
     */
    const getOptions = (data) => map(data, ({ id, name }) => ({
        value: id,
        display: name,
    }));

    /**
     * Submit handler repsonsible for calling onSubmit and OnSuccess handlers when creating the document
     *
     * @param {object} formData captured form data
     */
    const handleOnSubmit = (formData) => {
        const { request } = DocumentsApi.createDocument(formData);

        onSubmit();
        request.then(onSuccess).catch(onClose);
    };

    return (
        <FormProvider {...methods}>
            <Form>
                <SelectInputRow
                    label="Company"
                    name="company"
                    options={getOptions(companies)}
                    data-testid="company-select"
                    aria-label="company-select"
                    error={getFormErrors(errors, "company")}
                    required={true}
                    {...register("company", { required: true })}
                />
                <SelectInputRow
                    label="Payroll"
                    name="payrolls[]"
                    options={getOptions(selectedCompanyPayrolls)}
                    data-testid="payroll-select"
                    aria-label="payroll-select"
                    error={getFormErrors(errors, "payrolls")}
                    required={true}
                    {...register("payrolls", { required: true })}
                />
                <TextInputRow
                    label="Description"
                    name="description"
                    data-testid="description-input"
                    error={getFormErrors(errors, "description")}
                    required={true}
                    {...register("description", { required: true })}
                />
                <FileInputRow
                    {...register("file", { required: true })}
                    required={true}
                    error={getFormErrors(errors, "file")}
                    data-testid="file-input"
                />
                <CheckBoxInputRow text="Notify Employee" {...register("notify")} data-testid="notify-input" />
                <FormActions onSubmit={handleSubmit(handleOnSubmit)} onCancel={onClose} />
            </Form>
        </FormProvider>
    );
};

CreateDocumentForm.defaultProps = {
    onClose: noop,
    onSuccess: noop,
    onSubmit: noop,
};

CreateDocumentForm.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    onSubmit: PropTypes.func,
};

export default CreateDocumentForm;
