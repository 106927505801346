import { useState, useEffect } from "react";
import { TroncSchemesApi } from "lib/api-endpoints";

const initialState = {
    scheme: {},
    isLoaded: false,
};

const useTroncScheme = (id) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        setState(initialState);

        const { request, abortController } = TroncSchemesApi.getTroncScheme(id, {
            "include_current_period": true,
            "include_tax_years": true,
        });

        request.then(({ data }) => setState({ scheme: data, isLoaded: true }));

        return () => abortController?.abort();
    }, [id]);

    return state;
};

export default useTroncScheme;
