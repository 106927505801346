import React from 'react';
import PropTypes from 'prop-types';
import { MultipartForm } from 'components';
import TicketDetails from './ticket-details';
import TicketUserVisibility from './ticket-user-visibility';
import { noop } from 'lodash';

import styles from './ticket-form.module.scss';

/**
 * Renders multipart form for creating new tickets
 *
 * @param {object} props component props
 * @param {function} props.onCancel form close handler
 * @param {function} props.onSubmit form submit handler
 * @param {boolean} props.showForm if the form should be shown
 * @param {function} props.setShowForm show form handler
 *
 * @returns {React.Component} TicketForm Component
 */
const TicketForm = ({ onCancel, onSubmit, showForm, setShowForm }) => (
    <MultipartForm
        className={styles.ticketDrawer}
        footerAlignment="right"
        footerClassName={styles.ticketFooter}
        formTitle="Create New Ticket"
        mode="drawer"
        onClose={onCancel}
        onSubmit={onSubmit}
        setShowForm={setShowForm}
        showForm={showForm}
        stepper={false}
        submitText="Add Ticket"
        defaultValues={{
            visible: [],
            notifications: [],
        }}
        config={[
            {
                stepNum: 1,
                component: TicketDetails,
            },
            {
                stepNum: 2,
                component: TicketUserVisibility,
            },
        ]}
    />
);

TicketForm.propTypes = {
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    showForm: PropTypes.bool,
    setShowForm: PropTypes.func,
};

TicketForm.defaultProps = {
    onCancel: noop,
    onSubmit: noop,
    showForm: false,
    setShowForm: noop,
};

export default TicketForm;
