import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { TicketsApi } from "lib/api-endpoints";
import { getTicket } from "state/tickets-slice";
import ticketPropTypes, { ticketDefaultProps } from 'lib/props-schema/ticket-schema';
import { Badge, InlineEdit } from 'components';
import DateDisplay from 'components/date-display/date-display';
import TicketHeaderColumn from './ticket-header-column';

import styles from './ticket-header.module.scss';

/**
 * The header of an individual ticket
 *
 * @param {object} ticket The tikcet information
 *
 * @returns {React.Component} Ticket info fields
 */
const TicketHeader = ({ ticket }) => {
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.user.id);
    const ticketLoading = useSelector((state) => state.tickets.isLoading);

    return (
        <>
            <div className={styles.ticketDescription}>
                <span>Description: </span>
                <InlineEdit
                    content={ticket.subject}
                    fieldName="subject"
                    saveChangesCallback={(data) => TicketsApi.editTicket(ticket.id, data)}
                    fetchContent={() => dispatch(getTicket(ticket.id))}
                    contentLoading={ticketLoading}
                    disableEditing={(ticket.created_by_id !== userId)}
                    maxCharShown={256}
                />
            </div>

            <div className={styles.ticketInfoBlock} data-testid="ticket-info">
                <TicketHeaderColumn config={[
                    {
                        property: "Ticket ID",
                        value: ticket.id,
                    },
                    {
                        property: "Created",
                        value: (
                            <DateDisplay
                                data={ticket.created_datetime}
                                output={"DD-MM-YYYY"}
                            />
                        ),
                    },
                ]}
                />

                <TicketHeaderColumn config={[
                    {
                        property: "Reporter",
                        value: ticket.created_by,
                    },
                    {
                        property: "Visibility",
                        value: (
                            <>
                                {ticket.visibility}
                                <Badge>Everyone</Badge>
                            </>
                        ),
                    },
                ]}
                />

                <TicketHeaderColumn config={[
                    {
                        property: "Status",
                        value: (
                            <Badge status={ticket.status.toLowerCase()}>
                                {ticket.status}
                            </Badge>
                        ),
                    },
                ]}
                />
            </div>
        </>
    );
};

TicketHeader.propTypes = {
    ticket: PropTypes.oneOfType([
        PropTypes.array,
        ticketPropTypes,
    ]),
};

TicketHeader.defaultProps = {
    ticket: ticketDefaultProps,
};

export default TicketHeader;
