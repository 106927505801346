import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { resetCrumbs, setCrumbs, selectCompany } from "state/app-slice";
import useTroncSchemesList from 'lib/api-endpoints/hooks/tronc-schemes-list';
import { Page, PageContent, TabSection } from 'components';
import LengthOfService from './length-of-service';
import OverView from './overview';
import SchemeJobRoles from './job-roles';
import SchemeTraining from './training';

/**
 * Renders the company tabs and content
 *
 * @returns {React.Component} The company page
 */
const Scheme = () => {
    const dispatch = useDispatch();
    const { app } = useSelector((state) => state);
    const { scheme, id } = useParams();

    /**
     * Finds current company and gets relevant permissions
     *
     * @returns {object} selected company and permissions
     */
    const {...selectedCompany} = useMemo(() => selectCompany(app, id), [app, id]);

    const { schemes } = useTroncSchemesList({
        companyIds: [selectedCompany.id],
        getCurrentPeriod: true,
    });

    /**
     * Resets the breadcrumbs when leaving the company page.
     */
    useEffect(() => {
        if (selectedCompany?.name) {
            dispatch(setCrumbs({
                company: selectedCompany.name,
                end: true,
            }));
        }

        return () => dispatch(resetCrumbs());
    }, [dispatch, selectedCompany?.name]);

    const selectedScheme = schemes.find((singleScheme) => parseInt(singleScheme.id, 10) === parseInt(scheme, 10));

    return (
        <Page title={selectedScheme?.name} marginTop={false}>
            <PageContent>
                <TabSection
                    headerUnderline={false}
                    tabs={[
                        {
                            title: "Overview",
                            route: "overview",
                            content: <OverView />,
                        },
                        {
                            title: "Job roles",
                            route: "job-roles",
                            content: <SchemeJobRoles selectedScheme={selectedScheme} />,
                        },
                        {
                            title: "Training",
                            route: "training",
                            content: <SchemeTraining selectedScheme={selectedScheme} />,
                        },
                        {
                            title: "Length of service",
                            route: "length-of-service",
                            content: <LengthOfService selectedScheme={selectedScheme} />,
                        },
                    ]}
                />
            </PageContent>
        </Page>
    );
};

export default Scheme;
