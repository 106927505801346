import React, { forwardRef } from "react";
import PropTypes from 'prop-types';
import { FormRow, FormRowLabel } from "./form-controls";
import FileInput from "./form-controls/file-input";
import ErrorHelpText from "./error-help-text";

/**
 * Renders the file input row
 *
 * @param {object} props The file input row properties
 *
 * @return {React.Component} The file input row
 */
const FileInputRow = forwardRef(({ name, error, label, required, ...otherProps }, ref) => {
    return (
        <FormRow>
            { label ? <FormRowLabel label={label} required={required} /> : null }
            <FileInput ref={ref} name={name} error={Boolean(error)} required={required} {...otherProps} />
            { error ? <ErrorHelpText text={error} /> : null }
        </FormRow>
    );
});

FileInputRow.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    error: PropTypes.string,
    required: PropTypes.bool,
    buttonLabel: PropTypes.string,
};

FileInputRow.defaultProps = {
    error: "",
    label: null,
    buttonLabel: "Upload Files",
    required: false,
};

export default FileInputRow;
