import React from "react";
import PropTypes from "prop-types";
import { isEmpty, upperFirst, noop } from "lodash";
import Card from "./card";
import UserAvatar from "components/user-avatar/user-avatar";
import DateDisplay from "components/date-display/date-display";
import announcementProps from "lib/props-schema/announcement-schema";

import styles from "./announcement-card.module.scss";

/**
 * Gets the notification text to display
 *
 * @param {string} type The notice type
 *
 * @return {string} The notification text
 */
const getNotificationText = (type) => `A new ${upperFirst(type)} has been published by Dataplan`;

/**
 * Renders the announcement card
 *
 * @param {object} announcement The announcement
 * @param {function} onClick The card click handler
 *
 * @returns {React.Component} The announcement card
 */
const AnnouncementCard = ({ announcement, onClick }) => {
    const { addedby, "notice_type": type } = announcement;
    const name = [addedby?.forename[0], addedby?.surname[0]].filter((value) => !isEmpty(value)).join(" ");

    return (
        <Card onClick={onClick} className={styles.container}>
            <UserAvatar nameInitials={name} />
            <div className={styles.announcementDetails}>
                <h2 className={styles.heading}>{getNotificationText(type.system_name)}</h2>
                <h3 className={styles.type}>{`new ${upperFirst(type.system_name)}`}</h3>
            </div>
            <div className={styles.date}>
                <DateDisplay data={announcement.dateadded} output="DD-MM-YYYY" />
            </div>
        </Card>
    );
};

AnnouncementCard.propTypes = {
    announcement: announcementProps.isRequired,
    onClick: PropTypes.func,
};

AnnouncementCard.defaultProps = {
    onClick: noop,
};

export default AnnouncementCard;
