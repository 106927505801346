import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import styles from "./radio-boxes.module.scss";

/**
 * Renders the radio boxes
 *
 * @param {object} props The radio boxes properties
 *
 * @return {React.Component} The radio boxes
 */
const RadioBoxes = forwardRef(({ options, defaultValue, name, ...otherProps }, ref) => {
    return (
        <div className={styles.container}>
            {options.map(({ key, display, value, onClick = _.noop }) => (
                <label key={key} className={styles.box}>
                    {display}
                    <input
                        ref={ref}
                        value={value}
                        type="radio"
                        name={name}
                        onClick={onClick}
                        {...otherProps}
                    />
                    <span className={styles.checkmark} />
                </label>
            ))}
        </div>
    );
});

RadioBoxes.defaultProps = {
    defaultValue: "",
};

RadioBoxes.propTypes = {
    options: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
};

export default RadioBoxes;
