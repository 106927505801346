import React from "react";
import FileNavigationPage from "./components/file-navigation-page";
import TroncSchemePeriods from "pages/files/components/tronc-schemes-periods";

/**
 * Renders the files periods
 *
 * @return {React.Component} Renders the files periods page
 */
const Periods = () => (
    <FileNavigationPage title="Periods">
        <TroncSchemePeriods />
    </FileNavigationPage>
);

export default Periods;
