import React from 'react';
import { NavLink } from 'react-router-dom';
import { Home, SlimArrow } from 'components/icons';

import styles from "./breadcrumbs.module.scss";
import homeStyles from './home-crumb.module.scss';

/**
 * Returns the home icon crumb used in breadcrumbs
 *
 * @returns {React.Component} HomeCrumb Component
 */
const HomeCrumb = () => (
    <div className={styles.crumb}>
        <span id="home-label" hidden>Back to dashboard</span>
        <NavLink
            to={"/dashboard"}
            className={styles.crumbLink}
            data-testid={`crumb-link-home`}
            aria-labelledby="home-label"
        >
            <Home className={homeStyles.house} fillColor="#666D7A" />
        </NavLink>
        <SlimArrow className={styles.arrow} />
    </div>
);

export default HomeCrumb;

