import React from "react";
import PropTypes from "prop-types";

import styles from "./card-content.module.scss";

/**
 * Renders Card Content
 *
 * @param {any} children Any children to render
 * @param {object} props Any other card properties
 *
 * @returns {React.Component} The Card Content
 */
const CardContent = ({ children, ...props }) => (
    <div className={styles.content} {...props}>
        {children}
    </div>
);

CardContent.propTypes = {
    children: PropTypes.any,
};

CardContent.defaultProps = {
    children: null,
};

export default CardContent;
