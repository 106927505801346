import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import styles from './radio-select.module.scss';

/**
 * Renders a radio select form input
 *
 * @param {object} props component props
 *
 * @return {React.Component} Radio Select Inputs
 */
const RadioSelect = forwardRef(({ options, name, ...otherProps }, ref) => (
    <div className={styles.container} data-testid={`radio-select-${name}`}>
        {options.map(({ label, value, onChange }) => (
            <div className={styles.option} key={value}>
                <input
                    className={styles.radioSelect}
                    id={value}
                    name={name}
                    onChange={onChange}
                    ref={ref}
                    type="radio"
                    value={value}
                    data-testid={`radio-${name}-${value}`}
                    {...otherProps}
                />
                <label className={styles.label} htmlFor={value}>{label}</label>
            </div>
        ))}
    </div>
));

RadioSelect.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        label: PropTypes.string,
        onClick: PropTypes.func,
    })).isRequired,
};

export default RadioSelect;
