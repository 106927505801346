import PayrollPermissions from "../permissions/payroll-permissions";

export default class Payroll {

    /**
     * Payroll constructor
     *
     * @param {object} payroll The payroll
     * @param {object} permissions The payrolls permissions
     */
    constructor (payroll, permissions) {
        Object.assign(this, payroll);
        this.permissions = new PayrollPermissions(permissions);
    }

    /**
     * Gets a list of payroll permissions and registers the current policy per permission registered
     *
     * @return {void} Registers permissions
     */
    register () {
        this.permissions.getPermissionList().forEach((permission) => permission.register());
    }

}
