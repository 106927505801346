import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './carousel-card.module.scss';

/**
 * Renders the carousel card
 *
 * @param {object} item Card content
 * @param {number} cardWidth The card width number in px
 * @param {number} currentPage Current page
 * @param {number} cardNumber Tracks the number of cards shown on a carousel page, less in smaller containers
 * @param {boolean} visible Whether the card is currently visible to the user, used to hide shadow bleed
 *
 * @return {React.Component} Renders the carousel card
 */
const CarouselCard = ({ item, cardWidth, currentPage, cardNumber, visible }) => {
    const cardClasses = classNames(styles.card, {
        [styles.visibleShadow]: visible,
    });

    return (
        <Link
            to={item.linkTo}
            className={cardClasses}
            style={{
                minWidth: `${cardWidth - 20}px`,
                transform: `translateX(-${(cardWidth * currentPage * cardNumber)}px)`,
            }}
        >
            <div className={styles.cardTitle}>{item.title}</div>
            <span className={styles.subtitle}>{item.subtitle}</span>
            {item.currentBadge ? <span className={styles.badge}>Current</span> : null}
        </Link>
    );
};

CarouselCard.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        currentBadge: PropTypes.bool.isRequired,
        linkTo: PropTypes.string,
    }).isRequired,
    cardWidth: PropTypes.number.isRequired,
    currentPage: PropTypes.number,
    cardNumber: PropTypes.number,
    visible: PropTypes.bool,
};

CarouselCard.defaultProps = {
    currentPage: 0,
    cardNumber: 1,
    visible: true,
};

export default CarouselCard;
