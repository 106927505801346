import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/forms/form-controls';

import styles from './date-display.module.scss';

/**
 * Formats a given date string based on the input format and given an output format.
 * The format strings are moment format strings
 *
 * @param {string} data the date you wish to be formatted
 * @param {string} format the format of the input string
 * @param {string} output the format of the output string
 * @param {function} onClick on click function when clicking on a date
 * @param {string} children the component children
 *
 * @returns {React.Component} Date Display Component
 */
const DateDisplay = ({ data, format, output, onClick, children }) => {
    const momentDate = format ? moment(data, format) : moment(data);
    const formattedDate = momentDate.format(output);

    const dateSpan = (
        <span data-testid="date-display">
            {formattedDate}
        </span>
    );

    if (onClick === null) {
        return dateSpan;
    }

    return (
        <Button onClick={onClick} className={styles.clickableDate}>
            {dateSpan} {children}
        </Button>
    );
};

DateDisplay.propTypes = {
    data: PropTypes.string.isRequired,
    format: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onClick: PropTypes.func,
    output: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.element,
    ]),
};

DateDisplay.defaultProps = {
    format: "YYYY-MM-DD",
    onClick: null,
    output: "YYYY-MM-DD",
    children: null,
};

export default DateDisplay;

