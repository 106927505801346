import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Close } from "components/icons";

import styles from "./close-button.module.scss";

/**
 * Renders the close button
 *
 * @param {object} props The close button properties
 *
 * @return {React.Component} The close button
 */
const CloseButton = ({ onClick, className, iconClass }) => (
    <button
        type="button"
        aria-label="close"
        data-testid="close-button"
        onClick={onClick}
        className={classNames(styles.closeButton, className)}
    >
        <Close className={classNames(styles.closeIcon, iconClass)} />
    </button>
);

CloseButton.defaultProps = {
    className: "",
    iconClass: "",
    onClick: () => null,
};

CloseButton.propTypes = {
    className: PropTypes.string,
    iconClass: PropTypes.string,
    onClick: PropTypes.func,
};

export default CloseButton;
