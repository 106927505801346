import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InlineFormRow from './inline-form-row';
import TroncSchemeLengthOfServiceInput from './tronc-scheme-length-of-service-input';
import { useFieldArray, useFormContext } from 'react-hook-form';
import ToggleSwitchRow from 'components/forms/toggle-switch-row';
import { PrimaryButton } from 'components';
import { TextInputRow } from 'components/forms';
import { TroncSchemesApi } from 'lib/api-endpoints';

import styles from './tronc-schemes-length-of-service-form.module.scss';

const pointsRegex = new RegExp(/^[0-9]*(\.[0-9]{0,2})?$/);

const getFormType = (isEdit) => {
    return isEdit ? "Edit" : "Add";
};

/**
 * Renders Tronc schemes length of service form
 *
 * @returns {React.Component} Tronc schemes length of service form Component
 */
const TroncSchemeLengthOfServiceForm = ({ isEdit, scheme }) => {
    const { register, getValues, setValue, watch } = useFormContext();

    const {
        fields,
        append,
        remove,
    } = useFieldArray({
        name: "schemeLengthOfService",
    });

    const {
        schemeLengthOfService,
        troncSchemeLengthOfServiceOptIn,
        lengthOfServicePoints,
        lengthOfServiceMonths,
    } = getValues();

    const [loaded, setLoaded] = useState(false);

    watch();

    const getLengthOfService = useCallback(async () => {
        const { data } = await TroncSchemesApi.getTroncScheme(scheme?.id).request;

        const mappedData = data.length_of_service_tronc_points.map((LOS) => ({
            lengthOfServiceMonths: LOS.time_span,
            lengthOfServicePoints: LOS.tronc_points,
        }));

        append(mappedData);
    }, [append, scheme?.id]);

    useEffect(() => {
        if (scheme && !loaded && !fields.length) {
            getLengthOfService();
        }

        setLoaded(true);
    }, [append, loaded, fields, getLengthOfService, scheme]);

    const onAdd = () => {
        append({
            lengthOfServicePoints: parseFloat(lengthOfServicePoints),
            lengthOfServiceMonths: parseFloat(lengthOfServiceMonths),
        });

        setValue('lengthOfServicePoints', '');
        setValue('lengthOfServiceMonths', '');
    };

    return (
        <div className={styles.container}>
            <div className={styles.formWrapper}>
                <InlineFormRow
                    heading={`${getFormType(isEdit)} length of service and tronc points`}
                >
                    <div>
                        <ToggleSwitchRow
                            {...register('troncSchemeLengthOfServiceOptIn')}
                            label="Use tronc scheme length of service points"
                            topMargin={false}
                        />
                        {
                            troncSchemeLengthOfServiceOptIn ? (
                                <div className={styles.cardContainer}>
                                    <div className={styles.cardContent}>
                                        <div className={styles.titleContainer}>
                                            <div className={styles.roleTitle}>
                                                Time span (Months)
                                            </div>
                                            <div className={styles.pointsTitle}>
                                                Total additional tronc points
                                            </div>
                                        </div>
                                        <TroncSchemeLengthOfServiceInput
                                            fields={fields}
                                            register={register}
                                            remove={remove}
                                            schemeLengthOfService={schemeLengthOfService ?? schemeLengthOfService}
                                        />
                                    </div>
                                    <div className={styles.selectContainer}>
                                        <div className={styles.addLOSColumn}>
                                            <div className={styles.roleRow}>
                                                <TextInputRow
                                                    className={styles.input}
                                                    name={`length of service months`}
                                                    data-testid={"length of service months"}
                                                    {...register(`lengthOfServiceMonths`)}
                                                />
                                            </div>
                                            <div className={styles.pointsRow}>
                                                <TextInputRow
                                                    className={styles.input}
                                                    name={`length of service points`}
                                                    data-testid={"length of service points"}
                                                    {...register(`lengthOfServicePoints`)}
                                                />
                                            </div>
                                            <div className={styles.cancelRow}>
                                                <PrimaryButton
                                                    disabled={
                                                        !parseFloat(lengthOfServiceMonths)
                                                        || !parseFloat(lengthOfServicePoints)
                                                        || !pointsRegex.test(lengthOfServicePoints)
                                                    }
                                                    onClick={() => {
                                                        onAdd();
                                                    }}
                                                >
                                                    Add
                                                </PrimaryButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
                </InlineFormRow>
            </div>
        </div>
    );
};

TroncSchemeLengthOfServiceForm.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    scheme: PropTypes.object,
};

TroncSchemeLengthOfServiceForm.defaultProps = {
    scheme: null,
};

export default TroncSchemeLengthOfServiceForm;
