import React, { useState } from 'react';
import { head, isArray, isObject, map, noop } from 'lodash';
import { DateDisplay, LayoutContainer, MoreActionsButton, Page, PageContent, Table } from 'components';
import DocumentsFilters from './components/documents-filters';
import { Button } from 'components/forms';
import useDocumentsList from 'lib/api-endpoints/hooks/company-documents';
import CreateDocumentDrawer from './components/create-document-drawer';
import { DocumentsApi } from 'lib/api-endpoints';
import { streamFileHandler } from "lib/downloaders/stream-file-handler";
import { useSnackbarContext } from 'state/context';

const TABLE_HEADERS = {
    'description': 'subject',
    'uploader': 'added by',
    'datetimeadded': 'date created',
    'actions': 'actions',
};

/**
 * Formats raw endpoint document data into usable table data
 *
 * @param {array} documents array of documents
 *
 * @returns {array} documents table data
 */
const formatData = (documents, { onDocumentDelete }) => map(documents, (currentDocument) => {
    const { datetimeadded, id, uploader, descr } = currentDocument;
    let uploaderName = "Unknown User";

    if (isArray(uploader)) {
        uploaderName = head(uploader);
    } else if (isObject(uploader)) {
        uploaderName = `${uploader?.forename} ${uploader?.surname}`;
    }

    return {
        id,
        description: descr,
        datetimeadded: {
            component: <DateDisplay data={datetimeadded} format="YYYY-MM-DDTHH:mm:ss" output="DD-MM-YYYY HH:mm" />,
        },
        uploader: uploaderName,
        actions: {
            component: (
                <MoreActionsButton
                    actions={[
                        {
                            label: "Download",
                            testId: "action-Download-button",
                            handler: () => streamFileHandler(DocumentsApi.download(
                                id,
                                "company",
                            ).request, { filename: descr }),
                        },
                        {
                            label: "Audit",
                            testId: "action-Audit-button",
                            handler: noop,
                        },
                        {
                            label: "Delete",
                            testId: "action-Delete-button",
                            handler: () => onDocumentDelete(document),
                        },
                    ]}
                />
            ),
        },
    };
});

/**
 * Makes a call to delete the given document and calls a given callback
 *
 * @param {object} currentDocument document to delete
 * @param {function} callback callback function after delete call is made
 */
const handleDeleteDocument = (currentDocument, callback) => {
    DocumentsApi.deleteDocument(currentDocument.id, 'company')
        .request
        .finally(callback);
};

const initialState = {
    page: 1,
    deleteDocument: null,
    refresh: false,
    showDrawer: false,
};

/**
 * Renders the company documents page
 *
 * @returns {React.Component} Documents Page
 */
const Documents = () => {
    const [state, updateState] = useState(initialState);
    const [filters, setFilters] = useState({ start: '', end: '', uploader: '' });
    const { page, refresh, showDrawer } = state;
    const { openSnackbar } = useSnackbarContext();

    const { data: { data, meta, filter_options: filterOptions }, isLoaded } = useDocumentsList({
        type: "company",
        page,
        refresh,
        filters,
    });

    /**
     * Sets a given property to the given value on the state
     *
     * @param {string} property key of state property
     * @param {any} value the value to set the property to
     */
    const setState = (property, value) => {
        updateState((s) => ({
            ...s,
            [property]: value,
        }));
    };

    /**
     * Opens snackbar confirmation for deleting documents
     *
     * @param {object} currentDocument current document to delete
     */
    const onDocumentDelete = (currentDocument) => {
        const toggleRefresh = () => {
            setState('refresh', !refresh);
        };

        openSnackbar({
            message: "Are you sure you want to delete this document?",
            type: "warn",
            actions: [
                { id: 1, text: "Yes", handler: () => handleDeleteDocument(currentDocument, toggleRefresh) },
                { id: 2, text: "No, cancel" },
            ],
        });
    };

    /**
     * Sets drawer open to true
     *
     * @return {void}
     */
    const handleOpen = () => setState('showDrawer', true);

    /**
     * Drawer Success Handler - Closes the drawer and refreshes document data
     */
    const handleSuccess = () => {
        setState('showDrawer', false);
        setState('refresh', !refresh);
    };

    return (
        <Page
            title="Documents"
            titleAction={<Button data-testid="upload-document" onClick={handleOpen}>Create New</Button>}
        >
            <PageContent>
                <LayoutContainer
                    headerContent={(
                        <DocumentsFilters
                            onChange={setFilters}
                            uploaders={filterOptions?.uploader_id}
                            selectedUploader={filters?.uploader}
                        />
                    )}
                >
                    <Table
                        data-testid="documents-table"
                        data={formatData(data, { onDocumentDelete })}
                        skeletonConfig={{
                            rowCount: 10,
                            rowConfig: [
                                { key: 'documents' },
                            ],
                        }}
                        dataLoaded={isLoaded}
                        headings={TABLE_HEADERS}
                        noDataText="No Documents"
                        pagination={{
                            totalPages: meta?.lastPage,
                            currentPage: page,
                            onPageChange: (p) => setState('page', p),
                        }}
                    />
                    <CreateDocumentDrawer
                        isOpen={showDrawer}
                        onClose={() => setState('showDrawer', false)}
                        onSuccess={handleSuccess}
                    />
                </LayoutContainer>
            </PageContent>
        </Page>
    );
};

export default Documents;
