import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
import { useForm } from 'react-hook-form';

import { Form } from 'components/forms';
import { LimitedTextDisplay, SkeletonText } from 'components/';
import InlineEditIcons from './inline-edit-icons';
import InlineEditValidation from './inline-edit-validation';

import styles from './inline-edit.module.scss';

/**
 * Makes content provided clickable input at original location and provides callback to edit the stored data
 *
 * @param {string} content The text to edit
 * @param {string} fieldName The name of the field the content will correspond to in the data
 * @param {function} saveChangesCallback A function to send back the field data from the input
 * @param {function} fetchContent Pass function to refetch page content on successful edit
 * @param {bool} disableEditing Stops inline editing ability
 * @param {bool} contentLoading Check if content loading
 * @param {number} maxCharShown Max number of characters to show before ellipsis cutoff
 *
 * @returns {React.Component} The inline edit component
 */
const InlineEdit = ({
    content,
    fieldName,
    saveChangesCallback,
    fetchContent,
    disableEditing,
    contentLoading,
    maxCharShown,
}) => {
    const [editing, setEditing] = useState(false);
    const [showHoverIcon, setShowHoverIcon] = useState(false);
    const [isLoaded, setIsLoaded] = useState(true);
    const [inputLength, setInputLength] = useState(10);

    const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm({
        mode: "onChange",
        defaultValues: {
            [fieldName]: content,
        },
    });

    useEffect(() => {
        reset({ [fieldName]: content });
    }, [reset, content, fieldName]);

    if (disableEditing) {
        return content;
    }

    /**
     * Handles the submission of the changes if present when the input is blurred
     *
     * @param {object} data The form data to submit
     */
    const onSubmit = (data) => {
        setEditing(false);

        if (content !== data[fieldName]) {
            setIsLoaded(false);

            const { request } = saveChangesCallback({[fieldName]: data[fieldName]});

            request
                .then(() => {
                    fetchContent();
                    setIsLoaded(true);
                })
                .catch((error) => {
                    setIsLoaded(true);
                });
        }
    };

    return (
        <Form className={styles.formWrapper}>
            <label
                className={classNames(styles.inputLabel, { [styles.inputLabelEdit]: editing })}
                onMouseEnter={() => setShowHoverIcon(true)}
                onMouseLeave={() => setShowHoverIcon(false)}
            >
                <span
                    className={classNames(styles.inputValue, {
                        [styles.inputValueEdit]: editing,
                        [styles.inputValueLoading]: contentLoading,
                    })}
                >
                    <LimitedTextDisplay text={getValues(`${fieldName}`)} length={maxCharShown} />
                </span>
                {contentLoading && <SkeletonText className={styles.skeleton} />}
                <input
                    {...register(fieldName, {
                        validate: {
                            minLength: (v) => (v.length >= 3) || "This field requires at least 3 characters",
                        },
                        onChange: (event) => setInputLength(event.target.value.length),
                    })}
                    size={inputLength}
                    onBlur={handleSubmit(onSubmit)}
                    onFocus={() => setEditing(true)}
                    className={classNames(styles.defaultInput, { [styles.editingInput]: editing })}
                    disabled={(!isLoaded || contentLoading)}
                />
                <InlineEditIcons showHoverIcon={showHoverIcon} editing={editing} isLoaded={isLoaded} />
            </label>
            <InlineEditValidation errors={errors} fieldName={fieldName} />
        </Form>
    );
};

InlineEdit.propTypes = {
    content: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    saveChangesCallback: PropTypes.func,
    fetchContent: PropTypes.func,
    disableEditing: PropTypes.bool,
    contentLoading: PropTypes.bool,
    maxCharShown: PropTypes.number,
};

InlineEdit.defaultProps = {
    saveChangesCallback: noop,
    fetchContent: noop,
    disableEditing: false,
    contentLoading: false,
    maxCharShown: 60,
};

export default InlineEdit;
