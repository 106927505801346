import React from "react";
import { useSelector } from "react-redux";
import { routePaths } from "routes";
import NavLink from "./nav-link";
import { NavLink as RouterNavLink } from "react-router-dom";
import { Home, Employees, Companies, TipologyLogo, Files } from "components/icons";

import styles from "./main-navigation.module.scss";

const getCompanyPermissions = (user, companies) => {
    const viewEmployees = user.tronc_master
        || companies.filter(({ companyPermissions }) => companyPermissions?.employees?.canView).length > 0;

    const viewCompanies = user.tronc_master
        || companies.filter(({ companyPermissions }) => companyPermissions?.company?.canView).length > 0;

    const viewPeriodFiles = user.tronc_master
        || companies.filter(({ companyPermissions }) => companyPermissions?.periodFiles?.canView).length > 0;

    return {
        viewCompanies,
        viewEmployees,
        viewPeriodFiles,
    };
};

const MainNavigation = () => {
    const { app, user } = useSelector((state) => state);
    const { viewEmployees, viewCompanies, viewPeriodFiles } = getCompanyPermissions(user, app.companies);

    return (
        <div className={styles.container}>
            <div className={styles.logoContainer}>
                <RouterNavLink
                    to={routePaths.dashboard}
                >
                    <TipologyLogo className={styles.logo} />
                </RouterNavLink>
            </div>
            <nav className={styles.navContainer}>
                <NavLink icon={<Home />} to={routePaths.dashboard}>Home</NavLink>
                {viewEmployees && <NavLink icon={<Employees />} to={routePaths.employees}>Employees</NavLink>}
                {viewCompanies && <NavLink icon={<Companies />} to={routePaths.companies}>Companies</NavLink>}
                {viewPeriodFiles && <NavLink icon={<Files />} to={routePaths.troncFiles}>Files</NavLink>}
            </nav>
        </div>
    );
};

export default MainNavigation;
