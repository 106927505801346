import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { resetCrumbs, setCrumbs, selectCompany } from "state/app-slice";
import { Table } from 'components';
import { flatMap } from 'lodash';
import { SchemeJobRolesApi } from 'lib/api-endpoints';

import styles from './job-roles.module.scss';

/**
 * Renders the company tabs and content
 *
 * @returns {React.Component} The company page
 */
const SchemeJobRoles = ({ selectedScheme }) => {
    const dispatch = useDispatch();
    const { app } = useSelector((state) => state);
    const { id } = useParams();
    const [jobRoles, setJobRoles] = useState([]);

    useEffect(() => {
        const getJobRoles = async () => {
            const { data } = await SchemeJobRolesApi.getJobRoles(selectedScheme?.id).request;

            setJobRoles(data.data);
        };

        if (selectedScheme) {
            getJobRoles();
        }

    }, [selectedScheme]);

    /**
     * Finds current company and gets relevant permissions
     *
     * @returns {object} selected company and permissions
     */
    const {...selectedCompany} = useMemo(() => selectCompany(app, id), [app, id]);

    /**
     * Resets the breadcrumbs when leaving the company page.
     */
    useEffect(() => {
        if (selectedCompany?.name) {
            dispatch(setCrumbs({
                company: selectedCompany.name,
                end: true,
            }));
        }

        return () => dispatch(resetCrumbs());
    }, [dispatch, selectedCompany?.name]);

    const jobRolesData = flatMap(jobRoles, (roles) => {
        if (roles.job_roles.length < 1) {
            return ({
                id: "",
                jobRole: "",
                points: "",
            });
        }

        return flatMap(roles.job_roles, ((role) => {
            return ({
                id: role.id,
                jobRole: role.job_title,
                points: role.points.toString(),
            });
        }));
    }).filter((roles) => roles.id !== "");

    return (
        <div className={styles.tableContainer}>
            <Table
                data={jobRolesData}
                skeletonConfig={{
                    rowCount: 10,
                    rowConfig: [
                        { key: 'job-role' },
                    ],
                }}
                dataLoaded={true}
                headings={{
                    jobRole: "Job Role",
                    points: "Points",
                }}
                noDataText="No Job Roles"
            />
        </div>
    );
};

SchemeJobRoles.propTypes = {
    selectedScheme: PropTypes.object,
};

SchemeJobRoles.defaultProps = {
    selectedScheme: {},
};

export default SchemeJobRoles;
