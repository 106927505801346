import Permissions from "./permissions";

export default class CompanyDivisionsPermissions extends Permissions {

    /**
     * @inheritDoc
     */
    register () {
        const { troncmaster, permissions } = this;
        const viewDivisions = troncmaster || permissions.includes("view_divisions");
        const editDivisions = troncmaster || permissions.includes("edit_divisions");
        const createDivisions = troncmaster || permissions.includes("create_divisions");
        const deleteDivisions = troncmaster || permissions.includes("delete_divisions");

        this.canView = viewDivisions;
        this.canEdit = editDivisions;
        this.canCreate = createDivisions;
        this.canDelete = deleteDivisions;
    }

}
