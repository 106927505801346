import React from 'react';
import PropTypes from 'prop-types';
import numeral from "numeral";
import { CardDisplay, SkeletonText } from 'components';

import styles from './card-display.module.scss';

/**
 * Presents a summary of data with a link to view the full data
 *
 * @param {number} number The summary number to display
 * @param {number} decimalPlaces the amount of decimal places to show
 *
 * @returns {React.Component} The summary component
 */
const CardNumberDisplay = ({ number, decimalPlaces, fieldName }) => {
    const displayNumber = () => {
        const decimalisedNumber = number.toFixed(decimalPlaces);

        if (number === undefined) {
            return <SkeletonText className={styles.skeletonLoader} />;
        }

        if (decimalPlaces === 0) {
            return numeral(decimalisedNumber).format("0,0");
        }

        return numeral(decimalisedNumber).format("0,0[.]00");
    };

    return (
        <CardDisplay fieldName={fieldName} content={displayNumber()} />
    );
};

CardNumberDisplay.propTypes = {
    decimalPlaces: PropTypes.number,
    number: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    fieldName: PropTypes.string,
};

CardNumberDisplay.defaultProps = {
    decimalPlaces: 0,
    number: undefined,
    fieldName: "",
};

export default CardNumberDisplay;
