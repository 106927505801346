import PayrollFilePermissions from "./payroll-file-permissions";
import PayrollEmployeePermissions from "./payroll-employee-permissions";
import PayrollBatchPermissions from "./payroll-batch-permissions";
import UserManagementPermissions from 'lib/permissions/user-management-permissions';

export default class PayrollPermissions {

    /**
 * PayrollPermissions constructor
 *
 * @param {array} permissions The payrolls permissions
 */
    constructor (permissions) {
        Object.assign(this, permissions);
        this.files = new PayrollFilePermissions(permissions);
        this.employees = new PayrollEmployeePermissions(permissions);
        this.batches = new PayrollBatchPermissions(permissions);
        this.userManagement = new UserManagementPermissions(permissions);
    }

    /**
 * Gets a list of payroll permissions to register
 *
 * @returns {Permissions[]} An array containing permission policies
 */
    getPermissionList () {
        const { files, employees, batches, userManagement } = this;

        return [
            files,
            employees,
            batches,
            userManagement,
        ];
    }

}
