import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, LoadingSpinner } from 'components';
import { TroncSchemesApi } from 'lib/api-endpoints';
import { useSelector, useDispatch } from 'react-redux';
import { resetCrumbs, selectCompany, setCrumbs } from 'state/app-slice';
import { useParams } from 'react-router-dom';

import styles from './length-of-service.module.scss';

/**
 * Renders the length of service tabs and content
 *
 * @returns {React.Component} The training page
 */
const LengthOfService = ({ selectedScheme }) => {
    const dispatch = useDispatch();
    const { app } = useSelector((state) => state);
    const { id } = useParams();
    const [scheme, setScheme] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getLengthOfService = async () => {
            const { data } = await TroncSchemesApi.getTroncScheme(selectedScheme?.id).request;

            setScheme(data);
            setLoading(false);
        };

        if (selectedScheme) {
            getLengthOfService();
        }
    }, [selectedScheme]);

    /**
     * Finds current company and gets relevant permissions
     *
     * @returns {object} selected company and permissions
     */
    const {...selectedCompany} = useMemo(() => selectCompany(app, id), [app, id]);

    /**
     * Resets the breadcrumbs when leaving the company page.
     */
    useEffect(() => {
        if (selectedCompany?.name) {
            dispatch(setCrumbs({
                company: selectedCompany.name,
                end: true,
            }));
        }

        return () => dispatch(resetCrumbs());
    }, [dispatch, selectedCompany?.name]);

    return (
        <Card padding={false} className={styles.card}>
            <CardContent className={styles.cardContent}>
                { loading
                    ? (
                        <div className={styles.loadingContainer}>
                            <LoadingSpinner />
                        </div>
                    )
                    : (
                        <>
                            <div className={styles.headerText} >
                                {`Employee will have only one time span (and it's tronc points) active at once.`}
                            </div>
                            <div className={styles.headings}>
                                <div className={styles.typeHeading}>
                                    Time span (months)
                                </div>
                                <div className={styles.statusHeading}>
                                    Total additional tronc points
                                </div>
                            </div>
                            <>
                                { scheme.length_of_service_tronc_points?.map((lengthOfService) => (
                                    <div className={styles.trainingCard} key={lengthOfService.id}>
                                        <div className={styles.typeHeading}>
                                            {lengthOfService.time_span}
                                        </div>
                                        <div className={styles.statusContent}>
                                            {lengthOfService.tronc_points}
                                        </div>
                                    </div>
                                ))}
                            </>
                        </>)}
            </CardContent>
        </Card>
    );
};

LengthOfService.propTypes = {
    selectedScheme: PropTypes.object,
};

LengthOfService.defaultProps = {
    selectedScheme: null,
};

export default LengthOfService;
