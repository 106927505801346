import { useState, useEffect } from "react";
import UpdatesApi from "../updates-api";

const initialState = {
    data: {
        data: [],
    },
    isLoaded: false,
};

/**
 * Gets the updates hook
 *
 * @param {int} page The current page
 * @param {int} perPage The number of updates per page
 * @param {boolean} refresh Trigger the call again without any other property changes
 * @param {string} sortBy The sort by properties
 *
 * @returns {object} The updates state
 */
const useUpdates = ({
    page,
    perPage = 10,
    refresh = false,
    type = null,
}) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        setState(initialState);

        let queryParams = {
            "per_page": perPage,
            page,
        };

        if (type !== null) {
            queryParams.type = type;
        }

        const { request, abortController } = UpdatesApi.getUpdates(queryParams);

        request.then(({ data }) => setState({ data, isLoaded: true }));

        return () => abortController?.abort();
    }, [page, perPage, refresh, type]);

    return state;
};

export default useUpdates;
