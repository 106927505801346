import React from 'react';
import { useFormContext } from 'react-hook-form';
import { first, isEmpty, isFinite, map } from 'lodash';
import { getFormErrors } from 'lib/helpers/validation-helpers';
import { SelectInputRow, TextInputRow } from 'components/forms';
import InlineFormRow from './inline-form-row';

import styles from './tronc-schemes-fees-form.module.scss';

const deductionOptions = [
    { display: "0", value: 0 },
    { display: "50", value: 50 },
    { display: "100", value: 100 },
];

/**
 * Gets a rows error text allowing for multiple form properties to be given in
 * order to return 1 error at a time for those multiple properties
 *
 * @param {object} errors form errors object
 * @param {array} formValueKeys array of form property keys
 *
 * @returns {string} error string
 */
const getErrorText = (errors, formValueKeys) => {
    const errorMessages = map(formValueKeys, (key) => getFormErrors(
        errors,
        key,
        {
            isPercentValid: 'please enter a valid percentage between 0 and 100',
            isAmountValid: 'please enter a valid numerical amount',
            isDependentFieldValid: 'please enter a percentage or an amount but not both',
        }
    ));

    return errorMessages?.length ? first(errorMessages) : '';
};

/**
 * Checks a given percentage string is not empty and between 0 - 100
 *
 * @param {string} percentString percent text input value
 *
 * @returns {boolean} if the percent given is valid
 */
const isPercentValid = (percentString) => {
    const value = Number(percentString);

    return isFinite(value) && value >= 0 && value <= 100;
};

/**
 * Checks a given amount is not empty and above 0
 *
 * @param {string} amountString amount text input value
 *
 * @returns {boolean} if the given amount is valid
 */
const isAmountValid = (amountString) => {
    const value = Number(amountString);

    return isFinite(value) && value >= 0;
};

/**
 * Checks a value is empty - empty strings and 0 are considered empty in this case
 *
 * @param {string} value text input value
 *
 * @returns {boolean} if the value is empty or not
 */
const isValueEmpty = (value) => (isEmpty(value) || Number(value) === 0);

/**
 * Renders Tronc Scheme Fees Form
 *
 * @returns {React.Component} TroncSchemeFeesForm Component
 */
const TroncSchemeFeesForm = () => {
    const { register, getValues, watch, formState: { errors } } = useFormContext();

    const {
        troncmasters_fee_from_fund_percentage: troncmasterDeduction = 0,
        setup_fee_from_fund_percentage: setupDeduction = 0,
        troncs_reserve_percentage: reservePercentage = 0,
        troncs_reserve_fixed_amount: reserveAmount = 0,
        card_transaction_fee_percentage: cardTransactionPercentage = 0,
        card_transaction_fee_fixed_amount: cardTransactionAmount = 0,
    } = getValues();

    watch();

    const reserveError = getErrorText(errors, [
        'troncs_reserve_percentage',
        'troncs_reserve_fixed_amount',
    ]);

    const transactionFeeError = getErrorText(errors, [
        'card_transaction_fee_percentage',
        'card_transaction_fee_fixed_amount',
    ]);

    /**
     * Validates percentage and amount based on if the values are valid and assuming
     * only one of them is allowed to be filled in.
     *
     * @param {string} percent text input value
     * @param {string} amount  text input value
     *
     * @returns {boolean} if percent and amount pair are valid
     */
    const validatePercentAndAmount = (percent, amount) => {
        const isValidPercent = isPercentValid(percent) && isValueEmpty(amount);
        const isValidAmount = isAmountValid(amount) && isValueEmpty(percent);

        return (isValidPercent || isValidAmount);
    };

    return (
        <div className={styles.formWrapper}>
            <InlineFormRow heading="Troncmasters Fee">
                <TextInputRow
                    {
                        ...register('troncmasters_fee_percentage', {
                            required: true,
                            validate: isPercentValid,
                        })
                    }
                    required={true}
                    className={styles.percentage}
                    label="%"
                    topMargin={false}
                />
                <SelectInputRow
                    {...register('troncmasters_fee_from_fund_percentage', { required: true })}
                    required={true}
                    className={styles.deduction}
                    label="Deduct % from fund?"
                    aria-label="deduction options"
                    options={deductionOptions}
                    topMargin={false}
                    selected={troncmasterDeduction}
                />
            </InlineFormRow>
            <InlineFormRow heading="Setup Fee">
                <TextInputRow
                    {...register('setup_fee', { required: true })}
                    required={true}
                    label="Fee amount"
                    topMargin={false}
                    className={styles.amount}
                />
                <SelectInputRow
                    {...register('setup_fee_from_fund_percentage', { required: true })}
                    required={true}
                    label="Deduct % from fund?"
                    aria-label="deduction options"
                    options={deductionOptions}
                    topMargin={false}
                    className={styles.deduction}
                    selected={setupDeduction}
                />
            </InlineFormRow>
            <InlineFormRow
                heading="Card transaction fee"
                error={transactionFeeError}
            >
                <TextInputRow
                    {
                        ...register('card_transaction_fee_percentage', {
                            validate: {
                                isPercentValid,
                                isDependentFieldValid: (percent) => validatePercentAndAmount(
                                    percent,
                                    cardTransactionAmount,
                                ),
                            },
                            deps: ['card_transaction_fee_fixed_amount'],
                        })
                    }
                    label="%"
                    topMargin={false}
                    className={styles.percentage}
                />
                <TextInputRow
                    {
                        ...register('card_transaction_fee_fixed_amount', {
                            validate: {
                                isAmountValid,
                                isDependentFieldValid: (amount) => validatePercentAndAmount(
                                    cardTransactionPercentage,
                                    amount
                                ),
                            },
                            deps: ['card_transaction_fee_percentage'],
                        })
                    }
                    label="Amount"
                    className={styles.amount}
                    topMargin={false}
                />
            </InlineFormRow>
            <InlineFormRow
                heading="Reserve"
                error={reserveError}
            >
                <TextInputRow
                    {
                        ...register('troncs_reserve_percentage', {
                            validate: {
                                isPercentValid,
                                isDependentFieldValid: (percent) => validatePercentAndAmount(
                                    percent,
                                    reserveAmount
                                ),
                            },
                            deps: ['troncs_reserve_fixed_amount'],
                        })
                    }
                    label="%"
                    className={styles.percentage}
                    topMargin={false}
                />
                <TextInputRow
                    {
                        ...register('troncs_reserve_fixed_amount', {
                            validate: {
                                isAmountValid,
                                isDependentFieldValid: (amount) => validatePercentAndAmount(
                                    reservePercentage,
                                    amount
                                ),
                            },
                            deps: ['troncs_reserve_percentage'],
                        })
                    }
                    label="Amount"
                    className={styles.amount}
                    topMargin={false}
                />
            </InlineFormRow>
        </div>
    );
};

export default TroncSchemeFeesForm;
