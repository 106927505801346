import React from 'react';
import PropTypes from 'prop-types';

import styles from './form-content.module.scss';

/**
 * Form content wrapper to prevent overflows
 *
 * @returns {React.Component} Form content Component
 */
const FormContent = ({ children }) => {
    return (
        <div className={styles.preventOverflow}>{children}</div>
    );
};

FormContent.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.node,
    ]).isRequired,
};

export default FormContent;
