import qs from 'qs';
import { api, apiUrl } from "lib/api";

export default class EmployeesApi {

    /**
     * Gets the employee list request promise
     *
     * @param {object} params Additional query params
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getEmployees = (params = {}) => api({
        url: "/employees",
        config: {
            params: { ...params, "troncs_only": true },
        },
    });

    /**
     * Gets the single employee request promise
     *
     * @param {int} employeeId The employee id
     * @param {object} params The request params
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getEmployee = (employeeId, params) => api({
        url: `/employee/${employeeId}`,
        config: {
            params: {...params, "tronc_points_training": true},
        },
    });

    /**
     * Edits the employee details request promise
     *
     * @param {int} employeeId The employee id
     * @param {obj} data The employee data to patch
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static editEmployee = (employeeId, data) => api({
        url: `/employee/${employeeId}`,
        type: 'patch',
        data,
    });

    /**
     * Resets the single employee request promise
     *
     * @param {int} employeeId The employee id
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static resetEmployee = (employeeId) => api({ url: `/employee/${employeeId}/reset`, type: 'put' });

    /**
     * Resets the single employee request promise
     *
     * @param {int} params The employee ids
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static setSignup = (params) => api({ url: `employees/signup_instructions`, type: 'put', config: { params } });

    /**
     * Fetches signup instaructions for the single employee request promise
     *
     * @param {int} employeeId The employee id
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static fetchSignupInstructions = (employeeId) => api({
        url: `/employees/signup_instructions/${employeeId}/pdf_preview`,
        config: { responseType: 'blob' },
    });

    /**
     * Gets list of payslips for the given employee
     *
     * @param {int} employeeId the employee id
     * @param {object} params query params
     *
     * @returns {Promise} axios promis
     */
    static getPayslips = (employeeId, params = {}) => api({
        url: `employee/${employeeId}/payslips`,
        config: { params },
    });

    /**
     * Gets a list of documents for the given employee
     *
     * @param {int} employeeId The employee id
     * @param {object} params query params
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getDocuments = (employeeId, params = {}) => api({
        url: `/documents/${employeeId}`,
        config: { params },
    });

    /**
     * Gets the employee p60s request promise
     *
     * @param {number} employeeId The id of the employee
     * @param {object} params Additional query params
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getP60s = (employeeId, params) => api({ url: `/documents/${employeeId}/eP60`, config: { params } });

    /**
     * Gets the p60 file data promise
     *
     * @param {number} p60Id The id of the p60
     * @param {object} params Additional query params
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getP60FileData = (p60Id, params) => api({
        url: `/raw/file/eP60/${p60Id}`,
        config: { responseType: 'blob', params },
    });

    /**
     * Gets the employee p60s raw pdf file
     *
     * @param {number} p60Id The id of the p60
     * @param {object} params Additional query params
     *
     * @returns {string} payslip url string
     */
    static getP60File = (p60Id, params = {}) => (
        `${apiUrl}/raw/file/eP60/${p60Id}?${qs.stringify(params)}`
    );

    /**
     * Downloads the employee CSV import template
     *
     * @param {object} params The query params
     *
     * @returns {{ request: Promise<unknown>, abortController: AbortController }} The api promise with abort controller
     */
    static getImportCSVTemplate = (params) => api({
        url: '/employees/import_csv_template',
        config: { responseType: 'blob', params },
    });

    /**
     * Handles uploading the employees CSV template to a given payroll
     *
     * @param {string} payroll the payroll id
     * @param {File} employees The file to upload
     *
     * @returns {{request: Promise<unknown>, abortController: AbortController}} The api promise with abort controller
     */
    static uploadEmployees = ({ payroll, employees, scheme }) => {
        const data = new FormData();

        data.append('payroll_id', payroll);
        data.append('employees', employees);
        data.append('tronc_scheme_id', scheme);

        return api({
            type: 'post',
            url: '/employees',
            data,
        });
    };

    /**
     * Edits the employee details request promise
     *
     * @param {int} employeeId The employee id
     * @param {obj} data The employee data to patch
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static editEmployeeTraining = (employeeId, data) => api({
        url: `/employee/${employeeId}/training_status`,
        type: 'patch',
        data,
    });

}
