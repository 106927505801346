import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { LeftArrow, RightArrow } from "components/icons";

import styles from "./pagination.module.scss";

/**
 * Returns an array of numbers based on the given min and max
 *
 * @param {int} max max number in the range
 * @param {int} min min number in the range
 * @returns {array} array of numbers within given range
 */
const getNumberRange = (max = 1, min = 1) => {
    const numbers = [];

    for (let i = min; i <= max; ++i) {
        numbers.push(i);
    }

    return numbers;
};

/**
 * The Pagination Component - renders page numbers and manages which numbers you can see
 *
 * @param {object} props component props
 * @param {string} className classname to be applied to the container
 * @param {int} totalPages the total number of pages
 * @param {int} currentPage the current page you are on
 * @param {function} onPageChange function that is called when prev or next button is clicked
 *
 * @returns {React.Component} Pagination Component
 */
const Pagination = ({
    buttonCount,
    className,
    totalPages,
    currentPage,
    onPageChange,
}) => {
    /**
     * Returns a number button
     * @param {int} number the number on the rendered button
     * @returns {React.Element} Number Button
    */
    const renderNumberButton = (number) => (
        <button
            key={number}
            type="button"
            className={classNames({
                [styles.numberButton]: true,
                [styles.current]: number === currentPage,
            })}
            onClick={() => onPageChange(number)}
            disabled={!Number.isInteger(number)}
        >
            {number}
        </button>
    );

    /**
     * Returns array of numbers that make up the left side numbers of the pagination component
     * @returns {array} Array of numbers
     */
    const getLeftNumbers = () => {
        let nums = [];

        // Gets the set of numbers that the current page is in and uses it to set the range max and min.
        let set = Math.ceil(currentPage / buttonCount);
        let max = (set * buttonCount);
        let min = (max - buttonCount) + 1;

        nums = getNumberRange(max, min);
        nums.push('...');

        return nums;
    };

    /**
     * Renders an array of pagination buttons
     * @returns {array} Array of button React Elements
     */
    const renderNumbers = () => {
        const MAX_CLICKABLE_BUTTONS = buttonCount * 2;
        const END_RANGE_START = (totalPages + 1) - (MAX_CLICKABLE_BUTTONS);

        if (totalPages <= MAX_CLICKABLE_BUTTONS) {
            return getNumberRange(totalPages).map(renderNumberButton);
        } else if (currentPage >= END_RANGE_START) {
            return getNumberRange(totalPages, END_RANGE_START).map(renderNumberButton);
        }

        const leftSideHighestNum = totalPages - buttonCount;
        const leftNums = getLeftNumbers();
        const rightNums = getNumberRange(totalPages, leftSideHighestNum + 1);

        return [...leftNums, ...rightNums].map(renderNumberButton);
    };

    return (
        <div data-testid="pagination-container" className={classNames(styles.container, className)}>
            <button
                type="button"
                className={styles.previousButton}
                disabled={currentPage === 1}
                onClick={() => onPageChange(currentPage - 1)}
            >
                <LeftArrow />
                <span>Previous</span>
            </button>
            <div>
                {renderNumbers()}
            </div>
            <button
                type="button"
                className={styles.nextButton}
                disabled={currentPage === totalPages}
                onClick={() => onPageChange(currentPage + 1)}
            >
                <span>Next</span>
                <RightArrow />
            </button>
        </div>
    );
};

Pagination.propTypes = {
    buttonCount: PropTypes.number,
    className: PropTypes.string,
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    onPageChange: PropTypes.func,
};

Pagination.defaultProps = {
    currentPage: 1,
    totalPages: 1,
    buttonCount: 3,
    className: "",
    onPageChange: () => null,
};

export default Pagination;
