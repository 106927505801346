import React, { useRef } from 'react';
import PropTypes from "prop-types";
import { toNumber } from 'lodash';
import moment from 'moment';
import { TroncReportsApi } from "lib/api-endpoints";
import { streamFileHandler } from 'lib/downloaders/stream-file-handler';
import {
    Card,
    CardContent,
    CardHeader,
    CardNumberDisplay,
    PrimaryButton,
    TertiaryButton,
} from 'components';
import { useNotificationContext } from 'state/context';

import styles from './tronc-report-card.module.scss';

const getHeader = (report) => {
    const message = report.approved
        ? `Approved by ${report.approved_by} at ${report.approved_at}`
        : `Created by ${report.created_by} at ${moment(new Date(report.created_at)).format("DD/MM/YYYY")}`;

    return (
        <>
            <span key={report.id} className={styles.title}>{`Report v${report.version}`}</span>
            <div>{message}</div>
        </>
    );
};

/**
 * This component is used to display the tronc report card
 *
 * @param {number} latestVersion - latest version of the report
 * @param {object} report - report object
 * @param {object} scheme - scheme object
 * @param {function} onGeneratereport - function to generate report
 *
 * @returns {React.Component} - returns the tronc report card
 */
const TroncReportCard = ({ latestVersion, report, scheme, onGeneratereport }) => {
    const { openNotification } = useNotificationContext();
    const showApprove = (report.version === latestVersion) && (!report.approved);
    const currentPeriod = scheme?.currentPeriod.period_number;
    const currentYear = scheme?.currentPeriod.tax_year;
    const cardRef = useRef(null);

    const approveReport = async (id) => {
        await TroncReportsApi.approveReport(id).request;
        onGeneratereport();

        openNotification({
            message: `Reports approved and released successfully`,
            type: "success",
        });
    };

    const downloadReport = async () => {
        await TroncReportsApi.exportReport(report.id).request.then(({ data, headers }) => {
            const { "content-type": type } = headers;
            const blob = new Blob([data], { type });

            const dataPromise = new Promise((resolve) => {
                resolve({ data: blob });
            });

            streamFileHandler(dataPromise, {
                filename: `${scheme.name} report period ${currentPeriod} ${currentYear} version ${report.version}`,
                targetNode: cardRef.current,
            });
        });
    };

    return (
        <div ref={cardRef}>
            <Card padding={false} className={styles.reportCard}>
                <CardHeader title={<div className={styles.header}>{getHeader(report)}</div>} />
                <CardContent className={styles.reportInfo}>
                    <CardNumberDisplay
                        fieldName="Total troncs"
                        number={toNumber(report.total_troncs)}
                        decimalPlaces={2}
                    />
                    <CardNumberDisplay
                        fieldName="Troncmaster fee"
                        number={toNumber(report.troncmasters_fee)}
                        decimalPlaces={2}
                    />
                    <CardNumberDisplay
                        fieldName="Setup fee"
                        number={toNumber(report.setup_fee)}
                        decimalPlaces={2}
                    />
                    <CardNumberDisplay
                        fieldName="Reserve"
                        number={toNumber(report.reserve)}
                        decimalPlaces={2}
                    />
                    <CardNumberDisplay
                        fieldName="Card transaction fee"
                        number={toNumber(report.card_transaction_fee)}
                        decimalPlaces={2}
                    />
                </CardContent>
                <div className={styles.footer}>
                    <TertiaryButton
                        className={styles.exportBtn}
                        onClick={downloadReport}
                    >
                        Export
                    </TertiaryButton>
                    {showApprove ? (
                        <PrimaryButton
                            onClick={() => approveReport(report.id)}
                        >
                            Approve and release to client
                        </PrimaryButton>
                    ) : null}
                </div>
            </Card>
        </div>
    );
};

TroncReportCard.propTypes = {
    latestVersion: PropTypes.number.isRequired,
    report: PropTypes.object.isRequired,
    scheme: PropTypes.object.isRequired,
    onGeneratereport: PropTypes.func.isRequired,
};

export default TroncReportCard;
