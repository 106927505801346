import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isEmpty, noop } from "lodash";
import { useFormContext, Controller } from "react-hook-form";
import FileInputLabel from "./file-input-label";
import FileUpload from "components/icons/file-upload";

import styles from "./file-input.module.scss";

/**
 * Renders the file input
 *
 * @param {object} props The file input properties
 *
 * @return {React.Component} The file input
 */
const FileInput = forwardRef(({
    name,
    className,
    buttonLabel,
    error,
    required,
    onFileUpload,
    ...otherProps
}, ref) => {
    const [fileState, setFileState] = useState({ fileList: {} });
    const { fileList } = fileState;
    const { resetField } = useFormContext();

    const handleFileRemove = (file, field) => {
        const newFiles = new DataTransfer();

        Array.from(fileList)
            .filter((item) => item.name !== file)
            .forEach((fileItem) => newFiles.items.add(fileItem));

        setFileState({ fileList: newFiles.files });

        if (newFiles.files.length > 0) {
            field.onChange(newFiles.files);
        } else {
            resetField(name);
        }
    };

    /**
     * Handle files being selected
     *
     * @param {ChangeEvent} event The form input change event
     * @param {object} field The current field controller
     *
     * @return {void}
     */
    const handleChange = (event, field) => {
        setFileState({ fileList: event.target.files });
        field.onChange(event.target.files);
        onFileUpload();
    };

    const classList = classNames([
        styles.container,
        className,
        {
            [styles.centerButton]: (!fileList.length),
        },
    ]);

    return (
        <div className={styles.wrapper}>
            <div className={classList}>
                <Controller
                    name={name}
                    defaultValue=""
                    render={({ field }) => (
                        <>
                            <input
                                {...otherProps}
                                ref={ref}
                                className={classNames(
                                    styles.nativeInput,
                                    {
                                        [styles.error]: error,
                                    }
                                )}
                                aria-required={required}
                                type="file"
                                name={name}
                                id={name}
                                onChange={(event) => handleChange(event, field)}
                            />
                            {!isEmpty(fileList) && (
                                <FileInputLabel
                                    fileList={fileList}
                                    onClose={(filename, event) => handleFileRemove(
                                        filename,
                                        field,
                                        event,
                                        setFileState
                                    )}
                                />
                            )}
                            {isEmpty(fileList) && (
                                <div className={styles.centerIcon}>
                                    <FileUpload className={styles.icon} />
                                    <label htmlFor={name} className={styles.button}>
                                        Upload a file
                                    </label>
                                </div>
                            )}
                        </>
                    )}
                />
            </div>
        </div>
    );
});

FileInput.propTypes = {
    name: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    error: PropTypes.bool,
    required: PropTypes.bool,
    buttonLabel: PropTypes.string,
    onFileUpload: PropTypes.func,
};

FileInput.defaultProps = {
    multiple: true,
    disabled: false,
    className: "",
    error: false,
    required: false,
    buttonLabel: "Upload Files",
    onFileUpload: noop,
};

export default FileInput;
