import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FormRow, FormRowLabel, TableInput } from "./form-controls";

/**
 * Renders Table Input Row with Label
 *
 * @param {object} props The table input props
 *
 * @return {React.Component} TableInputRow Component
 */
const TableInputRow = forwardRef(({ label, required, ...otherProps }, ref) => {
    return (
        <FormRow>
            <FormRowLabel label={label} required={required} />
            <TableInput ref={ref} {...otherProps} />
        </FormRow>
    );
});

TableInputRow.defaultProps = {
    label: null,
    required: false,
};

TableInputRow.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
};

export default TableInputRow;
