import React from 'react';
import PropTypes from 'prop-types';
import { TextInputRow } from 'components/forms';
import { Cancel } from 'components/icons';
import { TextButton } from 'components';

import styles from './tronc-schemes-job-roles-form.module.scss';

/**
 * Removes the job role from the form state
 *
 * @param {number} index the index of the currently selected job role to remove
 * @param {func} remove removes the object from the form state
 * @param {func} getJobRoleOptions Gets the available job roles for the select
 */
const onRemove = async (index, remove, getJobRoleOptions) => {
    await remove(index);
    getJobRoleOptions();
};

/**
 * Renders Tronc schemes job roles form
 *
 * @returns {React.Component} Tronc schemes job roles form Component
 */
const TroncSchemeJobRolesInput = ({ register, schemeJobRoles, fields, remove, getJobRoleOptions }) => {
    return (
        schemeJobRoles && schemeJobRoles.length > 0 ? (
            fields.map((item, index) => {
                return (
                    <div className={styles.titleContainer} key={item.job_role_id}>
                        <div className={styles.roleRow}>{item.job_title}</div>
                        <input
                            type="hidden" value={item.job_title}
                            {...register(
                                `schemeJobRoles.${index}.job_role_id`,
                                { required: true }
                            )}
                        />
                        <div className={styles.pointsRow}>
                            <TextInputRow
                                className={styles.input}
                                name={`points`}
                                data-testid={"points"}
                                {...register(
                                    `schemeJobRoles.${index}.points`,
                                    { required: true }
                                )}
                                defaultValue={item.points}
                            />
                        </div>
                        <div className={styles.cancelRow}>
                            <TextButton
                                className={styles.cancelButton}
                                onClick={() => onRemove(index, remove, getJobRoleOptions)}
                                data-testid="job-role-cancel-button"
                            >
                                <Cancel className={styles.cancelIcon} />
                            </TextButton>
                        </div>
                    </div>
                );
            })) : <div className={styles.skeletonRow} />
    );
};

TroncSchemeJobRolesInput.propTypes = {
    register: PropTypes.func.isRequired,
    schemeJobRoles: PropTypes.array,
    fields: PropTypes.array.isRequired,
    remove: PropTypes.func.isRequired,
    getJobRoleOptions: PropTypes.func.isRequired,
};

TroncSchemeJobRolesInput.defaultProps = {
    schemeJobRoles: null,
};

export default TroncSchemeJobRolesInput;
