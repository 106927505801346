import React from "react";
import PropTypes from "prop-types";
import SearchInput from "./search-input";

const EmployeeSearchInput = ({ onChange, ...props }) => (
    <SearchInput
        {...props}
        data-testid="employee-search"
        onChange={onChange}
        tooltipText="Search employees by name, email, payroll ref and NI number. (Minimum of 3 characters)"
    />
);

EmployeeSearchInput.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default EmployeeSearchInput;
