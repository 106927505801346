import Permissions from "./permissions";

export default class CompanyPeriodFilesPermissions extends Permissions {

    /**
     * @inheritDoc
     */
    register () {
        const { troncmaster, permissions } = this;

        this.canView = troncmaster || permissions.includes("read_tronc_period_files");
        this.canEdit = troncmaster || permissions.includes("update_tronc_period_files");
        this.canCreate = troncmaster || permissions.includes("create_tronc_period_files");
        this.canDelete = troncmaster || permissions.includes("delete_tronc_period_files");
    }

}
