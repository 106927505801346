import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './tertiary-button.module.scss';

/**
 * Button component with outline styles
 *
 * @param {any} children The content of the button
 * @param {string} className The classname styles
 * @param {boolean} compact Smaller everything
 *
 * @returns {React.Component} The tertiary button
 */
const TertiaryButton = ({ children, className, compact, table, ...otherProps }) => {

    return (
        <button
            type="button"
            className={classNames(styles.tertiaryButton, className, {
                [styles.compact]: compact,
                [styles.table]: table,
            })}
            {...otherProps}
        >
            {children}
        </button>
    );
};

TertiaryButton.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    compact: PropTypes.bool,
    table: PropTypes.bool,
};

TertiaryButton.defaultProps = {
    children: "",
    className: "",
    compact: false,
    table: false,
};

export default TertiaryButton;
