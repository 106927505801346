import React, { useState } from "react";
import PropTypes from "prop-types";
import TypeAhead from "./typeahead";
import UsersApi from "lib/api-endpoints/users-api";

const initialState = [];

const UserListTypeAhead = ({ onSelected }) => {
    const [users, setUsers] = useState(initialState);
    const [loading, setLoading] = useState(false);

    const handleReset = () => setUsers(initialState);

    const onChange = (query) => {
        setLoading(true);

        UsersApi.getUsers({ "search_string": query })
            .request
            .then(({ data }) => {
                const userList = data.data.map((user) => {
                    const { id, forename, surname } = user;

                    return {
                        key: id,
                        display: `${forename} ${surname}`,
                        value: user,
                    };
                });

                setUsers(userList);
                setLoading(false);
            });
    };

    return (
        <TypeAhead
            placeholder="Find User…"
            suggestions={users}
            onChange={onChange}
            onSelected={onSelected}
            loading={loading}
            onBeforeSearch={handleReset}
            onAfterSelect={handleReset}
        />
    );
};

UserListTypeAhead.propTypes = {
    onSelected: PropTypes.func.isRequired,
};

export default UserListTypeAhead;
