import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { find, map } from 'lodash';
import { LayoutContainer, Table } from 'components';
import { resetCrumbs, setCrumbs } from 'state/app-slice';
import useTroncSchemePeriodsFiles from 'lib/api-endpoints/hooks/tronc-scheme-periods-files';

import styles from "./tronc-schemes-periods.module.scss";

const formatPeriods = (periods, routeParams) => {
    const { company, scheme } = routeParams;

    return map(periods, (period) => ({
        ...period,
        "period_number": {
            text: `Period ${period.period_number}`,
            link: `/files/company/${company}/tronc-schemes/${scheme}/periods/${period.id}/files`,
        },
        "tax_year": String(`${period.tax_year} / ${period.tax_year + 1}`),
    }));
};

const perPage = 52;

/**
 * Gets the options for the select input
 *
 * @param {object} schemeState the current scheme
 * @returns {object} The select input options
 */
const getOptions = (schemeState) => {
    return schemeState?.scheme?.tax_years ? schemeState?.scheme?.tax_years.map((data) => ({
        value: data,
        display: `${data} / ${data + 1}`,
    })) : [];
};

/**
 * Renders the tronc scheme period files
 *
 * @returns {React.Component} The tronc scheme period files
 */
const TroncSchemePeriods = () => {
    const [page, setPage] = useState(1);
    const [selectedTaxYear, setSelectedTaxYear] = useState();
    const { company, scheme } = useParams();

    const companies = useSelector((state) => state.app.companies);
    const dispatch = useDispatch();

    const {schemeState, periodsState} = useTroncSchemePeriodsFiles({
        id: scheme,
        page,
        perPage,
        taxYear: selectedTaxYear,
    });

    /**
     * Sets the breadcrumbs state
    */
    useEffect(() => {
        if (periodsState?.isLoaded) {
            const crumbCompany = find(companies, { id: Number(company) });

            dispatch(setCrumbs({
                company: crumbCompany.name,
                scheme: schemeState?.scheme?.name,
            }));
        }

        return () => dispatch(resetCrumbs());
    }, [companies, company, dispatch, schemeState?.scheme?.name, periodsState?.isLoaded]);

    /**
     * Sets the current tax year state
    */
    useEffect(() => {
        if (periodsState?.isLoaded && !selectedTaxYear) {
            setSelectedTaxYear(schemeState?.scheme?.currentPeriod?.tax_year);
        }

    }, [selectedTaxYear, schemeState?.scheme?.currentPeriod?.tax_year, periodsState?.isLoaded]);

    /**
     * Handles a change in the select
     *
     * @param {event} event The input change event
     *
     * @return {void}
     */
    const handleSelectChange = (event) => {
        setSelectedTaxYear(event.target.value);
    };

    return (
        <>
            <div className={styles.buttonContainer}>
                <div className={styles.buttons}>
                    <select
                        value={selectedTaxYear}
                        className={styles.select}
                        onChange={handleSelectChange}
                    >
                        {getOptions(schemeState).map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.display}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <LayoutContainer>
                <Table
                    headings={{
                        "period_number": "Period Number",
                        "tax_year": "Tax Year",
                    }}
                    skeletonConfig={{
                        rowCount: 6,
                        rowConfig: [
                            { key: 'company-tronc-schemes' },
                        ],
                    }}
                    dataLoaded={periodsState.isLoaded}
                    noDataText="No Periods"
                    data={formatPeriods(periodsState.periods, { company, scheme })}
                    pagination={{
                        currentPage: page,
                        onPageChange: setPage,
                        totalPages: periodsState.meta?.last_page,
                    }}
                />
            </LayoutContainer>
        </>
    );
};

export default TroncSchemePeriods;
