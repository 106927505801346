import React from 'react';
import PropTypes from 'prop-types';
import ModalForm from '../modal-form/modal-form';
import SidePanelForm from '../side-panel-form/side-panel-form';

/**
 * Renders Wrapper component based on mode prop
 *
 * @param {object} props component props
 * @param {React.Element} props.children component child elements
 * @param {string} props.mode container mode eg. drawer/modal
 *
 * @returns {React.Component} FormContainer Wrapper Component
 */
const FormContainer = ({ children, mode, ...otherProps }) => {
    const Content = (mode === 'drawer') ? SidePanelForm : ModalForm;

    return (
        <Content {...otherProps}>
            {children}
        </Content>
    );

};

FormContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
        PropTypes.array,
        PropTypes.element,
    ]),
    mode: PropTypes.oneOf([
        "drawer",
        "modal",
    ]),
};

FormContainer.defaultProps = {
    children: null,
    mode: "modal",
};

export default FormContainer;
