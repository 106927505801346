import React from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";

import styles from "./unordered-list.module.scss";

/**
 * Renders UnorderedList
 *
 * @param {array} options The options available to render
 * @param {function|null} onClick The click event for the given list item
 * @param {boolean} margin Add margin
 *
 * @returns {React.Component} The UnorderedList
 */
const UnorderedList = ({ options, onClick, margin }) => (
    <ul
        className={classNames({ [styles.container]: true, [styles.margin]: margin })}
        data-testid="unordered-list"
    >
        {options.map((option) => (
            <li key={option.value}>
                {onClick !== null
                    ? (
                        <button className={styles.button} type="button" onClick={() => onClick(option)}>
                            {option.display}
                        </button>
                    )
                    : <div className={styles.item}>{option.display}</div>
                }
            </li>
        ))}
    </ul>
);

UnorderedList.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        display: PropTypes.any,
    })),
    onClick: PropTypes.func,
    margin: PropTypes.bool,
};

UnorderedList.defaultProps = {
    options: [],
    onClick: null,
    margin: true,
};

export default UnorderedList;
