import { React, useState, useEffect} from "react";
import { Form, FormRow, TextInputRow } from "components/forms";
import { useForm, FormProvider } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationDrawer from "./confirmation-drawer";
import SecurityApi from "lib/api-endpoints/security-api";
import { getUser } from "state/user-slice";
import { checkValidity } from "lib/helpers/validation-helpers";
import { useNotificationContext } from 'state/context';
import { PrimaryButton } from "components";

import styles from './components.module.scss';

/**
 * Handles the opening of the confirmation drawer
 *
 * @param {func} setDrawerState Sets the drawer state
 *
 * @return {void}
*/
const onSubmit = (setDrawerState) => {
    setDrawerState((prevState) => ({...prevState, isOpen: true, isLoading: false}));
};

/**
 * Function for confirming the change mobile number form
 *
 * @param {object} formData the formdata
 * @param {func} getValues gets the values of the form
 * @param {func} setDrawerState sets the drawer state
 * @param {func} openNotification opens the notification
 * @param {func} reset resets the form
 *
 * @return {void}
 */
const onConfirm = (formData, getValues, setDrawerState, openNotification, reset) => {
    const { newMobileNumber, newMobileNumberConfirm } = getValues();

    return SecurityApi.changeMobileNumber({
        "mobile_number": newMobileNumber,
        "mobile_number_confirm": newMobileNumberConfirm,
        ...formData,
    }).request.then(() => {
        setDrawerState({ isOpen: false, isLoading: true });

        openNotification({
            message: "Mobile number successfully changed",
            type: "success",
        });
        reset();
    });
};

/**
 * Renders the change mobile numebr form
 *
 * @returns {React.Component} The change mobile number form
 */
const MobileNumber = () => {
    const [drawerState, setDrawerState] = useState({isOpen: false, isLoading: true});
    const { openNotification } = useNotificationContext();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUser());
    }, [dispatch, drawerState.isOpen]);

    const user = useSelector((state) => state.user);

    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            newMobileNumber: "",
            newMobileNumberConfirm: "",
        },
    });

    const { handleSubmit, reset, getValues, register, formState, watch } = methods;
    const { newMobileNumber, newMobileNumberConfirm } = getValues();
    watch();

    return (
        <>
            <div className={styles.container} data-testid="change mobile number form">
                <FormProvider {...methods}>
                    <Form className={styles.formContainer} onSubmit={handleSubmit(() => onSubmit(setDrawerState))}>
                        <label htmlFor="mobile number" className={styles.inputRow}>Current mobile number</label>
                        <div id="mobile number">
                            {user !== null ? user.mobile_number : ""}
                        </div>
                        <TextInputRow
                            {...register("newMobileNumber", {
                                required: true,
                                validate: {
                                    validEmail: (mobile) => {
                                        const { isValid, message } = checkValidity([
                                            mobile,
                                            newMobileNumberConfirm,
                                        ], "newMobile");

                                        return isValid || message;
                                    },
                                },
                                deps: ['newMobileNumberConfirm'],
                            })}
                            name="newMobileNumber"
                            error={formState.errors?.newMobileNumber?.message}
                            label="New mobile number"
                            data-testid="new-mobile-number"
                            className={styles.input}
                            large
                        />
                        <TextInputRow
                            {...register("newMobileNumberConfirm", {
                                required: true,
                                validate: {
                                    validEmail: (mobile) => {
                                        const { isValid, message } = checkValidity([
                                            mobile,
                                            newMobileNumber,
                                        ], "newMobile");

                                        return isValid || message;
                                    },
                                },
                                deps: ['newMobileNumber'],
                            })}
                            name="newMobileNumberConfirm"
                            error={formState.errors?.newMobileNumberConfirm?.message}
                            label="Confirm new mobile number"
                            data-testid="confirm-new-mobile-number"
                            className={styles.input}
                            large
                        />
                        <FormRow>
                            <PrimaryButton
                                data-testid="mobile-number-save-button"
                                type="submit"
                                disabled={
                                    (!newMobileNumber)
                                    || (newMobileNumber !== newMobileNumberConfirm)
                                }
                                className={styles.saveButton}
                                onClick={() => handleSubmit(() => onSubmit(setDrawerState))}
                            >
                                Save
                            </PrimaryButton>
                        </FormRow>
                    </Form>
                </FormProvider>
            </div>
            <ConfirmationDrawer
                drawerState={drawerState}
                setDrawerState={setDrawerState}
                onConfirm={(formData) => onConfirm(formData, getValues, setDrawerState, openNotification, reset)}
            />
        </>
    );
};

export default MobileNumber;
