import React from "react";
import PropTypes from "prop-types";
import { NavLink, useParams, useLocation } from "react-router-dom";
import { isUndefined, find } from "lodash";
import classNames from "classnames";
import { LoadingMask } from "components/loading-mask";

import styles from "./tab-section.module.scss";

/**
 * Section showing toolbar with links to different content areas
 *
 * @param {array} tabs Objects detailing the available tabs to navigate to
 * @param {string} className The styles to apply
 * @param {boolean} headerUnderline Applies underline separator when true
 *
 * @returns {React.Component} The tab links and content of the active tab
 */
const TabSection = ({ tabs, className, loading, headerUnderline }) => {
    const params = useParams();
    const location = useLocation();

    const activeTab = find(tabs, (tab) => tab.route === params.tab) ?? tabs[0];

    const rootUrl = (params.tab)
        ? location.pathname.substring(0, location.pathname.length - params.tab.length - 1)
        : location.pathname;

    const shouldRenderTab = (tab) => {
        return (isUndefined(tab?.shouldRender) || tab?.shouldRender);
    };

    const renderHeaderAction = () => {
        return shouldRenderTab(activeTab) && (
            <div className={styles.activeTabAlign}>
                {activeTab?.headerAction && activeTab.headerAction}
            </div>
        );
    };

    return (
        <>
            <div className={classNames(styles.tabContainer, className, { [styles.headerUnderline]: headerUnderline })}>
                {tabs.map((tab) => (
                    shouldRenderTab(tab) && (
                        <NavLink
                            key={tab.route}
                            to={`${rootUrl}/${tab.route}`}
                            className={() => classNames({
                                [styles.tab]: true,
                                [styles.active]: tab.route === activeTab.route,
                            })}
                        >
                            {tab.title}
                        </NavLink>
                    )
                ))}
                {renderHeaderAction()}
            </div>
            {shouldRenderTab(activeTab) && (
                <div className={classNames(styles.content, className)}>
                    <LoadingMask loading={loading} className={styles.loadingMask}>
                        {activeTab.content}
                    </LoadingMask>
                </div>
            )}
        </>
    );
};

TabSection.propTypes = {
    className: PropTypes.string,
    headerUnderline: PropTypes.bool,
    loading: PropTypes.bool,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        route: PropTypes.string.isRequired,
        content: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.bool,
        ]),
    })).isRequired,
};

TabSection.defaultProps = {
    className: "",
    headerUnderline: true,
    loading: false,
};

export default TabSection;
