import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { LoadingSpinner } from "components";

import styles from "./loading-mask.module.scss";

/**
 * Component to indicate data is loading, masks underlying elements
 *
 * @param {boolean} loading Show the mask when loading
 * @param {string} containerClasses Classes to size the mask when needed
 *
 * @returns {React.Component} The loading mask
 */
const LoadingMask = ({ loading, containerClasses, children }) => {

    if (!loading) {
        return children;
    }

    return (
        <div className={classNames(styles.container, containerClasses)}>
            <LoadingSpinner />
            <span className={styles.text}>
                Loading&hellip;
            </span>
        </div>
    );
};

LoadingMask.propTypes = {
    loading: PropTypes.bool.isRequired,
    containerClasses: PropTypes.string,
    children: PropTypes.node,
};

LoadingMask.defaultProps = {
    containerClasses: null,
    children: null,
};

export default LoadingMask;
