import React from "react";
import { Page, PageContent } from "components/";

import EmployeeList from "components/employees/EmployeeList";

/**
 * Renders the employees page component
 *
 * @returns {React.Component} The employees component
 */
const Employees = () => {
    return (
        <Page title="Employees">
            <PageContent>
                <EmployeeList />
            </PageContent>
        </Page>
    );
};

export default Employees;
