import { api, apiUrl } from "lib/api";

export default class FilesApi {

    /**
     * Gets the post request promise for adding a new ticket
     *
     * @param {int} id The file id
     * @param {string} type The file type
     * @param {string} accessToken The file access token
     *
     * @returns {string} The api url for accessing file downloads using the access token
     */
    static downloadUrl = ({ id, type, "access_token": accessToken }) => {
        return `${apiUrl}/raw/file/${type}/${id}?token=${accessToken}`;
    };

    /**
     * Deletes the given file
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static deleteFile = ({ id, type }) => api({
        type: 'delete',
        url: `/file/${type}/${id}`,
    });

    /**
     * Uploads the given file
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static uploadFile = ({ file, employee, description, type }) => {
        const data = new FormData();

        data.append('employee', employee);
        data.append('description', description);

        Array.from(file).forEach((fileItem) => {
            data.append('file', fileItem);
        });

        return api({ type: 'post', url: `/file/${type}`, data });
    };

}
