import React from 'react';
import PropTypes from 'prop-types';
import { Tick, Close } from './';

import styles from './validation.module.scss';

const Validation = ({isValid, label}) => {
    const Icon = (isValid)
        ? Tick
        : Close;

    return (
        <div className={styles.validationIconRow}>
            <Icon
                className={(isValid) ? styles.passed : styles.failed}
                width="25"
                height="25"
            />
            {label}
        </div>
    );
};

Validation.propTypes = {
    isValid: PropTypes.bool,
    label: PropTypes.string,
};

Validation.defaultProps = {
    isValid: false,
    label: "",
};

export default Validation;
