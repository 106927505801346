import React from "react";
import { Navigate } from "react-router-dom";
import { isEmpty } from "lodash";

import {
    Announcements,
    Companies,
    Company,
    Dashboard,
    Documents,
    Employee,
    Employees,
    Files,
    FilesCompanies,
    Messages,
    MyAccount,
    Periods,
    Scheme,
    Schemes,
    UserManagement,
    Updates,
    UserDetails,
} from "pages";

/**
 * Check if user has payroll permissions
 *
 * @param {array} payrolls The payrolls for the user
 * @param {object} user The user information
 *
 * @returns {boolean} Should show payroll route if true
 */
const hasPayrollPermission = (payrolls, user) => user?.permissions?.length > 0;

const canViewTronEmployees = (companies, user) => (
    user.tronc_master || !isEmpty(companies.filter((company) => company?.companyPermissions?.employees?.canView))
);

/**
 * Check if user has company permissions
 *
 * @param {array} companies The companies for the user
 * @param {object} user The user information
 *
 * @returns {boolean} Should show company route if true
 */
const hasCompanyPermission = (companies, user) => user?.company_permissions?.length > 0 || user.tronc_master;

/**
 * Check if user has company permissions
 *
 * @param {array} companies The companies for the user
 * @param {object} user The user information
 *
 * @returns {boolean} Should show company route if true
 */
const canViewTroncFiles = (companies, user) => user.tronc_master;

const routes = [
    {
        key: "announcements",
        path: "/announcements",
        name: "Announcements",
        Component: <Announcements />,
        hasPayrollPermission,
    },
    { key: "dashboard", path: "/dashboard", name: "Dashboard", Component: <Dashboard />, hasCompanyPermission: true },
    { key: "documents", path: "/documents", name: "Documents", Component: <Documents />, hasPayrollPermission },
    {
        key: "employees",
        path: "/employees",
        name: "Employees",
        Component: <Employees />,
        hasPayrollPermission: (payrolls) => (
            payrolls.filter(({ permissions }) => permissions.employees.canView).length > 0
        ),
        hasCompanyPermission: canViewTronEmployees,
    },
    {
        key: "employee",
        path: "/employees/:id",
        name: "Employee",
        Component: <Employee />,
        hasCompanyPermission: canViewTronEmployees,
    },
    {
        key: "employeeTab",
        path: "/employees/:id/:tab",
        name: "Employee",
        Component: <Employee />,
        hasCompanyPermission: canViewTronEmployees,
    },
    {
        key: "troncFiles",
        path: "/files",
        name: "Companies",
        Component: <FilesCompanies />,
        hasCompanyPermission: canViewTroncFiles,
    },
    {
        key: "filesTroncSchemes",
        path: "/files/company/:company/tronc-schemes",
        name: "Company",
        Component: <Schemes />,
        hasCompanyPermission: canViewTroncFiles,
        crumbOverrideKey: "company",
    },
    {
        key: "filesPeriods",
        path: "/files/company/:company/tronc-schemes/:scheme/periods",
        name: "Scheme Periods",
        crumbName: "Scheme",
        crumbOverrideKey: "scheme",
        Component: <Periods />,
        hasCompanyPermission: canViewTroncFiles,
    },
    {
        key: "schemePeriodFiles",
        path: "/files/company/:company/tronc-schemes/:scheme/periods/:period/files",
        name: "Period Files",
        crumbName: "Files...",
        crumbOverrideKey: "periodFiles",
        Component: <Files />,
        hasCompanyPermission: canViewTroncFiles,
    },
    { key: "files", path: "/files/payrolls/:period/files", name: "Files", Component: <Files />, hasPayrollPermission },
    {
        key: "companies",
        path: "/companies",
        name: "Companies",
        Component: <Companies />,
        hasCompanyPermission,
    },
    {
        key: "company",
        path: "/companies/:id",
        name: "Company",
        crumbName: "Company",
        crumbOverrideKey: "company",
        Component: <Company />,
        hasCompanyPermission,
    },
    {
        key: "companyTab",
        path: "/companies/:id/:tab",
        name: "Company",
        Component: <Company />,
        hasCompanyPermission,
    },
    {
        key: "scheme",
        path: "/companies/:id/schemes/:scheme",
        name: "Scheme",
        Component: <Scheme />,
        hasCompanyPermission,
    },
    {
        key: "scheme",
        path: "/companies/:id/schemes/:scheme/:tab",
        name: "Scheme",
        Component: <Scheme />,
        hasCompanyPermission,
    },
    { key: "messages", path: "/messages", name: "Messages", Component: <Messages />, hasPayrollPermission },
    {
        key: "myAccount",
        path: "/my-account",
        name: "My Account",
        Component: <Navigate to="/my-account/password" />,
        hasCompanyPermission: true,
    },
    {
        key: "myAccountTab",
        path: "/my-account/:tab",
        name: "My Account",
        crumbOverrideKey: "myAccount",
        Component: <MyAccount />,
        hasCompanyPermission: true,
    },
    {
        key: "userManagement",
        path: "/user-management",
        name: "User Management",
        Component: <UserManagement />,
        hasPayrollPermission: (payrolls) => (
            payrolls.filter(({ permissions }) => permissions?.userManagement.canView)
        ).length > 0,
    },
    {
        key: "userManagementTab",
        path: "/user-management/:tab",
        name: "User Management",
        Component: <UserManagement />,
        hasPayrollPermission: (payrolls) => (
            payrolls.filter(({ permissions }) => permissions?.userManagement.canView)
        ).length > 0,
    },
    {
        key: "userManagementDetails",
        path: "/user-management/:tab/user-details/:id",
        name: "User Details",
        Component: <UserDetails />,
        hasPayrollPermission: (payrolls) => (
            payrolls.filter(({ permissions }) => permissions?.userManagement.canView)
        ).length > 0,
    },
    {
        key: "updates",
        path: "/updates",
        name: "Updates",
        Component: <Updates />,
        hasCompanyPermission,
        hasPayrollPermission,
    },
];

const routePaths = routes.reduce((accum, route) => {
    accum[route.key] = route.path;

    return accum;
}, {});

export { default as ProtectedRoute } from "./protected-route";
export { routes, routePaths };
