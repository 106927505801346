import React from 'react';
import { useSelector } from 'react-redux';
import { map, sortBy } from 'lodash';
import usePaginatedData from 'hooks/usePaginatedData';
import { companySearch } from "lib/search-helpers";
import { Table, PageFilters, CompanySearchInput, LayoutContainer } from 'components';

const formatData = (companies) => map(companies, ({ id, name, site_stats: siteStats }) => {
    const siteCount = siteStats?.count;

    return {
        id,
        name: {
            text: name,
            link: `/files/company/${id}/tronc-schemes`,
        },
        "site_count": String(siteCount),
    };
});

const FilesCompanyList = () => {
    const companies = useSelector((state) => state.app.companies);

    const { setPage, setSearch, data, meta } = usePaginatedData({
        data: companies,
        page: 1,
        perPage: 10,
        handleSearch: companySearch,
    });

    /**
     * Sorts the companies into alphabetical order and filtered by search term
     */
    const sortedCompanies = sortBy(data, (company) => company.name.toLowerCase());

    return (
        <>
            <PageFilters>
                <CompanySearchInput onChange={setSearch} transparent />
            </PageFilters>
            <LayoutContainer>
                <Table
                    data={formatData(sortedCompanies)}
                    noDataText="No Companies"
                    skeletonConfig={{
                        rowCount: 6,
                        rowConfig: [
                            { key: 'companies' },
                        ],
                    }}
                    headings={{
                        name: "Company",
                        "site_count": "Sites",
                    }}
                    pagination={{
                        ...meta,
                        onPageChange: setPage,
                    }}
                />
            </LayoutContainer>
        </>
    );
};

export default FilesCompanyList;
