import React from "react";
import PropTypes from "prop-types";

import styles from "./form-row-label.module.scss";

/**
 * Renders the form row label component
 *
 * @param {object} props The form row label properties
 *
 * @return {React.Component} The form row label component
 */
const FormRowLabel = (props) => {
    if (!props.label) {
        return null;
    }

    return (
        <div className={styles.label} {...props}>
            {props.label} {`${props.required ? "*" : ""}`}
        </div>
    );
};

FormRowLabel.defaultProps = {
    required: false,
    label: null,
};

FormRowLabel.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
};

export default FormRowLabel;
