import React, { useRef } from "react";
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";
import { noop } from "lodash";
import { Attachment } from "components/icons";
import { TertiaryButton } from "components";

import styles from './attachment-input.module.scss';

/**
 * Renders the attachment input
 *
 * @param {function} onChange The change event to handle when input changes
 *
 * @returns {React.Component} The attachment input
 */
const AttachmentInput = ({ onChange }) => {
    const { register } = useForm({ mode: "onChange" });
    const inputElement = useRef(null);
    const { ref } = register("attachments");

    const handleAttachmentChange = (e) => {
        onChange(Array.from(e.target.files));
    };

    const handleAttachmentClick = () => {
        inputElement.current.click();
    };

    return (
        <>
            <input
                className={styles.input}
                {...register("attachments")}
                ref={(e) => {
                    ref(e);
                    inputElement.current = e;
                }}
                data-testid="attachment-button"
                type="file"
                id="attach"
                multiple
                onChange={handleAttachmentChange}
            />
            <TertiaryButton className={styles.replyButton} onClick={handleAttachmentClick}>
                <Attachment className={styles.fileIcon} />
            </TertiaryButton>
        </>
    );
};

AttachmentInput.propTypes = {
    onChange: PropTypes.func,
};

AttachmentInput.defaultProps = {
    onChange: noop,
};

export default AttachmentInput;
