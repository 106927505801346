import React from 'react';
import PropTypes from 'prop-types';
import { TextInputRow } from 'components/forms';
import { Cancel } from 'components/icons';
import { TextButton } from 'components';

import styles from './tronc-schemes-length-of-service-form.module.scss';

/**
 * Renders Tronc schemes length of service input
 *
 * @returns {React.Component} Tronc schemes length of service input Component
 */
const TroncSchemeLengthOfServiceInput = ({ schemeLengthOfService, register, fields, remove }) => {
    return (
        schemeLengthOfService && schemeLengthOfService.length > 0 ? (
            fields.map((item, index) => {
                return (
                    <div className={styles.titleContainer} key={item.id}>
                        <div className={styles.pointsRow}>
                            <TextInputRow
                                className={styles.input}
                                name={`LOSMonths`}
                                data-testid={"LOSMonths"}
                                {...register(
                                    `schemeLengthOfService.${index}.lengthOfServiceMonths`,
                                    { required: true }
                                )}
                                defaultValue={item.lengthOfServiceMonths}
                            />
                        </div>
                        <div className={styles.pointsRow}>
                            <TextInputRow
                                className={styles.input}
                                name={`LOSPoints`}
                                data-testid={"LOSPoints"}
                                {...register(
                                    `schemeLengthOfService.${index}.lengthOfServicePoints`,
                                    { required: true }
                                )}
                                defaultValue={item.lengthOfServicePoints}
                            />
                        </div>
                        <div className={styles.cancelRow}>
                            <TextButton
                                className={styles.cancelButton}
                                onClick={() => remove(index)}
                                data-testid="length-of-service-cancel-button"
                            >
                                <Cancel className={styles.cancelIcon} />
                            </TextButton>
                        </div>
                    </div>
                );
            })) : <div className={styles.skeletonRow} />
    );
};

TroncSchemeLengthOfServiceInput.propTypes = {
    schemeLengthOfService: PropTypes.array,
    register: PropTypes.func.isRequired,
    fields: PropTypes.array.isRequired,
    remove: PropTypes.func.isRequired,
};

TroncSchemeLengthOfServiceInput.defaultProps = {
    schemeLengthOfService: null,
};

export default TroncSchemeLengthOfServiceInput;
