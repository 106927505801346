import React from "react";
import PropTypes from "prop-types";
import { first, isEmpty } from "lodash";
import { useFormContext } from "react-hook-form";
import payrollsPropTypes from "lib/props-schema/payrolls-schema";
import { SelectInputRow, TextInputRow } from "components/forms";
import FileInputRow from "components/forms/file-input-row";
import NoDataCard from "components/no-data-card/no-data-card";

import styles from './import-employee-form-content.module.scss';

/**
 * Formats given array into display value pairs for use as select options
 *
 * @param {array} list array of objects
 * @param {string} displayKey display object key
 * @param {string} valueKey value object key
 *
 * @returns {array} formatted sites into display value option pairs
 */
const getOptions = (list, displayKey, valueKey) => list.map((item) => ({
    display: item[displayKey],
    value: item[valueKey],
}));

/**
 * ImportEmployeeFormContent Component
 *
 * @param {object} props component props
 * @param {array} payrolls The payrolls available
 * @param {array} schemes The schemes available
 *
 * @returns {React.Component} Renders ImportEmployeeFormContent Component
 */
const ImportEmployeeFormContent = ({ payrolls, schemes }) => {
    const { register, getValues } = useFormContext();
    const { payroll, scheme } = getValues();

    /**
     * Displays the only payroll available or a select input for choosing
     *
     * @returns {React.Element} Form Display Row or Select Row
     */
    const renderPayroll = () => {
        if (payrolls.length === 1) {
            const defaultPayroll = first(payrolls);

            return (
                <>
                    <input {...register('payroll')} type="hidden" value={defaultPayroll.id} />
                    <TextInputRow
                        {...register('payroll_name')}
                        label="Payroll"
                        disabled
                        value={defaultPayroll.name}
                    />
                </>
            );
        }

        return (
            <SelectInputRow
                {...register('payroll', { required: true })}
                required={true}
                label="Payroll"
                aria-label="payroll options"
                options={getOptions(payrolls, 'name', 'id')}
                selected={payroll}
            />
        );
    };

    if (isEmpty(payrolls)) {
        return <NoDataCard text="A company must have at least one payroll in order to import employees" />;
    }

    return (
        <>
            {renderPayroll()}
            <SelectInputRow
                {...register('scheme')}
                label="Scheme"
                aria-label="scheme options"
                options={getOptions(schemes, 'name', 'id')}
                selected={scheme}
                className={styles.schemeSelect}
            />
            <FileInputRow
                {...register('employees', { required: true })}
                required={true}
                multiple={false}
                className={styles.fileInput}
            />
        </>
    );
};

ImportEmployeeFormContent.propTypes = {
    payrolls: payrollsPropTypes.isRequired,
    schemes: PropTypes.array.isRequired,
};

export default ImportEmployeeFormContent;
