import { find, map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { LoadingMask } from 'components';
import { SelectInputRow, TextInputRow } from 'components/forms';
import useSitesList from 'lib/api-endpoints/hooks/sites-list';
import useTroncSchemesFrequencyList from 'lib/api-endpoints/hooks/tronc-schemes-frequency-list';
import useTroncPaydateList from 'lib/api-endpoints/hooks/tronc-paydate-list';
import { getFormErrors } from 'lib/helpers/validation-helpers';
import { wholeNumberRegEx } from 'lib/helpers/regex';

import styles from './tronc-schemes-details-form.module.scss';

/**
 * Formats given array into display value pairs for use as select options
 *
 * @param {array} list array of objects
 * @param {string} displayKey display object key
 * @param {string} valueKey value object key
 *
 * @returns {array} formatted sites into display value option pairs
 */
const getOptions = (list, displayKey, valueKey) => map(list, (item) => ({
    display: item[displayKey],
    value: item[valueKey],
}));

/**
 * Formats given array into display/value pairs for use as select options
 *
 * @param {array} arr the array of options
 *
 * @returns {array} array of option objects with display/value pairs
 */
const getOptionsFromArray = (arr) => map(arr, (item) => ({
    display: item,
    value: item,
}));

/**
 * Renders the tronc scheme details form
 *
 * @param {object} props component props
 * @param {integer} companyId company id to get the sites for
 * @param {boolean} isEdit if the form is in edit mode
 *
 * @returns {React.Component} TroncSchemeDetailsForm Component
 */
const TroncSchemeDetailsForm = ({ companyId, isEdit }) => {
    const { register, getValues, formState: { errors }, watch } = useFormContext();
    const { site_id: siteId, frequency_id: frequencyId, pay_date: paydate } = getValues();
    const { sites, isLoaded: sitesLoaded } = useSitesList({ companyId });
    const { frequencies } = useTroncSchemesFrequencyList({ siteId });
    const { payDates } = useTroncPaydateList(true);

    const selectedFrequency = find(frequencies, ({ id }) => Number(frequencyId) === id);

    watch();

    return (
        <LoadingMask loading={!sitesLoaded}>
            <div className={styles.formWrapper}>
                <TextInputRow
                    {...register('name', { required: true })}
                    required={true}
                    label="Scheme Name"
                    className={styles.detailInput}
                    error={getFormErrors(errors, "name")}
                />
                <SelectInputRow
                    {...register('site_id', { required: true })}
                    required={true}
                    label="Site"
                    aria-label="site options"
                    options={getOptions(sites, 'name', 'id')}
                    className={styles.detailInput}
                    selected={siteId}
                    error={getFormErrors(errors, "site_id")}
                />
                {!isEdit
                    ? (
                        <SelectInputRow
                            {...register('frequency_id', { required: true })}
                            required={true}
                            label="Frequency"
                            aria-label="frequency options"
                            options={getOptions(frequencies, 'display_name', 'id')}
                            className={styles.detailInput}
                            selected={frequencyId}
                            error={getFormErrors(errors, "frequency_id")}
                        />
                    ) : null
                }
                <SelectInputRow
                    {...register('pay_date', { required: true })}
                    required={true}
                    label="Pay Date"
                    aria-label="pay date options"
                    options={selectedFrequency
                        ? getOptionsFromArray(payDates?.[selectedFrequency.frequency_type])
                        : []
                    }
                    className={styles.detailInput}
                    selected={paydate}
                    error={getFormErrors(errors, 'pay_date')}
                />
                <TextInputRow
                    {...register('submission_days_before_pay_date', {
                        required: true,
                        pattern: wholeNumberRegEx,
                    })}
                    required={true}
                    label="Submission Days Before Pay Date"
                    className={styles.detailInput}
                    error={getFormErrors(
                        errors,
                        'submission_days_before_pay_date',
                        { pattern: "must be a whole number" }
                    )}
                />
                <TextInputRow
                    {...register('report_return_days_before_pay_date', {
                        required: true,
                        pattern: wholeNumberRegEx,
                    })}
                    required={true}
                    label="Report Return Days Before Pay Date"
                    className={styles.detailInput}
                    error={getFormErrors(
                        errors,
                        'report_return_days_before_pay_date',
                        { pattern: "must be a whole number" }
                    )}
                />
            </div>
        </LoadingMask>
    );
};

TroncSchemeDetailsForm.propTypes = {
    companyId: PropTypes.number.isRequired,
    isEdit: PropTypes.bool,
};

TroncSchemeDetailsForm.defaultProps = {
    isEdit: false,
};

export default TroncSchemeDetailsForm;
