import { useState, useEffect } from "react";
import CompaniesApi from "lib/api-endpoints/companies-api";

const initialState = {
    data: {},
    isLoaded: false,
};

/**
 * Gets the valid user permissions for a given company
 *
 * @param {number} id The company id
 *
 * @returns {object} The user permissions
 */
const useAvailableCompanyPermissions = (id) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        setState(initialState);

        const { request, abortController } = CompaniesApi.getUserPermissions(id);

        request.then(({ data }) => setState({ data, isLoaded: true }));

        return () => abortController?.abort();
    }, [id]);

    return state;
};

export default useAvailableCompanyPermissions;
