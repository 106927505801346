import React from "react";
import PropTypes from "prop-types";

import styles from "./tab-header.module.scss";

const TabHeader = ({ title, children, ...props }) => (
    <div data-testid="tab-header" className={styles.container} {...props}>
        <h1 className={styles.title}>
            {title}
        </h1>
        <div className={styles.header}>
            {children}
        </div>
    </div>
);

TabHeader.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any,
};

TabHeader.defaultProps = {
    children: null,
};

export default TabHeader;
