import { useState, useEffect } from "react";
import { CompaniesApi } from "lib/api-endpoints";

const initialState = {
    sites: [],
    isLoaded: false,
};

const useSitesList = ({ companyId, page = 1 }) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        setState(initialState);

        const { request, abortController } = CompaniesApi.getSites({
            "company_ids": [companyId],
            page,
        });

        request.then(({ data }) => setState({ sites: data?.data, isLoaded: true }));

        return () => abortController?.abort();
    }, [page, companyId]);

    return state;
};

export default useSitesList;
