import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { head, noop } from 'lodash';
import { useSelector } from "react-redux";
import Portal from 'utils/portal';
import { Notification } from 'components';
import { useNotificationContext } from 'state/context';

/**
* Renders the top notification on the notification state if it exists
 *
 * @param {object} props component props
 * @param {string} props.heading the notification title
 * @param {object} props.attributes additional notification container attributes
 *
 * @return {ReactElement} The snack bar handler
 */
const NotificationHandler = ({ heading, attributes, duration }) => {
    const [currentNotification, setCurrentNotification] = useState({});
    const loaded = useSelector((state) => state.app.loaded);
    const { state: notificationState = {}, closeNotification } = useNotificationContext();
    const { notifications } = notificationState;

    useEffect(() => {
        setCurrentNotification(head(notifications));
    }, [notifications]);

    if (notifications?.length === 0 || !loaded) {
        return null;
    }

    /**
     * Closes the current notification and runs the onClose callback if provided
     *
     * @param {Event} e html click event
     */
    const handleCloseNotification = (e) => {
        const { id, onClose = noop } = currentNotification;
        e.preventDefault();

        closeNotification({ id });

        onClose();
    };

    return (
        <Portal>
            <Notification
                id={currentNotification?.id || 0}
                type={currentNotification?.type}
                message={currentNotification?.message}
                attributes={attributes}
                onClose={handleCloseNotification}
                heading={heading}
                duration={duration}
            />
        </Portal>
    );
};

NotificationHandler.propTypes = {
    heading: PropTypes.string,
    attributes: PropTypes.object,
    duration: PropTypes.number,
};

NotificationHandler.defaultProps = {
    heading: null,
    attributes: {},
    duration: 5000,
};

export default NotificationHandler;
