import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import { store, persistor } from "state/store";
import App from "./app";
import Interceptor from "./lib/interceptor";
import ErrorFallback from "components/errors/error-fallback";
import { SnackbarProvider } from "state/context/snackbar-context";
import { NotificationProvider } from "state/context/notification-context";

import "./index.css";
import BackToTop from "components/back-to-top/back-to-top";

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: window?.ENV?.SENTRY_DSN,
    });
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <SnackbarProvider>
                    <NotificationProvider>
                        <Sentry.ErrorBoundary
                            fallback={({ resetError, eventId }) => (
                                <ErrorFallback
                                    resetError={resetError}
                                    eventId={eventId}
                                />
                            )}
                        >
                            <PersistGate loading={null} persistor={persistor}>
                                <Interceptor />
                                <App />
                                <BackToTop />
                            </PersistGate>
                        </Sentry.ErrorBoundary>
                    </NotificationProvider>
                </SnackbarProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);
