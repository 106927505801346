import { useState, useEffect } from "react";
import { TicketsApi } from "lib/api-endpoints";

/**
 * Manages getting ticket visibilities from the api
 *
 * @returns {React.Component} Ticket Visibilty Hook
 */
const useTicketVisibilities = () => {
    const [visibilityData, setData] = useState([]);

    /**
     * Gets ticket visibilities and sets them on the state
     */
    useEffect(() => {
        const { request, abortController } = TicketsApi.getTicketsVisibilityTypes();

        request.then(({ data }) => setData(data));

        return () => abortController?.abort();
    }, []);

    return visibilityData;
};

export default useTicketVisibilities;
