import React from 'react';
import PropTypes from 'prop-types';
import { head } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { Checkbox } from './form-controls';

import styles from './checkbox-group-row.module.scss';

/**
 * Row in the checkbox group showing the label name and checkbox spaced horizontally
 *
 * @param {string} label The label being shown
 * @param {array} headers The system and human readable names
 * @param {string} id The company id
 *
 * @returns {React.Component} Returns the checkbox and label text
 */
const CheckboxGroupRow = ({ label, headers, id, ...otherProps }) => {
    const { register } = useFormContext();

    const labelName = () => head(headers.filter((header) => header.system_name === label)).name;

    return (
        <label className={styles.row} data-testid="checkbox-row-label">
            <div className={styles.permissionName}>{labelName()}</div>
            <Checkbox
                large
                aria-label={`${label}`}
                name={`${label}`}
                value={id}
                {...register(`${label}`)}
                {...otherProps}
            />
        </label>
    );
};

CheckboxGroupRow.propTypes = {
    label: PropTypes.string,
    headers: PropTypes.arrayOf(PropTypes.object),
    id: PropTypes.string.isRequired,
};

CheckboxGroupRow.defaultProps = {
    label: "",
    headers: [],
};

export default CheckboxGroupRow;
