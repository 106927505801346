import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { noop } from "lodash";

import styles from "./text-input.module.scss";

/**
 * Renders the text input
 *
 * @param {object} props The text input properties
 *
 * @return {React.Component} The text input
 */
const DateInput = forwardRef(({ className, error, onBlur, required, placeholder, value, ...otherProps }, ref) => {
    const [type, setType] = useState(value ? 'date' : 'text');

    useEffect(() => {
        const newType = (value) ? 'date' : 'text';
        setType(newType);
    }, [value, setType]);

    return (
        <input
            data-testid="date-input"
            type={type}
            ref={ref}
            placeholder={placeholder}
            aria-required={required}
            className={classNames(
                styles.input,
                className,
                {
                    [styles.error]: error,
                },
            )}
            onFocus={() => setType('date')}
            {...otherProps}
        />
    );
});

DateInput.defaultProps = {
    className: "",
    error: false,
    required: false,
    onBlur: noop,
    placeholder: "",
    value: "",
};

DateInput.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    error: PropTypes.bool,
    required: PropTypes.bool,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string,
};

export default DateInput;
