import React from "react";
import PropTypes from "prop-types";
import Button from "components/forms/form-controls/button";
import { PeriodsApi } from "lib/api-endpoints";
import periodPropTypes from "lib/props-schema/period-schema";
import PeriodFileUploadDrawer from "pages/files/components/file-uploader/period-file-upload-drawer";

/**
 * Renders the open batch actions
 *
 * @param {object} period The period details
 * @param {function} onComplete Callback to be made when marked as complete
 * @param {function} onUpload Callback to be made when files are uploaded
 *
 * @returns {React.Component} The OpenBatchActions component
 */
const OpenBatchActions = ({ period, onComplete, onUpload }) => {
    const completeInput = () => {
        PeriodsApi.completeInput(period.id)
            .request
            .then(({ data }) => onComplete(data));
    };

    return (
        <>
            <PeriodFileUploadDrawer period={period.id} onSuccess={onUpload} buttonText="Upload file" />
            {period.submitted_by === null && <Button onClick={completeInput}>Complete Payroll Input</Button>}
        </>
    );
};

OpenBatchActions.defaultProps = {
    onComplete: () => null,
    onUpload: () => null,
};

OpenBatchActions.propTypes = {
    onComplete: PropTypes.func,
    onUpload: PropTypes.func,
    period: periodPropTypes.isRequired,
};

export default OpenBatchActions;
