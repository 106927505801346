import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { each, groupBy, head, isString, map, repeat } from 'lodash';
import { useNotificationContext } from 'state/context';
import { UsersApi } from 'lib/api-endpoints';
import { getFormErrors } from 'lib/helpers/validation-helpers';
import { emailRegEx } from 'lib/helpers/regex';
import { Form, FormActions, TextInputRow, TableInputRow } from 'components/forms';
import PermissionsTableConfig from './permissions-table-config';

import styles from './edit-user-form.module.scss';

/**
 * Filters out the payrolls not editable by the current user
 *
 * @param {object} defaultValues The default form values including permissions
 * @param {array} allowedPayrollIds List of allowed payroll ids to edit
 * @param {array} userPermissions The user permissions to use as headers
 *
 * @returns {array} The table defaults
 */
const allowedTableDefaults = (defaultValues, allowedPayrollIds) => {
    const defaultPayrolls = defaultValues.permissions.filter((
        (userPayroll) => allowedPayrollIds.includes(userPayroll.payroll_id)
    ));

    return defaultPayrolls.map((payroll) => ({ ...payroll, id: payroll.payroll_id }));
};

/**
 * Formats the assignable permissions, adding initial column for payroll names
 *
 * @param {array} headers Describes the available permissions for the table headers
 *
 * @returns {array} All headers required for table
 */
const formattedTableHeaders = (headers) => {
    return [
        {
            name: "All",
            "system_name": "payroll_name",
        },
        ...headers,
    ];
};

/**
 * Form to edit the user permissions
 *
 * @param {object} defaultValues The default values
 * @param {func} onCancel Function to cancel the edit of user values
 *
 * @returns {React.Component} The form to edit the user
 */
const EditUserForm = ({ defaultValues, onCancel }) => {
    const { allPayrolls, assignablePermissions: headers } = useSelector(({ app: { payrolls } }) => {
        const allAssignablePermissions = payrolls.reduce((acc, payroll) => (
            [...acc, ...payroll.assignable_permissions]
        ), []);
        const groupedAssignablePermissions = groupBy(allAssignablePermissions, "system_name");
        const assignablePermissions = Object.values(groupedAssignablePermissions).map((val) => head(val));

        return { allPayrolls: payrolls, assignablePermissions };
    });
    const { openNotification } = useNotificationContext();
    const { id } = useParams();
    const navigate = useNavigate();

    /**
     * Gets the payrolls the current user can edit
     *
     * @return {func} Memoised payroll id list
     */
    const editablePayrolls = useMemo(
        () => allPayrolls
            .filter((payroll) => payroll?.permissions?.userManagement?.canEdit === true)
            .map((payroll) => payroll?.id),
        [allPayrolls]
    );

    /**
     * Removes non editable payrolls, then maps each permission into a list of the selected ids to match table format
     *
     * @returns {object} Returns default values plus list of payroll id's checked against each header
     */
    const formattedDefaults = useCallback(() => {
        let tablePermissions = { ...defaultValues };

        allowedTableDefaults(defaultValues, editablePayrolls).map(({ payroll_id: payrollId, ...payroll }) => {
            return payroll.permissions.reduce((acc, permission) => {
                if (acc[permission]) {
                    acc[permission] = [...acc[permission], String(payrollId)];
                    return acc;
                }

                acc[permission] = [String(payrollId)];
                return acc;
            }, tablePermissions);
        });

        return {
            ...tablePermissions,
            permissions: [
                ...allowedTableDefaults(defaultValues, editablePayrolls),
                ...allowedTableDefaults(defaultValues, editablePayrolls),
            ],
        };
    }, [defaultValues, editablePayrolls]);

    const methods = useForm({
        mode: "onChange",
        defaultValues: formattedDefaults(),
    });

    const { register, handleSubmit, formState: { errors } } = methods;

    /**
     * Formats the data for submission
     *
     * @param {object} data Form data
     */
    const onSubmit = (data) => {
        const allowedPermissions = headers.map(({ system_name: systemName }) => systemName);

        let toBeProcessed = {};

        each(data, (values, key) => {
            if (allowedPermissions.includes(key)) {
                let usableValues = values ? values : [];

                if (isString(usableValues)) {
                    usableValues = [usableValues];
                }

                usableValues?.forEach((payrollId) => {
                    if (toBeProcessed[payrollId]) {
                        toBeProcessed[payrollId].push(key);
                    } else {
                        toBeProcessed[payrollId] = [key];
                    }
                });
            }
        });

        const permissionsPayload = map(toBeProcessed, (permissions, payrollId) => ({
            "payroll_id": Number(payrollId),
            permissions,
        }));

        const payloadData = {
            forename: data.forename,
            surname: data.surname,
            email: data.email,
            "mobile_number": data.mobile_number,
            permissions: permissionsPayload,
        };

        UsersApi.editUsers(id, payloadData).request
            .then(() => {
                openNotification({
                    message: "User successfully changed",
                    type: "success",
                });
            })
            .then(() => {
                onCancel();
                navigate("/user-management");
            });
    };

    return (
        <FormProvider {...methods} >
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.nameRow}>
                    <TextInputRow
                        {...register("forename", { required: true })}
                        required={true}
                        label="Forename"
                        error={getFormErrors(errors, "forename")}
                    />
                    <TextInputRow
                        {...register("surname", { required: true })}
                        required={true}
                        label="Surname"
                        error={getFormErrors(errors, "surname")}
                    />
                </div>
                <TextInputRow
                    {...register("email", {
                        required: true,
                        pattern: emailRegEx,
                    })}
                    required={true}
                    label="Email"
                    error={getFormErrors(errors, "email", { pattern: "Must be a valid email" })}
                />
                <TextInputRow
                    {...register("mobile_number", {
                        maxLength: 15,
                        pattern: /[0-9\s]{5,6}[0-9]{6,7}/,
                    })}
                    label="Mobile No."
                    error={getFormErrors(errors, "mobile_number", { pattern: "Must be a valid mobile number" })}
                />

                {allowedTableDefaults(defaultValues, editablePayrolls).length ? (
                    <TableInputRow
                        data={allowedTableDefaults(defaultValues, editablePayrolls)}
                        headers={formattedTableHeaders(headers)}
                        config={PermissionsTableConfig}
                        tableClassName={styles.table}
                        headerClassName={styles.tableHeader}
                        rowClassName={styles.tableRows}
                        gridTemplateCols={repeat(`110px `, (headers.length + 1))}
                        bodyScroll={false}
                    />
                ) : null}

                <FormActions onSubmit={handleSubmit(onSubmit)} onCancel={onCancel} />
            </Form>
        </FormProvider>
    );
};

EditUserForm.propTypes = {
    defaultValues: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default EditUserForm;
