import PropTypes from "prop-types";

export const payrollProps = {
    "id": PropTypes.number.isRequired,
    "company_id": PropTypes.number.isRequired,
    "data_template_group": PropTypes.number.isRequired,
    "epay_notif_time": PropTypes.number.isRequired,
    "epay_signupmethod": PropTypes.number.isRequired,
    "epay_useblank_templates": PropTypes.bool.isRequired,
    "frequency": PropTypes.string.isRequired,
    "irish_payroll": PropTypes.bool.isRequired,
    "name": PropTypes.string.isRequired,
    "prints_p45s": PropTypes.bool.isRequired,
    "uses_epayslips": PropTypes.bool.isRequired,
    "live_employees": PropTypes.number,
    "total_employees": PropTypes.number,
};

export const payrollPropTypes = PropTypes.shape(payrollProps);
export const payrollDefaultProps = {};
export default payrollPropTypes;
