import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy, isEmpty } from 'lodash';

import {
    LayoutContainer,
    Page,
    PageContent,
    Table,
    TertiaryButton,
    CreateButton,
    PageFilters,
    CompanySearchInput,
} from 'components';

import AddEditCompanyDrawer from './company-forms/add-edit-company-drawer';
import CompaniesGrid from './companies-grid';
import usePaginatedData from "hooks/usePaginatedData";
import { Button } from 'components/forms';
import { companySearch } from "lib/search-helpers";
import { getCompanies } from "state/app-slice";
import { routePaths } from 'routes';

import styles from './companies.module.scss';

/**
 * Formats the companies for use in the companies table
 *
 * @param {array} companies The companies list
 * @param {function} navigate Allows action to navigate to individual company
 *
 * @returns {array} The formatted companies
 */
const formattedCompanies = (companies, navigate) => companies.map(({
    id: companyId,
    name,
    company_number: companyNumber,
    employee_stats: employeeStats,
    site_stats: siteStats,
    tronc_scheme_stats: schemeStats,
}) => {
    return {
        id: companyId,
        name: {
            text: name,
            className: styles.companyTableName,
            link: `${routePaths.companies}/${companyId}`,
        },
        companyNumber: companyNumber ?? "-",
        employees: String(employeeStats?.count ?? "-"),
        sites: String(siteStats?.count ?? "-"),
        schemes: String(schemeStats?.count ?? "-"),
        actions: {
            component: (
                <div className={styles.actionsCol}>
                    <TertiaryButton
                        compact
                        table
                        onClick={() => navigate(`${routePaths.companies}/${companyId}`)}
                    >
                        View
                    </TertiaryButton>
                </div>
            ),
        },
    };
});

/**
 * The page to add a company when none exist
 *
 * @param {function} setIsOpen sets drawer open
 *
 * @returns {React.Component} The no companies page element
 */
const noExistingCompanies = (setIsOpen) => (
    <CreateButton onClick={() => setIsOpen(true)} >
        Create company
    </CreateButton>
);

/**
 * Companies page displays companies in list, grid or table formats
 *
 * @returns {React.Component} The companies page
 */
const Companies = () => {
    const dispatch = useDispatch();
    const { app: { companies, loaded }, user } = useSelector((state) => state);
    const [isOpen, setIsOpen] = useState(false);

    const { setPage, setSearch, data, meta } = usePaginatedData({
        data: companies,
        page: 1,
        perPage: 10,
        handleSearch: companySearch,
    });

    const sortedCompanies = sortBy(data, (company) => company.name);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getCompanies());
    }, [dispatch]);

    /**
     * Shows companies views based on the number of companies
     *
     * @returns {React.Component} The companies views
     */
    const existingCompanies = () => {
        const tableView = (
            <LayoutContainer>
                <Table
                    data={formattedCompanies(sortedCompanies, navigate)}
                    headings={{
                        name: "Name",
                        companyNumber: "No.",
                        employees: "Employees",
                        sites: "Sites",
                        schemes: "Schemes",
                        actions: "Actions",
                    }}
                    pagination={{
                        ...meta,
                        onPageChange: setPage,
                    }}
                />
            </LayoutContainer>
        );

        const component = companies.length > 10 ? tableView : <CompaniesGrid companies={sortedCompanies} />;

        return (
            <>
                <PageFilters>
                    <CompanySearchInput onChange={setSearch} transparent />
                </PageFilters>
                {component}
            </>
        );
    };

    return (
        <Page
            title="Companies"
            titleAction={(!isEmpty(companies) && user?.tronc_master) && (
                <Button
                    data-testid="create-company"
                    onClick={() => setIsOpen(true)}
                >
                    Add Company
                </Button>
            )}
        >
            <PageContent>
                {!isEmpty(companies) ? existingCompanies() : noExistingCompanies(setIsOpen)}
                <AddEditCompanyDrawer
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    isLoaded={loaded}
                    onCancel={() => setIsOpen(false)}
                />
            </PageContent>
        </Page>
    );
};

export default Companies;
