import { useState, useEffect } from "react";
import PayrollsApi from "lib/api-endpoints/payrolls-api";

const initialState = {
    files: {
        data: [],
    },
    isLoaded: false,
};

const PayrollFiles = ({ period, page, refresh = false, excludeBatchFiles = 0 }) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        if (!period) {
            return null;
        }

        setState(initialState);

        const { request, abortController } = PayrollsApi.getPayrollFiles({
            "period_ids": [period],
            "exclude_batch_files": excludeBatchFiles,
            page,
        });

        request.then(({ data }) => setState({ files: data, isLoaded: true }));

        return () => abortController?.abort();
    }, [page, period, excludeBatchFiles, refresh]);

    return state;
};

export default PayrollFiles;
