import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { head, noop } from 'lodash';
import { useSelector } from "react-redux";
import { Snackbar } from 'components';
import { useSnackbarContext } from 'state/context';

/**
* Renders the top snackbar on the snackbar state if it exists
 *
 * @param {object} props component props
 * @param {string} props.heading the snackbar title
 * @param {object} props.attributes additional snackbar container attributes
 *
 * @return {ReactElement} The snack bar handler
 */
const SnackbarHandler = ({ heading, attributes }) => {
    const [currentSnackbar, setCurrentSnackbar] = useState({});
    const loaded = useSelector((state) => state.app.loaded);
    const { state: snackbarState = {}, closeSnackbar } = useSnackbarContext();
    const { snackbars } = snackbarState;

    useEffect(() => {
        setCurrentSnackbar(head(snackbars));
    }, [snackbars]);

    if (snackbars?.length === 0 || !loaded) {
        return null;
    }

    /**
     * Closes the current snackbar and runs the onClose callback if provided
     *
     * @param {Event} e html click event
     */
    const handleCloseSnackbar = (e) => {
        const { id, onClose = noop } = currentSnackbar;
        e.preventDefault();

        closeSnackbar({ id });

        onClose();
    };

    return (
        <>
            {snackbars.reverse().map((snackbar) => (
                <Snackbar
                    key={snackbar.id}
                    id={snackbar?.id || 0}
                    type={snackbar.type}
                    message={snackbar?.message}
                    attributes={attributes}
                    onClose={handleCloseSnackbar}
                    heading={heading}
                    actions={snackbar?.actions}
                    currentSnackbar={currentSnackbar?.id === snackbar.id}
                />
            ))}
        </>
    );
};

SnackbarHandler.propTypes = {
    heading: PropTypes.string,
    attributes: PropTypes.object,
};

SnackbarHandler.defaultProps = {
    heading: null,
    attributes: {},
};

export default SnackbarHandler;
