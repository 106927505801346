import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import CheckboxGroupRow from './checkbox-group-row';

import styles from './checkbox-group.module.scss';

/**
 * Contains the checkbox rows and section label
 *
 * @param {string} groupName The label for the checkbox section
 * @param {array} permissions The list of system permissions for this group name
 * @param {array} headers The system and human readable permissions
 * @param {string} id The company id
 *
 * @returns {React.Component} The group of checkboxes
 */
const CheckboxGroup = ({ groupName, permissions, headers, id }) => {
    if (!permissions) {
        return null;
    }

    return (
        <div className={styles.groupWrapper}>
            <div className={styles.header}>{capitalize(groupName)}</div>
            <div className={styles.checkboxContainer}>
                {permissions.map((perm) => (
                    <CheckboxGroupRow key={perm} label={perm} headers={headers} id={id} />
                ))}
            </div>
        </div>
    );
};

CheckboxGroup.propTypes = {
    groupName: PropTypes.string,
    permissions: PropTypes.arrayOf(PropTypes.string),
    headers: PropTypes.arrayOf(PropTypes.object),
    id: PropTypes.string.isRequired,
};

CheckboxGroup.defaultProps = {
    groupName: "",
    permissions: null,
    headers: [],
};

export default CheckboxGroup;
