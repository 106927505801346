import React from 'react';

const LinkIcon = () => {
    return (
        <svg
            width="20"
            height="10"
            viewBox="0 0 20 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 10H5c-1.383 0-2.562-.488-3.537-1.463C.488 7.562 0 6.383
                    0 5s.488-2.563 1.463-3.538C2.438.487 3.617 0 5 0h4v2H5c-.833
                    0-1.542.292-2.125.875A2.893 2.893 0 0 0 2 5c0 .833.292 1.542.875 2.125A2.893
                    2.893 0 0 0 5 8h4v2ZM6 6V4h8v2H6Zm5 4V8h4c.833 0 1.542-.292 2.125-.875A2.893 2.893
                    0 0 0 18 5c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0 0 15 2h-4V0h4c1.383 0
                    2.563.487 3.538 1.462C19.513 2.437 20 3.617 20 5s-.487 2.562-1.462 3.537C17.563
                    9.512 16.383 10 15 10h-4Z"
                fill="currentColor"
            />
        </svg>

    );
};

export default LinkIcon;
