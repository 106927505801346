import classNames from "classnames";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";

import styles from "./checkbox.module.scss";

/**
 * Renders a checkbox input
 *
 * @param {bool} error if to apply error styling
 *
 * @return {React.Component} Checkbox Component
 */
const Checkbox = forwardRef(({ error, large, ...otherProps }, ref) => (
    <input
        ref={ref}
        type="checkbox"
        className={classNames(styles.input, {
            [styles.error]: error,
            [styles.large]: large,
        })}
        data-testid="table-checkbox"
        {...otherProps}
    />
));

Checkbox.propTypes = {
    error: PropTypes.bool,
    large: PropTypes.bool,
};

Checkbox.defaultProps = {
    error: false,
    large: false,
};

export default Checkbox;
