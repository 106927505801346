import PropTypes from 'prop-types';

export const ticketPropTypes = PropTypes.shape({
    "id": PropTypes.number.isRequired,
    "status": PropTypes.string.isRequired,
    "date_due": PropTypes.string.isRequired,
    "payroll_id": PropTypes.number.isRequired,
    "payroll_name": PropTypes.string.isRequired,
    "company_id": PropTypes.number.isRequired,
    "company_name": PropTypes.string.isRequired,
    "subject": PropTypes.string.isRequired,
    "created_datetime": PropTypes.string.isRequired,
    "created_by_id": PropTypes.number.isRequired,
    "created_by": PropTypes.string.isRequired,
    "assignee_id": PropTypes.number.isRequired,
    "assignee": PropTypes.string.isRequired,
    "visibility": PropTypes.string.isRequired,
    "restricted_user_access": PropTypes.array.isRequired,
});

export const ticketDefaultProps = {};

export default ticketPropTypes;
