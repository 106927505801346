import React from 'react';
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import _ from 'lodash';
import { Button } from "components/forms/form-controls";
import { Form, TextInputRow } from "components/forms";
import { useForm, FormProvider } from "react-hook-form";
import { checkValidity } from 'lib/helpers/validation-helpers';
import { useNotificationContext } from 'state/context';
import { getUser } from "state/user-slice";
import MFAApi from 'lib/api-endpoints/mfa-api';

import styles from './authentication-form.module.scss';

/**
 * Renders the authentication form for MFA
 *
 * @returns {React.Component} The authentication form
 */
const AuthenticatorForm = ({ disable, submitAction }) => {
    const dispatch = useDispatch();
    const label = (disable) ? "Disable" : "Setup";
    const { openNotification } = useNotificationContext();

    const methods = useForm({
        defaultValues: {
            authCode: "",
        },
    });

    const { register, handleSubmit, getValues, watch } = methods;
    watch();

    const { authCode } = getValues();

    /**
     * MFA api call
     *
     * @return {void}
     */
    const mfaApiCall = async () => {
        const postAddress = (disable) ? "disable" : "setup/verify";
        const type = (disable) ? "disabled" : "set up";

        await MFAApi.setMFAActive({"totp": authCode}, postAddress).request;
        await dispatch(getUser());

        openNotification({
            message: `MFA successfully ${type}`,
            type: "success",
        });
    };

    /**
     * Handles the saving of changes once validated
     *
     * @return {void}
     */
    const onSubmit = async () => {
        await mfaApiCall();
        submitAction();
    };

    return (
        <div data-testid="authentication_form">
            <FormProvider {...methods}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <TextInputRow
                        {...register("authCode", {
                            validate: {
                                valid: (code) => checkValidity(code, 'setupCode').isValid,
                            },
                        })}
                        placeholder="000000"
                        className={styles.textInput}
                        name={`authCode`}
                        label={`${label} Code`}
                    />
                    <Button
                        className={styles.button}
                        type="submit"
                    >
                        {label}
                    </Button>
                </Form>
            </FormProvider>
        </div>
    );
};

AuthenticatorForm.propTypes = {
    disable: PropTypes.bool,
    submitAction: PropTypes.func,
};

AuthenticatorForm.defaultProps = {
    disable: false,
    submitAction: _.noop,
};

export default AuthenticatorForm;
