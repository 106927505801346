import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { forEach, head, isEmpty, noop, parseInt } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { useNotificationContext } from 'state/context';
import { UsersApi, useAvailableCompanyPermissions, useUserDetails } from "lib/api-endpoints";
import { Drawer, DrawerTitle } from "components";
import { Form, FormActions } from "components/forms";
import UserFormContent from "./user-form-content";
import UserListTypeAhead from "components/typeahead/user-list-typeahead";
import FormContent from "components/forms/form-container/form-content";

/**
 * Formats the default form values returned as part of the userDetails
 *
 * @param {object} userDetails The current user information if present
 * @param {int} companyId The company id
 *
 * @returns {object} The formatted default values for the form
 */
const formattedDefaults = (userDetails, companyId) => {
    let formDefaults = {};

    const formFields = [
        "forename",
        "surname",
        "mobile_country_code",
        "mobile_number",
        "email",
    ];

    const currentUserPermissisons = head(userDetails?.company_permissions?.filter((comp) => {
        return parseInt(comp.company_id) === companyId;
    }))?.permissions;

    formFields.forEach((field) => (
        formDefaults[field] = userDetails[field]
    ));

    currentUserPermissisons?.forEach((perm) => {
        formDefaults[perm] = true;
    });

    return formDefaults;
};

/**
 * Creates the payload to submit for the add or edit forms based on form data and available perms
 *
 * @param {object} formData The data from the form
 * @param {array} allowedPermissions The permissions list for the current user
 * @param {int} companyId The current company id
 * @param {boolean} isEdit Whether the form is editing or creating a user payload
 *
 * @returns {object} The formatted default values for the form
 */
const createUserPayload = (formData, allowedPermissions, companyId, isEdit) => {
    const { forename, surname, email, mobile_number: mobileNumber, mobile_country_code: countryCode } = formData;
    const newUserPermissions = [];

    forEach(formData, (value, key) => {
        if (allowedPermissions.includes(key) && value) {
            newUserPermissions.push(key);
        }
    });

    const addUserPayload = {
        forename,
        surname,
        email,
        "mobile_country_code": countryCode ? countryCode : "+44 ",
        "mobile_number": mobileNumber,
        companies: [
            {
                "company_id": Number(companyId),
                permissions: newUserPermissions,
            },
        ],
    };

    const editUserPayload = {
        forename,
        surname,
        email,
        "mobile_country_code": countryCode,
        "mobile_number": mobileNumber,
        "company_permissions": [
            {
                "company_id": Number(companyId),
                permissions: newUserPermissions,
            },
        ],
    };

    return isEdit ? editUserPayload : addUserPayload;
};

/**
 * Determines if selected user belongs to the company
 *
 * @param {object} user The user details
 * @param {int} companyId The company id
 *
 * @returns {boolean} Belongs to the company or not
 */
const userBelongsToCompany = (user, companyId) => {
    return user.company_permissions?.filter(({ "company_id": selectedCompanyId }) => {
        return selectedCompanyId === companyId;
    }).length > 0;
};

/**
 * Gets the title to show for the add/edit/assign user drawer
 *
 * @param {boolean} isEdit Editing an existing user
 * @param {boolean} userExistsOnComplete User belongs to selected company
 *
 * @returns {string} The title
 */
const getTitle = (isEdit, userExistsOnComplete) => {
    if (!userExistsOnComplete && isEdit) {
        return "Assign Existing User To This Company";
    }

    return !isEdit ? "Add a User" : "Edit a User";
};

/**
 * Gets the form settings display
 *
 * @param {object} userDetails The user details
 * @param {int} companyId The company id
 *
 * @returns {object} The form settings
 */
const getFormSettings = (userDetails, companyId) => {
    const isEdit = !isEmpty(userDetails);
    const userExistsOnComplete = userBelongsToCompany(userDetails, companyId);
    const secondaryButtonTitle = (isEdit && !userExistsOnComplete) ? "Add" : "Edit";
    const primaryButtonText = (!isEdit) ? "Create" : secondaryButtonTitle;

    return {
        isEdit,
        title: getTitle(isEdit, userExistsOnComplete),
        primaryButtonText,
    };
};

/**
 * Resets the form and runs custom methods on close
 *
 * @param {function} onClose The custom methods to run
 * @param {function} reset The form reset to default values
 *
 * @returns {void}
 */
const onDrawerClose = (onClose, reset) => {
    onClose();
    reset();
};

/**
 * Renders a drawer to create a new compny user
 *
 * @param {boolean} isOpen Is the form drawer open
 * @param {int} companyId the company id
 * @param {int} userId the user id
 * @param {func} refreshUsers refreshes parent component state to update user list
 * @param {func} onClose The action to close the form
 * @param {func} onSelected The action to call when a user is selected from the typeahead
 *
 * @returns {React.Component} The create company user drawer
 */
const AddEditUserDrawer = ({ isOpen, companyId, userId, refreshUsers, onClose, onSelected }) => {
    const {
        data: { data: userPermissions },
        isLoaded: isPermsLoaded,
    } = useAvailableCompanyPermissions(companyId);

    const { data: userDetails, isLoaded: isUserLoaded } = useUserDetails(userId);
    const { openNotification } = useNotificationContext();
    const { isEdit, title, primaryButtonText } = getFormSettings(userDetails, companyId);

    const methods = useForm({
        mode: "onChange",
        defaultValues: formattedDefaults(userDetails, companyId),
    });

    methods.watch();

    useEffect(() => {
        methods.reset(formattedDefaults(userDetails, companyId));

        return methods.reset;
    }, [companyId, isPermsLoaded, isUserLoaded, methods, methods.reset, userDetails]);

    /**
     * Formats a payload from form data on submission and posts to add user endpoint
     *
     * @param {object} formData the form data
     */
    const onSubmit = (formData) => {
        const allowedPermissions = userPermissions.map(({ system_name: systemName }) => systemName);

        if (isEdit) {
            UsersApi
                .editUsers(userId, createUserPayload(formData, allowedPermissions, companyId, isEdit))
                .request
                .then(onSuccess);
        } else {
            UsersApi
                .addUsers(createUserPayload(formData, allowedPermissions, companyId, isEdit))
                .request
                .then(onSuccess);
        }
    };

    /**
     * Success method to notify user, reset the form and close drawer
     */
    const onSuccess = () => {
        openNotification({
            message: "User successfully updated",
            type: "success",
        });

        onClose();
        refreshUsers();
    };

    return (
        <Drawer
            isOpen={isOpen}
            isLoading={!((isPermsLoaded && isUserLoaded) || !userId)}
            headerContent={<DrawerTitle>{title}</DrawerTitle>}
            onClose={() => onDrawerClose(onClose, methods.reset)}
            onOutsideClick={() => onDrawerClose(onClose, methods.reset)}
            padding={false}
            wide={true}
            ariaLabel="user drawer"
        >
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <FormContent>
                        <UserListTypeAhead onSelected={onSelected} />
                        <UserFormContent headers={userPermissions} />
                    </FormContent>
                    <FormActions
                        onSubmit={methods.handleSubmit(onSubmit)}
                        submitText={primaryButtonText}
                        onCancel={() => onDrawerClose(onClose, methods.reset)}
                    />
                </Form>
            </FormProvider>
        </Drawer>
    );
};

AddEditUserDrawer.propTypes = {
    isOpen: PropTypes.bool,
    companyId: PropTypes.number.isRequired,
    userId: PropTypes.number,
    refreshUsers: PropTypes.func,
    onClose: PropTypes.func,
    onSelected: PropTypes.func,
};

AddEditUserDrawer.defaultProps = {
    isOpen: false,
    refreshUsers: noop,
    userId: null,
    onClose: noop,
    onSelected: noop,
};

export default AddEditUserDrawer;
