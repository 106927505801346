import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAvatar, UserName, FloatingMenu } from "components/";
import { routePaths } from "routes";
import { clearSessionData } from "lib/auth";

import styles from "./main-header.module.scss";

/**
 * Renders the user avatar with the available menu actions
 *
 * @returns {React.Component} The UserMenu component
 */
const UserMenu = () => {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const buttonRef = useRef(null);

    const handleClick = () => {
        setMenuOpen(true);
    };

    const handleClose = () => {
        setMenuOpen(false);
    };

    return (
        <>
            <button
                aria-label="Open User Menu"
                type="button"
                onClick={handleClick}
                ref={buttonRef}
                className={styles.userContainer}
            >
                <UserAvatar />
                <span className={styles.userName}>
                    <UserName />
                </span>
            </button>
            {menuOpen && (
                <FloatingMenu
                    actions={[
                        {
                            label: "My Account",
                            handler: () => {
                                navigate(routePaths.myAccount);
                            },
                        },
                        {
                            label: "Logout",
                            handler: clearSessionData,
                        },
                    ]}
                    anchorRef={buttonRef}
                    onAttemptClose={handleClose}
                />
            )}
        </>
    );
};

export default UserMenu;
