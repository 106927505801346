import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { SelectInputRow } from "components/forms";

/**
 * Renders the tax years options
 *
 * @param {object} props The tax year props
 * @param {array} props.years The tax years
 *
 * @returns {React.Component} The tax year selection
 */
const TaxYearsOptions = forwardRef(({ years, ...otherProps }, ref) => {
    const options = years.map(({ start, end }) => ({
        value: start,
        display: `${start}/${end.toString().slice(-2)}`,
    }));

    return (
        <SelectInputRow
            ref={ref}
            label="Tax Years"
            aria-label="Yax year options"
            options={options}
            {...otherProps}
        />
    );
});

TaxYearsOptions.propTypes = {
    years: PropTypes.array.isRequired,
};

export default TaxYearsOptions;
