import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Button } from 'components/forms';
import { TroncReportsApi } from "lib/api-endpoints";
import { useSnackbarContext, useNotificationContext } from "state/context";

import styles from './tronc-scheme-drawer-header.module.scss';

const TroncReportDrawerHeader = ({ scheme, newestReport, onGeneratereport }) => {
    const [disableButton, setDisableButton] = useState(false);
    const { openSnackbar } = useSnackbarContext();
    const { openNotification } = useNotificationContext();

    const handleGenerateReport = () => {
        if (newestReport?.approved) {
            openSnackbar({
                message: `Are you aware you are re-opening a tronc
                 report that has already been approved by the
                 client? You should only re-open this report if you need
                 to make a backdated change`,
                actions: [
                    { text: 'Yes', handler: generateReport, id: "confirm" },
                    { text: 'No', id: "cancel" },
                ],
                type: "warn",
            });
            return;
        }

        generateReport();
    };

    const generateReport = () => {
        setDisableButton(true);

        TroncReportsApi.generateReport(scheme.currentPeriod.id)
            .request
            .then(() => {
                onGeneratereport();
                setDisableButton(false);

                openNotification({
                    message: `Reports generated successfully`,
                    type: "success",
                });
            });
    };

    return (
        <div className={styles.header}>
            <div className={styles.company}>{scheme?.name}</div>
            <div className={styles.period}>{`Period ${scheme?.currentPeriod.period_number}`}</div>
            <Button disabled={disableButton} onClick={handleGenerateReport}>Generate report</Button>
        </div>
    );
};

TroncReportDrawerHeader.propTypes = {
    scheme: PropTypes.object.isRequired,
    newestReport: PropTypes.object,
    onGeneratereport: PropTypes.func.isRequired,
};

TroncReportDrawerHeader.defaultProps = {
    newestReport: {},
};

export default TroncReportDrawerHeader;
