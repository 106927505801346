import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmpty, map } from 'lodash';
import { TroncsPeriodsApi } from 'lib/api-endpoints';
import { streamFileHandler } from 'lib/downloaders/stream-file-handler';
import { DateDisplay, Table, TextButton } from 'components';
import TableAction from 'components/table/table-action';

import styles from './tronc-file-processing.module.scss';

/**
 * Downloads the tronc file
 *
 * @param {number} fileId The file id
 * @param {string} name The name of the file
 * @param {object} formRef Reference to the form downloading from
 *
 * @returns {void}
 */
const downloadFile = (fileId, name, formRef) => {
    const { request } = TroncsPeriodsApi.downloadTroncFile(fileId);

    streamFileHandler(request, { filename: name, targetNode: formRef.current });
};

/**
 * Formats the contact name
 *
 * @param {string} forename The first name of the contact
 * @param {string} surname The last name of the contact
 *
 * @returns {string} The full name or placeholder
 */
const getName = (forename, surname) => {
    const name = [forename, surname].filter((val) => !isEmpty(val));

    if (isEmpty(name)) {
        return "-";
    }
    return name.join(" ");
};

/**
 * Gets the data and formats for the tronc file table
 *
 * @param {array} data The table rows
 * @param {object} formRef Reference to the form
 *
 * @returns {string} The full name or placeholder
 */
const getTableData = (data, formRef) => map(data, ({
    id,
    filename,
    created_at: uploaded,
    uploader: { forename, surname },
    processed,
}) => {
    return {
        id,
        filename: {
            component: (
                <TextButton onClick={() => downloadFile(id, filename, formRef)}>
                    {filename}
                </TextButton>
            ),
        },
        contact: getName(forename, surname),
        status: processed ? "processed" : "pending",
        uploaded: {
            component: [
                <DateDisplay
                    key={`${id}-uploaded-date`}
                    format="YYYY-MM-DDTHH:mm:ss"
                    output="DD-MM-YY"
                    data={uploaded}
                >
                    {`by ${getName(forename, surname)}`}
                </DateDisplay>,

            ],
        },
        actions: {
            component: (
                <TableAction
                    onClick={() => downloadFile(id, filename, formRef)}
                >
                    Download
                </TableAction>
            ),
        },
    };
});

/**
 * Formats the period information for the tronc files drawer
 *
 * @param {object} scheme The scheme details
 *
 * @returns {string} The formatted period number and submission date
 */
const periodInfo = (scheme) => {
    const submissionDateFormatted = moment(scheme?.currentPeriod?.submission_date).format("DD MMM");

    return `Period ${scheme?.currentPeriod?.period_number} - ${submissionDateFormatted}`;
};

/**
 * Renders the tronc files for the given scheme
 *
 * @param {array} files The tronc files for all scheme periods
 * @param {object} scheme The selected scheme
 *
 * @returns {React.Component} Renders the tronc files for the given scheme
 */
const TroncFileProcessing = ({ files, scheme }) => {
    const formRef = useRef(null);

    if (!scheme) {
        return null;
    }

    const schemeFiles = files.filter((file) => (
        file.tronc_period_id === scheme.currentPeriod.id
    ));

    const unprocessedFiles = schemeFiles.filter((file) => !file.processed);

    return (
        <div ref={formRef}>
            <div>
                <div className={styles.schemeTitle}>
                    {scheme.name}
                </div>
                <div className={styles.periodInfo}>
                    {periodInfo(scheme)}
                </div>
            </div>
            <div className={styles.tableContainer}>
                <Table
                    data={getTableData(unprocessedFiles, formRef)}
                    headerCellClass={styles.tableHeader}
                    headings={{
                        filename: "File Name",
                        uploaded: "Uploaded",
                        contact: "Contact",
                        status: "Status",
                        actions: "Actions",
                    }}
                />
            </div>
        </div>
    );
};

TroncFileProcessing.propTypes = {
    scheme: PropTypes.object,
    files: PropTypes.array,
};

TroncFileProcessing.defaultProps = {
    scheme: null,
    files: [],
};

export default TroncFileProcessing;
