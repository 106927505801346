import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'components';

/**
 * Renders Drawer with any children
 *
 * @param {object} props component props
 * @param {React.Element} children component children
 *
 * @returns {React.Component} Drawer Component with any children
 */
const SidePanelForm = ({ children, ...otherProps }) => (
    <Drawer ariaLabel="side panel form" {...otherProps}>
        {children}
    </Drawer>
);

SidePanelForm.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
        PropTypes.array,
        PropTypes.element,
    ]),
};

SidePanelForm.defaultProps = {
    children: null,
};

export default SidePanelForm;
