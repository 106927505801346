import { api } from "lib/api";

export default class PayrollsApi {

    /**
     * Gets the payrolls list request promise
     *
     * @param {object} params Any additional query params
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getPayrolls = (params = { properties: "total_employees, live_employees, assignable_permissions" }) => {
        return api({ url: "/payrolls", config: { params }});
    };

    /**
     * Gets the single payroll request promise
     *
     * @param {int} id The payroll id
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getPayroll = (id) => api({ url: `/payroll/${id}` });

    /**
     * Gets the edit request promise
     *
     * @param {int} id The payroll id
     * @param {object} data The data to edit
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static editPayroll = (id, data) => api({ type: 'patch', url: `/payroll/${id}`, data });

    /**
     * Gets the periods request promise
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getPeriods = ({ id, year }) => api({ url: `/payroll/${id}/periods`, config: { params: { year }}});

    /**
     * Gets the tax years request promise
     *
     * @param {int} id The payroll id
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getTaxYears = (id) => api({ url: `/payroll/${id}/tax_years` });

    /**
     * Gets the payroll files request promise
     *
     * @param {object} params Additional query params
     *
     * @returns {Promise} Returns the promise
     */
    static getPayrollFiles = (params) => api({ url: `/payroll_files`, config: { params } });

    /**
     * Gets the payrolls file data request promise
     *
     * @param {int} id The payroll id
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getPayrollFileData = (id) => api({ url: `/payroll_files/${id}/data`, config: { responseType: 'blob' }});

    /**
     * Adds a list of files to a given payrolls period
     *
     * @param {object} formData The payroll files form data
     * @param {FileList} formData.files The files to upload
     * @param {string} formData.period The period to upload the files to
     * @param {string} formData.message Additional message for the files uploaded
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static addPayrollFiles = ({ period, files, message }) => {
        const data = new FormData();

        data.append('period', period);

        if (message) {
            data.append('message', message);
        }

        Array.from(files).forEach((fileItem) => {
            data.append('files[]', fileItem);
        });

        return api({ type: 'post', url: `/payroll_files`, data });
    };

}
