import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { SelectInputRow } from "components/forms";

/**
 * Renders the departments options
 *
 * @param {object} props The payroll departments options props
 * @param {array} props.departments The departments
 *
 * @returns {React.Component} The departments selection component
 */
const DepartmentOptions = forwardRef(({ departments, ...otherProps }, ref) => {
    const options = departments.map(({ dept_name: deptName, dept_no: deptNo }) => ({
        value: deptName,
        display: deptNo,
    }));

    return (
        <SelectInputRow
            data-testid="department-options"
            aria-label="Department options"
            ref={ref}
            label="Departments"
            options={options}
            {...otherProps}
        />
    );
});

DepartmentOptions.propTypes = {
    departments: PropTypes.array.isRequired,
};

export default DepartmentOptions;
