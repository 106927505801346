import React from "react";
import PropTypes from "prop-types";
import { Breadcrumbs } from "components/breadcrumbs";

import styles from "./page.module.scss";
import classNames from "classnames";

/**
 * Renders page frame with given title, actions, header and children
 *
 * @param {object} props component props
 * @param {React.Element} titleAction react element to be rendered in title bar
 * @param {string} title page title
 * @param {React.Element} header header component to be rendered beneath title
 * @param {boolean} renderBreadcrumbs control for showing breadcrumbs to user
 * @param {boolean} marginTop control to add margin to top of page
 * @param {React.Element} children page content
 *
 * @returns {React.Component} Page Component
 */
const Page = ({ titleAction, title, subtitle, header, renderBreadcrumbs, marginTop, children }) => {
    /**
     * Gets the page title or null if not provided
     *
     * @returns {React.Element} the page title
     */
    const renderTitle = () => {
        if (!title) {
            return null;
        }

        return (
            <h1 data-testid="page-title" className={styles.title}>
                {title}
            </h1>
        );
    };

    /**
     * Renders page subtitle if provided
     *
     * @returns {React.Element} the page subtitle
     */
    const renderSubtitle = () => {
        if (!subtitle) {
            return null;
        }

        return (
            <h2 data-testid="page-subtitle" className={styles.subtitle}>
                {subtitle}
            </h2>
        );
    };

    /**
     * Renders the title action content if provided
     *
     * @returns {React.Element} the title action content
     */
    const renderTitleBarRight = () => {
        if (!titleAction) {
            return null;
        }

        return (
            <div className={styles.rightTitleBar}>
                {titleAction}
            </div>
        );
    };

    /**
     * Renders title elements if they aren't both null
     *
     * @returns {React.Element} Title elements
     */
    const renderTitles = () => {
        if (!subtitle && !title) {
            return null;
        }

        return (
            <div>
                {renderTitle()}
                {renderSubtitle()}
            </div>
        );
    };

    return (
        <>
            <div className={styles.titleBarContainer}>
                {renderTitles()}
                {renderTitleBarRight()}
            </div>
            {(header)
                ? (
                    <div className={styles.header}>
                        {header}
                    </div>
                )
                : null
            }
            {renderBreadcrumbs && <Breadcrumbs />}
            <div className={classNames(styles.content, { [styles.topSpacing]: marginTop })}>
                {children}
            </div>
        </>
    );
};

Page.propTypes = {
    children: PropTypes.any,
    header: PropTypes.element,
    marginTop: PropTypes.bool,
    renderBreadcrumbs: PropTypes.bool,
    subtitle: PropTypes.string,
    titleAction: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
        PropTypes.string,
        PropTypes.oneOf([null]),
    ]),
    title: PropTypes.string,
};

Page.defaultProps = {
    children: null,
    header: <></>,
    marginTop: true,
    renderBreadcrumbs: true,
    subtitle: "",
    title: "",
    titleAction: null,
};

export default Page;
