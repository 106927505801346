import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { CheckboxGroup } from 'components/forms';
import { TertiaryButton } from 'components/buttons';

import styles from './checkbox-group-grid.module.scss';

const GRID_SECTIONS = [
    "company",
    "sites",
    "schemes",
    "divisions",
    "departments",
    "employees",
];

/**
 * A collection of checkbox permissions groups
 *
 * @param {array} headers The names and system names of the permissions
 * @param {string} id The company id
 *
 * @returns {React.Component} Returns the checkbox groups grid
 */
const CheckboxGroupGrid = ({ headers, id }) => {
    const { setValue } = useFormContext();
    const [selectedPreset, setSelectedPreset] = useState("");

    /**
     * The permissions by section name
     *
     * @returns {React.Component} Returns the checkbox groups grid
     */
    const permissionsGroups = useCallback(() => {
        let groups = {};

        headers.forEach(({ system_name: systemName }) => {
            GRID_SECTIONS.forEach((sectionName) => {
                if (groups[sectionName] && systemName.includes(sectionName)) {
                    groups[sectionName] = [...groups[sectionName], systemName];
                } else if (systemName.includes(sectionName)) {
                    groups[sectionName] = [systemName];
                }
            });
        });

        return groups;
    }, [headers]);

    /**
     * Selects all permissions allowing a user read access
     *
     * @returns {void}
     */
    const handleViewAllPreset = () => {
        setSelectedPreset("view");
        headers.forEach(({ system_name: systemName }) => {
            if (systemName.includes("view")) {
                setValue(systemName, id, { shouldDirty: true });
            } else {
                setValue(systemName, false, { shouldDirty: true });
            }
        });
    };

    /**
     * Selects all permissions
     *
     * @returns {void}
     */
    const handleAllowAllPreset = () => {
        setSelectedPreset("all");
        headers.forEach(({ system_name: systemName }) => {
            setValue(systemName, id, { shouldDirty: true });
        });
    };

    /**
     * Clears all permissions
     *
     * @returns {void}
     */
    const handleClear = () => {
        setSelectedPreset("clear");
        headers.forEach(({ system_name: systemName }) => {
            setValue(systemName, false, { shouldDirty: true });
        });
    };

    return (
        <>
            <div className={styles.permissionsLabel}>Permissions</div>
            <div className={styles.permissionsPresetContainer}>
                <TertiaryButton
                    onClick={handleViewAllPreset}
                    className={classNames({ [styles.selectedPreset]: selectedPreset === "view" })}
                >
                    View all
                </TertiaryButton>
                <TertiaryButton
                    onClick={handleAllowAllPreset}
                    className={classNames({ [styles.selectedPreset]: selectedPreset === "all" })}
                >
                    Allow all
                </TertiaryButton>
                <TertiaryButton
                    onClick={handleClear}
                    className={classNames({ [styles.selectedPreset]: selectedPreset === "clear" })}
                >
                    Clear
                </TertiaryButton>
            </div>
            <div className={styles.grid}>
                {Object.entries(permissionsGroups()).sort().map(([group, perms]) => (
                    <CheckboxGroup
                        key={group}
                        groupName={`${group}`}
                        permissions={perms}
                        headers={headers}
                        id={id}
                    />
                ))}
            </div>
        </>
    );
};

CheckboxGroupGrid.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.shape({
        "system_name": PropTypes.string.isRequired,
        "name": PropTypes.string.isRequired,
    }).isRequired),
    id: PropTypes.string.isRequired,
};

CheckboxGroupGrid.defaultProps = {
    headers: [],
};

export default CheckboxGroupGrid;
