import classNames from "classnames";
import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import styles from "./text-input.module.scss";

/**
 * Renders the text input
 *
 * @param {object} props The text input properties
 *
 * @return {React.Component} The text input
 */
const TextInput = forwardRef(({ className, error, large, required, ...otherProps }, ref) => (
    <input
        data-testid="text-input"
        aria-required={required}
        ref={ref}
        className={classNames(
            styles.input,
            className,
            {
                [styles.error]: error,
                [styles.large]: large,
            },
        )}
        {...otherProps}
    />
));

TextInput.defaultProps = {
    className: "",
    error: false,
    large: false,
    required: false,
    type: "text",
    name: "",
};

TextInput.propTypes = {
    className: PropTypes.string,
    error: PropTypes.bool,
    large: PropTypes.bool,
    name: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
};

export default TextInput;
