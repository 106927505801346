import React from 'react';
import PropTypes from 'prop-types';

import styles from './csv-line-error-validation.module.scss';

/**
 * Displays csv error messages parsed from the backend
 *
 * @param {array} errors The list of csv upload errors
 *
 * @returns {React.Component} The list of human-readable errors for the user
 */
const CsvLineErrorValidation = ({ errors }) => {
    const formattedErrors = errors.reduce((acc, val) => {
        const [lineInfo, message, message2] = val.split(".");
        const lineNumber = Number(lineInfo.substring(4));

        if (Array.isArray(acc[lineNumber])) {
            acc[lineNumber].push(message2 ? `${message}. ${message2}` : message);
        } else {
            acc[lineNumber] = [message2 ? `${message}. ${message2}` : message];
        }

        return acc;
    }, {});

    return errors.length > 0 && (
        <>
            <div className={styles.validationTitle}>We found errors in your csv</div>
            <div className={styles.csvValidationWrapper}>
                <div className={styles.msgWrapper}>
                    <div className={styles.header}>Line</div>
                    <div className={styles.header}>Message</div>
                    {Object.entries(formattedErrors).map(([line, msg]) => (
                        <React.Fragment key={line}>
                            <div className={styles.cellPadding}>{Number(line) + 1}</div>
                            <div className={styles.cellPadding}>
                                {msg.map((instruction) => (
                                    <div key={`${line}-${instruction}`} className={styles.instruction}>
                                        {instruction}
                                    </div>
                                ))}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </>
    );
};

CsvLineErrorValidation.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string),
};

CsvLineErrorValidation.defaultProps = {
    errors: [],
};

export default CsvLineErrorValidation;
