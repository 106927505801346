import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';

import styles from "./page-content.module.scss";

/**
 * Wrapper for the page content
 *
 * @param {React.Component} children The content elements
 * @param {string} className The container classes
 *
 * @returns {React.Component} The page container
 */
const PageContent = ({ children, className }) => (
    <div className={classNames(styles.container, className)}>
        {children}
    </div>
);

PageContent.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
};

PageContent.defaultProps = {
    className: "",
};

export default PageContent;
