import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "components/forms/form-controls";
import { Drawer, DrawerTitle } from "components";
import PeriodFileUploadForm from "./period-file-upload-form";

const initialState = {
    isOpen: false,
    isLoading: false,
};

/**
 * Renders the period file upload form drawer
 *
 * @param {string} period The current period
 * @param {function} onSuccess The callback
 * @param {string} uploadText The button text
 *
 * @return {React.Component} Renders the Files page
 */
const PeriodFileUploadDrawer = ({ period, onSuccess, buttonText }) => {
    const [fileUpload, setFileUpload] = useState(initialState);
    const { isOpen, isLoading } = fileUpload;
    const handleOpen = () => setFileUpload({ ...fileUpload, isOpen: true });
    const handleClose = () => setFileUpload(initialState);

    return (
        <>
            <Button onClick={handleOpen}>{buttonText}</Button>

            <Drawer
                isOpen={isOpen}
                isLoading={isLoading}
                onClose={handleClose}
                onOutsideClick={handleClose}
                headerContent={<DrawerTitle>Upload A File</DrawerTitle>}
                padding={false}
                ariaLabel="period file upload drawer"
            >
                {isOpen && (
                    <PeriodFileUploadForm
                        period={period}
                        onSuccess={() => {
                            handleClose();
                            onSuccess();
                        }}
                        onCancel={handleClose}
                    />
                )}
            </Drawer>
        </>
    );
};

PeriodFileUploadDrawer.propTypes = {
    period: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    onSuccess: PropTypes.func.isRequired,
    buttonText: PropTypes.string,
};

PeriodFileUploadDrawer.defaultProps = {
    buttonText: 'Upload Files',
};

export default PeriodFileUploadDrawer;
