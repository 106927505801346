import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { head } from 'lodash';
import { Button } from 'components/forms';
import { LoadingMask } from 'components/loading-mask';
import { Card } from 'components/card';

import styles from './card-tab.module.scss';

/**
 * Tabs within a card (not linked to the url)
 *
 * @param {array} tabs List of tab titles and components
 * @param {string} className Classes to add to the card
 *
 * @returns {React.Component} The card with tab details
 */
const CardTab = ({ tabs, className }) => {
    const [selectedTab, setSelectedTab] = useState(null);

    /**
     * Sets the default tab
     */
    useEffect(() => {
        setSelectedTab(head(tabs).id);
    }, [tabs]);

    /**
     * Renders the card tab headers with underline for the active tab
     *
     * @returns {React.Component} The card tab headers
     */
    const renderTabHeaders = () => {
        return tabs.map((tab) => (
            <Button
                key={tab.id}
                onClick={() => setSelectedTab(tab.id)}
                className={classNames(styles.tabButton, {
                    [styles.activeTab]: (tab.id === selectedTab),
                })}
            >
                {tab.title}
            </Button>
        ));
    };

    /**
     * Renders the currently selected tabs content
     *
     * @returns {React.Component} The tab content
     */
    const renderTabContent = () => {
        const renderTab = head(tabs.filter((tab) => (tab.id === selectedTab)));

        return renderTab?.content ?? (<LoadingMask loading />);
    };

    return (
        <Card className={classNames(styles.cardTab, className)} padding={false}>
            <div className={styles.headersContainer}>{renderTabHeaders()}</div>
            <div>{renderTabContent()}</div>
        </Card>
    );
};

CardTab.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string,
        content: PropTypes.element,
    })),
    className: PropTypes.string,
};

CardTab.defaultProps = {
    tabs: [],
    className: "",
};

export default CardTab;
