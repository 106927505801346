import { useState, useEffect, useRef } from "react";
import EmployeesApi from "lib/api-endpoints/employees-api";
import { omitBy, isNil } from "lodash";

const initialState = {
    data: {},
    isLoaded: false,
};

const useEmployeeList = ({
    page, perPage, payrollId, companyIds, refresh, sort = "name:asc", includeSites = 1, searchString,
}) => {
    const [state, setState] = useState(initialState);
    const { current: selectedCompanyIds } = useRef(companyIds);

    useEffect(() => {
        if (!searchString || searchString.length >= 3) {
            setState(initialState);

            const params = omitBy({
                "sort_by": sort,
                "company_ids": selectedCompanyIds,
                "payroll_id": payrollId,
                "per_page": perPage,
                "include_sites": includeSites,
                "search_string": searchString,
                page,
            }, isNil);
            const { request, abortController } = EmployeesApi.getEmployees(params);

            request.then(({ data }) => setState({ data, isLoaded: true }));

            return () => abortController?.abort();
        }
        return null;
    }, [selectedCompanyIds, refresh, payrollId, perPage, page, sort, includeSites, searchString]);

    return state;
};

export default useEmployeeList;
