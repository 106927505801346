import React from "react";
import PropTypes from "prop-types";
import { generatePath, useNavigate } from "react-router-dom";
import { keyBy, last } from "lodash";
import { routePaths } from "routes";
import PayrollCard from "./payroll-card";
import payrollsPropTypes, { payrollsDefaultProps } from "lib/props-schema/payrolls-schema";
import companiesPropTypes, { companiesDefaultProps } from "lib/props-schema/companies-schema";

import styles from "./payroll-cards.module.scss";

/**
 * Renders the PayrollCards
 *
 * @param {boolean} isLoaded The component data is ready to be shown
 * @param {array} companies A list of companies
 * @param {array} payrolls A list of payrolls
 *
 * @returns {React.Component} The PayrollCards
 */
const PayrollCards = ({ isLoaded, companies, payrolls }) => {
    const navigate = useNavigate();

    if (!isLoaded) {
        return null;
    }

    const groupedCompanies = keyBy(companies, 'id');

    const handleOnClick = (period) => navigate(generatePath(routePaths.payrollPeriods, { id: period.payroll_id }));

    const cards = payrolls.map((payroll) => {
        const company = groupedCompanies[payroll.company_id];
        const latestPeriod = last(payroll.periods);

        return (
            <PayrollCard
                key={payroll.id}
                payroll={payroll}
                company={company}
                period={latestPeriod}
                onClick={handleOnClick}
            />
        );
    });

    return (
        <div className={styles.container}>
            {cards}
        </div>
    );
};

PayrollCards.propTypes = {
    companies: companiesPropTypes,
    payrolls: payrollsPropTypes,
    isLoaded: PropTypes.bool,
};

PayrollCards.defaultProps = {
    companies: companiesDefaultProps,
    payrolls: payrollsDefaultProps,
    isLoaded: false,
};

export default PayrollCards;
