import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import SentryApi from "lib/api/sentry-api";
import { Form, TextInputRow } from 'components/forms';
import TextAreaInputRow from "components/forms/text-area-row";
import ErrorFrame from "./error-frame";
import { emailRegEx } from "lib/helpers/regex";
import { getFormErrors } from "lib/helpers/validation-helpers";
import { PrimaryButton, SecondaryButton } from "components/buttons";
import { useSnackbarContext } from "state/context";

import styles from './error-fallback.module.scss';

/**
 * The Fallback component for the error boundary
 *
 * @param {object} props error event properties
 *
 * @returns {React.Component} The fallback component for sentry errors
 */
const ErrorFallback = ({resetError, eventId}) => {
    const navigate = useNavigate();
    const methods = useForm();
    const { openSnackbar } = useSnackbarContext();

    const { register, handleSubmit, watch, formState: { errors } } = methods;
    watch();

    const onSubmit = (formData) => {
        const errorData = { ...formData, eventId };

        SentryApi.addError(errorData)
            .then(() => {
                openSnackbar({
                    message: "Your feedback was submitted successfully and you were redirected to your previous page.",
                    type: "success",
                });

                resetError();
                navigate(-1);
            });
    };

    return (
        <FormProvider {...methods}>
            <div className={styles.errorFrameWrapper}>
                <ErrorFrame
                    title="We're having some internal issues"
                    content="Our team has been notified. If you'd like to help, tell us what happened below."
                    form={
                        <Form onSubmit={handleSubmit(onSubmit)} data-testid="error-fallback-form">
                            <div className={styles.formContainer}>
                                <TextInputRow
                                    data-testid="error-fallback-name"
                                    {...register("name", {
                                        required: true,
                                    })}
                                    required={true}
                                    name="name"
                                    label="Name"
                                    error={getFormErrors(errors, "name")}
                                />
                                <TextInputRow
                                    data-testid="error-fallback-email"
                                    {...register("email", {
                                        required: true,
                                        pattern: emailRegEx,
                                    })}
                                    required={true}
                                    name="email"
                                    label="Email"
                                    error={getFormErrors(errors, "email", {
                                        pattern: "The email you have entered is invalid, please try again.",
                                    })}
                                />
                                <TextAreaInputRow
                                    data-testid="error-fallback-comments"
                                    className={styles.multiLineText}
                                    {...register("comments", {
                                        required: true,
                                    })}
                                    required={true}
                                    name="comments"
                                    label="Message"
                                    error={getFormErrors(errors, "comments")}
                                />
                            </div>
                            <div className={styles.buttonContainer}>
                                <PrimaryButton
                                    data-testid="error-fallback-submit"
                                    type="submit"
                                >
                                    Submit crash report
                                </PrimaryButton>
                                <SecondaryButton
                                    data-testid="error-fallback-button"
                                    type="button"
                                    className={styles.homepageButton}
                                    onClick={() => {
                                        navigate("dashboard", { replace: true });
                                        resetError();
                                    }}
                                >
                                    Back to homepage
                                </SecondaryButton>
                            </div>
                        </Form>
                    }
                />
            </div>
        </FormProvider>
    );
};

ErrorFallback.propTypes = {
    resetError: PropTypes.func.isRequired,
    eventId: PropTypes.string.isRequired,
};

export default ErrorFallback;
