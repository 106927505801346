import React from "react";
import PropTypes from "prop-types";

import styles from "./profile-section.module.scss";

const ProfileSection = ({ children }) => (
    <div className={styles.container}>
        {children}
    </div>
);

ProfileSection.propTypes = {
    children: PropTypes.any,
};

ProfileSection.defaultProps = {
    children: null,
};

export default ProfileSection;
