import { useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { apiClient } from 'lib/api.js';
import { clearSessionData } from "lib/auth";
import { useSnackbarContext } from "state/context";

/**
 * The interceptor is a component that houses the api clients interception methods
 *
 * @return {React.Component} The interceptor
 */
const Interceptor = () => {
    const { loaded } = useSelector((state) => state.app);
    const { openSnackbar } = useSnackbarContext();

    /**
     * Adds a snackbar for errors that are caught
     *
     * @param {object} error The error to create the snackbar for
     */
    const addErrorSnackbar = useCallback((error) => {
        openSnackbar({
            message: error.response.data.message ? error.response.data.message : "Error",
            type: "error",
        });
    }, [openSnackbar]);

    /**
     * Adds the auth interceptor to the axios api client so the auth token is attached each time if present
     *
     * @return {void}
     */
    const addAuthInterceptor = useCallback(() => {
        return apiClient.interceptors.request.use(
            (config) => {
                const token = sessionStorage.getItem("apiToken");

                if (token) {
                    config.headers['X-Token'] = token;
                }

                return config;
            },
            (error) => Promise.reject(error),
        );
    }, []);

    /**
     * Eject the auth token interceptor when the component is unmounted to avoid memory leak
     *
     * @return {void}
     */
    const removeAuthInterceptor = useCallback((authInterceptor) => {
        apiClient.interceptors.request.eject(authInterceptor);
    }, []);

    /**
     * Add the error interceptor to the axios api client in order to reset the app and log a user out
     * if an unauthorised response is ever returned from the API
     *
     * @return {void}
     */
    const addErrorInterceptor = useCallback(() => {
        return apiClient.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error?.message === 'canceled') {
                    return Promise.reject(error);
                }

                if (error?.response?.status === 401) {
                    clearSessionData();
                    return Promise.resolve();
                }

                if (loaded && (error?.response?.status !== 404)) {
                    addErrorSnackbar(error);
                }

                return Promise.reject(error);
            },
        );
    }, [addErrorSnackbar, loaded]);

    /**
     * Eject the error interceptor handler when the component is unmounted to avoid memory leak
     */
    const removeErrorInterceptor = useCallback((errorInterceptor) => {
        apiClient.interceptors.response.eject(errorInterceptor);
    }, []);

    useEffect(() => {
        const authInterceptor = addAuthInterceptor();
        const errorInterceptor = addErrorInterceptor();

        return () => {
            removeAuthInterceptor(authInterceptor);
            removeErrorInterceptor(errorInterceptor);
        };
    }, [addAuthInterceptor, addErrorInterceptor, removeAuthInterceptor, removeErrorInterceptor]);

    return null;
};

export default Interceptor;
