import { useState, useEffect } from "react";
import UsersApi from "lib/api-endpoints/users-api";
import { isNull } from "lodash";

const initialState = {
    data: [],
    isLoaded: false,
};

/**
 * Gets the users list hook
 *
 * @param {object} props The user list props
 * @param {int|null} props.payrollId the id of the payroll
 * @param {int} props.page The current page
 * @param {bool} props.active The flag to get active or inactive users
 * @param {string} props.searchString a search string to query the backend with
 * @param {int|null} props.companyId the id of the company
 * @param {boolean} props.refresh Refresh the data
 *
 * @returns {object} The users list state
 */
const useUsersList = ({
    payrollId = null,
    page = 1,
    active = null,
    searchString = "",
    companyId = null,
    refresh = false,
}) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        if (!searchString || searchString.length >= 3) {
            setState(initialState);

            const query = {
                "search_string": searchString,
                "payroll_ids": payrollId,
                "company_ids": companyId,
                page,
            };

            if (!isNull(active)) {
                query.active = Number(active);
            }
            const { request, abortController } = UsersApi.getUsers(query);

            request.then(({ data: { data, meta } }) => {
                return setState({ data, isLoaded: true, meta });
            });

            return () => abortController?.abort();
        }

        return null;
    }, [page, active, payrollId, searchString, companyId, refresh]);

    return state;
};

export default useUsersList;
