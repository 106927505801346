import { useState, useEffect } from "react";
import EmployeesApi from "lib/api-endpoints/employees-api";

const initialState = {
    data: {},
    loading: true,
};

const useEmployee = (employeeId, refresh = false) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        setState(initialState);

        const { request, abortController } = EmployeesApi.getEmployee(
            employeeId,
            {
                'include_current_tronc_scheme': true,
                'include_job_roles': true,
            }
        );

        request.then(({ data }) => setState({ data, loading: false }));

        return () => abortController?.abort();
    }, [employeeId, refresh]);

    return state;
};

export default useEmployee;
