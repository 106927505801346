import { useState, useEffect } from "react";
import { TroncSchemesApi } from "lib/api-endpoints";

const initialState = {
    payDates: [],
    isLoaded: false,
};

const useTroncPaydateList = (refresh) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        setState(initialState);

        const { request, abortController } = TroncSchemesApi.getTroncPaydates();

        request.then(({ data }) => setState({ payDates: data, isLoaded: true }));

        return () => abortController?.abort();
    }, [refresh]);

    return state;
};

export default useTroncPaydateList;
