import { useState, useEffect } from "react";
import { TroncFilesApi } from "lib/api-endpoints";

const initialState = {
    files: {
        data: [],
        meta: {},
    },
    isLoaded: false,
};

const useTroncFilesList = ({ page, perPage, period, refresh }) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        setState(initialState);

        const { request, abortController } = TroncFilesApi.getFiles({
            "tronc_period_ids": [period],
            "per_page": perPage,
            page,
        });

        request.then(({ data }) => setState({
            files: data,
            isLoaded: true,
        }));

        return () => abortController?.abort();
    }, [page, perPage, period, refresh]);

    return state;
};

export default useTroncFilesList;
