import React from 'react';
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import _ from 'lodash';
import { Table } from 'components';
import DateDisplay from "components/date-display/date-display";
import { routePaths } from "routes";
import { ticketsDefaultProps, ticketsPropTypes } from "lib/props-schema/tickets-schema";

const headings = {
    "ticket_id": "id",
    "subject": "subject",
    "assignee": "assigned",
    "created": "created",
    "reporter": "reporter",
    "status": "status",
};

/**
 * Converts the given data array into data that's compatible with the table
 *
 * @param {array} data rest data
 *
 * @returns {array} table data
 */
const getFormattedData = (data) => {
    return data.map(({
        id,
        subject,
        status,
        assignee,
        "created_datetime": created,
        "created_by": reporter,
        "payroll_name": payrollName,
        "company_name": companyName,
    }) => ({
        id,
        subject: {
            link: generatePath(routePaths.ticket, { id }),
            text: subject,
        },
        status,
        assignee,
        reporter,
        company: `${payrollName} - ${companyName}`,
        "ticket_id": id.toString(),
        "created": {
            component: (
                <DateDisplay
                    data={created}
                    format="YYYY-MM-DD HH:mm:ss"
                    output="DD-MM-YYYY"
                />
            ),
        },
    }));
};

/**
 * Payroll file list component
 *
 * @param {object} files The current paginated files data
 * @param {function} onPageChange Handles callback for page change
 * @param {boolean} isLoaded Is the data fetching
 *
 * @returns {React.Component} Payroll Files Component
 */
const TicketList = ({ tickets, onPageChange, isLoaded }) => {
    const { companies } = useSelector((state) => state.app);
    const keyedCompanies = _.keyBy(companies, 'id');
    const hasManyCompanies = Object.keys(keyedCompanies).length > 1;
    const { data, meta } = tickets;

    const allDataLoaded = (isLoaded && !_.isEmpty(companies));

    if (hasManyCompanies) {
        headings.company = "company/payroll";
    }

    const ticketsData = (allDataLoaded) ? data : [];

    return (
        <Table
            headings={headings}
            data={getFormattedData(ticketsData)}
            dataLoaded={isLoaded}
            data-testid="tickets"
            pagination={{
                totalPages: meta?.last_page,
                currentPage: meta?.current_page,
                onPageChange,
            }}
            skeletonConfig={{
                rowCount: 6,
                rowConfig: [
                    { key: 'tickets' },
                ],
            }}
        />
    );
};

TicketList.propTypes = {
    tickets: PropTypes.shape({
        data: ticketsPropTypes,
        meta: PropTypes.shape({
            total: PropTypes.number,
            "current_page": PropTypes.number,
            "last_page": PropTypes.number,
        }),
    }),
    onPageChange: PropTypes.func,
    isLoaded: PropTypes.bool,
};

TicketList.defaultProps = {
    tickets: {
        data: ticketsDefaultProps,
        meta: {},
    },
    onPageChange: () => null,
    isLoaded: false,
};

export default TicketList;
