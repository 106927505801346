import React from "react";
import PropTypes from "prop-types";
import { generatePath } from "react-router-dom";
import _ from "lodash";
import { useSelector } from "react-redux";

import { Table } from 'components/index';
import PayrollName from "./payroll-name";
import payrollsPropTypes, { payrollsDefaultProps } from "lib/props-schema/payrolls-schema";

const headings = {
    name: "Name",
    frequency: 'Frequency',
};

/**
 * Converts the given data array into data that's compatible with the table
 *
 * @param {array} payrolls An array of payrolls
 * @param {object} companies An object containing companies keyed by id
 * @param {function} getNavigation Gets the navigation path and paths to trigger for this list
 *
 * @returns {array} table data
 */
const getFormattedPayrolls = (payrolls, companies, getNavigation) => {
    const hasManyCompanies = _.uniqBy(payrolls, 'company_id').length > 1;

    return payrolls.map((payroll) => {
        const { name, frequency, "company_id": companyId } = payroll;

        return {
            ...payroll,
            name: {
                link: generatePath(...getNavigation(payroll)),
                text: (
                    <PayrollName
                        name={name}
                        companyName={companies[companyId]?.name}
                        hasManyCompanies={hasManyCompanies}
                    />
                ),
            },
            frequency,
        };
    });
};

/**
 * PayrollsList Component renders a table of payrolls
 *
 * @param {array} payrolls An array of payrolls to render
 * @param {boolean} isLoaded Is the data fetching
 * @param {function} getNavigation Gets the navigation path to use for the link
 *
 * @returns {React.Component} PayrollsList Component
 */
const PayrollsList = ({ payrolls, getNavigation, isLoaded }) => {
    const companies = useSelector((state) => state.app.companies);
    const companiesById = _.keyBy(companies, 'id');

    return (
        <Table
            data-testid="payrolls"
            noDataText="No Payrolls"
            headings={headings}
            data={getFormattedPayrolls(payrolls, companiesById, getNavigation)}
            dataLoaded={isLoaded}
            skeletonConfig={{
                rowCount: 6,
                rowConfig: [
                    { key: 'payroll-files' },
                ],
            }}
        />
    );
};

PayrollsList.propTypes = {
    getNavigation: PropTypes.func.isRequired,
    payrolls: payrollsPropTypes,
    isLoaded: PropTypes.bool,
};

PayrollsList.defaultProps = {
    payrolls: payrollsDefaultProps,
    isLoaded: false,
};

export default PayrollsList;
