import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./form.module.scss";

const voidSubmitHandler = (event) => event.preventDefault();

/**
 * Renders the form
 *
 * @param {object} props The form properties
 * @param {object} ref The form dom ref
 *
 * @return {React.Component} The form component
 */
const Form = forwardRef((props, ref) => (
    <form
        ref={ref}
        className={classNames(styles.form, props.className)}
        action={props.action}
        method={props.method}
        encType={props.encType}
        onSubmit={props.onSubmit ?? voidSubmitHandler}
    >
        {props.children}
    </form>
));

Form.defaultProps = {
    action: "",
    encType: "mulipart/form-data",
    method: "post",
    children: null,
    onSubmit: null,
    className: "",
};

Form.propTypes = {
    action: PropTypes.string,
    encType: PropTypes.string,
    method: PropTypes.string,
    children: PropTypes.any,
    onSubmit: PropTypes.func,
    className: PropTypes.string,
};

export default Form;
