import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ArrowDropDown from 'components/icons/arrow-dropdown';

import styles from './authenticator-code-dropdown.module.scss';

/**
 * Renders the authentication code dropdown
 *
 * @returns {React.Component} The auth code dropdown
 */
const AuthenticatorCodeDropdown = ({code}) => {
    const [dropdownState, setDropdownState] = useState({ isOpen: false });

    const classList = classNames(styles.icon, {
        [styles.open]: (dropdownState.isOpen),
    });

    /**
     * Toggles / sets if the accordion is open
     */
    const toggleOpen = () => {
        setDropdownState((prevState) => ({...prevState, isOpen: !prevState.isOpen}));
    };

    /**
     * Renders the code
     *
     * @return {ReactElement} The authentication code
     */
    const renderCode = () => {
        return (
            <>
                <div className={styles.header}>
                    Enter this setup key into your authentication app
                </div>
                <div className={styles.code}>
                    {code}
                </div>
            </>
        );
    };

    if (!code) {
        return null;
    }

    return (
        <div data-testid="authenticator_code_dropdown">
            <div
                role="button"
                tabIndex={0}
                onClick={toggleOpen}
                onKeyDown={toggleOpen}
                className={styles.dropdownHeader}
            >
                <ArrowDropDown className={classList} />
                Having trouble scanning your code?
            </div>
            {dropdownState.isOpen && renderCode()}
        </div>
    );
};

AuthenticatorCodeDropdown.propTypes = {
    code: PropTypes.string,
};

AuthenticatorCodeDropdown.defaultProps = {
    code: "",
};

export default AuthenticatorCodeDropdown;
