import React from 'react';
import PropTypes from 'prop-types';
import { routePaths } from 'routes';
import { Card, CardContent, CardHeader, CardNumberDisplay, TextLink } from 'components';

import styles from './company-card.module.scss';

/**
 * Displays the company information
 *
 * @param {object} company Company info
 * @param {function} handleCompanyView Function to navigate to company
 *
 * @returns {React.Component} The company card
 */
const CompanyCard = ({ company }) => {

    /**
     * Displays the formatted company name, number and actions
     *
     * @param {string} name The company name
     * @param {string} companyNumber The company number
     *
     * @returns {React.Component} Renders the company card title area
     */
    const cardTitle = (name, companyNumber) => (
        <TextLink className={styles.cardTitle} to={`${routePaths.companies}/${company.id}`} >
            <div className={styles.companyTitle}>{name}</div>
            {companyNumber && <div className={styles.companyNumber}># {companyNumber}</div>}
        </TextLink>
    );

    return (
        <Card data-testid="company-card" padding={false} className={styles.card}>
            <CardHeader title={cardTitle(company.name, company?.company_number)} />
            <CardContent className={styles.panelContentWrapper}>
                <TextLink className={styles.cardSubtitle} to={`${routePaths.companies}/${company.id}/employees`} >
                    <CardNumberDisplay fieldName="Employees" number={company?.employee_stats?.count ?? "-"} />
                </TextLink>
                <TextLink className={styles.cardSubtitle} to={`${routePaths.companies}/${company.id}/sites`} >
                    <CardNumberDisplay fieldName="Sites" number={company?.site_stats?.count ?? "-"} />
                </TextLink>
                <TextLink className={styles.cardSubtitle} to={`${routePaths.companies}/${company.id}/schemes`} >
                    <CardNumberDisplay fieldName="Schemes" number={company?.tronc_scheme_stats?.count ?? "-"} />
                </TextLink>
            </CardContent>
        </Card>
    );
};

CompanyCard.propTypes = {
    company: PropTypes.object.isRequired,
};

export default CompanyCard;
