import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { noop, debounce } from 'lodash-es';

import windowScroll from 'lib/window-scroll/window-scroll';
import ThinArrowDropDown from 'components/icons/thin-arrow';

import styles from './back-to-top.module.scss';

const BackToTop = ({backgroundColor, bottom, className, left, onClick, right, size, top}) => {
    const bufferZone = 30;
    const [visible, setVisible] = useState(false);
    const atTop = windowScroll.getY() < bufferZone;

    /**
     * Called on page scroll to check if the button should hide/show
     *
     * @param {event} scroll The onScroll event
     *
     * @return {void}
     */
    const calculateVisibility = useCallback(() => {
        if (windowScroll.getY() >= bufferZone && !visible) {
            setVisible(true);
        } else if (windowScroll.getY() < bufferZone && visible) {
            setVisible(false);
        }
    }, [visible]);

    useEffect(() => {
        const onScroll = debounce(calculateVisibility, 100);
        document.addEventListener('scroll', onScroll);

        return () => {
            document.removeEventListener('scroll', onScroll);
        };
    }, [atTop, calculateVisibility]);

    const classList = classNames({
        [styles.button]: true,
        [className]: true,
        [styles.visible]: visible,
        [styles.hidden]: !visible,
    });

    const style = {
        width: `${size}px`,
        height: `${size}px`,
        top,
        right,
        bottom,
        left,
        backgroundColor,
    };

    /**
     * Scroll the page to top
     *
     * @return {void}
     */
    const scrollToTop = () => {
        window.scroll({ left: 0, top: 0, behavior: 'smooth'});
    };

    /**
     * Called when the button is clicked
     *
     * @param {event} event The onClick event
     *
     * @return {void}
     */
    const handleClick = (event) => {
        event.preventDefault();

        scrollToTop();

        if (onClick) {
            onClick();
        }
    };

    return (
        <button type="button" className={classList} style={style} onClick={handleClick}>
            <ThinArrowDropDown className={styles.icon} />
        </button>
    );
};

BackToTop.propTypes = {
    size: PropTypes.number,
    top: PropTypes.string,
    right: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
    className: PropTypes.string,
    backgroundColor: PropTypes.string,
    onClick: PropTypes.func,
};

BackToTop.defaultProps = {
    size: 50,
    top: "auto",
    right: "20px",
    bottom: "20px",
    left: "auto",
    className: "",
    backgroundColor: "#951B81",
    onClick: noop,
};

export default BackToTop;
