import { api } from "lib/api";

export default class MFAApi {

    /**
     * Gets the QR code request promise
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    /* eslint-disable-next-line camelcase */
    static getQRCode = () => api({ url: "/auth/mfa/totp/setup?", config: { params: { include_qr: 1 } }});

    /**
     * Gets the mfa request promise
     *
     * @param {object} data Data for the post address
     * @param {string} postAddress Address to post to
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    /* eslint-disable-next-line camelcase */
    static setMFAActive = (data, postAddress) => api({ type: "post", url: `auth/mfa/totp/${postAddress}`, data });

}
