import { api } from "lib/api";

export default class TroncPeriodsApi {

    /**
     * Gets the tronc schemes list axios promise
     *
     * @param {object} params The query parameters
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getTroncSchemesPeriods = (params) => api({
        url: `/tronc_periods`,
        config: { params },
    });

    /**
     * Gets the tronc schemes list axios promise
     *
     * @param {string|number} id the period id
     * @param {object} params The query parameters
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getTroncPeriod = (id, params) => api({
        url: `/tronc_periods/${id}`,
        config: { params },
    });

    /**
     * Adds a list of files to a given payrolls period
     *
     * @param {number} period The period id
     * @param {object} formData The payroll files form data
     * @param {FileList} formData.files The files to upload
     * @param {string} formData.description Additional description for the files uploaded
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static uploadFiles = (period, { files, description }) => {
        const data = new FormData();

        data.append('period', period);

        if (description) {
            data.append('description', description);
        }

        Array.from(files).forEach((fileItem) => {
            data.append('files[]', fileItem);
        });

        return api({ type: 'post', url: `/tronc_periods/${period}/troncs_files`, data });
    };

}
