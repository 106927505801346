import Permissions from "./permissions";

export default class PayrollFilePermissions extends Permissions {

    /**
     * @inheritDoc
     */
    register () {
        this.canView = this.permissions.includes("view_files");
        this.canCreate = this.permissions.includes("upload");
    }

}
