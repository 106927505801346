import { includes, map, noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from 'components/forms';
import TicketForm from './forms/ticket-form';
import { TicketsApi } from 'lib/api-endpoints';

/**
 * Renders create ticket button and form drawer for creating tickets
 *
 * @param {object} props component props
 * @param {function} onSuccess on success handler function
 *
 * @returns {React.Component} CreateTicketDrawer component
 */
const CreateTicketDrawer = ({ onSuccess }) => {
    const [isVisible, setVisible] = useState(false);

    /**
     * Given an array of users who will get notifications and an array of users who can see the ticket
     * returns an array of objects with the user id and if they are following the ticket.
     *
     * @param {array} notifications array of user ids of users to be notified of changes
     * @param {array} users array of uses selected to see the ticket
     *
     * @returns {array} restricted access user array
     */
    const formatSelectedUsers = (notifications, users) => map(users, (id) => ({
        "user_id": id,
        following: Number(includes(notifications, id)),
    }));

    /**
     * Formats a payload from the form data upon form submit and posts to the endpoint
     *
     * @param {object} formData the captured form data
     */
    const handleFormSubmit = (formData) => {
        const { notifications, visible, visibility } = formData;
        const payload = {
            ...formData,
            visibility,
            "relates_to_payroll": 1,
            "restricted_access_users": formatSelectedUsers(notifications, visible),
            "email_notifications": 0,
        };

        TicketsApi.addTicket(payload).request
            .then(onSuccess)
            .finally(setVisible(false));
    };

    return (
        <>
            <Button onClick={() => setVisible(true)}>Create Ticket</Button>
            <TicketForm
                showForm={isVisible}
                setShowForm={setVisible}
                onCancel={setVisible}
                onSubmit={handleFormSubmit}
            />
        </>
    );
};

CreateTicketDrawer.propTypes = {
    onSuccess: PropTypes.func,
};

CreateTicketDrawer.defaultProps = {
    onSuccess: noop,
};

export default CreateTicketDrawer;
