import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { EmployeeProfile, Page, PageContent } from "components/";

const Employee = () => {
    const params = useParams();
    const [refresh, setRefresh] = useState(false);

    return (
        <Page>
            <PageContent>
                <EmployeeProfile
                    id={Number(params.id)}
                    setRefresh={() => setRefresh(!refresh)}
                    refresh={refresh}
                />
            </PageContent>
        </Page>
    );
};

export default Employee;
