import React from "react";
import PropTypes from "prop-types";
import { has } from "lodash";
import { useSelector } from "react-redux";
import { matchPath, NavLink, useLocation } from "react-router-dom";
import { routes } from "routes";
import { SlimArrow } from "components/icons";
import HomeCrumb from "./home-crumb";

import styles from "./breadcrumbs.module.scss";

/**
 * Gets the breadcrumb name
 *
 * @param {object} crumb the crumb object
 *
 * @returns {string} the crumb name
 */
const getCrumbName = ({ name, crumbName }) => (crumbName || name);

/**
 * Breadcrumbs component
 *
 * @param {object} props The input props
 * @param {number} props.minimumDepth The minimum depth of breadcrumbs required before rendering. By default,
 * this will not show the dashboard
 *
 * @returns {React.Element} The component
 */
const Breadcrumbs = ({ minimumDepth }) => {
    const location = useLocation();
    const { crumbs: stateCrumbs } = useSelector((state) => state.app);
    const { pathname } = location;

    /**
     * Helper combines path
     *
     * @param {array} path An array of paths to join
     *
     * @returns {string} The combined path
     */
    const joinPaths = (path) => path.join("/").replace(/\/\/+/g, "/");

    /**
     * Iterates over the routes and child routes in order to build up a breadcrumb list
     *
     * @param {array} definitions The route list
     * @param {string} locationPathname The path name
     * @param {string} parentPath The parent path name
     *
     * @returns {array} An array of crumbs
     */
    const matchRoute = (definitions, locationPathname, parentPath = '') => {
        const crumbs = [];

        const pushCrumb = (children, pathPatternWithParent) => {
            if (children) {
                const nestedMatches = matchRoute(
                    children,
                    locationPathname,
                    pathPatternWithParent
                );

                crumbs.push(...nestedMatches);
            }
        };

        definitions.forEach(({ path, children, name, key, crumbOverrideKey, crumbName }) => {
            const pathPatternWithParent = joinPaths([parentPath, path]);
            const match = matchPath({ path: pathPatternWithParent, end: false }, location.pathname);

            if (match && path !== '/' && !path.includes(":tab")) {
                const hasCustomName = has(stateCrumbs, crumbOverrideKey);
                const displayName = hasCustomName ? stateCrumbs[crumbOverrideKey] : getCrumbName({ name, crumbName });

                crumbs.push({ ...match, name: displayName, key });
                pushCrumb(children, pathPatternWithParent);
            }
        });

        return crumbs;
    };

    const breadcrumbs = matchRoute(routes, pathname);
    const totalCrumbs = breadcrumbs.length;

    if (totalCrumbs < minimumDepth) {
        return null;
    }

    return (
        <div className={styles.crumbs} data-testid="breadcrumbs">
            {breadcrumbs.length > 0 && <HomeCrumb />}
            {breadcrumbs.map((breadcrumb, index) => {
                const isLastTime = (totalCrumbs - 1) === index;

                return (
                    <div className={styles.crumb} key={breadcrumb.key} data-testid={`crumb-${breadcrumb.key}`}>
                        <>
                            <NavLink
                                to={breadcrumb.pathname}
                                className={styles.crumbLink}
                                data-testid={`crumb-link-${breadcrumb.key}`}
                            >
                                {breadcrumb.name}
                            </NavLink>

                            {!isLastTime && <SlimArrow className={styles.arrow} />}
                        </>
                    </div>
                );
            })}
        </div>
    );
};

Breadcrumbs.propTypes = {
    minimumDepth: PropTypes.number,
};

Breadcrumbs.defaultProps = {
    minimumDepth: 2,
};

export default Breadcrumbs;
