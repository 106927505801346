import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { SelectInputRow, TextInputRow } from 'components/forms';
import { checkValidity } from 'lib/helpers/validation-helpers';
import CheckboxGroupGrid from 'components/forms/checkbox-group-grid';
import CountryCodes from 'components/forms/select-options/country-codes-options';

import styles from './add-edit-user-drawer.module.scss';

const required = "Required field";

/**
 * Set up the user form content including validation, error handling and permissions
 *
 * @param {array} headers The headers for the user permissions table
 *
 * @returns {React.Component} Returns the fields for the company user forms
 */
const UserFormContent = ({ headers }) => {
    const { id } = useParams();
    const { register, formState, getValues } = useFormContext();
    const { errors } = formState;

    return (
        <div className={styles.userFormContentWrapper}>
            <div className={styles.userDetails}>
                <TextInputRow
                    {...register("forename", { required })}
                    name="forename"
                    data-testid="forename"
                    label="Forename"
                    error={errors?.forename?.message}
                />
                <TextInputRow
                    {...register("surname", { required })}
                    name="surname"
                    data-testid="surname"
                    label="Surname"
                    error={errors?.surname?.message}
                />
                <SelectInputRow
                    {...register("mobile_country_code")}
                    label="Country Code"
                    aria-label="site options"
                    options={CountryCodes}
                    selected={
                        getValues("mobile_country_code") === ("" || undefined)
                            ? "+44" : getValues("mobile_country_code")
                    }
                    error={errors?.mobile_country_code?.message}
                />
                <TextInputRow
                    {...register("mobile_number", {
                        maxLength: 15,
                        validate: {
                            validPhone: (mobile) => {
                                const { isValid, message } = checkValidity(mobile, "mobile");

                                return isValid || message;
                            },
                        },
                    })}
                    name="mobile_number"
                    data-testid="mobile_number"
                    label="Mobile Number"
                    error={errors?.mobile_number?.message}
                />
                <TextInputRow
                    {...register("email", {
                        validate: {
                            validEmail: (email) => {
                                const { isValid, message } = checkValidity(email, "email");

                                return isValid || message;
                            },
                        },
                    })}
                    name="email"
                    data-testid="email"
                    label="Email"
                    error={errors?.email?.message}
                />
            </div>
            <CheckboxGroupGrid headers={headers} id={id} />
        </div>
    );
};

UserFormContent.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.shape({
        "system_name": PropTypes.string.isRequired,
        "name": PropTypes.string.isRequired,
    }).isRequired),
};

UserFormContent.defaultProps = {
    headers: [],
};

export default UserFormContent;

