import { truncate } from 'lodash';
import PropTypes from 'prop-types';

/**
 * Limits the number of characters in a string to specified length and includes an ellipsis and a tooltip
 *
 * @param {string} text The string you want to truncate
 * @param {number} length The length of the string you want to truncate to including the ellipsis
 *
 * @return {React.Component} returns the truncated string
 */
const LimitedTextDisplay = ({ text, length }) => {
    if (!text) {
        return null;
    }

    if (text.length <= length) {
        return text;
    }

    return truncate(text, {
        'omission': '…',
        length,
    });
};

LimitedTextDisplay.defaultProps = {
    text: "",
    length: 20,
};

LimitedTextDisplay.propTypes = {
    text: PropTypes.string,
    length: PropTypes.number,
};

export default LimitedTextDisplay;
