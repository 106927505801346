import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './toggle-switch.module.scss';

/**
 * Renders Toggle Switch Input
 *
 * @param {object} props component props
 * @param {string} props.leftText left label text
 * @param {string} props.rightText right label text
 * @param {object} ref forward ref to input
 *
 * @returns {React.Component} ToggleSwitch Component
 */
const ToggleSwitch = React.forwardRef(({ leftText, name, error, rightText, required, small, ...otherProps }, ref) => {

    /**
     * Returns label if value is defined
     *
     * @param {string} value the label string
     * @param {string} className the label class
     *
     * @returns {HTMLElement} label html element
     */
    const getLabel = (value, className) => {
        if (!value) {
            return null;
        }

        return (
            <label htmlFor={name} className={className}>{ value }</label>
        );
    };

    return (
        <div className={classNames(styles.container, { [styles.small]: small })}>
            {getLabel(leftText, styles.leftLabel)}
            <label className={styles.switch}>
                <input
                    ref={ref}
                    id={name}
                    name={name}
                    aria-required={required}
                    className={classNames(
                        styles.switchInput,
                        {
                            [styles.error]: error,
                        }
                    )}
                    type="checkbox"
                    {...otherProps}
                />
                <span className={styles.slider} />
            </label>
            {getLabel(rightText, styles.rightLabel)}
        </div>
    );
});

ToggleSwitch.propTypes = {
    leftText: PropTypes.string,
    name: PropTypes.string,
    rightText: PropTypes.string,
    error: PropTypes.bool,
    required: PropTypes.bool,
    small: PropTypes.bool,
};

ToggleSwitch.defaultProps = {
    leftText: "",
    name: "toggle",
    rightText: "",
    error: false,
    required: false,
    small: false,
};

export default ToggleSwitch;
