import React from "react";
import PropTypes from "prop-types";

const ArrowDropDown = (props) => (
    <svg data-testid="arrow-dropdown-icon" viewBox="-8 -10 24 24" {...props}>
        <path d="M8.243.953l-3.674 3.92a.493.493 0 01-.738 0L.156.953a.575.575 0 010-.787A.489.489
         0 01.525 0h7.349a.489.489 0 01.369.166.575.575 0 010 .787z"
        />
    </svg>
);

ArrowDropDown.propTypes = {
    fill: PropTypes.string,
};

ArrowDropDown.defaultProps = {
    fill: '#111827',
};

export default ArrowDropDown;

