import React from "react";
import PropTypes from "prop-types";
import { Employees } from "components/icons";

import styles from "./employees-count.module.scss";

/**
 * Renders EmployeesCount
 *
 * @param {number} count The total employees
 *
 * @returns {React.Component} The EmployeesCount
 */
const EmployeesCount = ({ count }) => (
    <div className={styles.container}>
        <Employees />
        {count}
    </div>
);

EmployeesCount.propTypes = {
    count: PropTypes.number.isRequired,
};

export default EmployeesCount;
