import { React, useState, useEffect} from "react";
import { Form, TextInputRow } from "components/forms";
import { useForm, FormProvider } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationDrawer from "./confirmation-drawer";
import SecurityApi from "lib/api-endpoints/security-api";
import { getUser } from "state/user-slice";
import { useNotificationContext } from 'state/context';
import { PrimaryButton } from "components";

import styles from './components.module.scss';

/**
 * Handles the opening of the confirmation drawer
 *
 * @param {func} setDrawerState Sets the drawer state
 *
 * @return {void}
*/
const onSubmit = (setDrawerState) => {
    setDrawerState((prevState) => ({...prevState, isOpen: true, isLoading: false}));
};

/**
 * Renders the error text
 *
 * @param {object} formState Tyhe form state of the change username form
 *
 * @return {React.Component} Renders error text
 */
const renderError = (formState) => {
    const error = formState.errors?.newUsernameConfirm?.type ?? null;

    const messages = {
        "same": "Usernames must match",
        "filled": "Please enter a new username",
    };

    return messages[error] ?? null;
};

/**
 * Function for confirming the change username form
 *
 * @param {object} formData the formdata
 * @param {func} getValues gets the values of the form
 * @param {func} setDrawerState sets the drawer state
 * @param {func} openNotification opens the notification
 * @param {func} reset resets the form
 *
 * @return {void}
 */
const onConfirm = (formData, getValues, setDrawerState, openNotification, reset) => {
    const { newUsername, newUsernameConfirm } = getValues();

    return SecurityApi.changeUsername({
        "username": newUsername,
        "username_confirm": newUsernameConfirm,
        ...formData,
    }).request.then(() => {
        setDrawerState({ isOpen: false, isLoading: true });

        openNotification({
            message: "Username succesfully changed",
            type: "success",
        });
        reset();
    });
};

/**
 * Renders the change username form
 *
 * @returns {React.component} The change username form
 */
const Username = () => {
    const [drawerState, setDrawerState] = useState({isOpen: false, isLoading: true});
    const { openNotification } = useNotificationContext();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUser());
    }, [dispatch, drawerState.isOpen]);

    const user = useSelector((state) => state.user);

    const methods = useForm({
        defaultValues: {
            newUsername: "",
            newUsernameConfirm: "",
        },
    });

    const { register, handleSubmit, getValues, watch, reset, formState } = methods;
    const { newUsername, newUsernameConfirm } = getValues();
    watch();

    return (
        <>
            <div className={styles.container} data-testid="change username form">
                <FormProvider {...methods}>
                    <Form className={styles.formContainer} onSubmit={handleSubmit(() => onSubmit(setDrawerState))}>
                        <label htmlFor="username" className={styles.inputRow}>Current username</label>
                        <div id="username">
                            {user !== null ? user.username : ""}
                        </div>
                        <TextInputRow
                            {...register("newUsername")}
                            error={renderError(formState)}
                            name="newUsername"
                            label="New username"
                            data-testid="new-username"
                            className={styles.input}
                            large
                        />
                        <TextInputRow
                            {...register("newUsernameConfirm", {
                                validate: {
                                    same: (confirmingUsername) => (confirmingUsername === newUsername),
                                    filled: () => (
                                        newUsername.length > 0
                                        && newUsernameConfirm.length > 0
                                    ),
                                },
                            })}
                            error={renderError(formState)}
                            name="newUsernameConfirm"
                            label="Confirm new username"
                            data-testid="confirm-new-username"
                            className={styles.input}
                            large
                        />
                        <PrimaryButton
                            data-testid="username-save-button"
                            type="submit"
                            disabled={
                                (!newUsername)
                                || (newUsername !== newUsernameConfirm)
                            }
                            className={styles.saveButton}
                            onClick={() => handleSubmit(() => onSubmit(setDrawerState))}
                        >
                            Save
                        </PrimaryButton>
                    </Form>
                </FormProvider>
            </div>
            <ConfirmationDrawer
                drawerState={drawerState}
                setDrawerState={setDrawerState}
                onConfirm={(formData) => onConfirm(formData, getValues, setDrawerState, openNotification, reset)}
            />
        </>
    );
};

export default Username;
