import { api } from "lib/api";

export default class SchemeJobRolesApi {

    /**
     * Gets the job roles request promise
     *
     * @param {int} id The scheme id
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getJobRoles = (id) => api({ url: `/tronc_schemes/${id}/job_roles?archived=0` });

    /**
     * Add the job roles request promise
     *
     * @param {int} id The scheme id
     * @param {object} data The data to add
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static addJobRoles = (id, data) => api({ type: 'put', url: `/tronc_schemes/${id}/job_roles`, data });

}
