import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./select.module.scss";

/**
 * Renders the selection input
 *
 * @param {object} props The selection properties
 * @param {object} ref The selection properties
 *
 * @return {React.Component} The select input
 */
const Select = React.forwardRef(({
    placeholder,
    className,
    error,
    required,
    options,
    selected,
    ...otherProps
}, ref) => (
    <select
        value={selected}
        ref={ref}
        aria-required={required}
        className={classNames(
            styles.select,
            className,
            {
                [styles.error]: error,
            }
        )}
        {...otherProps}
    >
        <option value="">
            {placeholder}
        </option>
        {options.map((option) => (
            <option key={option.value} value={option.value}>
                {option.display}
            </option>
        ))}
    </select>
));

Select.defaultProps = {
    onChange: () => null,
    placeholder: "Please select…",
    className: "",
    error: false,
    selected: "",
    required: false,
};

Select.propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.bool,
    required: PropTypes.bool,
    selected: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.number,
    ]),
};

export default Select;
