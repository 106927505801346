import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate, useLocation, matchPath } from "react-router-dom";
import { MainHeader } from "app/main-header";
import { MainNavigation } from "app/main-navigation";
import { Login, SetToken } from "./pages";
import { ProtectedRoute, routePaths, routes } from "./routes";

import styles from "./app.module.scss";

/**
 * Renders main app container including routes/navigation and the main header
 *
 * @returns {React.Component} Main App Component
 */
const App = () => {
    const { pathname } = useLocation();
    const { app: { loaded, payrolls, companies }, user } = useSelector((state) => state);

    useEffect(() => {
        const activeRoutes = routes.filter(({ path }) => matchPath({ path }, pathname));

        if (activeRoutes.length > 0) {
            const { name } = activeRoutes[0];
            document.title = name;
        }
    }, [pathname]);

    const getProtectedRoutes = () => {
        const checkPayrollPerms = (route) => (
            route?.hasPayrollPermission === true || route.hasPayrollPermission?.(payrolls, user)
        );

        const checkCompanyPerms = (route) => (
            route?.hasCompanyPermission === true || route.hasCompanyPermission?.(companies, user)
        );

        return routes
            .filter((route) => checkCompanyPerms(route) || checkPayrollPerms(route))
            .map(({ path, Component, key }) => (
                <Route exact path={path} key={key} element={<ProtectedRoute>{Component}</ProtectedRoute>} />
            ));
    };

    const routeList = (!loaded)
        ? (
            <>
                <Route path="login" element={<Login />} />
                <Route path="set_token" element={<SetToken />} />
                <Route path="*" element={<Navigate to="login" replace={true} />} />
            </>
        )
        : (
            <>
                <Route index element={<Navigate to={routePaths.dashboard} replace={true} />} />
                <Route path="*" element={<div>Not Found</div>} />
                {getProtectedRoutes()}
            </>
        );

    const containerClassName = (loaded)
        ? styles.pageContainer
        : styles.fullPageContainer;

    return (
        <main>
            <div className={containerClassName}>
                <Routes>
                    {routeList}
                </Routes>
            </div>
            {(loaded) ? (
                <>
                    <MainNavigation />
                    <MainHeader />
                </>
            ) : null}
        </main>
    );
};

export default App;
