import { React, useState, useEffect } from "react";
import { Form, FormRow, TextInputRow } from "components/forms";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDrawer from "./confirmation-drawer";
import SecurityApi from "lib/api-endpoints/security-api";
import { checkValidity } from "lib/helpers/validation-helpers";
import { getUser } from "state/user-slice";
import { useNotificationContext } from 'state/context';
import { PrimaryButton } from "components";

import styles from './components.module.scss';

/**
 * Handles the opening of the confirmation drawer
 *
 * @param {func} setDrawerState Sets the drawer state
 *
 * @return {void}
*/
const onSubmit = (setDrawerState) => {
    setDrawerState((prevState) => ({...prevState, isOpen: true, isLoading: false}));
};

/**
 * Function for confirming the change email address form
 *
 * @param {object} formData the formdata
 * @param {func} getValues gets the values of the form
 * @param {func} setDrawerState sets the drawer state
 * @param {func} openNotification opens the notification
 * @param {func} reset resets the form
 *
 * @return {void}
 */
const onConfirm = (formData, getValues, setDrawerState, openNotification, reset) => {
    const { newEmail, newEmailConfirm } = getValues();

    return SecurityApi.changeEmail({
        "email": newEmail,
        "email_confirm": newEmailConfirm,
        ...formData,
    }).request.then(() => {
        setDrawerState({ isOpen: false, isLoading: true });

        openNotification({
            message: "Email address successfully changed",
            type: "success",
        });
        reset();
    });
};

/**
 * Renders the change email address form
 *
 * @returns {React.Component} The change email address form
 */
const EmailAddress = () => {
    const [drawerState, setDrawerState] = useState({isOpen: false, isLoading: true});
    const { openNotification } = useNotificationContext();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUser());
    }, [dispatch, drawerState.isOpen]);

    const user = useSelector((state) => state.user);

    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            newEmail: "",
            newEmailConfirm: "",
        },
    });

    const { handleSubmit, reset, getValues, register, formState, watch } = methods;
    const { newEmail, newEmailConfirm } = getValues();
    watch();

    return (
        <>
            <div className={styles.container} data-testid="change email form">
                <FormProvider {...methods}>
                    <Form className={styles.formContainer} onSubmit={handleSubmit(() => onSubmit(setDrawerState))}>
                        <label htmlFor="email" className={styles.inputRow}>
                            Current email
                        </label>
                        <div id="email">
                            {user?.email}
                        </div>
                        <TextInputRow
                            {...register("newEmail", {
                                required: true,
                                validate: {
                                    validEmail: (email) => {
                                        const { isValid, message } = checkValidity([
                                            email,
                                            newEmailConfirm,
                                        ], "newEmail");

                                        return isValid || message;
                                    },
                                },
                                deps: ['newEmailConfirm'],
                            })}
                            name="newEmail"
                            error={formState.errors?.newEmail?.message}
                            label="New email"
                            data-testid="new-email"
                            className={styles.input}
                            large
                        />
                        <TextInputRow
                            {...register("newEmailConfirm", {
                                required: true,
                                validate: {
                                    validEmail: (email) => {
                                        const { isValid, message } = checkValidity([email, newEmail], "newEmail");

                                        return isValid || message;
                                    },
                                },
                                deps: ['newEmail'],
                            })}
                            error={formState.errors?.newEmailConfirm?.message}
                            name="newEmailConfirm"
                            label="Confirm new email"
                            data-testid="confirm-new-email"
                            className={styles.input}
                            large
                        />
                        <FormRow>
                            <PrimaryButton
                                data-testid="email-save-button"
                                type="submit"
                                disabled={
                                    (!newEmail)
                                    || (newEmail !== newEmailConfirm)
                                }
                                className={styles.saveButton}
                                onClick={() => handleSubmit(() => onSubmit(setDrawerState))}
                            >
                                Save
                            </PrimaryButton>
                        </FormRow>
                    </Form>
                </FormProvider>
            </div>
            <ConfirmationDrawer
                drawerState={drawerState}
                setDrawerState={setDrawerState}
                onConfirm={(formData) => onConfirm(formData, getValues, setDrawerState, openNotification, reset)}
            />
        </>
    );
};

export default EmailAddress;
