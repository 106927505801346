import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isString } from 'lodash-es';

import './Tooltip.scss';
import windowScroll from 'lib/window-scroll/window-scroll';

export default class Tooltip extends React.Component {

    static propTypes = {
        children: PropTypes.any,
        position: PropTypes.oneOf(["top", "right", "bottom", "left"]),
        shown: PropTypes.bool,
        targetNode: PropTypes.instanceOf(HTMLElement),
        text: PropTypes.any.isRequired,
        visible: PropTypes.bool,
    };

    static defaultProps = {
        children: null,
        position: "top",
        shown: false,
        targetNode: null,
        visible: false,
    };

    /**
     * Creates an instance of the tooltip wrapper
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.anchorRef = props.targetNode;
        this.hideTimeout = null;

        this.state = {
            shown: props.shown,
            visible: props.visible,
        };
    }

    /**
     * Sets the element reference that the tooltip should be positiond next to
     *
     * @param {HTMLElement} element The reference element
     */
    setAnchorRef = (element) => {
        this.anchorRef = element;
    };

    /**
     * Adds then shows the tooltip
     *
     * @return {void}
     */
    showTooltip () {
        // If the user hovers off then back rapidly the delay before removing
        // the tooltip will not have passed and it will weirdly vanish.
        if (this.hideTimeout) {
            window.clearTimeout(this.hideTimeout);
        }

        this.setState({ shown: true }, () => {
            this.setState({ visible: true });
        });
    }

    /**
     * Hides then removes the tooltip
     *
     * @return {void}
     */
    hideTooltip () {
        this.setState({ visible: false });

        this.hideTimeout = window.setTimeout(() => {
            this.setState({ shown: false });
        }, 300);
    }

    /**
     * Called when the mouse is moved over the source element
     *
     * @return {void}
     */
    handleMouseOver = () => {
        this.showTooltip();
    };

    /**
     * Called when the mouse is moved away from the source element
     *
     * @return {void}
     */
    handleMouseOut = () => {
        this.hideTooltip();
    };

    /**
     * Called when focus is moved to from the source element
     *
     * @return {void}
     */
    handleFocus = () => {
        this.showTooltip();
    };

    /**
     * Called when focus is moved away from the source element
     *
     * @return {void}
     */
    handleBlur = () => {
        this.hideTooltip();
    };

    /**
     * Called just after the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        if (!this.anchorRef) {
            throw new Error("Tooltip anchor ref not set");
        }

        this.anchorRef.addEventListener('mouseover', this.handleMouseOver);
        this.anchorRef.addEventListener('mouseout', this.handleMouseOut);
        this.anchorRef.addEventListener('focus', this.handleFocus);
        this.anchorRef.addEventListener('blur', this.handleBlur);
    }

    /**
     * Called just before the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        if (!this.anchorRef) {
            throw new Error("Tooltip anchor ref not set");
        }

        this.anchorRef.removeEventListener('mouseover', this.handleMouseOver);
        this.anchorRef.removeEventListener('mouseout', this.handleMouseOut);
        this.anchorRef.removeEventListener('focus', this.handleFocus);
        this.anchorRef.removeEventListener('blur', this.handleBlur);
    }

    /**
     * Gets the top position for the tooltip
     *
     * @param {DOMRect} anchorBox The anchor elements bounding box
     *
     * @return {integer} The position
     */
    getTopOffset (anchorBox) {
        let offset = anchorBox.top + windowScroll.getY();

        if (this.props.position === "bottom" && this.props.text === "Search") {
            offset += anchorBox.height + 45;
        }

        if (this.props.position === "bottom") {
            offset += anchorBox.height;
        } else if (this.props.position !== "top") {
            offset += anchorBox.height / 2;
        }

        return offset;
    }

    /**
     * Gets the left position for the tooltip
     *
     * @param {DOMRect} anchorBox The anchor elements bounding box
     *
     * @return {integer} The position
     */
    getLeftOffset (anchorBox) {
        let offset = anchorBox.left + windowScroll.getX();

        if (this.props.position === "right") {
            offset += anchorBox.width;
        } else if (this.props.position !== "left") {
            offset += anchorBox.width / 2;
        }

        return offset;
    }

    /**
     * Gets the left position of the tooltip when close to the edge of the viewport
     *
     * @param {DOMRect} anchorBox The anchor elements bounding box
     *
     * @return {integer} The position
     */
    getLeftEdgeOffset (anchorBox) {
        let offset = anchorBox.left + windowScroll.getX();
        const offSetCalc = window.innerWidth - anchorBox.right;

        if (this.props.text.length <= 15) {
            offset -= (offSetCalc);
        } else if (this.props.text.length > 16) {
            offset -= (offSetCalc + 20);
        }

        return offset;
    }

    /**
     * Renders the tooltip information based on the supplied text property type.
     * Objects will generate a list representation of the data set. Strings will output as is.
     *
     * @return {any} Components to render in tooltip.
     */
    renderText = () => {
        const { text } = this.props;

        if (isString(text)) {
            return text;
        }

        return Object.keys(text).map((keyName) => {
            const keyValue = text[keyName];

            return (
                <div key={keyName}>{keyName}: {keyValue}</div>
            );
        });
    };

    /**
     * Renders the tooltip
     *
     * @return {ReactElement} The tooltip content
     */
    renderTooltip () {
        if (!this.state.shown || !this.anchorRef) {
            return null;
        }

        const anchorBox = this.anchorRef.getBoundingClientRect();

        const leftOffset = (window.innerWidth < anchorBox.right + 50 && this.props.position === "bottom")
            ? this.getLeftEdgeOffset(anchorBox)
            : this.getLeftOffset(anchorBox);

        const anchorCenter = {
            top: this.getTopOffset(anchorBox),
            left: leftOffset,
        };

        const position = {
            top: `${anchorCenter.top}px`,
            left: `${anchorCenter.left}px`,
        };

        const classList = classNames(
            "tooltip",
            this.props.position,
            {
                "visible": this.state.visible,
            },
        );

        return (
            <div className={classList} style={position}>
                {this.renderText()}
            </div>
        );
    }

    /**
     * Renders the tooltip
     *
     * @return {Fragment} The child element and a tooltip for it
     */
    render () {
        if (this.props.targetNode) {
            return ReactDOM.createPortal(this.renderTooltip(), document.body);
        }

        const tooltip = ReactDOM.createPortal(this.renderTooltip(), document.body);
        const element = (
            <div ref={this.setAnchorRef}>
                {this.props.children}
            </div>
        );

        return (
            <Fragment>
                {tooltip}
                {element}
            </Fragment>
        );
    }

}
