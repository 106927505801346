import { useEffect, useState } from "react";
import { TroncSchemesApi } from "lib/api-endpoints";

const initialState = {
    schemes: [],
    meta: {},
    isLoaded: false,
};

const useTroncSchemesList = ({ page, companyIds = [], siteIds = [], refresh, getCurrentPeriod }) => {
    const [state, setState] = useState(initialState);
    const companiesString = JSON.stringify(companyIds);
    const sitesString = JSON.stringify(siteIds);

    useEffect(() => {
        setState(initialState);

        const { request, abortController } = TroncSchemesApi.getTroncSchemes({
            "company_ids": JSON.parse(companiesString),
            "site_ids": JSON.parse(sitesString),
            page,
            "include_current_period": getCurrentPeriod,
        });

        request.then(({ data }) => setState({
            schemes: data?.data,
            isLoaded: true,
            meta: data?.meta,
        }));

        return () => abortController?.abort();
    }, [page, refresh, getCurrentPeriod, companiesString, sitesString]);

    return state;
};

export default useTroncSchemesList;
