import React, { useState } from 'react';

import {
    Page,
    PageContent,
    LayoutContainer,
    Table,
    SecondaryButton,
    TextButton,
    DateDisplay,
} from 'components/';

import { Select } from 'components/forms';
import { streamFileHandler } from 'lib/downloaders/stream-file-handler';
import { useUpdates } from 'lib/api-endpoints';
import UpdatesApi from 'lib/api-endpoints/updates-api';

import styles from './updates.module.scss';

const Updates = () => {
    const [updatesParams, setUpdateParams] = useState({ page: 1, type: "all" });
    const { page, type } = updatesParams;
    const { data } = useUpdates({ page, type: (type === "all") ? null : type });
    const responseData = data?.data;

    const getFormattedData = () => {
        return responseData.map((updates) => ({
            id: updates.id,
            name: {
                component: (
                    <TextButton
                        onClick={() => handleDownload(updates)}
                        className={styles.text}
                    >
                        {updates.name}
                    </TextButton>
                ),
            },
            date: {
                component: (
                    <DateDisplay data={updates.date} output="DD-MM-YYYY" />
                ),
            },
            type: updates.type,
            actions: {
                component: (
                    <SecondaryButton onClick={() => handleDownload(updates)}>
                        Download
                    </SecondaryButton>
                ),
            },
        }));
    };

    const tableData = getFormattedData(responseData);

    const handleDownload = (updates) => {
        streamFileHandler(UpdatesApi.getUpdatesFileData(updates.id).request, { filename: updates.name });
    };

    return (
        <Page title="Updates">
            <PageContent className={styles.page}>
                <Select
                    className={styles.filter}
                    aria-label="updates options"
                    onChange={({ target: { value }}) => {
                        setUpdateParams({ ...updatesParams, type: value });
                    }}
                    options={[
                        { value: "resource", display: "Resources"},
                        { value: "update", display: "Updates"},
                        { value: "all", display: "All" },
                    ]}
                    value={updatesParams.type}
                />
                <LayoutContainer title="Updates">
                    <Table
                        data={tableData}
                        skeletonConfig={{
                            rowCount: 10,
                            rowConfig: [
                                { key: 'updates' },
                            ],
                        }}
                        headings={{
                            name: "Subject",
                            date: "Created",
                            type: "Type",
                            actions: "Actions",
                        }}
                        noDataText="No Updates"
                        pagination={{
                            totalPages: responseData?.meta?.last_page,
                            currentPage: updatesParams.page,
                            onPageChange: (newPage) => setUpdateParams({ ...updatesParams, page: newPage }),
                        }}
                    />
                </LayoutContainer>
            </PageContent>
        </Page>
    );
};

export default Updates;
