import PropTypes from "prop-types";
import React from "react";

const Home = ({ fillColor, ...props}) => (
    <svg
        width="16"
        height="18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M2 15.5h3v-6h6v6h3v-9L8 2 2 6.5v9Zm0 2c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 0
                15.5v-9c0-.317.071-.617.213-.9.141-.283.337-.517.587-.7l6-4.5a2.11 2.11 0 0
                1 .575-.3c.2-.067.408-.1.625-.1.217 0 .425.033.625.1s.392.167.575.3l6 4.5a1.99 1.99 0 0
                1 .8 1.6v9c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 14 17.5H9v-6H7v6H2Z"
            fill={fillColor}
        />
    </svg>
);

Home.propTypes = {
    fillColor: PropTypes.string,
};

Home.defaultProps = {
    fillColor: '#fff',
};

export default Home;
