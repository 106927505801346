import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './stepper.module.scss';

/**
 * Renders the step as part of the stepper
 *
 * @returns {React.Component} Step component
 */
const Step = ({ step, currentPage }) => {

    /**
     * Checks the step is currently selected
     */
    const isCurrentStep = (step.stepNum === currentPage);

    const stepBarClassList = classNames(styles.stepBar, {
        [styles.activeStep]: isCurrentStep,
    });

    const currentStepClassList = classNames(styles.currentStep, {
        [styles.activeCurrentStep]: isCurrentStep,
    });

    return (
        <div className={styles.stepContainer}>
            <div className={stepBarClassList} />
            <div className={currentStepClassList}>Step {step.stepNum}</div>
            <div className={styles.stepTitle}>{step.stepTitle}</div>
        </div>
    );
};

Step.propTypes = {
    step: PropTypes.shape({
        stepNum: PropTypes.number.isRequired,
        stepTitle: PropTypes.string.isRequired,
        component: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node,
            PropTypes.element,
            PropTypes.func,
        ]),
    }).isRequired,
    currentPage: PropTypes.number.isRequired,
};

export default Step;
