import axios from 'axios';

const sentryClient = axios.create({
    baseURL: 'https://crash.datacode.dev/api/0/projects/dataplan/tipology',
    headers: {
        'Authorization': `DSN ${window?.ENV?.SENTRY_DSN}`,
        'Content-Type': 'application/json',
    },
});

export default class SentryApi {

    /**
     * Sends a post request to sentry
     *
     * @param {object} data The error form payload
     * @returns {request} The post request to Sentry's user-feedback
     */
    static addError = (data) => sentryClient.post("/user-feedback/", {
        "event_id": data.eventId,
        "name": data.name,
        "email": data.email,
        "comments": data.comments,
    });

}
