import React from "react";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { Page, PageContent } from "components/";

/**
 * Renders the file navigation wrapper page
 *
 * @param {object} props The navigation page props
 * @param {any} props.title The page title
 * @param {any} props.children The page contents
 * @param {any} props.titleAction The title action
 *
 * @return {React.Component} Renders the Companies page
 */
const FileNavigationPage = ({ title, children, titleAction }) => {
    const { app, user } = useSelector((state) => state);
    const companies = app.companies;

    const canUploadFiles = user.tronc_master
        || companies.filter(({ companyPermissions }) => companyPermissions?.periodFiles?.canCreate).length > 0;

    const availableAction = canUploadFiles ? titleAction : null;

    return (
        <Page title={title} titleAction={availableAction} renderBreadcrumbs>
            <PageContent>
                {children}
            </PageContent>
        </Page>
    );
};

FileNavigationPage.defaultProps = {
    titleAction: null,
    title: null,
    children: null,
};

FileNavigationPage.propTypes = {
    title: PropTypes.string,
    titleAction: PropTypes.any,
    children: PropTypes.any,
};

export default FileNavigationPage;
