import Permissions from "./permissions";

export default class UserManagementPermissions extends Permissions {

    /**
     * @inheritDoc
     */
    register () {
        const isSuperUser = this.permissions.includes("super_user");

        this.canView = isSuperUser;
        this.canEdit = isSuperUser;
        this.canCreate = isSuperUser;
        this.canDelete = isSuperUser;
    }

}
