import React from 'react';
import PropTypes from 'prop-types';
import { Tick, Warning, Info } from 'components/icons';

import styles from './information-icon.module.scss';

const iconMap = {
    success: Tick,
    warn: Warning,
    error: Warning,
    info: Info,
};

/**
 * Gets the icon that should be shown on the alert card
 *
 * @param {string} type The type of alert
 *
 * @return {string} The icon to show
 */
const InformationIcon = ({ type, ...props }) => {
    const Component = iconMap[type] ?? null;

    if (!Component) {
        return null;
    }

    return (
        <div className={styles.iconContainer}>
            <Component {...props} />
        </div>
    );
};

InformationIcon.propTypes = {
    type: PropTypes.any,
};

InformationIcon.defaultProps = {
    type: null,
};

export default InformationIcon;
