import Permissions from "./permissions";

export default class CompanyDepartmentsPermissions extends Permissions {

    /**
     * @inheritDoc
     */
    register () {
        const { troncmaster, permissions } = this;
        const viewDepartments = troncmaster || permissions.includes("view_departments");
        const editDepartments = troncmaster || permissions.includes("edit_departments");
        const createDepartments = troncmaster || permissions.includes("create_departments");
        const deleteDepartments = troncmaster || permissions.includes("delete_departments");

        this.canView = viewDepartments;
        this.canEdit = editDepartments;
        this.canCreate = createDepartments;
        this.canDelete = deleteDepartments;
    }

}
