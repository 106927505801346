import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { noop, debounce } from "lodash";
import classNames from "classnames";
import { LoadingSpinner } from "components";
import { TextInputRow } from "components/forms";

import styles from "./typeahead.module.scss";

const TypeAhead = ({
    placeholder,
    debounceDelay,
    suggestions,
    loading,
    onChange,
    minLength,
    onBeforeSearch,
    onSelected,
    onAfterSelect,
}) => {
    const [text, setState] = useState("");
    const callBack = debounce((query) => onChange(query), debounceDelay);
    const delayedQuery = useCallback(callBack, [callBack]);
    const isVisible = loading || suggestions.length > 0;

    const ulClassNames = classNames({
        [styles.suggestions]: isVisible,
    });

    const handleOnChange = (event) => {
        setState(event.target.value);
        onBeforeSearch();

        if (event.target.value.length < minLength) {
            return;
        }

        delayedQuery(event.target.value);
    };

    const handleSelected = (suggestion) => {
        setState(suggestion.display);
        onSelected(suggestion);
        onAfterSelect();
    };

    return (
        <div className={styles.typeahead}>
            <TextInputRow
                role="combobox"
                aria-expanded={isVisible ? "true" : "false"}
                aria-owns="list_box"
                aria-autocomplete="list"
                aria-controls="list_box"
                name="typeahead"
                type="text"
                placeholder={placeholder}
                onChange={handleOnChange}
                value={text}
            />
            <ul id="list_box" className={ulClassNames}>
                {loading && <li className={styles.loading}><LoadingSpinner /></li>}
                {suggestions?.map((suggestion) => (
                    <li
                        key={suggestion.key}
                        className={styles.list}
                    >
                        <button
                            type="button"
                            className={styles.suggestion}
                            onClick={() => handleSelected(suggestion)}
                        >
                            {suggestion.display}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

TypeAhead.propTypes = {
    suggestions: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.number.isRequired,
        display: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
    })),
    onChange: PropTypes.func,
    onSelected: PropTypes.func,
    onBeforeSearch: PropTypes.func,
    onAfterSelect: PropTypes.func,
    placeholder: PropTypes.string,
    minLength: PropTypes.number,
    debounceDelay: PropTypes.number,
    loading: PropTypes.bool,
};

TypeAhead.defaultProps = {
    suggestions: [],
    placeholder: "Search…",
    minLength: 3,
    debounceDelay: 300,
    onChange: noop,
    loading: false,
    onSelected: noop,
    onBeforeSearch: noop,
    onAfterSelect: noop,
};

export default TypeAhead;
