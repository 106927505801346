import React from 'react';

/**
 * Renders the warning icon component
 *
 * @param {object} props The icon props
 *
 * @return {ReactElement} The warning icon element
 */
const Warning = (props) => (
    <svg data-testid="warning-icon" viewBox="0 0 23 20.462" {...props}>
        <g>
            <path
                d="M13.671,5.851a2.195,2.195,0,0,1,1.807,1.243l6.917,
                11.53c1,1.656.227,3.01-1.7,3.01H6.651c-1.931,0-2.7-1.356-1.7-3.01l6.917-11.53a2.195,
                2.195,0,0,1,1.807-1.243m0-2.34A4.49,4.49,0,0,0,9.858,5.887L2.941,17.419A4.375,4.375,
                0,0,0,2.666,21.9a4.38,4.38,0,0,0,3.985,2.073h14.04A4.378,4.378,0,0,0,24.677,21.9,4.377,
                4.377,0,0,0,24.4,17.42L17.484,5.891A4.486,4.486,0,0,0,13.671,3.511Z"
                transform="translate(-2.171 -3.511)"
            />
        </g>
        <g transform="translate(9.979 13.091)">
            <circle cx="1.521" cy="1.521" r="1.521" />
        </g>
        <g transform="translate(9.745 5.837)">
            <path
                d="M14.01,10.255a1.755,1.755,0,1,0-3.38.658c.648,
                1.61,1.625,4.023,1.625,4.023s.977-2.413,1.628-4.023A1.8,1.8,0,0,0,14.01,10.255Z"
                transform="translate(-10.5 -8.5)"
            />
        </g>
    </svg>
);

export default Warning;
