import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { groupBy, isEmpty, find, parseInt } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { Form, FormActions, TextInputRow, DateRangeInputRow } from "components/forms";
import TextAreaInputRow from "components/forms/text-area-row";
import { CompanyOptions, PayrollOptions, DepartmentOptions } from "components/forms/select-options";
import { DateDisplay } from "components";
import AnnouncementsApi from "lib/api-endpoints/announcements-api";
import FormContent from "components/forms/form-container/form-content";

import styles from "./add-edit-announcement.module.scss";

/**
 * Gets the form detault values
 *
 * @param {object|null} announcement The announcement
 * @param {array} companies An array of companies
 * @param {object} payrolls An object containing a list of payrolls grouped by company id
 *
 * @returns {object} The default form values
 */
const getDefaultValues = (announcement, companies, payrolls) => {
    if (isEmpty(announcement)) {
        const company = companies?.[0];
        const payroll = payrolls?.[company.id]?.[0];
        const department = payroll?.departments?.[0];

        return {
            company: company.id,
            payrollid: payroll?.id,
            deptno: department?.deptno,
        };
    }

    return {
        ...announcement,
        datestart: moment(announcement.datestart).format('YYYY-MM-DD'),
        dateend: moment(announcement.dateend).format('YYYY-MM-DD'),
    };
};

/**
 * Renders the add/edit announcement form
 *
 * @param {object|null} announcement The announcement to edit
 * @param {function} onSuccess The success callback
 * @param {function} onCancel The onCancel callback
 *
 * @returns {React.Component} The add / edit announcement component
 */
const AddEditAnnouncement = ({ announcement, onSuccess, onCancel }) => {
    const { companies, payrolls } = useSelector((state) => state.app);
    const groupedPayrolls = groupBy(payrolls, 'company_id');

    const methods = useForm({
        defaultValues: getDefaultValues(announcement, companies, groupedPayrolls),
    });

    const { register, handleSubmit, getValues, watch } = methods;
    watch();

    const { company, payrollid } = getValues();
    const selectedPayroll = find(payrolls, { id: parseInt(payrollid) });

    const renderCompanyDetails = () => {
        if (!isEmpty(announcement)) {
            const selectedCompany = find(companies, { id: parseInt(selectedPayroll.company_id) });
            const { addedby, dateadded } = announcement;
            const name = [addedby?.forename, addedby?.surname].filter((value) => !isEmpty(value)).join(" ");

            return (
                <dl className={styles.details}>
                    <div>
                        <dt>Company</dt>
                        <dd>{selectedCompany?.name}</dd>
                    </div>
                    <div>
                        <dt>Payroll</dt>
                        <dd>{selectedPayroll?.name}</dd>
                    </div>
                    <div>
                        <dt>Created</dt>
                        <dd>{name} {<DateDisplay data={dateadded} output="DD-MM-YYYY" />}</dd>
                    </div>
                </dl>
            );
        }

        return (
            <>
                <CompanyOptions
                    {...register("company")}
                    companies={companies}
                    selected={getValues("company")}
                />
                <PayrollOptions
                    {...register("payrollid")}
                    payrolls={groupedPayrolls[company]}
                    selected={getValues("payrollid")}
                />
            </>
        );
    };

    /**
     * Saves the files to the selected period
     *
     * @param {object} formData The form data
     *
     * @return {void}
     */
    const onSubmit = (formData) => {
        const isEdit = !isEmpty(announcement);

        if (isEdit) {
            AnnouncementsApi.edit(announcement.id, formData)
                .request
                .then(() => onSuccess(isEdit));
        } else {
            AnnouncementsApi.create(formData)
                .request
                .then(() => onSuccess(isEdit));
        }
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <FormContent>
                    {renderCompanyDetails()}
                    <DepartmentOptions {...register("deptno")} departments={selectedPayroll?.departments || []} />
                    <DateRangeInputRow
                        start={{ ...register("datestart")}}
                        end={{ ...register("dateend")}}
                        label="Date Range Visible"
                    />
                    <TextInputRow {...register("subject")} label="Subject" data-testid="subject" />
                    <TextAreaInputRow {...register("message")} label="Message" rows={30} data-testid="message" />
                </FormContent>
                <FormActions onSubmit={handleSubmit(onSubmit)} onCancel={onCancel} />
            </Form>
        </FormProvider>
    );
};

AddEditAnnouncement.propTypes = {
    isOpen: PropTypes.bool,
    isLoading: PropTypes.bool,
    editable: PropTypes.bool,
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func,
    announcement: PropTypes.object,
};

AddEditAnnouncement.defaultProps = {
    isOpen: false,
    isLoading: false,
    editable: false,
    onCancel: () => null,
    onSuccess: () => null,
    announcement: null,
};

export default AddEditAnnouncement;
