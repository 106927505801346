import React from "react";

const LeftArrow = () => (
    <svg
        width="16"
        height="11"
        viewBox="0 0 16 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden
    >
        <path
            d="M5.70711 10.0694C5.31658 10.4599 4.68342 10.4599 4.2929 10.0694L0.292894 6.06941C-0.0976312
                5.67889 -0.0976312 5.04572 0.292894 4.6552L4.29289 0.655198C4.68342 0.264673 5.31658 0.264673 5.70711
                0.655198C6.09763 1.04572 6.09763 1.67889 5.70711 2.06941L3.41421 4.36231L15 4.3623C15.5523 4.3623 16
                4.81002 16 5.3623C16 5.91459 15.5523 6.3623 15 6.3623L3.41421 6.36231L5.70711 8.6552C6.09763 9.04572
                6.09763 9.67889 5.70711 10.0694Z"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="currentColor"
        />
    </svg>
);

export default LeftArrow;
