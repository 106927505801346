import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CompanyCard from './company-card';

import styles from './companies-grid.module.scss';

/**
 * Renders the company cards ordered by name alphabetically
 *
 * @param {array} companies An array of companies
 *
 * @returns {React.Component} The companies grid
 */
const CompaniesGrid = ({ companies }) => {
    return (
        <div className={classNames(styles.companiesWrapper, {
            [styles.listView]: companies.length,
            [styles.gridView]: (companies.length > 6),
        })}
        >
            {companies.map((company) => (
                <CompanyCard key={company.id} company={company} />
            ))
            }
        </div>
    );
};

CompaniesGrid.propTypes = {
    companies: PropTypes.array,
};

CompaniesGrid.defaultProps = {
    companies: [],
};

export default CompaniesGrid;

