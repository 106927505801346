import React from "react";
import PropTypes from "prop-types";
import SearchInput from "./search-input";

const CompanySearchInput = ({ onChange, ...props }) => (
    <SearchInput
        {...props}
        data-testid="company-search"
        onChange={onChange}
        tooltipText="Search company by name and companies house number"
    />
);

CompanySearchInput.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default CompanySearchInput;
