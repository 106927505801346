import React from "react";
import PropTypes from "prop-types";
import { useForm, FormProvider } from "react-hook-form";
import { isEmpty } from "lodash";
import { Form, FormActions, TextInputRow } from "components/forms";
import FileInputRow from "components/forms/file-input-row";
import { TroncPeriodsApi } from "lib/api-endpoints";

import styles from './period-file-upload-form.module.scss';

/**
 * Renders the period file upload form
 *
 * @param {string} period The current period
 * @param {function} onSuccess The callback
 * @param {function} onCancel The callback
 *
 * @return {React.Component} Period file upload form
 */
const PeriodFileUploadForm = ({ period, onSuccess, onCancel }) => {
    const methods = useForm({
        defaultValues: {
            files: {},
        },
    });

    const { register, handleSubmit, getValues, watch } = methods;
    watch();

    const { files } = getValues();

    /**
     * Saves the files to the selected period
     *
     * @param {object} formData The form data
     *
     * @return {void}
     */
    const onSubmit = (formData) => {
        TroncPeriodsApi.uploadFiles(period, formData).request.then(onSuccess);
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.formContent}>
                    <FileInputRow {...register("files")} />
                    {!isEmpty(files) && (<TextInputRow {...register("description")} label="Additional note" />)}
                </div>
                <FormActions onSubmit={handleSubmit(onSubmit)} onCancel={onCancel} />
            </Form>
        </FormProvider>
    );
};

PeriodFileUploadForm.propTypes = {
    period: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default PeriodFileUploadForm;
