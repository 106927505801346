import React, { useEffect, useCallback, useState } from 'react';
import { Drawer, DrawerTitle } from 'components';
import _, { noop } from 'lodash';
import PropTypes from "prop-types";
import TroncReportCard from './components/tronc-report-card';
import TroncReportDrawerHeader from './components/tronc-report-drawer-header';
import { TroncReportsApi } from "lib/api-endpoints";

import styles from './tronc-report-drawer.module.scss';

/**
 * Renders the tronc report drawer
 *
 * @returns {React.Element} The tronc report drawer
 */
const TroncReportDrawer = ({ drawerState, setDrawerState, refreshData, refresh }) => {
    const [reports, setReports] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const scheme = drawerState?.currentScheme;
    const reverseReports = _.sortBy(reports, (report) => report.version).reverse();

    const getReports = useCallback(() => {
        setLoaded(false);

        TroncReportsApi.getReports({
            "tronc_period_ids": [scheme?.currentPeriod?.id],
            "show_all_data": 1,
        }).request.then(({ data }) => {
            setReports(data.data);
            setLoaded(true);
        });
    }, [scheme]);

    /**
     * Sets tronc report drawer to close and signals to refresh the scheme data
     *
     * @returns {void}
     */
    const onCloseDrawer = () => {
        setDrawerState({ ...drawerState, isOpen: false });
        refreshData(!refresh);
    };

    /**
     * gets report for the required site ids
    */
    useEffect(() => {
        if (scheme?.id) {
            getReports();
        }

        return () => {
            setReports([]);
            setLoaded(false);
        };
    }, [scheme.id, setReports, scheme, getReports]);

    const reportsList = reverseReports?.map((report) => (
        <TroncReportCard
            drawerState={drawerState}
            onGeneratereport={getReports}
            key={report.id}
            latestVersion={reports.length}
            report={report}
            scheme={scheme}
            setDrawerState={setDrawerState}
        />
    ));

    return (
        <Drawer
            isOpen={drawerState.isOpen}
            isLoading={!scheme || !loaded}
            onClose={onCloseDrawer}
            onOutsideClick={onCloseDrawer}
            headerContent={<DrawerTitle>Generate report</DrawerTitle>}
            padding={false}
            ariaLabel="tronc report drawer"
            wide
        >
            {(scheme && drawerState.isOpen && loaded) ? (
                <div className={styles.formContent}>
                    <TroncReportDrawerHeader
                        scheme={scheme}
                        drawerState={drawerState}
                        setDrawerState={setDrawerState}
                        newestReport={reverseReports[0]}
                        onGeneratereport={getReports}
                    />
                    {reportsList}
                </div>
            ) : null}
        </Drawer>
    );
};

TroncReportDrawer.propTypes = {
    drawerState: PropTypes.object.isRequired,
    setDrawerState: PropTypes.func.isRequired,
    refreshData: PropTypes.func,
    refresh: PropTypes.bool.isRequired,
};

TroncReportDrawer.defaultProps = {
    refreshData: noop,
};

export default TroncReportDrawer;
