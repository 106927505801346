import { useState, useEffect } from "react";
import UsersApi from "lib/api-endpoints/users-api";

const initialState = {
    data: {},
    isLoaded: false,
};

/**
 * Gets the individual user details
 *
 * @param {int} id The user id
 *
 * @returns {object} The user state
 */
const useUserDetails = (id) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        setState(initialState);

        if (!id) {
            return { ...initialState, isLoaded: true };
        }

        const { request, abortController } = UsersApi.getUserDetails(id);

        request.then(({ data }) => setState({ data, isLoaded: true }));

        return () => abortController?.abort();
    }, [id]);

    return state;
};

export default useUserDetails;
