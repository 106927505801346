import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { size, map, isEmpty } from "lodash";
import { Checkbox } from "components/forms/form-controls";
import Pagination from "components/pagination/pagination";
import SkeletonText from "components/skeleton-loaders/skeleton-text";
import Row from './row';

import styles from "./table.module.scss";

const hidePaginationLength = 1;

/**
 * Renders the table component
 *
 * @param {object} props The component properties
 *
 * @returns {React.Component} The table component
 */
const Table = ({
    actions,
    data,
    dataLoaded,
    headings,
    noDataText,
    onRowsSelected,
    pagination,
    skeletonConfig,
    withSelectableRows,
    headerCellClass,
    ...props
}) => {
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(
        () => {
            if (onRowsSelected) {
                onRowsSelected(selectedRows);
            }
        },
        [selectedRows, onRowsSelected]
    );

    /**
     * Add selected row for given row id
     *
     * @param {number} rowId the selected row's id
     */
    const addSelectedRow = (rowId) => {
        if (selectedRows.indexOf(rowId) === -1) {
            setSelectedRows(selectedRows.concat([rowId]));
        }
    };

    /**
     * Remove selected row for given row id
     *
     * @param {number} rowId the selected row's id
     */
    const removeSelectedRow = (rowId) => {
        if (selectedRows.indexOf(rowId) !== -1) {
            setSelectedRows(selectedRows.filter((existingRowId) => existingRowId !== rowId));
        }
    };

    /**
     * handles the selected row
     *
     * @param {any} event the select event
     */
    const handleSelect = (event) => {
        const rowId = Number(event.target.value);
        const checked = event.target.checked;

        if (!checked) {
            removeSelectedRow(rowId);
        } else {
            addSelectedRow(rowId);
        }
    };

    /**
     * handles all the selected rows
     *
     * @param {any} event  the select all event
     */
    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedRows(data.map((row) => row.id));
        } else {
            setSelectedRows([]);
        }
    };

    /**
     * Gets an amount of rows based on config
     *
     * @returns {array} of rows to skeleton
     */
    const getSkeletonRows = () => {
        let skeletonRows = [];

        const { rowCount, rowConfig, className } = skeletonConfig;

        for (let i = 0; i < rowCount; i++) {
            skeletonRows.push((
                <tr key={`skeleton-row-${i}`} data-testid={`skeleton-row-${i}`}>
                    {rowConfig.map(({ key }) => (
                        <td
                            colSpan={size(headings)}
                            key={`skeleton-row-cell-${i}-${key}`}
                            data-testid={`skeleton-cell-${i}`}
                        >
                            <SkeletonText className={className} />
                        </td>
                    ))}
                </tr>
            ));
        }

        return skeletonRows;
    };

    /**
     * Gets a table row with text for when there's no data
     *
     * @returns {HTMLElement} Table row containing text
     */
    const getNoDataSlate = () => {
        return (
            <tr>
                <td
                    className={styles.noData}
                    colSpan={size(headings)}
                    data-testid="no-data-cell"
                >
                    {noDataText}
                </td>
            </tr>
        );
    };

    /**
     * Renders the table rows based on whether it has data to show
     *
     * @returns {React.Component} Row component to render
     */
    const renderRows = () => {
        if (!dataLoaded) {
            return getSkeletonRows();
        } else if (isEmpty(data)) {
            return getNoDataSlate();
        } else {
            return data.map((rowData) => (
                <Row
                    key={`row-${rowData.id}`}
                    actions={actions}
                    rowData={rowData}
                    cellOrder={headings}
                    id={rowData.id}
                    onRowCheck={handleSelect}
                    checked={selectedRows.indexOf(rowData.id) !== -1}
                    showCheckBoxes={withSelectableRows}
                />
            ));
        }
    };

    return (
        <>
            <table {...props} className={styles.table}>
                <thead>
                    <tr data-testid="table-header-row">
                        {(withSelectableRows && !isEmpty(data))
                            ? (
                                <th>
                                    <Checkbox
                                        checked={selectedRows.length === data.length}
                                        onChange={handleSelectAll}
                                        data-testid="select-all-rows-checkbox"
                                    />
                                </th>
                            )
                            : null
                        }
                        {map(headings, (heading, index) => (
                            <th
                                key={index}
                                className={headerCellClass}
                            >
                                {heading}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    { renderRows() }
                </tbody>
            </table>
            {size(data) && pagination?.totalPages > hidePaginationLength
                ? <Pagination {...pagination} />
                : null
            }
        </>
    );
};

Table.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        handler: PropTypes.func,
    })),
    withSelectableRows: PropTypes.bool,
    noDataText: PropTypes.string,
    onRowsSelected: PropTypes.func,
    headings: PropTypes.object.isRequired,
    pagination: PropTypes.shape({
        className: PropTypes.string,
        totalPages: PropTypes.number,
        currentPage: PropTypes.number,
        onPageChange: PropTypes.func,
    }),
    skeletonConfig: PropTypes.shape({
        rowCount: PropTypes.number,
        rowConfig: PropTypes.arrayOf(PropTypes.shape({ colSpan: PropTypes.number })),
        className: PropTypes.string,
    }),
    data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]).isRequired,
    })),
    dataLoaded: PropTypes.bool,
    headerCellClass: PropTypes.string,
};

Table.defaultProps = {
    actions: [],
    withSelectableRows: false,
    noDataText: "No Data",
    onRowsSelected: () => null,
    pagination: {},
    skeletonConfig: {},
    dataLoaded: true,
    data: null,
    headerCellClass: "",
};

export default Table;
