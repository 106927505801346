import { createSlice } from "@reduxjs/toolkit";
import _, { groupBy } from "lodash";
import Payroll from "lib/payrolls/payroll";
import Company from "lib/companies/company";
import { NewsApi, PeriodsApi, CompaniesApi, PayrollsApi, AnnouncementsApi, UpdatesApi } from "lib/api-endpoints";

const initialState = {
    loaded: false,
    payrolls: [],
    companies: [],
    news: {},
    announcements: [],
    updates: [],
    crumbs: {},
};

const appSlice = createSlice({
    name: "app",
    reducers: {
        loaded: (state) => {
            state.loaded = true;
        },
        setNews: (state, { payload }) => {
            state.news = payload;
        },
        setNewsSeen: (state) => {
            /* eslint-disable-next-line camelcase */
            state.news.display_news = false;
        },
        setPayrolls: (state, action) => {
            const { payload: { payrolls, permissions }} = action;
            const payrollPermissions = _.keyBy(permissions, 'payroll_id');

            state.payrolls = payrolls.map((payroll) => {
                const payrollManager = new Payroll(
                    payroll,
                    payrollPermissions[payroll.id] ?? { permissions: [] }
                );

                payrollManager.register();

                // best not to store a non-serializable value in the state
                return JSON.parse(JSON.stringify(payrollManager));
            });

        },
        setPayrollsPeriods: (state, action) => {
            const { payrolls, periods } = action.payload;
            const payrollsPeriods = groupBy(periods, 'payroll_id');

            state.payrolls = payrolls.map((payroll) => (
                {
                    ...payroll,
                    periods: payrollsPeriods[payroll.id] ?? [],
                }
            ));
        },
        setCompanies: (state, { payload }) => {
            const { companies, companyPermissions, troncmaster } = payload;
            const companyPermissionsKeyed = _.keyBy(companyPermissions, 'company_id');

            state.companies = companies.map((company) => {
                const companyManager = new Company(
                    company,
                    companyPermissionsKeyed[company.id] ?? { permissions: [] },
                    troncmaster,
                );

                companyManager.register();

                // best not to store a non-serializable value in the state
                return JSON.parse(JSON.stringify(companyManager));
            });
        },
        setAnnouncements: (state, { payload }) => {
            state.announcements = payload;
        },
        setUpdates: (state, { payload }) => {
            state.updates = payload;
        },
        setCrumbs: (state, { payload }) => {
            state.crumbs = payload;
        },
    },
    initialState,
});

export { appSlice };
export const {
    loaded,
    setPayrolls,
    setCompanies,
    setNews,
    setNewsSeen,
    setPayrollsPeriods,
    setAnnouncements,
    setUpdates,
    setCrumbs,
} = appSlice.actions;

export default appSlice.reducer;

/**
 * Gets the users payrolls
 *
 * @returns {Promise} The payrolls dispatcher promise
 */
export const getPayrolls = () => async (dispatch, getState) => {
    const { user: { permissions }} = getState();
    const { data } = await PayrollsApi.getPayrolls().request;
    const payload = { payrolls: data, permissions };

    dispatch(setPayrolls(payload));
};

/**
 * Gets the payrolls periods
 *
 * @param {object} params Additional query params for the payrolls periods
 *
 * @returns {Promise} The payrolls dispatcher promise
 */
export const getPayrollsPeriods = (params = {}) => async (dispatch, getState) => {
    const { app: { payrolls }} = getState();
    const { data } = await PeriodsApi.getPeriods(params).request;

    dispatch(setPayrollsPeriods({ periods: data, payrolls }));
};

export const selectPayroll = (state, payrollId) => state.payrolls.find(({ id }) => id === _.parseInt(payrollId));

/**
 * Gets the users companies
 *
 * @returns {Promise} The companies dispatcher promise
 */
export const getCompanies = () => async (dispatch, getState) => {
    const { user: { company_permissions: companyPermissions, tronc_master: troncmaster }} = getState();
    const companiesParams = { "troncs_only": true, "get_stats": ["employees", "schemes", "sites"] };
    const { data } = await CompaniesApi.getCompanies(companiesParams).request;
    const payload = { companies: data, companyPermissions, troncmaster };

    dispatch(setCompanies(payload));
};

/**
 * Gets the news for the current user
 *
 * @returns {Promise} The news dispatcher promise
 */
export const getNews = () => async (dispatch) => {
    const newsNotFoundCode = 404;
    // News resource path is empty and as such is throwing a 404 error.
    // axios catch here to set the news to empty object
    await NewsApi.get()
        .request
        .then(({ data }) => {
            dispatch(setNews(data));
        }).catch((error) => {
            if (error?.response?.status === newsNotFoundCode) {
                dispatch(setNews({}));
            }
        });
};

/**
 * Gets the news for the current user
 *
 * @returns {Promise} The news dispatcher promise
 */
export const getAnnouncements = () => async (dispatch) => {
    const { data } = await AnnouncementsApi.getList({
        page: 1,
        "per_page": 10,
        "unread_only": true,
        "sort_by": "date_added:desc",
    }).request;

    dispatch(setAnnouncements(data.data));
};

/**
 * Gets the updates/resources for the current user
 *
 * @returns {Promise} The updates/resources dispatcher promise
 */
export const getUpdates = () => async (dispatch) => {
    const { data } = await UpdatesApi.getUpdates({
        "read": 0,
    }).request;

    dispatch(setUpdates(data.data));
};

export const selectCompany = (state, companyId) => state.companies.find(({ id }) => id === _.parseInt(companyId));
/**
 * Resets the breadcrumbs on the state
 *
 * @returns {Promise} The updates/resources dispatcher promise
 */
export const resetCrumbs = () => (dispatch) => dispatch(setCrumbs(initialState.crumbs));
