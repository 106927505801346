import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import {
    Card,
    CardContent,
    DateDisplay,
    LoadingSpinner,
    MoreActionsButton,
    PrimaryButton,
    TextButton,
} from 'components';

import styles from './tronc-scheme-card.module.scss';

/**
 * Formats a given date string and displays it.
 *
 * @param {string} date date string
 *
 * @returns {string|React.Element} dash or DateDisplay element
 */
const getDate = (date) => {
    if (isEmpty(date)) {
        return "-";
    }

    return (
        <div className={styles.date}>
            <DateDisplay data={date} output="DD-MM-YY" />
        </div>
    );
};

/**
 * A card to show the details of tronc schemes
 *
 * @param {object} props component props
 * @param {object} props.scheme scheme details
 * @param {function} handleProcessFiles opens/closes form to process scheme files with id
 *
 * @returns {React.Component} Tronc Scheme Card
 */
const TroncSchemeCard = ({
    scheme,
    actionHandlers,
    reportDrawer,
    setReportDrawer,
    handleProcessFiles,
    schemeFiles,
    troncFilesLoaded,
}) => {
    const { tronc_master: troncmaster } = useSelector((state) => state.user);
    const { currentPeriod = {} } = scheme;
    const params = useParams();
    const location = useLocation();

    const rootUrl = (params.tab)
        ? location.pathname.substring(0, location.pathname.length - params.tab.length - 1)
        : location.pathname;

    const {
        submission_date: submissionDate,
        report_return_date: reportDate,
        actual_pay_date: payDate,
    } = currentPeriod;

    /**
     * Count of files to be processed
     *
     * @returns {number} The number of unprocessed files
     */
    const numberFilesToProcess = () => {
        return schemeFiles.filter((file) => !file.processed).length;
    };

    /**
     * Button for genereating scheme report
     *
     * @returns {React.Element} Button for generating a report
     */
    const reportsButton = () => {
        if (!troncFilesLoaded || !schemeFiles.length) {
            return null;
        }

        return (
            <PrimaryButton
                onClick={() => setReportDrawer({
                    ...reportDrawer, isOpen: true, currentScheme: scheme,
                })}
                className={styles.generateButton}
            >
                Reports
            </PrimaryButton>
        );
    };

    /**
     * Button states for processing files
     *
     * @returns {React.Component} The button to process or show processing done
     */
    const howManyFilesNeedProcessingButton = () => {
        const buttonContent = () => (
            !troncFilesLoaded
                ? <LoadingSpinner className={styles.smallSpinner} />
                : (
                    <>
                        <span className={styles.fileCount}>
                            {numberFilesToProcess()}
                        </span> Files need processing
                    </>
                )
        );

        if (numberFilesToProcess() === 0) {
            return (
                <TextButton outline={true}>
                    {buttonContent()}
                </TextButton>
            );
        }

        return (
            <PrimaryButton onClick={() => handleProcessFiles(scheme)} disabled={!troncFilesLoaded}>
                {buttonContent()}
            </PrimaryButton>
        );
    };

    return (
        <Card padding={false} className={styles.card}>
            <div className={styles.header}>
                <NavLink
                    className={styles.titleLink}
                    key={`${scheme?.name}`}
                    to={`${rootUrl}/schemes/${scheme?.id}`}
                >
                    {scheme?.name}
                </NavLink>
                <div className={styles.items}>
                    <MoreActionsButton
                        actions={[
                            {
                                label: "Edit",
                                handler: () => actionHandlers?.edit(scheme),
                            },
                            {
                                label: "Re-process",
                                handler: () => actionHandlers?.reprocess(scheme),
                                hasPermission: schemeFiles.filter((file) => file.processed).length > 0,
                            },
                        ]}
                    />
                </div>
            </div>
            <CardContent className={styles.cardContent}>
                <div className={styles.infoBlock}>
                    <div data-testid="submission-date">
                        <h3>Data Submission</h3>
                        {getDate(submissionDate)}
                    </div>
                </div>
                <div className={styles.infoBlock}>
                    <div data-testid="report-date">
                        <h3>Tronc Report Return</h3>
                        {getDate(reportDate)}
                    </div>
                </div>
                <div className={styles.infoBlock}>
                    <div data-testid="pay-date">
                        <h3>Pay Date</h3>
                        {getDate(payDate)}
                    </div>
                </div>
            </CardContent>
            <div className={styles.footer}>
                {troncmaster && (
                    <>
                        {howManyFilesNeedProcessingButton()}
                        {reportsButton()}
                    </>
                )}
            </div>
        </Card>
    );
};

TroncSchemeCard.propTypes = {
    scheme: PropTypes.object,
    actionHandlers: PropTypes.object,
    reportDrawer: PropTypes.object.isRequired,
    setReportDrawer: PropTypes.func.isRequired,
    handleProcessFiles: PropTypes.func,
    schemeFiles: PropTypes.array,
    troncFilesLoaded: PropTypes.bool,
};

TroncSchemeCard.defaultProps = {
    scheme: {},
    actionHandlers: {},
    handleProcessFiles: noop,
    schemeFiles: [],
    troncFilesLoaded: false,
};

export default TroncSchemeCard;
