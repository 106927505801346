import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { find } from "lodash";
import FileList from "./components/file-list";
import FileNavigationPage from "./components/file-navigation-page";
import PeriodFileUploadDrawer from "./components/file-uploader/period-file-upload-drawer";
import useTroncPeriod from "lib/api-endpoints/hooks/tronc-period";
import useTroncScheme from "lib/api-endpoints/hooks/tronc-scheme";
import { resetCrumbs, setCrumbs } from "state/app-slice";
import { useTroncFilesList } from "lib/api-endpoints";
import { LayoutContainer } from "components";

const Files = () => {
    const dispatch = useDispatch();
    const companies = useSelector((state) => state.app.companies);
    const [refresh, toggleRefresh] = useState(false);
    const [page, setCurrentPage] = useState(1);
    const { company, period, scheme } = useParams();
    const { files, isLoaded } = useTroncFilesList({ page, perPage: 20, period, refresh });
    const { period: periodInfo, isLoaded: periodLoaded } = useTroncPeriod(period);
    const { scheme: troncScheme, isLoaded: schemeLoaded } = useTroncScheme(scheme);

    /**
     * Resets the breadcrumbs when leaving the files page.
     */
    useEffect(() => {
        if (periodInfo?.period_number && schemeLoaded) {
            const crumbCompany = find(companies, { id: Number(company) });

            dispatch(setCrumbs({
                periodFiles: `Files - Period ${periodInfo?.period_number}`,
                company: crumbCompany.name,
                scheme: troncScheme.name,
            }));
        }

        return () => dispatch(resetCrumbs());
    }, [dispatch, periodInfo?.period_number, troncScheme, company, companies, schemeLoaded]);

    return (
        <FileNavigationPage
            title="Files"
            titleAction={(
                <PeriodFileUploadDrawer
                    period={period}
                    onSuccess={() => toggleRefresh(!refresh)}
                />
            )}
        >
            <LayoutContainer>
                <FileList
                    files={files}
                    onPageChange={setCurrentPage}
                    isLoaded={isLoaded && periodLoaded}
                />
            </LayoutContainer>
        </FileNavigationPage>
    );
};

export default Files;
