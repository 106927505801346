import { createSlice } from "@reduxjs/toolkit";
import UserApi from "lib/api-endpoints/user-api";

const initialState = {
    forename: "",
    surname: "",
};

const userSlice = createSlice({
    name: "user",
    reducers: {
        setUser: (state, { payload }) => payload,
    },
    initialState,
});

export { userSlice };
export const { setUser } = userSlice.actions;

export default userSlice.reducer;

/**
 * Gets user information and then dispatches it to the user state
 *
 * @returns {Promise} The get user dispatcher promise
 */
export const getUser = () => async (dispatch) => {
    const { data } = await UserApi.getProfile().request;
    dispatch(setUser(data));
};
