import React from "react";
import PropTypes from "prop-types";
import { isEmpty, noop } from "lodash";
import { Card, CardHeader, TextButton } from "components";
import DateDisplay from "components/date-display/date-display";
import Indicator from "components/indicator/indicator";
import periodPropTypes, { periodDefaultProps } from "lib/props-schema/period-schema";
import batchPropTypes, { batchDefaultProps } from "lib/props-schema/batch-schema";

import styles from "./period-key-information.module.scss";

const PeriodKeyInformation = ({ period, batch, onStartersLeaversClick }) => {
    const {
        payroll_input_due: payrollInputDue,
        pay_date: payDate,
    } = period;

    const {
        net_pay: netPay,
        employees_paid: employeesPaid,
        gross_pay: grossPay,
        total_pay: totalPay,
    } = batch;

    const getStartersLeaversButton = (type) => {
        const employeeCount = period[type]?.length;

        return (employeeCount) ? (
            <TextButton
                data-testid={`${type}-count-button`}
                aria-label="open starters leavers drawer"
                onClick={() => onStartersLeaversClick(type)}
            >
                {employeeCount}
            </TextButton>
        ) : "-";
    };

    const details = [
        {
            key: 'input due',
            value: (payrollInputDue) ? <DateDisplay data={payrollInputDue} output={'ddd, Do MMM, YY'} /> : null,
            "data-testid": "input",
        },
        {
            key: 'employees paid',
            value: employeesPaid,
            "data-testid": "employees-paid",
        },
        {
            key: 'gross',
            value: grossPay,
            "data-testid": "gross",
        },
        {
            key: 'bacs day',
            value: '-',
            "data-testid": "bacs-day",
        },
        {
            key: 'starters',
            value: getStartersLeaversButton('starters'),
            "data-testid": "starters",
        },
        {
            key: 'net',
            value: netPay,
            "data-testid": "net-pay",
        },
        {
            key: 'pay date',
            value: (payDate) ? <DateDisplay data={payDate} output={'ddd, Do MMM, YY'} /> : null,
            "data-testid": "pay-date",
        },
        {
            key: 'leavers',
            value: getStartersLeaversButton('leavers'),
            "data-testid": "leavers",
        },
        {
            key: 'total',
            value: totalPay,
            "data-testid": "total-paid",
        },
    ];

    const list = details.map(({ key, value, ...props }) => (
        <div key={key} {...props}>
            <dt className={styles.term}>{key}</dt>
            <dd>{(!isEmpty(value) || value > 0) ? value : '-'}</dd>
        </div>
    ));

    return (
        <Card padding={false}>
            <CardHeader title="Key information" data-testid="period-key-information-header">
                {period.status !== undefined && <Indicator status={period.status} />}
            </CardHeader>
            <dl className={styles.keyInfo} data-testid="period-key-information">
                {list}
            </dl>
        </Card>
    );
};

PeriodKeyInformation.defaultProps = {
    period: periodDefaultProps,
    batch: batchDefaultProps,
    onStartersLeaversClick: noop,
};

PeriodKeyInformation.propTypes = {
    period: periodPropTypes,
    batch: batchPropTypes,
    onStartersLeaversClick: PropTypes.func,
};

export default PeriodKeyInformation;
