import { React, useState } from "react";
import classNames from 'classnames';
import ArrowDropDown from 'components/icons/arrow-dropdown';

import styles from './link-dropdown.module.scss';

/**
 * Renders the dropdown of links for MFA
 *
 * @returns {React.Component} the link dropdown
 */
const LinkDropdown = () => {
    const [dropdownState, setDropdownState] = useState({ isOpen: false });

    const classList = classNames(styles.icon, {
        [styles.open]: (dropdownState.isOpen),
    });

    /**
     * Toggles / sets if the accordion is open
     */
    const toggleOpen = () => {
        setDropdownState((prevState) => ({...prevState, isOpen: !prevState.isOpen}));
    };

    /**
     * The table for the authenticator links
     *
     * @return {ReactElement} the table for links
     */
    const renderLinks = () => {
        return (
            <div className={styles.tableContainer}>
                <div>
                    <div className={styles.tableHeading}>Android</div>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.externalLink}
                    >
                        download microsoft app
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.duosecurity.duomobile&hl=en"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.externalLink}
                    >
                        download duo app
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.authy.authy"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.externalLink}
                    >
                        download authy app
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.lastpass.authenticator"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.externalLink}
                    >
                        download lastpass app
                    </a>
                </div>
                <div>
                    <div className={styles.tableHeading}>iOS</div>
                    <a
                        href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.externalLink}
                    >
                        download microsoft app
                    </a>
                    <a
                        href="https://apps.apple.com/us/app/duo-mobile/id422663827"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.externalLink}
                    >
                        download duo app
                    </a>
                    <a
                        href="https://apps.apple.com/us/app/authy/id494168017"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.externalLink}
                    >
                        download authy app
                    </a>
                    <a
                        href="https://apps.apple.com/app/id324613447"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.externalLink}
                    >
                        download lastpass app
                    </a>
                </div>
            </div>
        );
    };

    /**
     * Renders the table components
     *
     * @return {ReactElement} The table component
     */
    return (
        <div data-testid="link-dropdown">
            <div
                role="button"
                tabIndex={0}
                onClick={toggleOpen}
                onKeyDown={toggleOpen}
                className={styles.dropdownHeader}
            >
                <ArrowDropDown className={classList} />
                Other authentication apps
            </div>
            {dropdownState.isOpen && renderLinks()}
        </div>
    );
};

export default LinkDropdown;
