import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
import FocusTrap from 'focus-trap-react';
import { compare } from 'stacking-order';
import CloseButton from "components/buttons/close-button";
import LoadingMask from 'components/loading-mask/loading-mask';

import styles from "./modal.module.scss";

/**
 * Checks if the drawer is above the given element in stacking order
 *
 * @param {element} drawerElement The drawer element
 * @param {element} element The given element
 *
 * @return {boolean} If the drawer is above the given element in stacking order
 */
const isAbove = (drawerElement, element) => {
    if (!drawerElement || element === drawerElement) {
        return false;
    }

    return (compare(drawerElement, element) === 1);
};

/**
 * Renders the loading mask
 *
 * @param {boolean} isLoading Puts the drawer into a loading state
 * @param {any} children Main body content
 *
 * @return {React.Component} The loading mask
 */
const renderLoading = (isLoading) => {
    return (
        <div className={styles.drawerContent}>
            {isLoading && <LoadingMask loading={isLoading} />}
        </div>
    );
};

/**
 * Portals a modal to the document body with focus trap
 *
 * @param {boolean} isOpen Trigger modal open or closed
 * @param {boolean} isLoading Puts the drawer into a loading state
 * @param {any} headerContent Custom header content
 * @param {any} children Main body content
 * @param {any} footerContent Custom footer content
 * @param {func} onClose Function to close the modal
 * @param {func} onOutsideClick Function to handle outside clicks
 *
 * @returns {React.Component} The modal component
 */
const Modal = ({
    isOpen,
    isLoading,
    headerContent,
    children,
    footerContent,
    onClose,
    onOutsideClick,
}) => {
    const [visible, setVisible] = useState(false);
    const [transitionEnded, setTransitionEnded] = useState(true);
    const modalRef = useRef();

    /**
     * The button used for the fallbackFocus
     *
     * @returns {React.Component} The fallback focus
     */
    const fallbackFocus = () => {
        return <button type="button" tabIndex={-1} />;
    };

    /**
     * Handles user clicking page
     *
     * @param {ClickEvent} event The event
     *
     * @return {void}
     */
    const handleWindowClick = useCallback((event) => {
        if (onOutsideClick && visible && isAbove(modalRef.current, event.target)) {
            onOutsideClick();
        }
    }, [onOutsideClick, visible]);

    /**
         * Sets whether the drawer should be visible to the user
         */
    useEffect(() => {
        window.addEventListener('mousedown', handleWindowClick);
        window.addEventListener('touchstart', handleWindowClick);

        if (isOpen) {
            setVisible(true);
        } else if (!isOpen) {
            setVisible(false);
        }

        return () => {
            window.removeEventListener('mousedown', handleWindowClick);
            window.removeEventListener('touchstart', handleWindowClick);
            setTransitionEnded(false);
        };
    }, [isOpen, handleWindowClick]);

    /**
     * Runs once close transition has ended
     *
     * @return {void}
     */
    const onTransitionEnd = () => {
        if (!isOpen) {
            setTransitionEnded(true);
        }
    };

    if (!isOpen && !visible && transitionEnded) {
        return null;
    }

    return ReactDOM.createPortal(
        (
            <FocusTrap
                active={isOpen}
                focusTrapOptions={{fallbackFocus, initialFocus: false, delayInitialFocus: true}}
            >
                <div
                    ref={modalRef}
                    data-testid="modal"
                    role="complementary"
                    onTransitionEnd={onTransitionEnd}
                    className={classNames(styles.modalContainer, {
                        [styles.entering]: visible,
                        [styles.leaving]: !isOpen,
                    })}
                >
                    {isOpen ? (
                        <>
                            <div className={styles.modalHeader}>
                                {headerContent}
                                <CloseButton onClick={onClose} />
                            </div>
                            <div className={styles.modalContent}>
                                <LoadingMask loading={isLoading}>
                                    {children}
                                </LoadingMask>
                            </div>
                            <div className={styles.modalFooter}>
                                {footerContent}
                            </div>
                        </>
                    ) : (
                        <>
                            {renderLoading(isLoading)}
                        </>
                    )}
                </div>
            </FocusTrap>
        ),
        document.body
    );
};

Modal.propTypes = {
    isOpen: PropTypes.bool,
    isLoading: PropTypes.bool,
    headerContent: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.element,
    ]),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.element,
    ]),
    footerContent: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.element,
    ]),
    onClose: PropTypes.func,
    onOutsideClick: PropTypes.func,
};

Modal.defaultProps = {
    isOpen: false,
    isLoading: true,
    headerContent: null,
    children: null,
    footerContent: null,
    onClose: noop,
    onOutsideClick: noop,
};

export default Modal;
