import { api } from "lib/api";

export default class TroncsPeriodsApi {

    /**
     * Gets the files on a specific period
     *
     * @param {object} params The request parameters
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getFiles = (params) => api({
        url: "/tronc_files",
        config: { params },
    });

    /**
     * Gets the csv upload template for the current period
     *
     * @param {number} periodId The period id
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getEmployeeHoursCsvTemplate = (periodId) => api({
        url: `/tronc_periods/${periodId}/employee_hours/import_csv_template`,
        config: { responseType: 'blob' },
    });

    /**
     * Uploads the employee hours csv data for the current period
     *
     * @param {number} periodId The period id
     * @param {object} data The form data
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static uploadEmployeeHoursFiles = (periodId, data) => api({
        type: "post",
        url: `/tronc_periods/${periodId}/employee_hours`,
        data,
    });

    /**
     * Uploads tronc files for a period
     *
     * @param {number} periodId The period id
     * @param {object} data The file
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static uploadFiles = (periodId, data) => api({
        type: "post",
        url: `/troncs_periods/${periodId}/troncs_files`,
        data,
    });

    /**
     * Download a specific tronc file based on its id
     *
     * @param {number} fileId The file id
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static downloadTroncFile = (fileId) => api({
        url: `/tronc_files/${fileId}/data`,
        config: { responseType: 'blob' },
    });

    /**
     * Update the tronc amount currently stored on the period
     *
     * @param {number} periodId The period id
     * @param {object} data The file
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static uploadTroncAmount = (periodId, data) => api({
        type: "put",
        url: `tronc_periods/${periodId}/tronc_amounts`,
        data,
    });

}
