import React from 'react';
import PropTypes from 'prop-types';
import styles from './error-help-text.module.scss';

/**
 * Renders error help text div
 *
 * @param {object} props component props
 * @param {string} props.text error text
 *
 * @return {React.Component} ErrorHelpText Component
 */
export const ErrorHelpText = ({ text }) => (
    <div data-testid="error-help-text" className={styles.errorText}>
        { text }
    </div>
);

ErrorHelpText.propTypes = {
    text: PropTypes.string.isRequired,
};

export default ErrorHelpText;
