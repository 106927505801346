import React from 'react';

const Companies = (props) => {
    return (
        <svg
            width="21"
            height="21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.5 7a.875.875 0 0 0-.875.875v8.75c0 .483.392.875.875.875h14a.875.875 0 0
            0 .875-.875v-8.75A.875.875 0 0 0 17.5 7h-14Zm-2.625.875A2.625 2.625 0 0 1 3.5 5.25h14a2.625
            2.625 0 0 1 2.625 2.625v8.75A2.625 2.625 0 0 1 17.5 19.25h-14a2.625 2.625 0 0
            1-2.625-2.625v-8.75Z"
                fill="#fff"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.894 2.519A2.625 2.625 0 0 1 8.75 1.75h3.5a2.625 2.625 0 0 1 2.625 2.625v14a.875.875
            0 0 1-1.75 0v-14a.875.875 0 0 0-.875-.875h-3.5a.875.875 0 0 0-.875.875v14a.875.875 0 0
            1-1.75 0v-14c0-.696.277-1.364.769-1.856Z"
                fill="#fff"
            />
        </svg>
    );
};

export default Companies;
