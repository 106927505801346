import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import CarouselCard from "components/carousel/carousel-card";
import CarouselControls from "components/carousel/carousel-controls";
import useResizeObserver from 'hooks/useResizeObserver';

import styles from './carousel.module.scss';

const MIN_CARD_WIDTH = 230;

/**
 * Renders the card carousel
 *
 * @param {array} items Objects containg card content
 *
 * @return {React.Component} Renders the carousel
 */
const Carousel = ({ items }) => {
    const {targetRef, width} = useResizeObserver();
    const [currentPage, setCurrentPage] = useState(0);
    const [cardWidth, setCardWidth] = useState(MIN_CARD_WIDTH);

    /**
     * Gets the maximum number of cards which will reasonably fit onto the carousel
     *
     * @returns {number} Floored number of cards in carousel at this container width
     */
    const getMaxCardNumber = useCallback(() => {
        return Math.floor(width / MIN_CARD_WIDTH);
    }, [width]);

    /**
     * On resize updates the card width and resets the carousel to it's initial position
     *
     * @returns {void}
     */
    useEffect(() => {
        setCardWidth(((width - 20) / getMaxCardNumber()));
        setCurrentPage(0);
    }, [getMaxCardNumber, width]);

    /**
     *  Increments the current page count
     *
     * @returns {void}
     */
    const nextPage = () => setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);

    /**
     *  Decrements the current page count
     *
     * @returns {void}
     */
    const prevPage = () => setCurrentPage((prevCurrentPage) => prevCurrentPage - 1);

    if (!items?.length) {
        return null;
    }

    return (
        <div className={styles.carousel} ref={targetRef}>
            <div className={styles.itemContainer}>
                {items.map((item, idx) => {
                    const startingVisibleIndex = currentPage * getMaxCardNumber();
                    const isVis = (idx < (getMaxCardNumber() + startingVisibleIndex) && (idx >= startingVisibleIndex));

                    return (
                        <CarouselCard
                            key={item.id}
                            item={item}
                            cardWidth={cardWidth}
                            currentPage={currentPage}
                            cardNumber={getMaxCardNumber()}
                            visible={isVis}
                        />
                    );
                })}
            </div>
            <CarouselControls
                items={items}
                cardNumber={getMaxCardNumber()}
                currentPage={currentPage}
                nextPage={nextPage}
                prevPage={prevPage}
            />
        </div>
    );
};

Carousel.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        currentBadge: PropTypes.bool,
        linkTo: PropTypes.string,
    })),
};

Carousel.defaultProps = {
    items: {
        title: "",
        subtitle: "",
        currentBadge: false,
    },
};

export default Carousel;
