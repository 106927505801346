import React from 'react';
import PropTypes from 'prop-types';
import { TextInputRow } from 'components/forms';
import { useFormContext } from 'react-hook-form';
import { getFormErrors } from 'lib/helpers/validation-helpers';

import styles from './company-site-form-content.module.scss';

/**
 * Renders the form content for the company site add/edit page
 *
 * @param {bool} isEditPage Should render the edit page form content
 *
 * @returns {React.Component} Renders the company site form elements
 */
const CompanySiteFormContent = ({ isEditPage }) => {
    const { register, formState: { errors } } = useFormContext();

    return (
        <div className={styles.formWrapper}>
            <div className={styles.pageInstructions}>
                <div className={styles.subtitle}>{`${isEditPage ? "Edit" : "Add"}`} a site</div>
            </div>
            <TextInputRow
                {...register("siteName", { required: true, max: 80 })}
                required={true}
                name="siteName"
                label="Name"
                error={getFormErrors(errors, "siteName")}
            />
            <TextInputRow
                {...register("address1")}
                name="address1"
                label="Address line 1"
            />
            <TextInputRow
                {...register("address2")}
                name="address2"
                label="Address line 2"
            />
            <TextInputRow
                {...register("address3")}
                name="address3"
                label="Address line 3"
            />
            <TextInputRow
                {...register("town")}
                name="town"
                label="Town"
                error={getFormErrors(errors, "town")}
            />
            <TextInputRow
                {...register("postcode")}
                name="postcode"
                label="Postcode"
                error={getFormErrors(errors, "postcode")}
            />
        </div>
    );
};

CompanySiteFormContent.propTypes = {
    isEditPage: PropTypes.bool,
};

CompanySiteFormContent.defaultProps = {
    isEditPage: false,
};

export default CompanySiteFormContent;
