import { NotificationHandler } from 'components';
import PropTypes from 'prop-types';
import React, { useCallback, useReducer } from 'react';

let currentNotificationId = 0;

const initialState = {
    notifications: [],
};

const NotificationContext = React.createContext(initialState);

/**
 * Updates the state with a new notification
 *
 * @param {object} prevState previous context state
 * @param {object} payload data to set on state
 * @returns {object} new state
 */
const addNotification = (prevState, payload) => ({
    ...prevState,
    notifications: [...prevState.notifications, payload.notification],
});

/**
 * Updates the state by removing a given notification
 *
 * @param {object} prevState previous context state
 * @param {object} payload data to set on state
 * @returns {object} new state
 */
const removeNotification = (prevState, payload) => {
    const filteredNotifications = prevState.notifications.filter((item) => (item.id !== payload.notification.id));

    return {
        ...prevState,
        notifications: filteredNotifications,
    };
};

/**
 * Resets the state back to its initial value
 *
 * @returns {object} initial state value
 */
const reset = () => initialState;

/**
 * Performs actions based on the payload type given
 *
 * @param {object} prevState previous context state
 * @param {object} payload data to set on state
 *
 * @returns {object} new state value
 */
const reducer = (prevState, payload) => {
    const { type } = payload;

    const actions = {
        RESET: () => reset(),
        ADD_NOTIFICATION: () => addNotification(prevState, payload),
        REMOVE_NOTIFICATION: () => removeNotification(prevState, payload),
    };

    return (actions[type])
        ? actions[type]()
        : { ...prevState, payload };
};

/**
 * Notification Context Provider Wrapper Component
 *
 * @param {object} props component props
 * @param {any} children child elements
 *
 * @returns {React.Component} NotificationProvider Component
 */
const NotificationProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const dispatchApp = useCallback(dispatch, [dispatch]);

    /**
     * Dispatches remove notification state action
     */
    const closeNotification = useCallback((notification) => {
        dispatchApp({ type: "REMOVE_NOTIFICATION", notification });
    }, [dispatchApp]);

    /**
     * Dispatches add notification state action
     */
    const openNotification = useCallback((notification) => {
        notification.id = currentNotificationId++;
        dispatchApp({ type: "ADD_NOTIFICATION", notification });
    }, [dispatchApp]);

    return (
        <NotificationContext.Provider
            value={{
                state,
                dispatchApp,
                openNotification,
                closeNotification,
            }}
        >
            <NotificationHandler />
            { children }
        </NotificationContext.Provider>
    );
};

NotificationProvider.propTypes = {
    children: PropTypes.any.isRequired,
};

export {
    NotificationProvider,
    NotificationContext,
};
