import React, { useEffect, useState } from "react";
import companyPropTypes from "lib/props-schema/company-schema";
import CompanyJobRolesApi from "lib/api-endpoints/company-job-roles-api";
import { Card, CardContent, CardHeader } from "components";
import { useForm } from "react-hook-form";
import AddJobRolesForm from "./add-job-roles-form";
import EditArchiveJobRolesForm from "./edit-archive-job-roles-form";

import styles from "./job-roles.module.scss";

/**
 * Renders the job roles section for a selected company
 *
 * @param {object} company The selected company
 * @param {function} setDrawer Handle the drawer state
 * @param {boolean} isDrawerOpen Determines if drawer is open or closed
 *
 * @returns {React.Component} Employees Tab
 */
const JobRoles = ({ company }) => {
    const [jobRoles, setJobRoles] = useState([]);
    const [refresh, setRefresh] = useState([]);

    const methods = useForm({
        defaultValues: {
            files: {},
        },
    });

    const { watch } = methods;
    watch();

    useEffect(() => {
        const getJobRoles = async () => {
            const { data } = await CompanyJobRolesApi.getJobRoles(company.id).request;

            setJobRoles(data.data);
        };

        if (company?.id) {
            getJobRoles();
        }

        setRefresh(false);
    }, [company, refresh]);

    /**
     * Displays the formatted job role title
     *
     * @returns {React.Component} Renders the job role card title area
     */
    const cardTitle = () => (
        <div className={styles.cardTitle}>
            <div className={styles.companyTitle}>Job roles</div>
        </div>
    );

    return (
        <Card data-testid="company-card" padding={false} className={styles.card}>
            <CardHeader title={cardTitle()} />
            <CardContent className={styles.panelContentWrapper}>
                <AddJobRolesForm companyId={company.id} setRefresh={setRefresh} />
                <div>
                    { jobRoles.map((role) => (
                        <EditArchiveJobRolesForm
                            key={role?.id}
                            jobRole={role}
                            companyId={company.id}
                            setRefresh={setRefresh}
                        />
                    ))}
                </div>
            </CardContent>
        </Card>
    );
};

JobRoles.propTypes = {
    company: companyPropTypes.isRequired,
};

export default JobRoles;
