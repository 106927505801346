import React from 'react';
import PropTypes from 'prop-types';
import { Button } from "components/forms";

import styles from './primary-button.module.scss';

/**
 * Button component with outline styles
 *
 * @param {any} children The content of the button
 * @param {object} props Component properties
 *
 * @returns {React.Component} The primary button
 */
const PrimaryButton = ({ children, ...props }) => {
    return (
        <Button
            className={styles.primary}
            data-testid="primary-button-component"
            {...props}
        >
            {children}
        </Button>
    );
};

PrimaryButton.propTypes = {
    children: PropTypes.any,
};

PrimaryButton.defaultProps = {
    children: null,
};

export default PrimaryButton;
