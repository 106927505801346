import React from 'react';
import PropTypes from 'prop-types';
import { ErrorHelpText, FormRowLabel } from 'components/forms';

import styles from './inline-form-row.module.scss';

/**
 * Renders a row container with the given header and children that is styled to
 * go next to other form elements with headers.
 *
 * @param {object} props component props
 * @param {string} props.heading row header
 * @param {any} props.children row child#
 *
 * @returns {React.Component} InlineFormRow Component
 */
const InlineFormRow = ({ children, error, heading }) => (
    <div className={styles.inlineRow}>
        <FormRowLabel label={heading} data-testid="row-label" />
        <div className={styles.children}>
            {children}
        </div>
        {error ? <ErrorHelpText text={error} /> : null}
    </div>
);

InlineFormRow.defaultProps = {
    children: null,
    error: '',
    heading: '',
};

InlineFormRow.propTypes = {
    children: PropTypes.any,
    error: PropTypes.string,
    heading: PropTypes.string,
};

export default InlineFormRow;
