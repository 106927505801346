import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ErrorHelpText from './error-help-text';
import { FormRow, FormRowLabel, RadioSelect } from './form-controls';
/**
 * Renders form row for a radio select form input
 *
 * @param {object} props component props
 * @param {string} props.error error message
 * @param {string} props.label row label
 * @param {array} props.options array of choosable options
 * @param {object} props.otherOptions further radio select props
 *
 * @return {React.Comonent} Radio Select Form Input Row
 */
const RadioSelectInputRow = forwardRef(({ error, label, options, required, ...otherOptions }, ref) => (
    <FormRow>
        <FormRowLabel label={label} required={required} />
        <RadioSelect ref={ref} options={options} {...otherOptions} />
        { error ? <ErrorHelpText text={error} /> : null }
    </FormRow>
));

RadioSelectInputRow.defaultProps = {
    error: "",
    label: null,
    required: false,
};

RadioSelectInputRow.propTypes = {
    error: PropTypes.string,
    options: PropTypes.array.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
};

export default RadioSelectInputRow;
