import React from "react";
import PropTypes from "prop-types";

/**
 * Gets the slim arrow component
 *
 * @param {string} className Additional class names
 *
 * @returns {React.Component} The slim arrow
 */
const SlimArrow = ({ className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" className={className}>
        <path d="M.354.354l4.17 4.17-4.17 4.17" />
    </svg>
);

SlimArrow.defaultProps = {
    className: "",
};

SlimArrow.propTypes = {
    className: PropTypes.string,
};

export default SlimArrow;
