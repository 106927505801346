import React, { useState, useEffect, useRef, useCallback } from 'react';
import { compare } from 'stacking-order';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { noop } from 'lodash';
import CloseButton from "components/buttons/close-button";
import LoadingMask from 'components/loading-mask/loading-mask';

import styles from "./drawer.module.scss";

/**
 * Checks if the drawer is above the given element in stacking order
 *
 * @param {element} drawerElement The drawer element
 * @param {element} element The given element
 *
 * @return {boolean} If the drawer is above the given element in stacking order
 */
const isAbove = (drawerElement, element) => {
    if (!drawerElement || element === drawerElement) {
        return false;
    }

    return (compare(drawerElement, element) === 1);
};

/**
 * Renders the loading mask
 *
 * @param {boolean} isAsync Drawer renders children which then load in the relevant data/content
 * @param {boolean} isLoading Puts the drawer into a loading state
 * @param {any} children Main body content
 *
 * @return {React.Component} The loading mask
 */
const renderLoading = (isAsync, isLoading, children) => {
    return (
        <div className={styles.drawerContent}>
            {(!isAsync && isLoading) ? <LoadingMask loading={isLoading} /> : children}
        </div>
    );
};

/**
 * Renders footer content
 *
 * @param {any} footerContent Custom footer content
 *
 * @returns {React.Component} Renders the drawers footer content
 */
const renderFooter = (footerContent) => (footerContent
    && (
        <footer className={styles.drawerFooter}>
            {footerContent}
        </footer>
    )
);

/**
 * The button used for the fallbackFocus
 *
 * @returns {React.Component} The fallback focus
 */
const fallbackFocus = () => {
    return <button type="button" tabIndex={-1} />;
};

/**
 * Portals a drawer to the document body, slides from right with focus trap
 *
 * @param {string} className custom class for styling
 * @param {boolean} isOpen Trigger drawer open or closed
 * @param {boolean} isLoading Puts the drawer into a loading state
 * @param {boolean} isAsync The drawer opens and then loads in the relevant data
 * @param {any} headerContent Custom header content
 * @param {any} children Main body content
 * @param {any} footerContent Custom footer content
 * @param {func} onClose Function to close the drawer
 * @param {func} onOutsideClick Function to handle outside clicks
 * @param {func} handleTransitionEnd Function to handle additional actions on the end of the close transition
 * @param {boolean} padding Add/remove the padding
 * @param {boolean} wide Increase width
 *
 * @returns {React.Component} The drawer component
 */
const Drawer = ({
    ariaLabel,
    children,
    className,
    footerContent,
    handleTransitionEnd,
    headerContent,
    isAsync,
    isLoading,
    isOpen,
    onClose,
    onOutsideClick,
    padding,
    wide,
}) => {
    const [visible, setVisible] = useState(false);
    const [transitionEnded, setTransitionEnded] = useState(true);
    const drawerRef = useRef();

    /**
     * Handles user clicking page
     *
     * @param {ClickEvent} event The event
     *
     * @return {void}
     */
    const handleWindowClick = useCallback((event) => {
        if (onOutsideClick && visible && isAbove(drawerRef.current, event.target)) {
            onOutsideClick();
        }
    }, [onOutsideClick, visible]);

    /**
     * Sets whether the drawer should be visible to the user
     */
    useEffect(() => {
        window.addEventListener('mousedown', handleWindowClick);
        window.addEventListener('touchstart', handleWindowClick);
        document.body.classList[(visible) ? 'add' : 'remove'](styles.disableScrolling);

        if (isOpen) {
            setVisible(true);
        } else if (!isOpen) {
            setVisible(false);
        }

        return () => {
            window.removeEventListener('mousedown', handleWindowClick);
            window.removeEventListener('touchstart', handleWindowClick);
            setTransitionEnded(false);
        };
    }, [isOpen, handleWindowClick, visible]);

    /**
     * Runs once close transition has ended
     *
     * @return {void}
     */
    const onTransitionEnd = () => {
        if (!isOpen) {
            setTransitionEnded(true);
        }

        handleTransitionEnd();
    };

    if (!isOpen && !visible && transitionEnded) {
        return null;
    }

    return ReactDOM.createPortal(
        (
            <FocusTrap
                active={isOpen}
                focusTrapOptions={{fallbackFocus: () => fallbackFocus(), initialFocus: false, delayInitialFocus: true}}
            >
                <div
                    data-testid="drawer"
                    ref={drawerRef}
                    role="complementary"
                    onTransitionEnd={onTransitionEnd}
                    className={classNames(styles.drawerContainer, className, {
                        [styles.entering]: visible,
                        [styles.leaving]: !isOpen,
                        [styles.padding]: padding,
                        [styles.wide]: wide,
                    })}
                    aria-label={ariaLabel}
                >
                    <div className={classNames(styles.drawerHeader)}>
                        {headerContent}
                        <CloseButton onClick={onClose} />
                    </div>
                    {renderLoading(isAsync, isLoading, children)}
                    {renderFooter(footerContent)}
                </div>
            </FocusTrap>
        ),
        document.body
    );
};

Drawer.propTypes = {
    ariaLabel: PropTypes.string.isRequired,
    className: PropTypes.string,
    isAsync: PropTypes.bool,
    isOpen: PropTypes.bool,
    isLoading: PropTypes.bool,
    headerContent: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.element,
    ]),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.element,
    ]),
    footerContent: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.element,
    ]),
    handleTransitionEnd: PropTypes.func,
    onClose: PropTypes.func,
    onOutsideClick: PropTypes.func,
    padding: PropTypes.bool,
    wide: PropTypes.bool,
};

Drawer.defaultProps = {
    children: null,
    className: "",
    footerContent: null,
    handleTransitionEnd: noop,
    headerContent: null,
    isAsync: false,
    isLoading: true,
    isOpen: false,
    onClose: noop,
    onOutsideClick: noop,
    padding: true,
    wide: false,
};

export default Drawer;
