import { api } from "lib/api";

export default class UserApi {

    /**
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getProfile = () => api({ url: "/user/profile" });

    /**
     * Gets the auth login
     *
     * @param {object} data The user auth data
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static login = (data) => api({ type: 'post', url: "/auth/password", data });

}
