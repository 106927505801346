import React from 'react';
import PropTypes from 'prop-types';
import { map, noop, size } from 'lodash';
import { Checkbox } from 'components/forms/form-controls';
import MoreActionsButton from 'components/more-actions-button/more-actions-button';
import Cell from './cell';

import styles from './row.module.scss';

/**
 * Renders a table row for use with the table component
 *
 * @param {object} props component props
 *
 * @returns {React.Component} Row Component
 */
const Row = ({ actions, cellOrder, checked, id, onRowCheck, rowData, showCheckBoxes }) => {

    /**
     * Gets an array of table cells based on the row data
     *
     * @param {object} row the row data object
     *
     * @returns {array} array of row cells
     */
    const renderCells = (row) => {
        if (!row) {
            return null;
        }

        return map(cellOrder, (header, key) => {
            if (!row[key] || key === 'id') {
                return null;
            }

            return (
                <Cell
                    key={`${id}-cell-${key}`}
                    cellData={row[key]}
                />
            );
        });
    };

    /**
     * Returns table cell containing an action button component based on the actions config provided
     *
     * @returns {HTMLElement} Table cell containing actions button
     */
    const renderActions = () => {
        if (!(actions && size(actions))) {
            return null;
        }

        return (
            <td key={`${id}-kebab-cell`}>
                <MoreActionsButton
                    key={`${id}-kebab-cell`}
                    className={styles.rowActions}
                    actions={actions}
                />
            </td>
        );
    };

    /**
     * Returns a Checkbox cell
     *
     * @returns {HTMLElement} table checkbox cell
     */
    const renderCheckbox = () => {
        if (!showCheckBoxes) {
            return null;
        }

        return (
            <td>
                <Checkbox
                    checked={checked}
                    onChange={onRowCheck}
                    value={id}
                    data-testid="table-checkbox"
                />
            </td>
        );
    };

    return (
        <tr key={id} data-testid={`row-${id}`}>
            { renderCheckbox() }
            { renderCells(rowData) }
            { renderActions() }
        </tr>
    );
};

Row.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        handler: PropTypes.func,
    })),
    cellOrder: PropTypes.object.isRequired,
    checked: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    rowData: PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]).isRequired,
    }).isRequired,
    onRowCheck: PropTypes.func,
    showCheckBoxes: PropTypes.bool,
};

Row.defaultProps = {
    actions: [],
    checked: false,
    onRowCheck: noop,
    showCheckBoxes: false,
};

export default Row;
