import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loaded } from "state/app-slice";
import { getUser } from "state/user-slice";

const SetToken = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const token = window.location.hash.substr(1);
        const data = JSON.parse(decodeURIComponent(token));

        if (!token || !data) {
            navigate("/", { replace: true });
        } else {
            sessionStorage.setItem("apiToken", data.api_token);
            sessionStorage.setItem("userId", data.user_id);
            sessionStorage.setItem("deviceToken", data.device_token);

            dispatch(getUser());
            dispatch(loaded());
            navigate("/dashboard", { replace: true });
        }
    }, [dispatch, navigate]);

    return null;
};

export default SetToken;
