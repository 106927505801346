import React from 'react';
import { useFormContext } from "react-hook-form";
import { isObject } from 'lodash';
import { Checkbox } from 'components/forms';

import styles from './permissions-table-config.module.scss';

/**
 * Gets the system_name to register the checkbox permissions to
 *
 * @param {string | object} header describes the header value
 *
 * @returns {string} The system name
 */
const headerSystemName = (header) => {
    return isObject(header) ? header.system_name : header;
};

/**
 * The config for the permissions table input
 *
 * @param {object} headers List of header descriptors
 * @param {number} value The id of the checkbox rendered (payroll_id)
 * @param {boolean} isLastRow True if last row in table
 *
 * @returns {React.Component} The config describing the permissions table values
 */
const PermissionsTableConfig = ({ headers, value: id, isLastRow, isShadedRow }) => {
    const { register } = useFormContext();

    return (
        headers.filter((header) => headerSystemName(header) !== "payroll_name").map((header) => {
            const headerValue = headerSystemName(header);

            return (
                <label
                    key={`${headerValue}-${id}`}
                    className={styles.checkboxCell}
                    htmlFor={`${headerValue}`}
                >
                    <Checkbox
                        aria-label={`${headerValue}`}
                        id={`${headerValue}`}
                        name={`${headerValue}`}
                        value={id}
                        {...register(`${headerValue}`)}
                    />
                </label>
            );
        })
    );
};

export default PermissionsTableConfig;
