import React, { useEffect, useMemo } from 'react';
import { first, filter, uniq } from 'lodash';
import { useFormContext } from 'react-hook-form';
import RadioSelectInputRow from 'components/forms/radio-select-input-row';
import UserTable from '../user-table/user-table';
import UserTicketSettings from '../user-table/user-ticket-settings';
import useTicketVisibilities from 'lib/api-endpoints/hooks/ticket-visibility-list';

import styles from './ticket-user-visibility.module.scss';

/**
 * Returns the system name identifier for a given visibility id
 *
 * @param {integer} visibilityId the visibility id we want the name of
 * @param {array} visibilities visibilities to search
 *
 * @returns {string} system name
 */
const getVisibilityName = (visibilityId, visibilities = []) => first(filter(
    visibilities,
    ({ id }) => (id === Number(visibilityId))
))?.system_name;

/**
 * Renders user visibility page for ticket form creation/editing
 *
 * @returns {React.Component} TicketUserVisibility component
 */
const TicketUserVisibility = () => {
    const { register, resetField, watch, getValues } = useFormContext();
    const visibilities = useTicketVisibilities();

    watch();

    const { visibility, payrollid, visible, notifications } = getValues();

    /**
     * Resets notifications and visible data if visibility field is changed
     */
    useEffect(() => {
        resetField('notifications');
        resetField('visible');
    }, [resetField]);

    /**
     * Memoised payroll id array
     */
    const payrollId = useMemo(() => [payrollid], [payrollid]);

    /**
     * Renders UserTable component if visibility is public or restricted
     *
     * @returns {React.Element} UserTableComponent
     */
    const renderUserTable = () => {
        const selectedVisibility = getVisibilityName(visibility, visibilities);

        if (!selectedVisibility || selectedVisibility === 'private') {
            return null;
        }

        return (
            <div data-testid="ticket-user-table">
                <UserTable
                    label="Users"
                    payrollId={payrollId}
                    additionalHeaders={["visible", "notifications"]}
                    config={UserTicketSettings}
                    showSelectedCount
                    countLabel="Users selected"
                    selectedCount={uniq([...visible, ...notifications]).length}
                />
            </div>
        );
    };

    /**
     *  Renders info message about visibility based on the selected visibility value
     *
     * @returns {React.Element} info message
    */
    const renderVisibilityMessage = () => {
        const systemName = getVisibilityName(visibility, visibilities);

        if (systemName === 'private') {
            return (
                <div className={styles.visibilityText}>
                    Private tickets are visible to you and Dataplan only
                </div>
            );
        } else if (systemName === 'restricted') {
            return (
                <div className={styles.visibilityText}>
                    Please select which users can follow this ticket
                </div>
            );
        }

        return null;
    };

    return (
        <div data-testid="ticket-visibility-form">
            <RadioSelectInputRow
                {...register("visibility")}
                label="User Visibility"
                name="visibility"
                options={visibilities.map(({ id, description }) => ({ label: description, value: id }))}
            />
            { renderVisibilityMessage() }
            { renderUserTable() }
        </div>
    );
};

export default TicketUserVisibility;
