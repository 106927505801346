import { createSlice } from "@reduxjs/toolkit";
import { CompaniesApi } from "lib/api-endpoints";

const initialState = {
    company: {},
    isLoading: false,
    schemes: [],
    schemesLoading: false,
    sites: [],
};

const companySlice = createSlice({
    name: "company",
    reducers: {
        setCompany: (state, { payload }) => {
            state.company = payload;
        },
        setLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
        setSchemesLoading: (state, { payload }) => {
            state.schemesLoading = payload;
        },
        setSites: (state, { payload }) => {
            state.sites = payload;
        },
        setSchemes: (state, { payload }) => {
            state.schemes = payload;
        },
    },
    initialState,
});

export { companySlice };

export const {
    setCompany,
    setLoading,
    setSites,
    setSchemes,
    setSchemesLoading,
} = companySlice.actions;

export default companySlice.reducer;

/**
 * Gets the requested company
 *
 * @param {number} id The id of the company
 *
 * @returns {Promise} The company dispatcher promise
 */
export const getCompany = (id) => async (dispatch) => {
    dispatch(setLoading(true));
    const { data } = await CompaniesApi.getCompany(id).request;
    dispatch(setCompany(data));
    dispatch(setLoading(false));
};

/**
 * Clears the current company state
 *
 * @returns {Promise} The company dispatcher promise
 */
export const clearCompany = () => (dispatch) => {
    dispatch(setCompany({}));
};

/**
 * Gets the company site list
 *
 * @param {number} id The id of the company
 *
 * @returns {Promise} The company dispatcher promise
 */
export const getSites = (id) => async (dispatch) => {
    dispatch(setLoading(true));
    const { data } = await CompaniesApi.getSites({ "company_ids": id, "stats": ["employee_count"] }).request;
    dispatch(setSites(data));
    dispatch(setLoading(false));
};

/**
 * Gets the company schemes for the specified sites
 *
 * @param {array} ids The ids of the company sites
 *
 * @returns {Promise} The schemes dispatcher promise
 */
export const getSchemes = (ids) => async (dispatch) => {
    dispatch(setSchemesLoading(true));
    const { data } = await CompaniesApi.getSchemes({ "site_ids": ids }).request;
    dispatch(setSchemes(data.data));
    dispatch(setSchemesLoading(false));
};

/**
 * Clears the sites and schemes
 *
 * @returns {Promise} The clear schemes dispatcher promise
 */
export const clearSitesSchemes = () => (dispatch) => {
    dispatch(setSchemes([]));
    dispatch(setSites([]));
};
