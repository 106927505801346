import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { LeftArrow, RightArrow } from 'components/icons';

import styles from './carousel-controls.module.scss';

/**
 * Renders the card carousel controls
 *
 * @param {array} items Objects containg card content
 * @param {number} cardNumber Tracks the number of cards shown on a carousel page, less in smaller containers
 * @param {number} currentPage Current page
 * @param {function} nextPage Increments the selection of cards to show
 * @param {function} prevPage Decrements the selection of cards to show
 *
 * @return {React.Component} Renders the carousel controls
 */
const CarouselControls = ({ items, cardNumber, currentPage, nextPage, prevPage }) => {

    if (items.length <= 4) {
        return null;
    }

    return (
        <div className={styles.controlsContainer} >
            <button
                type="button"
                className={styles.controlCircle}
                onClick={prevPage}
                disabled={(currentPage === 0)}
                aria-label="previous carousel page"
            >
                <LeftArrow />
            </button>
            <button
                type="button"
                className={styles.controlCircle}
                onClick={nextPage}
                disabled={currentPage === Math.floor((items.length / cardNumber))}
                aria-label="next carousel page"
            >
                <RightArrow />
            </button>
        </div>
    );
};

CarouselControls.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        currentBadge: PropTypes.bool.isRequired,
    })).isRequired,
    cardNumber: PropTypes.number,
    currentPage: PropTypes.number,
    nextPage: PropTypes.func,
    prevPage: PropTypes.func,
};

CarouselControls.defaultProps = {
    cardNumber: 1,
    currentPage: 0,
    nextPage: noop,
    prevPage: noop,
};

export default CarouselControls;
