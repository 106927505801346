import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import classNames from 'classnames';
import PropTypes from "prop-types";
import FocusTrap from "focus-trap-react";
import _ from "lodash";
import OutsideClickHandler from "react-outside-click-handler";
import { KEY_ESCAPE } from "keycode-js";

import styles from "./floating-menu.module.scss";

const FloatingMenu = ({ children, actions, anchorRef, onAttemptClose }) => {
    const containerRef = useRef(null);

    const fallbackFocus = () => {
        return <button type="button" tabIndex={-1} />;
    };

    const positionContainer = _.throttle(
        (anchorElement, containerElement) => {
            const anchorBbox = anchorElement.getBoundingClientRect();
            const containerBbox = containerElement.getBoundingClientRect();

            const maxVisibleTop = window.innerHeight - containerBbox.height;
            const maxVisibleLeft = window.innerWidth - containerBbox.width;

            const top = _.clamp(
                anchorBbox.top + anchorBbox.height + 8,
                0,
                maxVisibleTop
            );

            const left = _.clamp(
                anchorBbox.left - containerBbox.width + anchorBbox.width,
                0,
                maxVisibleLeft
            );

            containerElement.style.top = `${top}px`;
            containerElement.style.left = `${left}px`;
        },
        50
    );

    useEffect(() => {
        positionContainer(anchorRef.current, containerRef.current);

        const handleResize = () => {
            positionContainer(anchorRef.current, containerRef.current);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [anchorRef, containerRef, positionContainer]);

    useEffect(() => {
        const handleKeyUp = (event) => {
            if (event.keyCode === KEY_ESCAPE) {
                onAttemptClose();
            }
        };

        window.addEventListener("keyup", handleKeyUp);

        return () => window.removeEventListener("keyup", handleKeyUp);
    }, [onAttemptClose]);

    return ReactDOM.createPortal(
        (
            <OutsideClickHandler onOutsideClick={onAttemptClose}>
                <FocusTrap
                    focusTrapOptions={{
                        fallbackFocus,
                        allowOutsideClick: true,
                        initialFocus: false,
                        delayInitialFocus: true,
                    }}
                >
                    <div
                        className={styles.container}
                        ref={containerRef}
                        data-testid="floating-menu"
                    >
                        {actions.map(({ testId, label, handler, hasPermission = true, warningText }) => {
                            return hasPermission && (
                                <button
                                    data-testid={testId}
                                    key={label}
                                    type="button"
                                    className={classNames(styles.button, { [styles.redActionText]: warningText })}
                                    onClick={() => {
                                        handler();
                                        onAttemptClose();
                                    }}
                                >
                                    {label}
                                </button>
                            );
                        })}
                        {children}
                    </div>
                </FocusTrap>
            </OutsideClickHandler>
        ),
        document.body
    );
};

FloatingMenu.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        handler: PropTypes.func.isRequired,
    })),
    anchorRef: PropTypes.shape({
        current: PropTypes.instanceOf(Element),
    }).isRequired,
    onAttemptClose: PropTypes.func.isRequired,
};

FloatingMenu.defaultProps = {
    actions: [],
};

export default FloatingMenu;
