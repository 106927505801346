import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { map, find, noop, uniq } from 'lodash';
import { Form, PasswordInputRow, SelectInputRow } from "components/forms";
import { useNotificationContext } from 'state/context';
import SecurityApi from "lib/api-endpoints/security-api";
import ConfirmationDrawer from "./confirmation-drawer";
import { PrimaryButton } from 'components';

import styles from './components.module.scss';

/**
 * Renders the security questions form
 *
 * @returns {React.Component} Security questions form
 */
const SecurityQuestions = ({ setLoading }) => {
    const { openNotification } = useNotificationContext();
    const [drawerState, setDrawerState] = useState({isOpen: false, isLoading: true});
    const [questionsState, setQuestionsState] = useState({
        answerList: [],
        password: "",
        questionList: [],
        hasChanged: false,
    });

    useEffect(() => {
        const { request: questionsList, abortController: questionsListAbort } = SecurityApi.getSecurityQuestionsList();
        const { request: questions, abortController: questionsAbort } = SecurityApi.getSecurityQuestions();

        Promise.all([questionsList, questions]).then((values) => {
            setQuestionsState((prevState) => ({
                ...prevState,
                questionList: values[0].data,
                answerList: values[1].data,
            }));

            return () => values[0].abortController?.abort();
        });

        return () => {
            questionsListAbort.abort();
            questionsAbort.abort();
        };
    }, [setLoading]);

    const setChange = (value, id, type) => {
        const questionAnswer = questionsState.answerList;
        let changed = find(questionAnswer, (v) => v.id === id);

        if (type === "question") {
            let foundQuestion = find(questionsState.questionList, (v) => v.question === value);
            changed.id = foundQuestion.id;
        } else if (type === "answer") {
            changed.answer = value;
        }

        setQuestionsState({...questionsState, hasChanged: true, answerList: uniq([
            ...questionsState.answerList,
            changed,
        ])});
    };

    /**
     * Handles the opening of the confirmation drawer
     *
     * @return {void}
    */
    const handleSubmit = () => {
        if (questionsState.hasChanged === true) {
            setDrawerState((prevState) => ({...prevState, isOpen: true, isLoading: false}));
        }
    };

    /**
     * Function for confirming the change questions form
     *
     * @param {object} formData the formdata
     *
     * @return {void}
     */
    const onConfirm = (formData) => {
        SecurityApi.changeSecurityQuestions({
            questions: questionsState.answerList,
            ...formData,
        }).request.then(() => {
            setDrawerState({ isOpen: false, isLoading: true });

            openNotification({
                message: "Security questions successfully changed",
                type: "success",
            });
        });
    };

    const { questionList, answerList } = questionsState;
    const questionsInUse = map(answerList, ({ id }) => id);

    return (
        <>
            <div className={styles.container} data-testid="security_questions_form">
                <Form onSubmit={handleSubmit}>
                    {questionsState.answerList.map((row) => {
                        const key = row.number;
                        const id = row.id;
                        const questionOptions = map(questionList, (question) => {
                            if (questionsInUse.includes(question.id) && question.id !== id) {
                                return ({
                                    value: "",
                                    display: "",
                                });
                            }

                            return ({
                                value: question.question,
                                display: question.question,
                            });
                        }).filter((question) => question.value !== "");

                        const selectedQuestion = questionList.find((question) => question.id === id);

                        return (
                            <div key={key} className={styles.inputRow}>
                                <div className={styles.questionInputContainer}>
                                    <SelectInputRow
                                        className={styles.select}
                                        name={`question[${key}]`}
                                        label={`Question ${key}`}
                                        aria-label="Question options"
                                        onChange={(event) => setChange(event.target.value, id, 'question')}
                                        options={questionOptions}
                                        selected={selectedQuestion.question}
                                    />
                                </div>
                                <div className={styles.questionInputContainer}>
                                    <PasswordInputRow
                                        autoComplete="off"
                                        name={`answer${key}`}
                                        label={`Answer ${key}`}
                                        large
                                        defaultValue={row.answer}
                                        onChange={(event) => setChange(event.target.value, id, "answer")}
                                    />
                                </div>
                            </div>
                        );
                    })}
                    <PrimaryButton
                        className={styles.saveButton}
                        onClick={handleSubmit}
                    >
                        Save
                    </PrimaryButton>
                </Form>
            </div>
            <ConfirmationDrawer
                drawerState={drawerState}
                setDrawerState={setDrawerState}
                onConfirm={onConfirm}
            />
        </>
    );
};

SecurityQuestions.propTypes = {
    setLoading: PropTypes.func,
};

SecurityQuestions.defaultProps = {
    setLoading: noop,
};

export default SecurityQuestions;
