import { useState, useEffect, useCallback, useRef } from 'react';

/**
 * Helper hook for tracking width and height of a target ref container
 *
 * @returns {function} useResizeObserver hook
 */
const useResizeObserver = () => {
    const [size, setSize] = useState({ width: 0, height: 0 });
    const resizeObserver = useRef(null);

    /**
     * Adds the width and height from the current node on resize detection
     */
    const onResize = useCallback((entries) => {
        const { width, height } = entries[0].contentRect;
        setSize({ width, height });
    }, []);

    /**
     * Sets/resets the ref observed
     */
    const targetRef = useCallback((node) => {
        if (node !== null) {
            if (resizeObserver.current) {
                resizeObserver.current.disconnect();
            }

            resizeObserver.current = new ResizeObserver(onResize);
            resizeObserver.current.observe(node);
        }
    }, [onResize]);

    /**
     * Disconnects the observer when unmounting
     *
     * @returns {void}
     */
    useEffect(() => () => (resizeObserver.current.disconnect()), []);

    return { targetRef, width: size.width, height: size.height };
};

export default useResizeObserver;
