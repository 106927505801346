import React from "react";
import PropTypes from "prop-types";
import { FormRow, FormRowLabel } from "./form-controls";
import DateInput from "./form-controls/date-input";

import styles from "./date-range-input-row.module.scss";

/**
 * Renders the text input row
 *
 * @param {object} props The text input props
 *
 * @return {React.Component} The text input row component
 */
const DateRangeInputRow = ({ label, start, end, required, ...otherProps }) => {
    return (
        <FormRow>
            <FormRowLabel label={label} required={required} />
            <div className={styles.dateRange}>
                <DateInput
                    {...start}
                    placeholder="Start Date"
                    data-testid="start-date"
                    className={styles.dateInput}
                    {...otherProps}
                />
                <DateInput
                    {...end}
                    placeholder="End Date"
                    data-testid="end-date"
                    className={styles.dateInput}
                    {...otherProps}
                />
            </div>
        </FormRow>
    );
};

DateRangeInputRow.propTypes = {
    label: PropTypes.string,
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
    required: PropTypes.bool,
};

DateRangeInputRow.defaultProps = {
    label: null,
    required: false,
};

export default DateRangeInputRow;
