import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import { noop } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { TroncsPeriodsApi } from 'lib/api-endpoints';
import { downloadBlob } from 'lib/downloaders/stream-file-handler';
import { PrimaryButton } from 'components';
import { TextInputRow } from 'components/forms';
import FileInput from 'components/forms/form-controls/file-input';
import CsvLineErrorValidation from 'components/csv/csv-line-error-validation';

import styles from './tronc-import-form.module.scss';

/**
 * Form to handle requesting csv template downloads and tronc period uploads
 *
 * @param {number} periodId The tronc period id
 * @param {function} setCsvUploadFeedback Set the list of csv upload errors
 *
 * @returns {React.Component} The form for importing processed tronc periods
 */
const TroncImportForm = ({ periodId, setCsvUploadFeedback }) => {
    const importFormRef = useRef(null);
    const { setValue, getValues, register, formState: { errors }, watch, clearErrors } = useFormContext();
    watch();

    /**
     * Makes request for csv template then downloads
     *
     * @returns {void}
     */
    const handleCsvTemplateDownload = () => {
        const { request } = TroncsPeriodsApi.getEmployeeHoursCsvTemplate(periodId);

        downloadBlob(request, {
            filename: `employee-hours-template-${moment(new Date()).format("DD/MM/YY")}.csv`,
            targetNode: importFormRef.current,
        });
    };

    /**
     * Clears any custom form errors when file uploaded changes
     * Gets the previous fund total and resets to revalidate and allow submission
     *
     * @returns {void}
     */
    const handleFileUpload = () => {
        clearErrors();
        setCsvUploadFeedback([]);

        setValue(
            "tronc_fund_total",
            getValues("tronc_fund_total"),
            { shouldValidate: true },
        );
    };

    return (
        <div ref={importFormRef} className={styles.form}>
            <PrimaryButton
                className={styles.downloadTemplate}
                onClick={handleCsvTemplateDownload}
            >
                Download Employee hours CSV template
            </PrimaryButton>
            <FileInput
                {...register("file_input")}
                className={styles.fileInput}
                onFileUpload={handleFileUpload}
            />
            <CsvLineErrorValidation errors={errors?.custom?.message} />
            <TextInputRow
                {...register("tronc_fund_total", {
                    pattern: {
                        value: /^(?:[1-9]\d+|\d)(?:\.[0-9]{2})$/,
                        message: "Please enter a numerical value including two decimal places",
                    },
                })}
                className={styles.formInput}
                label="Tronc fund total"
                error={errors?.tronc_fund_total?.message}
            />
        </div>
    );
};

TroncImportForm.propTypes = {
    periodId: PropTypes.number.isRequired,
    setCsvUploadFeedback: PropTypes.func,
};

TroncImportForm.defaultProps = {
    setCsvUploadFeedback: noop,
};

export default TroncImportForm;
