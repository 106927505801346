import React from 'react';
import PropTypes from 'prop-types';
import CloseButton from "components/buttons/close-button";

import styles from './file-input-label.module.scss';

/**
 * Renders the file input label
 *
 * @return {ReactElement} The label container
 */
const FileInputLabel = ({ fileList, onClose }) => (
    <div className={styles.container}>
        {Array.from(fileList).map(({ name }) => (
            <div key={name} className={styles.file}>
                {name}
                <CloseButton onClick={(event) => onClose(name, event)} className={styles.fileClose} />
            </div>
        ))}
    </div>
);

FileInputLabel.propTypes = {
    fileList: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default FileInputLabel;
