import React from "react";
import { Card } from "components/card";
import PropTypes from "prop-types";
import EmployeesCount from "./employees-count";
import Indicator from "components/indicator/indicator";
import payrollPropTypes from "lib/props-schema/payroll-schema";
import companyPropTypes from "lib/props-schema/company-schema";
import periodPropTypes, { periodDefaultProps } from "lib/props-schema/period-schema";

import styles from "./payroll-card.module.scss";

/**
 * Renders PayrollCard
 *
 * @param {object} company The company details
 * @param {object} payroll The payroll details
 * @param {object} period The payrolls period details
 * @param {function} onClick The click event to handle when the card is clicked
 *
 * @returns {React.Component} The PayrollCard
 */
const PayrollCard = ({ company, payroll, period, onClick }) => (
    <Card onClick={() => onClick(period)} className={styles.payrollCard}>
        <div className={styles.title}>
            <div className={styles.companyName}>{company.name}</div>
            <EmployeesCount count={payroll.live_employees} />
        </div>
        <div className={styles.payroll}>{payroll.name}</div>
        <div>{period.period_name} {period.tax.start}/{period.tax.end}</div>
        <div>Paid in 10 days - Fri 14th Aug</div>
        <div className={styles.footer}>
            <Indicator status={period.status} />
            <div className={styles.action}>
                view
            </div>
        </div>
    </Card>
);

PayrollCard.propTypes = {
    payroll: payrollPropTypes.isRequired,
    company: companyPropTypes.isRequired,
    period: periodPropTypes,
    onClick: PropTypes.func,
};

PayrollCard.defaultProps = {
    onClick: () => null,
    period: periodDefaultProps,
};

export default PayrollCard;
