import React from "react";
import { isEmpty } from "lodash";
import OpenBatchActions from "./open-batch-actions";
import UnapprovedBatchActions from "./unapproved-batch-actions";
import periodPropTypes, { periodDefaultProps } from "lib/props-schema/period-schema";

import styles from "./batch-actions.module.scss";

const availableTransitions = {
    "not started": OpenBatchActions,
    "unapproved": UnapprovedBatchActions,
    "checked": UnapprovedBatchActions,
};

/**
 * Renders the batch actions
 *
 * @param {object} props The component properties
 *
 * @returns {React.Component|null} Renders the batch actions
 */
const BatchActions = (props) => {
    const { period } = props;

    if (isEmpty(period) || availableTransitions[period?.status] === undefined) {
        return null;
    }

    const Component = availableTransitions[period?.status];

    return (
        <div className={styles.actions} data-testid="batch-actions">
            <Component {...props} />
        </div>
    );
};

BatchActions.defaultProps = {
    period: periodDefaultProps,
};

BatchActions.propTypes = {
    period: periodPropTypes,
};

export default BatchActions;
