import React from "react";
import { useFormContext } from "react-hook-form";

import styles from './tronc-scheme-distribution-form.module.scss';

/**
 * Renders the splits buttons
 *
 * @param {boolean} departmentSplit The department split boolean
 * @param {boolean} divisionsSplit The divisions split boolean
 * @param {func} register Registers the input to the form
 *
 * @returns {React.Component} The splits buttons
 */
const splitsButtons = (departmentSplit, divisionsSplit, register) => {
    return (
        <>
            <label>
                <input
                    type="checkbox"
                    className={styles.hiddenInput}
                    label="Split tips across company departments"
                    {...register('apply_tip_split_to_departments')}
                />
                <span className={departmentSplit ? styles.selected : styles.squares}>
                    Split tips across company departments
                </span>
            </label>
            <label>
                <input
                    type="checkbox"
                    className={styles.hiddenInput}
                    label="Split tips across whole of company"
                    {...register('fundSplitCompany')}
                />
                <span className={(!departmentSplit && !divisionsSplit) ? styles.selected : styles.squares}>
                    Split tips across whole of company
                </span>
            </label>
            <label>
                <input
                    type="checkbox"
                    className={styles.hiddenInput}
                    label="Split tips across company divisions"
                    {...register('apply_tip_split_to_divisions')}
                />
                <span className={divisionsSplit ? styles.selected : styles.squares}>
                    Split tips across company divisions
                </span>
            </label>
        </>
    );
};

/**
 * Renders the distribution buttons
 *
 * @param {func} register Registers the input to the form
 * @param {object} distributionFrequencyType The frequency distribution
 *
 * @returns {React.Component} The distribution buttons
 */
const distributionButtons = (register, distributionFrequencyType) => {
    return (
        <>
            <label>
                <input
                    type="radio"
                    className={styles.hiddenInput}
                    label="Daily"
                    value="daily"
                    {...register('distribution_frequency_type')}
                />
                <span className={distributionFrequencyType === "daily" ? styles.selected : styles.squares}>
                    Daily
                </span>
            </label>
            <label>
                <input
                    type="radio"
                    className={styles.hiddenInput}
                    label="Weekly"
                    value="weekly"
                    {...register('distribution_frequency_type')}
                />
                <span className={distributionFrequencyType === "weekly" ? styles.selected : styles.squares}>
                    Weekly
                </span>
            </label>
            <label>
                <input
                    type="radio"
                    className={styles.hiddenInput}
                    label="Period"
                    value="period"
                    {...register('distribution_frequency_type')}
                />
                <span className={distributionFrequencyType === "period" ? styles.selected : styles.squares}>
                    Period
                </span>
            </label>
        </>
    );
};

/**
 * Renders the tronc scheme distribution form
 *
 * @returns {React.Component} Tronc scheme distribution form
 */
const TroncSchemeDistributionForm = () => {
    const { register, getValues, watch } = useFormContext();
    const {
        distribution_frequency_type: distributionFrequencyType,
        apply_tip_split_to_departments: departmentSplit = false,
        apply_tip_split_to_divisions: divisionsSplit = false,
    } = getValues();

    watch();

    return (
        <div className={styles.formWrapper}>
            <div className={styles.title}>
                Tronc distribution
            </div>
            <div className={styles.subTitle}>
                Fund splits
            </div>
            <div className={styles.squaresContainer}>
                {splitsButtons((departmentSplit || false), (divisionsSplit || false), register)}
            </div>
            <div className={styles.subTitle}>
                Frequency of distribution
            </div>
            <div className={styles.squaresContainer}>
                {distributionButtons(register, distributionFrequencyType)}
            </div>
        </div>
    );
};

export default TroncSchemeDistributionForm;
