import React from 'react';
import PropTypes from 'prop-types';

import styles from './inline-edit.module.scss';

const InlineEditValidation = ({ errors, fieldName }) => {
    if (!errors?.[fieldName]) {
        return null;
    }

    return (
        <div className={styles.inlineEditValidation}>
            {errors[fieldName].message}
        </div>
    );
};

InlineEditValidation.propTypes = {
    errors: PropTypes.object.isRequired,
    fieldName: PropTypes.string.isRequired,
};

export default InlineEditValidation;
