import { useState, useEffect } from "react";
import { TroncSchemesApi } from "lib/api-endpoints";

const initialState = {
    frequencies: [],
    isLoaded: false,
};

const useTroncSchemesFrequencyList = ({ siteId }) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        if (!siteId) {
            return () => null;
        }

        setState(initialState);

        const { request, abortController } = TroncSchemesApi.getTroncSchemeFrequencies(siteId);

        request.then(({ data }) => setState({ frequencies: data, isLoaded: true }));

        return () => abortController?.abort();
    }, [siteId]);

    return state;
};

export default useTroncSchemesFrequencyList;
