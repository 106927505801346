import React from 'react';
import PropTypes from 'prop-types';

import styles from './drawer-title.module.scss';

/**
 * Renders a title component primarily for use in drawer headers
 *
 * @param {object} props component props
 * @param {string} props.children the title string
 *
 * @returns {React.Component} DrawerTitle Component
 */
const DrawerTitle = ({ children }) => (
    <h2 className={styles.title}>
        {children}
    </h2>
);

DrawerTitle.propTypes = {
    children: PropTypes.any.isRequired,
};

export default DrawerTitle;
