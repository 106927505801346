import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { SelectInputRow } from "components/forms";

/**
 * Renders the payrolls options
 *
 * @param {object} props The payrolls options props
 * @param {array} props.payrolls The payrolls
 *
 * @returns {React.Component} The payrolls selection component
 */
const PayrollOptions = forwardRef(({ payrolls, ...otherProps }, ref) => {
    const options = payrolls.map(({ id, name }) => ({
        value: id,
        display: name,
    }));

    return (
        <SelectInputRow
            data-testid="payroll-options"
            aria-label="Payroll options"
            ref={ref}
            label="Payrolls"
            options={options}
            {...otherProps}
        />
    );
});

PayrollOptions.propTypes = {
    payrolls: PropTypes.array,
};

PayrollOptions.defaultProps = {
    payrolls: [],
};

export default PayrollOptions;
