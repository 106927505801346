import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filter } from "lodash";
import { clearSitesSchemes, getSchemes } from "state/company-slice";
import SiteCard from "./site-card";

/**
 * Renders all the sites necessary, gets scheme data
 *
 * @returns {React.Component} Renders the sites cards for a company with scheme info
 */
const SitesWithSchemes = () => {
    const dispatch = useDispatch();
    const { sites, schemes, schemesLoading } = useSelector((state) => state.company);

    /**
     * gets schemes for the required site ids
     */
    useEffect(() => {
        const siteIds = sites.data.map((site) => site.id);

        dispatch(getSchemes(siteIds));

        return () => {
            dispatch(clearSitesSchemes());
        };
    }, [sites, dispatch]);

    return (
        <>
            {sites?.data.map((site) => {
                const siteSchemes = filter(schemes, ({ site_id: siteId }) => siteId === site.id);

                return <SiteCard key={site.id} site={site} schemes={siteSchemes} schemesLoading={schemesLoading} />;
            })}
        </>
    );
};

export default SitesWithSchemes;
