import _ from 'lodash';
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import styles from "./user-avatar.module.scss";

/**
 * Renders the UserAvatar component which displays an icon containing the users initials
 *
 * @param {object} props component props
 * @param {string} nameInitials the name initials to be displayed
 * @param {string} size the size of the avatar in pixels
 *
 * @returns {React.Component} UserAvatar Component
 */
const UserAvatar = ({ nameInitials, size }) => {
    const user = useSelector((state) => state.user);

    const initials = [
        (user?.forename),
        (user?.surname),
    ]
        .filter((value) => !_.isEmpty(value))
        .map((value) => value[0]);

    return (
        <div
            className={styles.container}
            style={{ width: `${size}px`, height: `${size}px` }}
        >
            <svg width={size - 10} height={size - 10} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <text
                    fill="white"
                    x="50"
                    y="50"
                    fontSize="60"
                    fontWeight="500"
                    dominantBaseline="central"
                    textAnchor="middle"
                    data-testid="initials"
                >
                    {nameInitials ? nameInitials : initials.join('')}
                </text>
            </svg>
        </div>
    );
};

UserAvatar.propTypes = {
    nameInitials: PropTypes.string,
    size: PropTypes.number,
};

UserAvatar.defaultProps = {
    nameInitials: '',
    size: 35,
};

export default UserAvatar;
