import React, { useState } from "react";
import { isEmpty } from "lodash";
import { Page, PageContent, LayoutContainer, Table, SearchInput, DateDisplay } from "components";
import useAnnouncements from "lib/api-endpoints/hooks/announcements";
import MoreActionsButton from "components/more-actions-button/more-actions-button";
import { Button } from "components/forms";
import AnnouncementDrawer from "./components/announcement-drawer";

const headings = {
    "subject": "Subject",
    "added_by": "Added By",
    "date_created": "Date Created",
    "type": "Type",
    "actions": "Actions",
};

/**
 * Gets the formatted data for the table rendering
 *
 * @param {array} announcements An array of announcements
 * @param {function} setForm Handles the state of the current form
 *
 * @returns {array} The formatted data
 */
const getFormattedData = (announcements, setForm) => announcements.map((announcement) => {
    const { id, subject, addedby, dateadded, notice_type: type } = announcement;
    const { friendly_name: friendlyName } = type;

    return {
        id,
        subject,
        type: friendlyName,
        "added_by": [addedby?.forename, addedby?.surname].filter((value) => !isEmpty(value)).join(" "),
        "date_created": {
            component: <DateDisplay data={dateadded} output="DD-MM-YYYY" />,
        },
        actions: {
            component: (
                <MoreActionsButton
                    actions={[
                        {
                            label: "View",
                            handler: () => setForm({ announcement, isOpen: true }),
                        },
                        {
                            label: "Edit",
                            handler: () => setForm({ announcement, isOpen: true, editable: true }),
                        },
                    ]}
                />
            ),
        },
    };
});

const initialState = {
    announcement: null,
    isOpen: false,
    editable: false,
};

const initialPage = 1;

/**
 * Renders the announcements page
 *
 * @returns {React.Component} The Announcements component
 */
const Announcements = () => {
    const [formState, setForm] = useState(initialState);
    const [page, setPage] = useState(initialPage);
    const [refresh, setRefresh] = useState(false);
    const { data, isLoaded } = useAnnouncements({ page, refresh });
    const announcementData = data?.data;
    const { isOpen, announcement, editable } = formState;

    const handleOpen = () => setForm({ ...formState, isOpen: true });
    const handleClose = () => setForm(initialState);

    return (
        <Page
            title="Announcements"
            titleAction={<Button data-testid="create-announcement" onClick={handleOpen}>Create New</Button>}
        >
            <PageContent>
                <LayoutContainer
                    title="Announcements"
                    headerContent={<SearchInput />}
                >
                    <Table
                        data-testid="announcements"
                        data={getFormattedData(announcementData, setForm)}
                        skeletonConfig={{
                            rowCount: 10,
                            rowConfig: [
                                { key: 'announcements' },
                            ],
                        }}
                        dataLoaded={isLoaded}
                        headings={headings}
                        noDataText="No Announcements"
                        pagination={{
                            totalPages: data?.meta?.last_page,
                            currentPage: page,
                            onPageChange: setPage,
                        }}
                    />
                    <AnnouncementDrawer
                        announcement={announcement}
                        isOpen={isOpen}
                        isLoading={!isLoaded}
                        onCancel={handleClose}
                        editable={editable}
                        setAnnouncement={setForm}
                        onSuccess={(isEdit) => {
                            if (!isEdit && initialPage !== page) {
                                setPage(initialPage);
                            } else {
                                setRefresh(!refresh);
                            }

                            handleClose();
                        }}
                    />
                </LayoutContainer>
            </PageContent>
        </Page>
    );
};

export default Announcements;
