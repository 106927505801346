import React from 'react';
import PropTypes from 'prop-types';
import { CardDisplay } from 'components';

/**
 * Presents a summary of data with a link to view the full data
 *
 * @param {string} fieldName The name describing the number this field shows
 * @param {number} number The summary number to display
 * @param {number} decimalPlaces the amount of decimal places to show
 * @param {string} text The text to show
 *
 * @returns {React.Component} The summary component
 */
const CardTextDisplay = ({ text, fieldName }) => {
    return (
        <CardDisplay fieldName={fieldName} content={text} />
    );
};

CardTextDisplay.propTypes = {
    text: PropTypes.string,
    fieldName: PropTypes.string,
};

CardTextDisplay.defaultProps = {
    text: "-",
    fieldName: "",
};

export default CardTextDisplay;
