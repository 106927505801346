
/* eslint-disable max-len, no-useless-escape */
// Taken from: http://regexlib.com/REDetails.aspx?regexp_id=2558
const emailRegEx = new RegExp(/^((([!#$%&'*+\-/=?^_`{|}~\w])|([!#$%&'*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~\.\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$/);
const phoneRegEx = new RegExp(/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/);
/* eslint-enable */

const codeRegEx = new RegExp(/^[0-9]{1,6}$/);

const ninoRegEx = new RegExp(/^[A-Za-z]{2}\d{6}[A-Za-z]$/);

const wholeNumberRegEx = /^(?<![-.])\b[0-9]+\b(?!\.[0-9])$/;

export {
    codeRegEx,
    emailRegEx,
    ninoRegEx,
    phoneRegEx,
    wholeNumberRegEx,
};
