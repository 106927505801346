import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Button } from "./";

import styles from "./form-actions.module.scss";

/**
 * Renders the form actions component
 *
 * @param {object} props The form actions properties
 *
 * @return {React.Component} The form actions component
 */
const FormActions = ({
    onSubmit,
    onCancel,
    submitText,
    cancelText,
    disableSubmit,
    children,
    ...otherProps
}) => (
    <div className={styles.container} {...otherProps}>
        {children && (
            <div className={styles.additionalActionsContainer}>
                {children}
            </div>
        )}
        <Button className={styles.secondary} onClick={onCancel}>
            {cancelText}
        </Button>
        <Button type="submit" className={styles.primary} onClick={onSubmit} disabled={disableSubmit}>
            {submitText}
        </Button>
    </div>
);

FormActions.defaultProps = {
    onSubmit: noop,
    onCancel: noop,
    submitText: 'Submit',
    cancelText: 'Cancel',
    disableSubmit: false,
    children: null,
};

FormActions.propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    submitText: PropTypes.string,
    cancelText: PropTypes.string,
    disableSubmit: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.node,
    ]),
};

export default FormActions;
