/* eslint-disable max-len */
import React from 'react';

/**
 * Renders the tipology logo
 *
 * @param {object} props The component props
 * @returns {React.Component} The styled svg logo
 */
const Tipology = (props) => (
    <svg width="150" height="53" fill="none" viewBox="0 0 540.11 190.37" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#a)">
            <path fill="#FFFFFF" d="M191.36 124.83c0-19.42 15.91-35.2 35.6-35.2s35.87 15.91 35.87 35.2-16.05 35.33-35.87 35.33-35.6-15.64-35.6-35.33Zm57.58.13c0-12.81-9.71-23.2-21.98-23.2s-21.85 10.38-21.85 23.2 9.84 23.06 21.85 23.06 21.98-10.25 21.98-23.06ZM279.15 153.83V66.04c0-3.1 1.89-4.85 4.99-4.85h4.05c3.1 0 4.85 1.75 4.85 4.85v87.79c0 3.1-1.75 4.85-4.85 4.85h-4.05c-3.1 0-4.99-1.75-4.99-4.85ZM306.8 124.83c0-19.42 15.91-35.2 35.6-35.2s35.87 15.91 35.87 35.2-16.05 35.33-35.87 35.33-35.6-15.64-35.6-35.33Zm57.58.13c0-12.81-9.71-23.2-21.98-23.2s-21.85 10.38-21.85 23.2 9.84 23.06 21.85 23.06 21.98-10.25 21.98-23.06ZM399.58 183.36c-2.43-1.62-2.83-4.04-1.48-6.74l1.08-1.89c1.48-3.1 3.78-3.37 6.61-1.62 5.8 3.64 12.41 5.26 19.29 5.26 12.95 0 21.85-7.42 21.85-21.71v-8.63c-6.34 9.04-15.1 12.14-24.14 12.14-20.23 0-33.44-15.91-33.44-35.33s13.22-35.2 33.44-35.2c9.04 0 17.8 3.1 24.14 12.14v-5.93c0-2.97 1.89-4.72 4.99-4.72h3.91c3.1 0 4.99 1.75 4.99 4.85v61.63c0 22.93-16.86 32.77-35.6 32.77-11.73 0-19.42-2.97-25.62-7.01Zm47.6-58.53c-.13-13.35-9.17-23.2-22.52-23.2s-21.44 10.65-21.44 23.2 7.82 23.2 21.44 23.2 22.39-9.84 22.52-23.2ZM489.26 183.23l10.38-27.11-25.89-59.34c-1.48-3.37.13-5.66 3.78-5.66h5.39c2.83 0 4.58 1.21 5.53 3.78l18.75 45.72 17.26-45.58c.94-2.7 2.97-3.91 5.8-3.91h5.53c3.64 0 5.26 2.16 3.78 5.66l-37.62 88.33c-1.08 2.7-2.83 3.78-5.66 3.78h-3.24c-3.64 0-5.12-2.29-3.78-5.66Z" />
            <path fill="#FFFFFF" d="M10.09 135.99V106.1H3.41c-2.1 0-3.41-1.18-3.41-3.28V94.3c0-2.1 1.31-3.28 3.41-3.28h8.26l2.23-13.63c.52-2.75 2.36-4.19 5.11-4.19h6.42c3.02 0 4.59 1.7 4.59 4.59v13.24h15.21c2.1 0 3.28 1.18 3.28 3.28v8.52c0 2.1-1.18 3.28-3.28 3.28H30.02v30.54c0 4.98 2.49 6.95 5.51 6.95 3.41 0 5.64-2.62 5.64-6.16 0-.66 0-1.44-.13-2.36-.26-2.36.52-3.54 2.75-3.54h8c1.7 0 3.15.66 3.67 2.62.52 1.97.79 4.06.79 5.11 0 14.55-10.36 20.58-22.55 20.58-11.4 0-23.6-5.24-23.6-23.86ZM68.04 72.54c0-6.03 4.33-10.62 12.19-10.62s12.19 4.59 12.19 10.62-4.72 10.49-12.19 10.49-12.19-4.59-12.19-10.49Zm2.1 79.57V97.32c0-4.06 2.23-6.29 6.29-6.29h7.6c4.06 0 6.42 2.23 6.42 6.29v54.79c0 4.06-2.36 6.29-6.42 6.29h-7.6c-4.06 0-6.29-2.23-6.29-6.29ZM105.79 181.48V97.32c0-4.06 2.23-6.29 6.29-6.29h7.73c4.06 0 6.29 2.23 6.29 6.29v3.15c4.85-7.47 12.72-10.88 21.63-10.88 18.88 0 32.38 15.34 32.38 35.13s-13.5 35.13-32.38 35.13c-8.91 0-16.78-3.41-21.63-11.01v32.64c0 4.06-2.23 6.29-6.29 6.29h-7.73c-4.06 0-6.29-2.23-6.29-6.29Zm54.01-56.76c0-10.36-6.16-18.88-16.78-18.88s-17.17 8.13-17.17 18.88 6.82 18.88 17.17 18.88 16.78-8.39 16.78-18.88ZM65.95 1.54c-4.51.94-7.41 5.37-6.46 9.88.94 4.51 5.37 7.41 9.88 6.46 14.47-3.03 29.28-.2 41.7 7.93 12.23 8 20.92 20.8 23.73 35 .94 4.51 5.3 7.5 9.82 6.56a8.38 8.38 0 0 0 5.33-3.63c1.17-1.79 1.68-4.01 1.22-6.19-3.66-18.65-15.09-35.26-31.09-45.72C103.99 1.3 84.78-2.4 65.95 1.54Z" />
            <path fill="#FFFFFF" d="M71.95 30.58c-4.51.94-7.41 5.37-6.46 9.88.94 4.51 5.37 7.41 9.88 6.46 6.69-1.4 13.63-.09 19.37 3.66s9.72 9.58 11.12 16.27c.94 4.51 5.37 7.41 9.88 6.46 2.27-.39 4.16-1.85 5.33-3.63a8.332 8.332 0 0 0 1.19-6.34c-2.34-11.21-8.79-20.66-18.29-26.87-9.47-6.06-20.87-8.14-32.01-5.89ZM70.01 65.18c-3.82 5.83-2.16 13.77 3.68 17.59 5.83 3.82 13.77 2.16 17.59-3.68 3.82-5.83 2.25-13.71-3.68-17.59-5.93-3.88-13.77-2.15-17.59 3.68Z" />
        </g>
    </svg>
);

export default Tipology;
