import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { SelectInputRow } from "components/forms";

/**
 * Renders the period options
 *
 * @param {object} props The period options props
 * @param {array} props.periods The periods
 *
 * @returns {React.Component} The period selection component
 */
const PeriodOptions = forwardRef(({ periods, ...otherProps }, ref) => {
    const options = periods.map(({ id, "period_name": periodName, tax: { start } }) => ({
        value: id,
        display: `${periodName} ${start}`,
    }));

    return (
        <SelectInputRow
            ref={ref}
            label="Periods"
            aria-label="Periods options"
            options={options}
            {...otherProps}
        />
    );
});

PeriodOptions.propTypes = {
    periods: PropTypes.array.isRequired,
};

export default PeriodOptions;
