import Permissions from "./permissions";

export default class CompanyEmployeesPermissions extends Permissions {

    /**
     * @inheritDoc
     */
    register () {
        const { troncmaster, permissions } = this;
        const viewEmployees = troncmaster || permissions.includes("view_employees");
        const editEmployees = troncmaster || permissions.includes("edit_employees");
        const createEmployees = troncmaster || permissions.includes("create_employees");
        const deleteEmployees = troncmaster || permissions.includes("delete_employees");

        this.canView = viewEmployees;
        this.canEdit = editEmployees;
        this.canCreate = createEmployees;
        this.canDelete = deleteEmployees;
    }

}
