import React from "react";
import PropTypes from "prop-types";

const Tick = (props) => (
    <svg data-testid="tick-icon" viewBox="0 0 24 24" {...props}>
        <path
            d="M9 16.2l-3.5-3.5a.984.984 0 00-1.4 0 .984.984 0 000 1.4l4.19
             4.19c.39.39 1.02.39 1.41 0L20.3 7.7a.984.984 0 000-1.4.984.984
             0 00-1.4 0L9 16.2z"
        />
    </svg>
);

Tick.propTypes = {
    fill: PropTypes.string,
};

Tick.defaultProps = {
    fill: '#111827',
};

export default Tick;
