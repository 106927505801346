import { api } from "lib/api";

export default class UpdatesApi {

    /**
     * Gets the Updates list request promise
     *
     * @param {object} params Additional query params
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getUpdates = (params = {}) => api({ url: "/updates", config: { params }});

    /**
     * Gets the updates or resources file data request promise
     *
     * @param {int} id The update or resource id
     *
     * @returns {{ request: Promise, abortController: AbortController }} The api promise with abort controller
     */
    static getUpdatesFileData = (id) => api({ url: `/updates/${id}/download`, config: { responseType: 'blob' }});

}
