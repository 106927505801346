import React from "react";
import PropTypes from 'prop-types';
import { Drawer, DrawerTitle } from "components";
import { useSelector } from "react-redux";
import AuthenticationForm from "./authentication-form";
import StepHeading from "./step-heading";
import LinkDropdown from "./link-dropdown";
import QRcode from "./QR-code";

import styles from './TOTP-setup-drawer.module.scss';

/**
 * Renders the setup/disbale MFA drawer
 *
 * @returns {React.Component} the setup and disable drawer for MFA
 */
const TOTPSetupDrawer = ({ drawerState, setDrawerState }) => {
    const user = useSelector((state) => state.user);

    const setupDrawer = (
        <>
            <StepHeading
                stepNumber="Step 1"
                stepText="If you don't have an authenticator app on your device"
            />
            <br />
            <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_GB"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.externalLink}
            >
                download google app for android
            </a>
            <a
                href="https://apps.apple.com/us/app/google-authenticator/id388497605?mt=8"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.externalLink}
            >
                download google app for ios
            </a>
            <br />
            <LinkDropdown />
            <StepHeading
                stepNumber="Step 2"
                stepText="Open your authenticator app and register your device by scanning the QR code below"
                linebreak
            />
            <QRcode />
            <br />
            <StepHeading
                stepNumber="Step 3"
                stepText="Enter the six digit code generated by your authenticator app"
                linebreak
            />
            <AuthenticationForm submitAction={() => setDrawerState({isOpen: false, isLoading: true})} />
        </>
    );

    const disableDrawer = (
        <AuthenticationForm disable submitAction={() => setDrawerState({isOpen: false, isLoading: true})} />
    );

    const drawerType = (user.mfa_totp) ? disableDrawer : setupDrawer;

    return (
        <Drawer
            isOpen={drawerState.isOpen}
            isLoading={drawerState.isLoading}
            onClose={() => setDrawerState({isOpen: false, isLoading: true})}
            onOutsideClick={() => setDrawerState({isOpen: false, isLoading: true})}
            headerContent={<DrawerTitle>Setup Authentication</DrawerTitle>}
            ariaLabel="totp drawer"
        >
            {drawerType}
        </Drawer>
    );
};

TOTPSetupDrawer.propTypes = {
    drawerState: PropTypes.object.isRequired,
    setDrawerState: PropTypes.func.isRequired,
};

export default TOTPSetupDrawer;
