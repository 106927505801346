import React from 'react';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { getSites } from 'state/company-slice';
import { CompaniesApi } from 'lib/api-endpoints';
import { useNotificationContext } from 'state/context';
import { Drawer, DrawerTitle } from 'components';
import { Form, FormActions } from 'components/forms';
import CompanySiteFormContent from './company-site-form-content';
import FormContent from 'components/forms/form-container/form-content';

/**
 * Helper function to set default form values to a blank string when not present
 *
 * @param {object} site The site being edited
 * @param {string} field The site field to get the default for
 * @returns {string} The default value or a blank string as required
 */
const siteFieldDefault = (site, field) => (site[field] ? site[field] : "");

/**
 * Renders a drawer to edit a new site
 *
 * @param {object} site Contains the existing site information
 * @param {number} companyId The company id
 * @param {boolean} isOpen Is the form drawer open
 * @param {func} setIsOpen sets the state of the drawer
 * @param {boolean} isLoaded Whether the app sites are loaded
 * @param {func} onCancel function to execute on edit site cancellation
 *
 * @returns {React.Component} The edit company drawer
 */
const EditSiteDrawer = ({ isOpen, setIsOpen, isLoading, onCancel, site, companyId }) => {
    const dispatch = useDispatch();
    const { openNotification } = useNotificationContext();

    /**
     * Formats the site values to be used as default form values
     *
     * @returns {object} The formatted default form values
     */
    const formattedDefaults = () => ({
        siteName: siteFieldDefault(site, "name"),
        address1: siteFieldDefault(site, "address_line_1"),
        address2: siteFieldDefault(site, "address_line_2"),
        address3: siteFieldDefault(site, "address_line_3"),
        town: siteFieldDefault(site, "address_post_town"),
        postcode: siteFieldDefault(site, "address_post_code"),
    });

    const methods = useForm({
        mode: "onChange",
        defaultValues: formattedDefaults(),
    });

    const { handleSubmit, reset } = methods;

    /**
     * Formats edit form data and calls edit company site api endpoint
     *
     * @param {number} siteId The site id
     * @param {object} formData The edit form data
     *
     * @returns {object} Request response data for the editSite api call
     */
    const editSite = (siteId, {
        siteName,
        address1,
        address2,
        address3,
        town,
        postcode,
    }) => {
        const siteData = {
            name: siteName,
            "address_line_1": address1,
            "address_line_2": address2,
            "address_line_3": address3,
            "address_post_town": town,
            "address_post_code": postcode,
        };

        return CompaniesApi.editSite(siteId, siteData);
    };

    /**
     * Submit method to edit a site, resets and updates site data when complete
     *
     * @param {object} data The form data
     */
    const onSubmit = (data) => {
        editSite(site.id, data)
            .request
            .then(() => {
                openNotification({
                    message: "Site successfully edited",
                    type: "success",
                });
                setIsOpen(false);
                reset();
                dispatch(getSites([companyId]));
            });
    };

    /**
     * Sets up the form draw
     *
     * @returns {React.Component} The form content wrapper
     */
    const renderContent = () => {
        return (
            <Drawer
                isOpen={isOpen}
                isLoading={isLoading}
                onClose={onCancel}
                onOutsideClick={onCancel}
                headerContent={<DrawerTitle>Edit a Site</DrawerTitle>}
                padding={false}
                ariaLabel="edit site drawer"
            >
                <FormProvider {...methods}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <FormContent>
                            <CompanySiteFormContent isEditPage={true} />
                        </FormContent>
                        <FormActions
                            onSubmit={handleSubmit(onSubmit)}
                            submitText="Edit Site"
                            onCancel={onCancel}
                        />
                    </Form>
                </FormProvider>
            </Drawer>
        );
    };

    return isOpen && renderContent();
};

export default EditSiteDrawer;
