import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/forms';
import { noop } from 'lodash';

import styles from './create-button.module.scss';

const CreateButton = ({ onClick, children }) => {
    return (
        <div className={styles.createButtonWrapper}>
            <Button
                className={styles.createButton}
                onClick={onClick}
                data-testid="create-company"
            >
                {children}
            </Button>
        </div>
    );
};

CreateButton.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.any.isRequired,
};

CreateButton.defaultProps = {
    onClick: noop,
};

export default CreateButton;
