import React from "react";
import PropTypes from "prop-types";
import CompanyJobRolesApi from "lib/api-endpoints/company-job-roles-api";
import { SecondaryButton } from "components";
import { useForm, FormProvider } from "react-hook-form";
import { Form, TextInputRow } from "components/forms";
import { useNotificationContext, useSnackbarContext } from 'state/context';
import FormContent from 'components/forms/form-container/form-content';

import styles from "./job-roles.module.scss";

/**
 * Renders the edit or archive job roles form for a selected company
 *
 * @param {object} jobRole The job role
 * @param {number} companyId The selected company id
 * @param {func} setRefresh Refreshes the page after api call
 *
 * @returns {React.Component} Edit or archive job roles form
 */
const EditArchiveJobRolesForm = ({ jobRole, companyId, setRefresh }) => {
    const { openNotification } = useNotificationContext();
    const { openSnackbar } = useSnackbarContext();
    const methods = useForm();

    const { register, handleSubmit, watch } = methods;
    watch();

    /**
     * Edits the job role title
     *
     * @param {object} formData The form data
     *
     * @return {void}
     */
    const onSubmit = async (formData) => {
        await CompanyJobRolesApi.editJobRoles(companyId, jobRole.id, {
            "job_title": formData.jobRole,
        }).request.then(() => {
            openNotification({
                message: `Job role edited successfully`,
                type: "success",
            });
            setRefresh(true);
        });
    };

    /**
     * Handles the api call to archive the chosen job role
     *
     * @return {void}
     */
    const archive = async () => {
        await CompanyJobRolesApi.editJobRoles(companyId, jobRole.id, {
            "archived": true,
        }).request.then(() => {
            openNotification({
                message: `Job role archived successfully`,
                type: "success",
            });
            setRefresh(true);
        });
    };

    /**
     * Archives the chosen job role
     *
     * @return {void}
     */
    const handleArchive = () => {
        openSnackbar({
            message: "Are you sure you want to archive this job role?",
            type: "warn",
            actions: [
                { id: 1, text: "Yes", handler: () => archive() },
                { id: 2, text: "No" },
            ],
        });
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <FormContent>
                    <div key={jobRole?.job_title} className={styles.cardContainer}>
                        <div className={styles.input}>
                            <TextInputRow {...register("jobRole")} large defaultValue={jobRole?.job_title} />
                        </div>
                        <SecondaryButton onClick={() => handleArchive()}>
                            Archive
                        </SecondaryButton>
                    </div>
                </FormContent>
            </Form>
        </FormProvider>
    );
};

EditArchiveJobRolesForm.propTypes = {
    jobRole: PropTypes.object.isRequired,
    companyId: PropTypes.number.isRequired,
    setRefresh: PropTypes.func.isRequired,
};

export default EditArchiveJobRolesForm;
