import React from "react";
import PropTypes from "prop-types";
import { FormRow, FormRowLabel, ToggleSwitch } from "./form-controls";
import ErrorHelpText from "./error-help-text";

/**
 * Renders the selection input row
 *
 * @param {object} props The ToggleSwitch properties
 * @param {string} props.label The label to render
 *
 * @return {React.Component} The select input row
 */
const ToggleSwitchRow = React.forwardRef(({ label, error, required, topMargin, ...otherProps }, ref) => {
    return (
        <FormRow topMargin={topMargin}>
            <FormRowLabel label={label} required={required} />
            <ToggleSwitch ref={ref} required={required} {...otherProps} />
            { error ? <ErrorHelpText text={error} /> : null }
        </FormRow>
    );
});

ToggleSwitchRow.defaultProps = {
    label: "",
    error: "",
    required: false,
    topMargin: true,
};

ToggleSwitchRow.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string,
    required: PropTypes.bool,
    topMargin: PropTypes.bool,
};

export default ToggleSwitchRow;
