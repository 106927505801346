import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from "react-hook-form";
import { Checkbox } from 'components/forms';

import styles from './user-ticket-settings.module.scss';

/**
 * Renders addition columns required for user table for tickets
 *
 * @param {object} props component props
 * @param {string} cellClass cell styling class
 * @param {string} value the checkbox value
 * @param {boolean} isShadedRow flag indicating row should be shaded
 *
 * @returns {React.Component} UserTicketSettings component
 */
const UserTicketSettings = ({ cellClass, value, isShadedRow }) => {
    const { register } = useFormContext();

    return (
        <>
            <div
                className={classNames(cellClass, { [styles.shadeRow]: isShadedRow})}
                key={`cell-visible-${value}`}
            >
                <Checkbox
                    name="visible[]"
                    value={value}
                    key={`visible-${value}`}
                    {...register("visible")}
                />
            </div>
            <div
                className={classNames(cellClass, { [styles.shadeRow]: isShadedRow})}
                key={`cell-notification-${value}`}
            >
                <Checkbox
                    name="notifications[]"
                    value={value}
                    key={`notification-${value}`}
                    {...register("notifications")}
                />
            </div>

        </>
    );
};

UserTicketSettings.propTypes = {
    cellClass: PropTypes.string,
    selected: PropTypes.object,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    isShadedRow: PropTypes.bool,
};

UserTicketSettings.defaultProps = {
    cellClass: "",
    value: "",
    selected: {},
    isShadedRow: false,
};

export default UserTicketSettings;
