import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import appReducer from "state/app-slice";
import userReducer from "state/user-slice";
import ticketsReducer from "state/tickets-slice";
import companyReducer from "state/company-slice";

const persistedReducers = persistReducer(
    {
        key: "root",
        storage,
    },
    combineReducers({
        app: appReducer,
        user: userReducer,
        tickets: ticketsReducer,
        company: companyReducer,
    })
);

const store = configureStore({
    reducer: persistedReducers,
    middleware: [thunk],
});

const persistor = persistStore(store);

export { store, persistor };
