import React from 'react';
import PropTypes from "prop-types";

import styles from './step-heading.module.scss';

/**
 * Renders the headings for steps
 *
 * @returns {React.Component} the step headings
 */
const StepHeading = ({ stepText, stepNumber }) => {
    /**
     * Renders the step headings
     *
     * @return {ReactElement} The step headings
     */
    return (
        <div data-testid="step-heading">
            <br className={styles.linebreak} />
            <h2 className={styles.stepHeading}>
                <strong>
                    {stepNumber}
                </strong>
                {stepText}
            </h2>
        </div>
    );
};

StepHeading.propTypes = {
    stepText: PropTypes.string,
    stepNumber: PropTypes.string,
};

StepHeading.defaultProps = {
    stepText: "",
    stepNumber: "",
};

export default StepHeading;
