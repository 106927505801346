import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import styles from './skeleton-text.module.scss';

/**
 * The Skeleton Text Component
 *
 * @param {object} props component props
 *
 * @returns {React.Component} SkeletonText
 */
const SkeletonText = (props) => (
    <div className={classNames(styles.loader, props.className)} data-testid="skeleton-text">
        <div className={classNames(styles.shimmer, props.shimmerClassName)} />
    </div>
);

SkeletonText.propTypes = {
    className: PropTypes.string,
    shimmerClassName: PropTypes.string,
};

SkeletonText.defaultProps = {
    className: '',
    shimmerClassName: '',
};

export default SkeletonText;
