import PropTypes from 'prop-types';
import React, { useCallback, useReducer } from 'react';
import { SnackbarHandler } from 'components';

let currentSnackbarId = 0;

const initialState = {
    snackbars: [],
};

const SnackbarContext = React.createContext(initialState);

/**
 * Updates the state with a new snackbar
 *
 * @param {object} prevState previous context state
 * @param {object} payload data to set on state
 * @returns {object} new state
 */
const addSnackbar = (prevState, payload) => ({
    ...prevState,
    snackbars: [...prevState.snackbars, payload.snackbar],
});

/**
 * Updates the state by removing a given snackbar
 *
 * @param {object} prevState previous context state
 * @param {object} payload data to set on state
 * @returns {object} new state
 */
const removeSnackbar = (prevState, payload) => {
    const filteredSnackbars = prevState.snackbars.filter((item) => (item.id !== payload.snackbar.id));

    return {
        ...prevState,
        snackbars: filteredSnackbars,
    };
};

/**
 * Resets the state back to its initial value
 *
 * @returns {object} initial state value
 */
const reset = () => initialState;

/**
 * Performs actions based on the payload type given
 *
 * @param {object} prevState previous context state
 * @param {object} payload data to set on state
 *
 * @returns {object} new state value
 */
const reducer = (prevState, payload) => {
    const { type } = payload;

    const actions = {
        RESET: () => reset(),
        ADD_SNACKBAR: () => addSnackbar(prevState, payload),
        REMOVE_SNACKBAR: () => removeSnackbar(prevState, payload),
    };

    return (actions[type])
        ? actions[type]()
        : { ...prevState, payload };
};

/**
 * Snackbar Context Provider Wrapper Component
 *
 * @param {object} props component props
 * @param {any} children child elements
 *
 * @returns {React.Component} SnackbarProvider Component
 */
const SnackbarProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const dispatchApp = useCallback(dispatch, [dispatch]);

    /**
     * Dispatches remove snackbar state action
     */
    const closeSnackbar = useCallback((snackbar) => {
        dispatchApp({ type: "REMOVE_SNACKBAR", snackbar });
    }, [dispatchApp]);

    /**
     * Dispatches add snackbar state action
     */
    const openSnackbar = useCallback((snackbar) => {
        snackbar.id = currentSnackbarId++;
        dispatchApp({ type: "ADD_SNACKBAR", snackbar });
    }, [dispatchApp]);

    return (
        <SnackbarContext.Provider
            value={{
                state,
                dispatchApp,
                openSnackbar,
                closeSnackbar,
            }}
        >
            <SnackbarHandler />
            { children }
        </SnackbarContext.Provider>
    );
};

SnackbarProvider.propTypes = {
    children: PropTypes.any.isRequired,
};

export {
    SnackbarProvider,
    SnackbarContext,
};
