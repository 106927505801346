import { useContext } from "react";
import { SnackbarContext } from "./snackbar-context";
import { NotificationContext } from "./notification-context";

/**
 * Gets an instance of the snackbar context
 *
 * @return {object} Snackbar Context
 */
export const useSnackbarContext = () => useContext(SnackbarContext);

/**
 * Gets an instance of the notification context
 *
 * @return {object} Notification Context
 */
export const useNotificationContext = () => useContext(NotificationContext);
