import React from "react";
import PropTypes from "prop-types";
import { EmployeesApi } from "lib/api-endpoints";
import { useNotificationContext } from "state/context";
import { ToggleSwitch } from "components/forms";
import ProfileSection from "./profile-section";

import styles from "./training-tab.module.scss";

/**
 * A component representing the Training tab content
 *
 * @param {object} props component props
 *
 * @returns {React.Component} Training tab component
 */
const TrainingTab = ({ employee, setRefresh }) => {
    const { openNotification } = useNotificationContext();

    const onToggle = (individualTraining) => {

        const { request } = EmployeesApi.editEmployeeTraining(
            employee.id,
            {
                "training_statuses": [{
                    'training_id': individualTraining.training_id,
                    'status': !individualTraining.status,
                }],
            }
        );

        request.then(({ data }) => openNotification({
            message: data.success,
            type: "success",
        }));

        setRefresh();
    };

    const mapTrainingToggle = (individualTraining) => {
        return (
            <div key={individualTraining.training_id}>
                <div className={styles.subTitle}>
                    {individualTraining.name}
                </div>
                <div className={styles.toggleContainer}>
                    <ToggleSwitch
                        name={individualTraining.name}
                        leftText="Incomplete"
                        rightText="Complete"
                        defaultChecked={individualTraining.status}
                        onClick={() => onToggle(individualTraining)}
                    />
                </div>
            </div>
        );
    };

    return (
        <ProfileSection>
            <div className={styles.sectionContainer}>
                <div className={styles.title}>
                    Training
                </div>
                {employee?.training_status ? employee.training_status.map(mapTrainingToggle) : null
                }
            </div>
        </ProfileSection>
    );
};

TrainingTab.propTypes = {
    employee: PropTypes.object,
    setRefresh: PropTypes.func.isRequired,
};

TrainingTab.defaultProps = {
    employee: {},
};

export default TrainingTab;
