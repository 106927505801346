import { React, useState } from "react";
import _ from 'lodash';
import { useForm, FormProvider } from "react-hook-form";
import SecurityApi from "lib/api-endpoints/security-api";
import { checkPasswordStrength } from "lib/helpers/password-strength-helpers";
import { Validation } from "components/icons";
import { useNotificationContext } from 'state/context';
import { PrimaryButton } from "components";
import { Form, PasswordInputRow } from "components/forms";

import styles from './components.module.scss';

const Password = () => {
    const { openNotification } = useNotificationContext();

    const [state, setState] = useState({
        validationState: {
            isLong: false,
            isStrong: false,
            lowercase: false,
            number: false,
            symbol: false,
            uppercase: false,
        },
        disabled: false,
    });

    const methods = useForm({
        defaultValues: {
            currentPassword: "",
            newPassword: "",
            newPasswordConfirm: "",
        },
    });
    const { register, handleSubmit, getValues, watch, reset } = methods;
    watch();

    const { currentPassword, newPassword, newPasswordConfirm } = getValues();

    const valid = (
        (Object.values(state.validationState).every((item) => item === true))
        && (newPassword === newPasswordConfirm)
        && (currentPassword.length > 0)
    );

    const checkValidity = (password) => {
        const validity = checkPasswordStrength(password);

        setState({
            ...state,
            validationState: validity,
        });

        return _.every(validity, (validityCheck) => Boolean(validityCheck));
    };

    /**
     * Handles the saving of changes once validated
     *
     * @param {event} event The form onSubmit event
     *
     * @return {void}
     */
    const onSubmit = (event) => {
        setState({
            ...state,
            disabled: true,
        });

        const { abortController } = SecurityApi.changePassword({
            "old_password": currentPassword,
            "password": newPassword,
            "password_confirm": newPasswordConfirm,
        }).request.then(() => {
            openNotification({
                message: "Password successfully changed",
                type: "success",
            });

            reset();

            setState({
                ...state,
                disabled: false,
            });
        }).catch(() => {
            setState({
                ...state,
                disabled: false,
            });
        });

        return () => abortController?.abort();
    };

    return (
        <div className={styles.container} data-testid="change password form">
            <FormProvider {...methods}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.passwordInputContainer} >
                        <PasswordInputRow
                            {...register("currentPassword")}
                            name="currentPassword"
                            label="Current password"
                            aria-label="current password"
                            large
                        />
                    </div>
                    <div className={styles.passwordInputContainer} >
                        <PasswordInputRow
                            {...register("newPassword", {
                                validate: {
                                    valid: (password) => {
                                        checkValidity(password);
                                    },
                                    same: (password) => (password === newPassword),
                                },
                            })}
                            name="newPassword"
                            label="New password"
                            aria-label="new password"
                            onChange={(event) => checkValidity(event.target.value)}
                            data-testid="new-password"
                            large
                        />
                    </div>
                    <div className={styles.validationContainer}>
                        <div className={styles.validationTitle}>
                            Password must be 8 or more characters, and contain all of the following:
                        </div>
                        <Validation label="8 or more characters long" isValid={state.validationState.isLong} />
                        <Validation label="Lowercase letter" isValid={state.validationState.lowercase} />
                        <Validation label="Uppercase letter" isValid={state.validationState.uppercase} />
                        <Validation label="Number" isValid={state.validationState.number} />
                        <Validation label="Symbol" isValid={state.validationState.symbol} />
                    </div>
                    <div className={styles.passwordInputContainer} >
                        <PasswordInputRow
                            {...register("newPasswordConfirm", {
                                validate: {
                                    valid: (password) => {
                                        checkValidity(password);
                                    },
                                    same: (password) => (password === newPassword),
                                },
                            })}
                            name="newPasswordConfirm"
                            label="New confirm password"
                            aria-label="new confirm password"
                            large
                        />
                    </div>
                    <PrimaryButton
                        type="submit"
                        disabled={!valid || state.disabled}
                        className={styles.saveButton}
                        onClick={() => handleSubmit(onSubmit)}
                    >
                        Save
                    </PrimaryButton>
                </Form>
            </FormProvider>
        </div>
    );
};

export default Password;
