import React, { useState } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { Card, CardHeader, Table } from "components/index";
import { usePayrollFiles } from "lib/api-endpoints";
import PeriodFileUploadDrawer from "pages/files/components/file-uploader/period-file-upload-drawer";
import TextButton from "components/buttons/text-button";
import DateDisplay from "components/date-display/date-display";
import { streamFileHandler } from "lib/downloaders/stream-file-handler";
import PayrollsApi from "lib/api-endpoints/payrolls-api";
import { periodPropTypes } from "lib/props-schema/period-schema";

const headings = {
    "filename": "name",
    "timestamp": "uploaded date",
    "uploaded_by": "uploaded by",
};

/**
 * Converts the given data array into data that's compatible with the table
 *
 * @param {array} files rest data
 *
 * @returns {array} table data
 */
const getFormattedData = (files) => files.map(({ id, filename, uploader, timestamp }) => (
    {
        id,
        "uploaded_by": `${uploader.forename} ${uploader.surname}`,
        timestamp: {
            component: (
                <DateDisplay
                    data={timestamp}
                    output="ddd, Do MMM"
                />
            ),
        },
        filename: {
            component: (
                <TextButton
                    aria-label={`Download ${filename}`}
                    onClick={() => streamFileHandler(PayrollsApi.getPayrollFileData(id).request, { filename })}
                >
                    {filename}
                </TextButton>
            ),
        },
    }
));

const PeriodFiles = ({ period, onUpload, refresh, padding }) => {
    const [page, setCurrentPage] = useState(1);

    const { files, isLoaded } = usePayrollFiles({
        period: period.id,
        excludeBatchFiles: 1,
        page,
        refresh,
    });

    const { data, meta } = files;

    return (
        <Card padding={padding}>
            <CardHeader title="Input">
                {!isEmpty(period)
                    && <PeriodFileUploadDrawer period={period.id} onSuccess={onUpload} buttonText="Upload" />
                }
            </CardHeader>
            <Table
                headings={headings}
                data={getFormattedData(data)}
                noDataText="No Companies"
                dataLoaded={isLoaded}
                skeletonConfig={{
                    rowCount: 6,
                    rowConfig: [
                        { key: 'period-files' },
                    ],
                }}
                pagination={{
                    totalPages: meta?.last_page,
                    currentPage: meta?.current_page,
                    onPageChange: setCurrentPage,
                }}
                data-testid="period-files"
            />
        </Card>
    );
};

PeriodFiles.defaultProps = {
    padding: true,
    refresh: false,
    onUpload: () => null,
};

PeriodFiles.propTypes = {
    period: periodPropTypes.isRequired,
    padding: PropTypes.bool,
    onUpload: PropTypes.func,
    refresh: PropTypes.bool,
};

export default PeriodFiles;
