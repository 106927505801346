import React, { useState } from 'react';
import PropTypes from "prop-types";
import { noop } from 'lodash';
import Drawer from 'components/drawer/drawer';
import { Button } from 'components/forms';
import DrawerTitle from 'components/drawer-title/drawer-title';
import DocumentUploadForm from './document-upload-form';

/**
 * Renders the document upload drawer and button
 *
 * @returns {React.Component} The document upload drawer and button
 */
const DocumentUploadDrawer = ({ employee, reloadPage }) => {
    const [openDrawer, setOpenDrawer] = useState(false);

    return (
        <>
            <Button onClick={() => setOpenDrawer(true)} data-testid={"document_upload_button"}>
                Upload
            </Button>
            <Drawer
                isOpen={openDrawer}
                isLoading={false}
                headerContent={<DrawerTitle>Upload document</DrawerTitle>}
                onClose={() => setOpenDrawer(false)}
                onOutsideClick={() => setOpenDrawer(false)}
                padding={false}
                ariaLabel="document upload drawer"
            >
                <DocumentUploadForm
                    onClose={() => setOpenDrawer(false)}
                    employee={employee}
                    reloadPage={reloadPage}
                />
            </Drawer>
        </>
    );
};

DocumentUploadDrawer.propTypes = {
    employee: PropTypes.object.isRequired,
    reloadPage: PropTypes.func,
};

DocumentUploadDrawer.defaultProps = {
    reloadPage: noop,
};

export default DocumentUploadDrawer;
