import CompanyCrudPermissions from './company-crud-permissions';
import CompanyDepartmentsPermissions from './company-departments-permissions';
import CompanyDivisionsPermissions from './company-divisions-permissions';
import CompanyEmployeesPermissions from './company-employees-permissions';
import CompanySitesPermissions from './company-sites-permissions';
import CompanyTroncSchemesPermissions from './company-tronc-schemes-permissions';
import CompanyPeriodFilesPermissions from "./company-period-files-permissions";

export default class CompanyPermissions {

    /**
     * Company Permissions constructor
     *
     * @param {array} permissions The company permissions
     * @param {boolean} troncmaster Is the user a troncmaster
     */
    constructor (permissions, troncmaster) {
        Object.assign(this, permissions);
        this.sites = new CompanySitesPermissions(permissions, troncmaster);
        this.departments = new CompanyDepartmentsPermissions(permissions, troncmaster);
        this.divisions = new CompanyDivisionsPermissions(permissions, troncmaster);
        this.employees = new CompanyEmployeesPermissions(permissions, troncmaster);
        this.troncSchemes = new CompanyTroncSchemesPermissions(permissions, troncmaster);
        this.company = new CompanyCrudPermissions(permissions, troncmaster);
        this.periodFiles = new CompanyPeriodFilesPermissions(permissions, troncmaster);
    }

    /**
     * Gets a list of company permissions to register
     *
     * @returns {Permissions[]} An array containing permission policies
     */
    getPermissionList () {
        const { sites, departments, divisions, employees, troncSchemes, company, periodFiles } = this;

        return [
            sites,
            departments,
            divisions,
            employees,
            troncSchemes,
            company,
            periodFiles,
        ];
    }

}
