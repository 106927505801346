import React from 'react';

/**
 * Renders cancel icon
 *
 * @param {object} props Props for the svg element
 * @returns {React.Component} Cancel icon
 */
const Cancel = (props) => (
    <svg data-testid="cancel-icon" viewBox="0 0 48 48" {...props}>
        <path d="M15.45 32.55q.45.45 1.05.45.6 0 1.05-.45L24 26.1l6.5 6.5q.4.4 1.025.375.625-.025
            1.025-.425.45-.45.45-1.05 0-.6-.45-1.05L26.1 24l6.5-6.5q.4-.4.375-1.025-.025-.625-.425-1.025Q32.1
            15 31.5 15q-.6 0-1.05.45L24 21.9l-6.5-6.5q-.4-.4-1.025-.375-.625.025-1.025.425-.45.45-.45 1.05 0 .6.45
            1.05L21.9 24l-6.5 6.5q-.4.4-.375 1.025.025.625.425 1.025ZM24 44q-4.25
            0-7.9-1.525-3.65-1.525-6.35-4.225-2.7-2.7-4.225-6.35Q4 28.25 4 24q0-4.2 1.525-7.85Q7.05
            12.5 9.75 9.8q2.7-2.7 6.35-4.25Q19.75 4 24 4q4.2 0 7.85 1.55Q35.5 7.1 38.2 9.8q2.7 2.7 4.25
            6.35Q44 19.8 44 24q0 4.25-1.55 7.9-1.55 3.65-4.25 6.35-2.7 2.7-6.35 4.225Q28.2 44 24 44Z"
        />
    </svg>
);

export default Cancel;
