/**
 * Checks the length of the password
 *
 * @param {string} password The password to check
 *
 * @return {bool} If the password is long enough
 */
const checkIsLong = (password) => {
    return password.length >= 8;
};

/**
 * Checks the password has a lower case character
 *
 * @param {string} password The password to check
 *
 * @return {bool} If the password has a lower case character
 */
const checkHasLowercase = (password) => {
    const lowercaseRegEx = new RegExp(/[a-z]/);

    return lowercaseRegEx.test(password);
};

/**
 * Checks the password has an upper case character
 *
 * @param {string} password The password to check
 *
 * @return {bool} If the password has an upper case character
 */
const checkHasUppercase = (password) => {
    const uppercaseRegEx = new RegExp(/[A-Z]/);

    return uppercaseRegEx.test(password);
};

/**
 * Checks the password has a numerical value
 *
 * @param {string} password The password to check
 *
 * @return {bool} If the password has a numerical value
 */
const checkHasNumber = (password) => {
    const numberRegEx = new RegExp(/\d/);

    return numberRegEx.test(password);
};

/**
 * Checks the password has a symbol
 *
 * @param {string} password The password to check
 *
 * @return {bool} If the password has a symbol
 */
const checkHasSymbol = (password) => {
    const symbolRegEx = new RegExp(/[^a-zA-Z\d]/);

    return symbolRegEx.test(password);
};

/**
 * Check the password is strong based on the criteria of the other checks
 *
 * @param {object} results The results of the strength checks
 *
 * @return {bool} If the password is considered strong or not
 */
const checkIsStrong = (results) => {
    return Object.keys(results).every((key) => results[key] === true);
};

/**
 * Checks if the password is strong against the defined criteria
 *
 * @param {string} password The password to check
 *
 * @return {object} The criteria with corresponding boolean values
 */
export const checkPasswordStrength = (password) => {
    let results = {
        isLong: checkIsLong(password),
        lowercase: checkHasLowercase(password),
        uppercase: checkHasUppercase(password),
        number: checkHasNumber(password),
        symbol: checkHasSymbol(password),
    };

    return {
        isStrong: checkIsStrong(results),
        isLong: results.isLong,
        lowercase: results.lowercase,
        uppercase: results.uppercase,
        number: results.number,
        symbol: results.symbol,
    };
};
