import Permissions from "./permissions";

export default class CompanySitesPermissions extends Permissions {

    /**
     * @inheritDoc
     */
    register () {
        const { troncmaster, permissions } = this;
        const viewSites = troncmaster || permissions.includes("view_sites");
        const editSites = troncmaster || permissions.includes("edit_sites");
        const createSites = troncmaster || permissions.includes("create_sites");
        const deleteSites = troncmaster || permissions.includes("delete_sites");

        this.canView = viewSites;
        this.canEdit = editSites;
        this.canCreate = createSites;
        this.canDelete = deleteSites;
    }

}
