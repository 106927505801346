import Permissions from "./permissions";

export default class PayrollEmployeePermissions extends Permissions {

    /**
     * @inheritDoc
     */
    register () {
        this.canView = this.permissions.some((permission) => [
            "employee_admin",
            "view_files",
            "epayslips_admin",
        ].includes(permission));
    }

}
