import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useUsersList from 'lib/api-endpoints/hooks/users-list';
import TableInputRow from 'components/forms/table-input-row';
import { debounce } from 'lodash';

import styles from './user-table.module.scss';

/**
 * Renders table of users given a payroll id
 *
 * @param {object} props component props
 * @param {array} props.additionalHeaders string array of extra table headers
 * @param {function} props.config Component import for additional columns
 * @param {integer} props.payrollId the payroll id to get the users
 *
 * @returns {React.Component} UserTable Component
 */
const UserTable = ({ additionalHeaders, config, payrollId, ...otherProps }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(1);
    const { data, meta } = useUsersList({ payrollId, page, searchTerm });

    /**
     * Debounces prop onSearch call when typing in the search
     */
    const callOnSearch = debounce((value) => {
        setSearchTerm(value);

        if (value.length >= 3) {
            setPage(1);
        }
    }, 500);

    /**
     * Search Box onChange Handler
     *
     * @param {object} e onChange event
     */
    const handleSearch = (e) => {
        callOnSearch(e?.target?.value);
    };

    /**
     * Processes user data to have searchable name property
     *
     * @returns {array} array of user objects
     */
    const processedData = data?.map((user) => ({
        ...user,
        name: `${user.forename} ${user.surname}`,
    }));

    return (
        <TableInputRow
            data={processedData}
            config={config}
            headers={["name", ...additionalHeaders]}
            onSearch={handleSearch}
            tableClassName={styles.tableAlign}
            gridTemplateCols="2fr 1fr 1fr"
            pagination={{
                currentPage: meta?.current_page,
                totalPages: meta?.last_page,
                onPageChange: setPage,
            }}
            searchable
            {...otherProps}
        />
    );
};

UserTable.propTypes = {
    additionalHeaders: PropTypes.array,
    config: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
        PropTypes.object,
        PropTypes.oneOf([null]),
    ]),
    payrollId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ])),
    ]).isRequired,
};

UserTable.defaultProps = {
    additionalHeaders: [],
    config: null,
};

export default UserTable;
