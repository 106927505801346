import { api } from "lib/api";

export default class CompanyJobRolesApi {

    /**
     * Gets the job roles request promise
     *
     * @param {int} id The company id
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getJobRoles = (id) => api({ url: `/company/${id}/job_roles?archived=0` });

    /**
     * Add the job roles request promise
     *
     * @param {int} id The company id
     * @param {object} data The data to add
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static addJobRoles = (id, data) => api({ type: 'post', url: `/company/${id}/job_roles`, data });

    /**
     * Edit the job roles request promise
     *
     * @param {int} id The company id
     * @param {int} roleId The role id
     * @param {object} data The data to edit
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static editJobRoles = (id, roleId, data) => api({ type: 'patch', url: `/company/${id}/job_roles/${roleId}`, data });

}
