import React from "react";

const Files = (props) => (
    <svg
        width="21"
        height="16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M2 16c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 0 14V2C0 1.45.196.98.588.588A1.923
            1.923 0 0 1 2 0h5.175a1.978 1.978 0 0 1 1.4.575L10 2h8c.55 0 1.021.196 1.413.588.391.391.587.862.587
            1.412H9.175l-2-2H2v12l1.975-6.575c.133-.433.38-.78.738-1.038A1.984 1.984 0 0 1 5.9 6h12.9c.683 0
            1.221.27 1.613.812.391.542.495 1.13.312 1.763l-1.8 6c-.133.433-.379.78-.737 1.038A1.989 1.989 0 0 1
            17 16H2Zm2.1-2H17l1.8-6H5.9l-1.8 6Zm0 0 1.8-6-1.8 6ZM2 6V2v4Z"
            fill="#fff"
        />
    </svg>
);

export default Files;
