import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEmpty, noop, parseInt } from 'lodash';
import { CompaniesApi, CompanyJobRolesApi, SchemeJobRolesApi, TroncSchemesApi } from 'lib/api-endpoints';
import { MultipartForm } from 'components';
import TroncSchemeDetailsForm from './tronc-schemes-details-form';
import TroncSchemeFeesForm from './tronc-schemes-fees-form';
import TroncSchemeDistributionForm from './tronc-scheme-distribution-form';
import TroncSchemeJobRolesForm from './tronc-schemes-job-roles-form';
import TroncSchemeTrainingForm from './tronc-schemes-training-form';
import TroncSchemeLengthOfServiceForm from './tronc-schemes-length-of-service-form';
import { selectCompany } from 'state/app-slice';

/**
 * Helper: gets the default values for the scheme
 *
 * @param {object} data The current scheme
 * @param {object} schemeJobRoles The scheme job roles
 * @param {object} trainingState The scheme training roles
 *
 * @return {object} The default values
 */
const getDefaultValues = (data, schemeJobRoles, trainingState) => {
    const {
        "troncs_reserve_type": troncsReserveType,
        "troncs_reserve_percentage": troncsReservePercentage,
        "troncs_reserve_fixed_amount": troncsReserveFixedAmount,
        "card_transaction_fee_type": transactionFeeType,
        "card_transaction_fee_percentage": feePercentage,
        "card_transaction_fee_fixed_amount": feeFixedAmount,
        "distribution_frequency": distributionFrequency,
        "tronc_points_training": troncSchemeTrainingOptIn,
        "tronc_points_job_role": troncSchemeJobRoleOptIn,
        "tronc_points_length_of_service": troncSchemeLengthOfServiceOptIn,
        ...otherData
    } = data;

    const defaultTrainingOptions = trainingState.companyTrainingRoles.map((role) => {
        return {
            "complete_points": 0,
            "incomplete_points": 0,
            "training_id": role.id,
            name: role.name,
        };
    });

    const [reserveKey, reserveValue] = getDefaultPercentageAmountKeyValue(
        troncsReserveType,
        ["troncs_reserve_percentage", String(troncsReservePercentage)],
        ["troncs_reserve_fixed_amount", String(troncsReserveFixedAmount !== undefined ? troncsReserveFixedAmount : 0)],
    );

    const [transactionFeeKey, transactionFeeValue] = getDefaultPercentageAmountKeyValue(
        transactionFeeType,
        ["card_transaction_fee_percentage", String(feePercentage)],
        ["card_transaction_fee_fixed_amount", String(feeFixedAmount !== undefined ? feeFixedAmount : 0)],
    );

    return {
        ...otherData,
        "troncs_reserve_type": troncsReserveType,
        "card_transaction_fee_type": transactionFeeType,
        "distribution_frequency_type": distributionFrequency?.frequency_type !== undefined
            ? distributionFrequency.frequency_type : "daily",
        [reserveKey]: reserveValue,
        [transactionFeeKey]: transactionFeeValue,
        troncSchemeTrainingOptIn,
        troncSchemeJobRoleOptIn,
        troncSchemeLengthOfServiceOptIn,
        schemeJobRoles,
        schemeTrainingRoles: (trainingState.schemeTrainingRoles)
            ? trainingState.schemeTrainingRoles
            : defaultTrainingOptions,
    };
};

/**
 * Returns the correct key and value to include in the default form data
 *
 * @param {string} fieldType The current type
 * @param {array} percentageKeyValue The key and value for the percentage field
 * @param {array} amountKeyValue The key and value for the amount field
 *
 * @return {object} The default values
 */
const getDefaultPercentageAmountKeyValue = (fieldType, percentageKeyValue, amountKeyValue) => {
    const isPercentage = fieldType === 'percentage';

    return isPercentage ? percentageKeyValue : amountKeyValue;
};

/**
 * Ensures the value passes validation if both linked form fields have been emptied
 *
 * @param {number} val The value to format
 * @param {number} linkedVal The linked value to check
 *
 * @return {number|string} Either the original numerical value or the string "0.00"
*/
const formatLinkedValuesToSubmit = (val, linkedVal) => {
    if (isEmpty(val) && isEmpty(linkedVal)) {
        return "0.00";
    }

    return val;
};

const processPercentageAmountFields = (fields) => (
    fields.map((field) => ({
        key: field[field.type].key,
        value: field[field.type].value,
    }))
);

/**
 * Gets the correct type to be used for reserve and transaction fee
 *
 * @param {string} fixed The fixed value
 * @param {string} percentage The percentage value
 *
 * @returns {string} The type
 */
const getType = (fixed, percentage) => {
    return (fixed && Number(percentage) <= 0) ? 'fixed_amount' : 'percentage';
};

/**
 * Handles the api calls and on submission function
 *
 * @param {object} scheme the current scheme being edited
 * @param {object} processedData the data in the format for the api call
 * @param {number} department the department id
 * @param {object} formData the form contexr data
 * @param {func} onSubmit the function to call on submit
 */
const handleCalls = async (scheme, processedData, department, formData, onSubmit) => {
    const { data } = await TroncSchemesApi.patchTroncScheme(scheme?.id, processedData).request;

    const mappedLOS = formData.schemeLengthOfService.map((LOS) => ({
        "tronc_points": parseFloat(LOS.lengthOfServicePoints),
        "time_span": parseInt(LOS.lengthOfServiceMonths),
    }));

    if (data.tronc_points_training) {
        TroncSchemesApi.setSchemeTraining(scheme?.id, {
            "points": formData.schemeTrainingRoles,
        });
    }

    if (data.tronc_points_job_role) {
        SchemeJobRolesApi.addJobRoles(scheme?.id, [{
            "department_id": department,
            "job_roles": formData.schemeJobRoles,
        }]);
    }

    if (data.tronc_points_length_of_service) {
        TroncSchemesApi.addLengthOfServicePoints(scheme?.id, {
            "points": mappedLOS,
        });
    }

    onSubmit();
};

const handleOnSubmit = (data, scheme, onSubmit, department) => {
    const {
        troncs_reserve_percentage: reservePercentage,
        troncs_reserve_fixed_amount: troncsReserveFixedAmount,
        troncmasters_fee_percentage: troncmasterFee,
        card_transaction_fee_fixed_amount: transactionFeeFixedAmount,
        card_transaction_fee_percentage: transactionFeePercentage,
        apply_tip_split_to_departments: splitToDepartments,
        apply_tip_split_to_divisions: splitToDivisions,
        distribution_frequency_type: distributionFrequencyType,
        troncSchemeTrainingOptIn,
        troncSchemeJobRoleOptIn,
        troncSchemeLengthOfServiceOptIn,
        ...otherData
    } = data;

    const reserveType = getType(troncsReserveFixedAmount, reservePercentage);
    const transactionFeeType = getType(transactionFeeFixedAmount, transactionFeePercentage);

    const processedData = {
        ...otherData,
        "apply_tip_split_to_divisions": splitToDivisions,
        "apply_tip_split_to_departments": splitToDepartments,
        "troncmasters_fee_percentage": String(troncmasterFee),
        "troncs_reserve_type": reserveType,
        "card_transaction_fee_type": transactionFeeType,
        "employee_time_bucket": distributionFrequencyType,
        "divisions": [
            {
                "name": "Default",
                "split_percentage": "100",
                "departments": [
                    {
                        "name": "Default",
                        "split_percentage": "100",
                    },
                ],
            },
        ],
        "tronc_points_training": troncSchemeTrainingOptIn,
        "tronc_points_job_role": troncSchemeJobRoleOptIn,
        "tronc_points_length_of_service": troncSchemeLengthOfServiceOptIn,
    };

    const processedPercentageAmountFields = processPercentageAmountFields([
        {
            type: reserveType,
            percentage: {
                key: 'troncs_reserve_percentage',
                value: formatLinkedValuesToSubmit(reservePercentage, troncsReserveFixedAmount),
            },
            "fixed_amount": {
                key: 'troncs_reserve_fixed_amount',
                value: formatLinkedValuesToSubmit(troncsReserveFixedAmount, reservePercentage),
            },
        },
        {
            type: transactionFeeType,
            percentage: {
                key: 'card_transaction_fee_percentage',
                value: formatLinkedValuesToSubmit(transactionFeePercentage, transactionFeeFixedAmount),
            },
            "fixed_amount": {
                key: 'card_transaction_fee_fixed_amount',
                value: formatLinkedValuesToSubmit(transactionFeeFixedAmount, transactionFeePercentage),
            },
        },
    ]);

    processedPercentageAmountFields.forEach((field) => {
        processedData[field.key] = field.value;
    });

    handleCalls(scheme, processedData, department, data, onSubmit);
};

/**
 * Drawer for creating a tronc scheme
 *
 * @param {object} props component props
 * @param {number} companyId the company id to get schemes for
 * @param {function} onCancel form cancel handler
 * @param {function} setShowForm used to auto hide the form when required
 * @param {boolean} showForm if the form is shown or not
 *
 * @returns {React.Component} TroncSchemeDrawer Component
 */
const TroncSchemeEditDrawer = ({
    companyId,
    onCancel,
    setShowForm,
    showForm,
    onSubmit,
    scheme,
    handleTransitionEnd,
}) => {
    const { app } = useSelector((state) => state);
    const [loading, setLoading] = useState(true);
    const [jobRoleState, setJobRoleState] = useState({
        schemeJobRoles: [],
        companyJobRoles: [],
        department: "",
    });
    const [trainingState, setTrainingState] = useState({
        schemeTrainingRoles: [],
        companyTrainingRoles: [],
    });

    /**
     * Finds current company and gets relevant permissions
     *
     * @returns {object} selected company and permissions
     */
    const {
        ...selectedCompany
    } = useMemo(() => selectCompany(app, companyId), [app, companyId]);

    useEffect(() => {
        setLoading(true);

        const setStates = (responses) => {
            setTrainingState({
                schemeTrainingRoles: responses[0].data.training_tronc_points,
                companyTrainingRoles: responses[1].data,
            });
            setJobRoleState({
                schemeJobRoles: responses[2].data.data[0].job_roles,
                companyJobRoles: responses[3].data.data.map((role) => {
                    return ({...role, "job_role_id": role.id});
                }),
                department: responses[2].data.data[0].department_id,
            });
            setLoading(false);
        };

        if (selectedCompany.id && scheme.id) {
            Promise.all([
                TroncSchemesApi.getTroncScheme(scheme?.id).request,
                CompaniesApi.getCompanyTraining(selectedCompany.id).request,
                SchemeJobRolesApi.getJobRoles(scheme.id).request,
                CompanyJobRolesApi.getJobRoles(selectedCompany.id).request,
            ]).then((responses) => {
                setStates(responses);
            });
        }

    }, [scheme, selectedCompany.id, showForm]);

    if (loading) {
        return null;
    }

    return (
        <MultipartForm
            key={scheme?.id}
            footerAlignment="right"
            formTitle={`Edit a scheme`}
            mode="drawer"
            padding={false}
            onClose={onCancel}
            onSubmit={(data) => handleOnSubmit(data, scheme, onSubmit, jobRoleState.department)}
            setShowForm={setShowForm}
            showForm={showForm}
            stepper={false}
            submitText={`Edit Scheme`}
            defaultValues={getDefaultValues(scheme, jobRoleState.schemeJobRoles, trainingState)}
            handleTransitionEnd={handleTransitionEnd}
            config={[
                {
                    stepNum: 1,
                    component: TroncSchemeDetailsForm,
                    props: { companyId, isEdit: true },
                },
                {
                    stepNum: 2,
                    component: TroncSchemeFeesForm,
                    props: { isEdit: true },
                },
                {
                    stepNum: 3,
                    component: TroncSchemeDistributionForm,
                    props: { isEdit: true },
                },
                {
                    stepNum: 4,
                    component: TroncSchemeTrainingForm,
                    props: { isEdit: true },
                },
                {
                    stepNum: 5,
                    component: TroncSchemeJobRolesForm,
                    props: { isEdit: true, companyJobRoles: jobRoleState.companyJobRoles },
                },
                {
                    stepNum: 6,
                    component: TroncSchemeLengthOfServiceForm,
                    props: { isEdit: true, scheme },
                },
            ]}
        />

    );
};

TroncSchemeEditDrawer.propTypes = {
    onCancel: PropTypes.func,
    setShowForm: PropTypes.func,
    showForm: PropTypes.bool,
    onSubmit: PropTypes.func,
    scheme: PropTypes.object,
    handleTransitionEnd: PropTypes.func,
    companyId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
};

TroncSchemeEditDrawer.defaultProps = {
    onCancel: noop,
    onSubmit: noop,
    setShowForm: noop,
    handleTransitionEnd: noop,
    showForm: false,
    scheme: {},
};

export default TroncSchemeEditDrawer;
