import React, { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { InformationIcon, CloseButton } from 'components';
import { useNotificationContext } from 'state/context';

import styles from './notification.module.scss';

/**
 * Renders a notification box with heading and text, styled based on the type
 *
 * @param {object} props component props
 * @param {string} prop.heading heading title
 * @param {integer} prop.id notification id
 * @param {string} prop.message the notification text
 * @param {function} prop.onClose close notification handler
 * @param {string} prop.type notification type
 *
 * @returns {React.Component} Notification Component
 */
const Notification = ({ attributes, heading, id, message, onClose, type, duration }) => {
    const { closeNotification } = useNotificationContext();

    const classList = classNames([
        styles.notificationContainer,
        styles[type],
    ]);

    const iconClass = classNames([
        styles.typeIcon,
        styles.iconClass,
        styles[type],
    ]);

    const confirmationClass = classNames([
        styles.confirmationInner,
        styles[type],
    ]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            closeNotification({ id });
        }, duration);

        return () => {
            clearTimeout(timeout);
        };
    }, [id, duration, closeNotification]);

    return (
        <div key={`notification-${id}`} className={classList} {...attributes} data-testid="notification">
            <div className={confirmationClass}>
                <InformationIcon type={type} className={iconClass} />
                <div className={styles.message}>
                    { heading && (<h3>{heading}</h3>) }
                    {message}
                </div>
                <div className={styles.actions}>
                    <CloseButton
                        onClick={onClose}
                        type={type}
                        iconClass={iconClass}
                    />
                </div>
            </div>
        </div>
    );
};

Notification.propTypes = {
    attributes: PropTypes.object,
    heading: PropTypes.string,
    id: PropTypes.number.isRequired,
    message: PropTypes.string,
    onClose: PropTypes.func,
    type: PropTypes.oneOf([
        "warn",
        "success",
        "error",
        "info",
    ]),
    duration: PropTypes.number,
};

Notification.defaultProps = {
    attributes: {},
    heading: null,
    message: "",
    onClose: noop,
    type: "info",
    duration: 5000,
};

export default Notification;
