import React, { useCallback, useEffect, useState } from "react";
import { find, parseInt } from "lodash";
import { Switcher } from "components";
import PropTypes from "prop-types";
import periodPropTypes, { periodDefaultProps } from "lib/props-schema/period-schema";
import periodsPropTypes, { periodsDefaultProps } from "lib/props-schema/periods-schema";

/**
 * Renders the PeriodSwitcher
 *
 * @param {array} periods an array of periods
 * @param {object} currentPeriod The selected period
 * @param {function} onChange Handles the period change
 *
 * @returns {React.Component} PeriodSwitcher
 */
const PeriodSwitcher = ({ periods, currentPeriod, onChange }) => {
    const [period, setPeriod] = useState(currentPeriod);

    useEffect(() => {
        if (period !== currentPeriod) {
            setPeriod(currentPeriod);
        }
    }, [period, currentPeriod]);

    const changePeriod = useCallback((newPeriod) => {
        setPeriod(newPeriod);
    }, [setPeriod]);

    const findPeriodData = useCallback(({ value }) => {
        const selectedPeriod = find(periods, { id: parseInt(value)});

        changePeriod(selectedPeriod);
        onChange(selectedPeriod);
    }, [periods, changePeriod, onChange]);

    const switcherOptions = periods.map((payrollPeriod) => ({
        value: payrollPeriod.id,
        display: `Period ${payrollPeriod.period_number} ${payrollPeriod.period_name}, ${payrollPeriod.year}`,
    }));

    const { "period_number": periodNumber, "period_name": periodName, year } = period;

    return (
        <Switcher
            options={switcherOptions}
            value={period.id}
            onChange={findPeriodData}
            getContent={() => `Period ${periodNumber} ${periodName}, ${year}`}
        />
    );
};

PeriodSwitcher.propTypes = {
    periods: periodsPropTypes,
    currentPeriod: periodPropTypes,
    onChange: PropTypes.func,
};

PeriodSwitcher.defaultProps = {
    periods: periodsDefaultProps,
    currentPeriod: periodDefaultProps,
    onChange: () => null,
};

export default PeriodSwitcher;
