import React from 'react';
import { Page, TabSection, LayoutContainer } from 'components';
import UserManagementTable from './user-managment-table';

import styles from './user-management.module.scss';

/**
 * Sets up the user management page
 *
 * @returns {React.Component} The user management page
 */
const UserManagement = () => (
    <Page title="User Management">
        <LayoutContainer className={styles.containerPadding}>
            <TabSection
                tabs={[
                    {
                        title: "All",
                        route: "all-users",
                        content: <UserManagementTable />,
                    },
                    {
                        title: "Active",
                        route: "active",
                        content: <UserManagementTable userActive />,
                    },
                    {
                        title: "Archived",
                        route: "archived",
                        content: <UserManagementTable userActive={false} />,
                    },
                ]}
            />
        </LayoutContainer>
    </Page>
);

export default UserManagement;
