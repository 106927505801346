import React, { useState } from "react";
import PropTypes from "prop-types";
import { head, noop } from "lodash";
import { TroncsPeriodsApi } from "lib/api-endpoints";
import { useNotificationContext } from "state/context";
import { MultipartForm } from "components";
import TroncImportForm from "./tronc-import-form";

/**
 * Renders the reprocess file drawer for a selected scheme
 *
 * @param {object} scheme The selected scheme
 * @param {boolean} showForm Display the form or not
 * @param {function} setShowForm Handle displaying the form
 *
 * @returns {React.Component} Renders the reprocess file drawer for a selected scheme
 */
const TroncReprocessFilesDrawer = ({ scheme, showForm, setShowForm }) => {
    const [csvUploadFeedback, setCsvUploadFeedback] = useState([]);
    const [clearCustomErrors, setClearCustomErrors] = useState(false);
    const { openNotification } = useNotificationContext();
    const [allowSuccess, setAllowSuccess] = useState(false);

    /**
     * Handles the form success case, clears any custom error handling, notifies user
     *
     * @returns {void}
     */
    const onSuccess = () => {
        setAllowSuccess(true);
        setCsvUploadFeedback([]);
        setClearCustomErrors(true);

        openNotification({
            message: "TRONCS input successful",
            type: "success",
        });
    };

    /**
     * Handles the csv upload error response, formats for react hook form setError
     *
     * @param {object} err The error response
     *
     * @returns {void}
     */
    const onCsvError = (err) => {
        const uploadFeedbackList = Object.values(err?.response?.data.messages).map(([val]) => head(val));

        const formattedFeedbackList = [{
            type: "custom",
            message: uploadFeedbackList,
        }];

        setClearCustomErrors(false);
        setCsvUploadFeedback(formattedFeedbackList);
    };

    /**
     * Tries to upload the employee hours / troncs amount
     *
     * @param {array} data The form data
     *
     * @returns {void}
     */
    const onSubmit = async (data) => {
        setCsvUploadFeedback([]);
        const formData = new FormData();
        formData.append("hours", Array.from(data.file_input)[0]);

        try {
            await TroncsPeriodsApi.uploadEmployeeHoursFiles(scheme.currentPeriod.id, formData).request;

            const troncAmounts = {
                "hour_buckets": [{ // eslint-disable-line camelcase
                    "tronc_period_id": scheme.currentPeriod.id, // eslint-disable-line camelcase
                    "amount": data.tronc_fund_total, // eslint-disable-line camelcase
                }],
            };

            await TroncsPeriodsApi.uploadTroncAmount(scheme.currentPeriod.id, troncAmounts).request;

            onSuccess();
        } catch (err) {
            onCsvError(err);
        }
    };

    return (
        <MultipartForm
            defaultValues={{ "tronc_fund_total": "0.00" }}
            footerAlignment="right"
            mode="drawer"
            setShowForm={setShowForm}
            showForm={showForm}
            stepper={false}
            onSubmit={onSubmit}
            submitText="Save"
            padding={false}
            customErrors={csvUploadFeedback}
            clearCustomErrors={clearCustomErrors}
            setClearCustomErrors={setClearCustomErrors}
            allowSuccess={allowSuccess}
            setAllowSuccess={setAllowSuccess}
            config={[
                {
                    stepNum: 1,
                    /* eslint-disable-next-line camelcase */
                    title: `File Import - Period ${scheme.currentPeriod.period_number}`,
                    component: TroncImportForm,
                    props: { periodId: scheme.currentPeriod.id, setCsvUploadFeedback },
                },
            ]}
        />
    );
};

TroncReprocessFilesDrawer.propTypes = {
    showForm: PropTypes.bool,
    setShowForm: PropTypes.func,
    scheme: PropTypes.object,
};

TroncReprocessFilesDrawer.defaultProps = {
    showForm: false,
    setShowForm: noop,
    scheme: null,
};

export default TroncReprocessFilesDrawer;

