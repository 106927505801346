import React, { useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { routePaths } from "routes";
import { Bell } from "components/icons";
import CountDisplay from "components/count-display/count-display";
import { getUpdates } from "state/app-slice";

import styles from "./updates-menu.module.scss";

/**
 * Renders the updates/resources button and applies a timed api call to fetch new updates
 *
 * @returns {React.Component|null} Renders the UpdatesMenu component
 */
const UpdatesMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const minuteTimer = 60000;

        const interval = setInterval(
            () => dispatch(getUpdates()),
            minuteTimer
        );

        return () => clearInterval(interval);
    }, [dispatch]);

    return (
        <button
            onClick={() => navigate(generatePath(routePaths.updates))}
            className={styles.userContainer}
            type="button"
            aria-label="updates"
        >
            <CountDisplay />
            <Bell />
        </button>
    );
};

export default UpdatesMenu;
