import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { SelectInputRow } from "components/forms";

/**
 * Renders the companies options
 *
 * @param {object} props The companies options props
 * @param {array} props.companies The companies
 *
 * @returns {React.Component} The companies selection component
 */
const CompanyOptions = forwardRef(({ companies, ...otherProps }, ref) => {
    const options = companies.map(({ id, name }) => ({
        value: id,
        display: name,
    }));

    return (
        <SelectInputRow
            data-testid="company-options"
            aria-label="Company options"
            ref={ref}
            label="Companies"
            options={options}
            {...otherProps}
        />
    );
});

CompanyOptions.propTypes = {
    companies: PropTypes.array.isRequired,
};

export default CompanyOptions;
