import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isObject, noop } from 'lodash';
import { useParams } from 'react-router-dom';
import useTroncSchemesList from 'lib/api-endpoints/hooks/tronc-schemes-list';
import TroncSchemeAddDrawer from './components/tronc-scheme-add-drawer.js';
import TroncSchemeEditDrawer from './components/tronc-scheme-edit-drawer.js';
import TroncReportDrawer from 'pages/tronc-schemes/tronc-report-drawer/tronc-report-drawer';
import TroncReprocessFilesDrawer from 'pages/tronc-schemes/tronc-reprocess-files-drawer.js';
import { CreateButton, LoadingSpinner } from 'components';
import SchemesCardList from './components/schemes-card-list.js';
import NoDataCard from 'components/no-data-card/no-data-card';

import styles from './schemes.module.scss';

const initialState = {
    scheme: {},
    isOpen: false,
    editMode: false,
};

/**
 * Form Submit Handler - toggles refresh data flag and closes the drawer
 *
 * @param {bool} refresh whether to refresh scheme or not
 * @param {func} refreshData refreshes the data via state
 * @param {func} setDrawer set drawer open or close
 */
const handleOnSubmit = (refresh, refreshData, setDrawer) => {
    refreshData(!refresh);
    setDrawer({
        editDrawer: false,
        addDrawer: false,
    });
};

/**
 * Renders the schemes page
 *
 * @returns {React.Component} Schemes Component
 */
const Schemes = ({ setDrawer, isDrawerOpen, permissions }) => {
    const [refresh, refreshData] = useState(false);
    const [reportDrawer, setReportDrawer] = useState({ isOpen: false, currentScheme: {} });
    const [reprocessScheme, setReprocessScheme] = useState(null);
    const { id: companyId } = useParams();
    const [formState, setFormState] = useState(initialState);

    const { schemes, isLoaded } = useTroncSchemesList({
        companyIds: [companyId],
        refresh,
        getCurrentPeriod: true,
    });

    /**
     * Renders create button or no data card
     *
     * @returns {React.Element} NoDataCard or CreateButton
     */
    const renderCreateCard = () => {
        if (!isEmpty(schemes)) {
            return null;
        }

        const handleAddOnClick = () => {
            setFormState(initialState);
            setDrawer({
                editDrawer: false,
                addDrawer: true,
            });
        };

        return (permissions?.canCreate)
            ? (
                <CreateButton onClick={handleAddOnClick}>
                    Add a scheme
                </CreateButton>
            ) : <NoDataCard text="No Schemes" />;
    };

    if (!isLoaded) {
        return (
            <div className={styles.loading}>
                <LoadingSpinner loading={!isLoaded} />
            </div>
        );
    }

    return (
        <>
            {renderCreateCard()}
            {!isEmpty(schemes) && (
                <SchemesCardList
                    schemes={schemes}
                    setFormState={setFormState}
                    setEditDrawer={() => setDrawer({
                        editDrawer: true,
                        addDrawer: false,
                    })}
                    reportDrawer={reportDrawer}
                    setReportDrawer={setReportDrawer}
                    setReprocess={setReprocessScheme}
                />
            )}
            {formState.scheme && (
                <TroncSchemeEditDrawer
                    scheme={formState?.scheme}
                    showForm={isDrawerOpen.editDrawer}
                    editMode={formState?.editMode}
                    setShowForm={() => setDrawer({
                        editDrawer: true,
                        addDrawer: false,
                    })}
                    companyId={Number(companyId)}
                    onSubmit={() => handleOnSubmit(refresh, refreshData, setDrawer)}
                    onCancel={() => setDrawer({
                        editDrawer: false,
                        addDrawer: false,
                    })}
                    handleTransitionEnd={() => {
                        if (!isDrawerOpen) {
                            setFormState(initialState);
                        }
                    }}
                />
            )}
            <TroncSchemeAddDrawer
                scheme={formState?.scheme}
                showForm={isDrawerOpen.addDrawer}
                editMode={formState?.editMode}
                setShowForm={() => setDrawer({
                    editDrawer: false,
                    addDrawer: true,
                })}
                companyId={Number(companyId)}
                onSubmit={() => handleOnSubmit(refresh, refreshData, setDrawer)}
                onCancel={() => setDrawer({
                    editDrawer: false,
                    addDrawer: false,
                })}
                handleTransitionEnd={() => {
                    if (!isDrawerOpen) {
                        setFormState(initialState);
                    }
                }}
            />
            {reprocessScheme && (
                <TroncReprocessFilesDrawer
                    scheme={reprocessScheme}
                    showForm={isObject(reprocessScheme)}
                    setShowForm={setReprocessScheme}
                />
            )}
            <TroncReportDrawer
                drawerState={reportDrawer}
                setDrawerState={setReportDrawer}
                refreshData={refreshData}
                refresh={refresh}
            />
        </>
    );
};

Schemes.propTypes = {
    setDrawer: PropTypes.func,
    isDrawerOpen: PropTypes.object,
    permissions: PropTypes.object,
};

Schemes.defaultProps = {
    setDrawer: noop,
    isDrawerOpen: {
        editDrawer: false,
        addDrawer: false,
    },
    permissions: {},
};

export default Schemes;
