import CompanyPermissions from "../permissions/company-permissions";

export default class Company {

    /**
     * Company constructor
     *
     * @param {object} company The company
     * @param {object} permissions The companies permissions
     * @param {boolean} troncmaster Is the user a troncmaster
     */
    constructor (company, permissions, troncmaster) {
        Object.assign(this, company);
        this.companyPermissions = new CompanyPermissions(permissions, troncmaster);
    }

    /**
     * Gets a list of company permissions and registers the current policy per permission registered
     *
     * @return {void}
     */
    register () {
        this.companyPermissions.getPermissionList().forEach((permission) => permission.register());
    }

}
