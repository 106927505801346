import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './badge.module.scss';

/**
 * Component to show the color coded status in a highlighted area
 *
 * @param {string} status Describes state of badge
 * @param {any} children The content of the badge
 *
 * @returns {React.Component} The badge component
 */
const Badge = ({ status, children }) => {
    const indicatorClasses = classNames(styles.statusIndicator, {
        [styles[status]]: true,
    });

    return (
        <div className={styles.badge} data-testid="badge">
            {children}
            {status && <div className={indicatorClasses} />}
        </div>
    );
};

Badge.propTypes = {
    status: PropTypes.oneOf(["open", "closed", ""]),
    children: PropTypes.any.isRequired,
};

Badge.defaultProps = {
    status: "",
};

export default Badge;
