import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './text-button.module.scss';

/**
 * Button component with outline styles
 *
 * @param {any} children The content of the button
 * @param {object} props Any other props
 *
 * @returns {React.Component} The tertiary button
 */
const TextButton = ({ children, className, outline, ...props }) => (
    <button
        type="button"
        className={classNames(styles.button, className, {
            [styles.outline]: outline,
        })}
        {...props}
    >
        {children}
    </button>
);

TextButton.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    outline: PropTypes.bool,
};

TextButton.defaultProps = {
    children: "",
    className: "",
    outline: false,
};

export default TextButton;
