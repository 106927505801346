import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Page } from "components";

/**
 * returns a string based on the time of day, "Morning", "Evening" or "Afternoon"
 *
 * @returns {string} time of day string
 */
const getTimeOfDay = () => {
    const currentHour = moment().format('HH');
    const midDayCutoff = 12;
    const eveningCutoff = 17;

    if (currentHour <= midDayCutoff) {
        return "Morning";
    }

    if (currentHour >= eveningCutoff) {
        return "Evening";
    }

    return "Afternoon";
};

/**
 * Renders the dashboard page
 *
 * @returns {React.Component} Dashboard Component
 */
const Dashboard = () => {
    const { user } = useSelector((state) => state);
    const welcomeMessage = user.tronc_master && "You can access all client sites and schemes via the Companies page";

    return (
        <Page
            title={`Good ${getTimeOfDay()}, ${user?.forename}`}
            subtitle={`Today is ${moment().format('ddd, Do MMM YYYY')}`}
        >
            {welcomeMessage}
        </Page>
    );
};

export default Dashboard;
