import React from 'react';
import { filter, map } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { SelectInputRow, TextInputRow } from 'components/forms';
import { useSelector } from 'react-redux';
import TextAreaInputRow from 'components/forms/text-area-row';
import FileInputRow from 'components/forms/file-input-row';
import { getFormErrors } from 'lib/helpers/validation-helpers';

/**
 * Renders the ticket details form page when creating/editing tickets
 *
 * @returns {React.Component} TicketDetails Component
 */
const TicketDetails = () => {
    const appState = useSelector((state) => state.app);
    const { register, formState: { errors }, getValues, watch } = useFormContext();
    watch();

    /**
     * Gets an array of select options from appState based on a given property name
     *
     * @param {string} property the appState property to get options from
     *
     * @returns {Array} array of select options
     */
    const getOptions = (property) => {
        return map(appState[property], ({ name, id }) => ({
            display: name,
            value: id,
        }));
    };

    /**
     * Filters payroll list down to an array of those which have ticket access
     *
     * @returns {array} list of payrolls with ticket access
     */
    const filterPayrolls = () => {
        const payrolls = appState.payrolls;
        return filter(payrolls, ({ epay_tickets: ticketAccess }) => Boolean(ticketAccess))
            .map(({ name, id }) => ({ display: name, value: id }));
    };

    return (
        <div data-testid="ticket-detail-form">
            <SelectInputRow
                {...register("company", { required: true })}
                required={true}
                selected={getValues("company")}
                data-testid="ticket-company"
                aria-label="ticket-company"
                options={getOptions("companies")}
                label="Company"
                error={getFormErrors(errors, "company")}
            />
            <SelectInputRow
                {...register("payrollid", { required: true })}
                required={true}
                selected={getValues("payrollid")}
                data-testid="ticket-payroll"
                aria-label="ticket-payroll"
                options={filterPayrolls()}
                label="Payroll"
                error={getFormErrors(errors, "payrollid")}
            />
            <TextInputRow
                {...register("subject", { required: true })}
                required={true}
                data-testid="ticket-subject"
                label="Subject"
                error={getFormErrors(errors, "subject")}
            />
            <TextAreaInputRow
                {...register("message", { required: true })}
                required={true}
                data-testid="ticket-message"
                label="Description"
                rows={10}
                error={getFormErrors(errors, "message")}
            />
            <FileInputRow
                {...register("attachments")}
                data-testid="ticket-file"
                label="Attachments"
            />
        </div>
    );
};

export default TicketDetails;
