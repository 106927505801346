import { useState, useEffect } from "react";
import { TroncPeriodsApi } from "lib/api-endpoints";

const initialState = {
    period: {},
    isLoaded: false,
};

const useTroncPeriod = (id) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        setState(initialState);

        const { request, abortController } = TroncPeriodsApi.getTroncPeriod(id);

        request.then(({ data }) => setState({
            period: data,
            isLoaded: true,
        }));

        return () => abortController?.abort();
    }, [id]);

    return state;
};

export default useTroncPeriod;
