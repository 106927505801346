import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Attachment, Cancel } from 'components/icons';
import { TextButton } from 'components';

import styles from './attachment-list.module.scss';

/**
 * Renders a list of attachments for given file list.
 *
 * @param {function} onRemove Remove attachments when called
 * @param {array} fileList The list of files
 *
 * @returns {React.Component} Formatted list of attachments
 */
const AttachmentList = ({ onRemove, fileList }) => {
    const fileNames = fileList.map((file) => {
        return (
            <div key={file.name} className={styles.attachmentAlign}>
                <Attachment data-testid="attachment-icon" className={styles.attachmentFileIcon} />
                {file.name}
                <TextButton onClick={() => onRemove(file)} data-testid="attachment-cancel-button">
                    <Cancel className={styles.attachmentCancelIcon} />
                </TextButton>
            </div>
        );
    });

    return (
        <div className={styles.attachmentsContainer}>
            {fileNames}
        </div>
    );
};

export default AttachmentList;

AttachmentList.propTypes = {
    onRemove: PropTypes.func,
    fileList: PropTypes.array,
};

AttachmentList.defaultProps = {
    onRemove: noop,
    fileList: [],
};
