import { api } from "lib/api";

export default class TroncReportsApi {

    /**
     * Get company reports
     *
     * @param {object} params The query parameters
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getReports = (params) => api({ type: "get", url: "/tronc_reports", config: { params } });

    /**
     * Export company report
     *
     * @param {object} id The report id
     * @param {object} params The query parameters
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static exportReport = (id, params) => api({
        type: "get",
        url: `/tronc_reports/${id}`,
        config: { params, headers: {'Accept': "text/csv"} },
    });

    /**
     * Generate period report
     *
     * @param {object} id The period id
     * @param {object} params The query parameters
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static generateReport = (id, params) => api({
        type: "post", url: `/tronc_periods/${id}/generate_report`, config: { params },
    });

    /**
     * Approves the company report
     *
     * @param {object} id The period id
     * @param {object} params The query parameters
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static approveReport = (id, params) => api({
        type: "post", url: `/tronc_reports/${id}/approve`, config: { params },
    });

}
