import React from "react";
import PropTypes from "prop-types";
import CompanyJobRolesApi from "lib/api-endpoints/company-job-roles-api";
import { PrimaryButton } from "components";
import { useForm, FormProvider } from "react-hook-form";
import { Form, TextInputRow } from "components/forms";
import { useNotificationContext } from 'state/context';
import FormContent from 'components/forms/form-container/form-content';

import styles from "./job-roles.module.scss";

/**
  * Renders the add job roles form for a selected company
  *
  * @param {number} companyId The selected company id
  * @param {func} setRefresh Refreshes the page after api call
  *
  * @returns {React.Component} Add job roles form
*/
const AddJobRolesForm = ({ companyId, setRefresh }) => {
    const { openNotification } = useNotificationContext();

    const methods = useForm({
        defaultValues: {
            jobRole: "",
        },
    });

    const { register, handleSubmit, watch } = methods;
    watch();

    /**
     * Add the job role
     *
     * @param {object} formData The form data
     *
     * @return {void}
     */
    const onSubmit = async (formData) => {
        await CompanyJobRolesApi.addJobRoles(companyId, [{
            "job_title": formData.jobRole,
        }]).request.then(() => {
            openNotification({
                message: `Job role added successfully`,
                type: "success",
            });
            setRefresh(true);
        });
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <FormContent>
                    <div className={styles.addSection}>
                        <div className={styles.input}>
                            <TextInputRow {...register("jobRole")} large label="Job title" />
                        </div>
                        <PrimaryButton className={styles.addButton} onClick={handleSubmit(onSubmit)}>
                            Add
                        </PrimaryButton>
                    </div>
                </FormContent>
            </Form>
        </FormProvider>
    );
};

AddJobRolesForm.propTypes = {
    companyId: PropTypes.number.isRequired,
    setRefresh: PropTypes.func.isRequired,
};

export default AddJobRolesForm;
