export default class Permissions {

    /**
     * Permissions constructor
     *
     * @param {object} permissions payroll permissions
     * @param {object} troncmaster Is the user a troncmaster
     */
    constructor ({ permissions }, troncmaster) {
        this.troncmaster = troncmaster;
        this.permissions = permissions;
    }

    /**
     * @type {boolean} View policy
     */
    canView = false;

    /**
     * @type {boolean} Edit policy
     */
    canEdit = false;

    /**
     * @type {boolean} Create policy
     */
    canCreate = false;

    /**
     * @type {boolean} Delete policy
     */
    canDelete = false;

    /**
     * Registers the policies for the payroll permissions
     *
     * @return {void}
     */
    register () {
        throw new Error('Permission register must be defined');
    }

}
