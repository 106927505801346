import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import ErrorHelpText from "./error-help-text";
import { FormRow, FormRowLabel, RadioBoxes } from "./form-controls";

import styles from "./radio-boxes-input-row.module.scss";
/**
 * Renders the radio input row
 *
 * @param {object} props The radio properties
 * @param {string} props.error error message
 * @param {string} props.label The label to render
 * @param {array} props.options An array of options to render
 *
 * @return {React.Component} The radio input row
 */
const RadioBoxesInputRow = forwardRef(({ error, label, options, required, ...otherProps }, ref) => {
    return (
        <FormRow className={styles.postRadios}>
            <FormRowLabel label={label} required={required} />
            <RadioBoxes ref={ref} options={options} {...otherProps} />
            { error ? <ErrorHelpText text={error} /> : null }
        </FormRow>
    );
});

RadioBoxesInputRow.defaultProps = {
    error: "",
    label: null,
    required: false,
};

RadioBoxesInputRow.propTypes = {
    error: PropTypes.string,
    options: PropTypes.array.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
};

export default RadioBoxesInputRow;
