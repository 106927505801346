import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { head, noop } from 'lodash';
import { routePaths } from 'routes';
import { selectCompany } from 'state/app-slice';
import { getSites } from 'state/company-slice';
import { LayoutContainer, LoadingMask, MoreActionsButton, Table, TertiaryButton } from 'components';
import CreateButton from 'components/buttons/create-button';
import NoDataCard from 'components/no-data-card/no-data-card';
import { SitesWithSchemes, EditSiteDrawer } from '.';

import styles from './sites.module.scss';

/**
 * Formats the sites data for the table
 *
 * @param {array} sites The sites data
 * @param {function} navigate The navigate function to redirect
 * @param {function} setEditSiteDrawerOpen Opens the edit site drawer
 * @param {function} setSelectedSiteId Sets the selected site id
 *
 * @returns {array} The formatted sites data
 */
const formattedSites = (sites, navigate, setEditSiteDrawerOpen, setSelectedSiteId) => sites.map(({
    id: siteId,
    name,
    address_line_1: address1,
    address_line_2: address2,
    address_line_3: address3,
    address_post_town: town,
    address_post_code: postcode,
    stats,
}) => {
    const formattedPostcode = postcode && (<>, <span className={styles.postcode}>{postcode}</span></>);
    const address = [address1, address2, address3, town].filter((line) => line).join(', ');
    const addressBuilder = [address, formattedPostcode];

    return {
        id: siteId,
        name: {
            text: name,
            className: styles.siteName,
            link: "/sites/my-stubbed-id",
        },
        address: {
            component: addressBuilder,
        },
        employees: String(stats?.employee_count) ?? "-",
        actions: {
            component: (
                <div className={styles.actionsCol}>
                    <TertiaryButton
                        compact
                        table
                        onClick={() => navigate(`${routePaths.companies}`)}
                    >
                        View
                    </TertiaryButton>
                    <MoreActionsButton
                        boldBorderless
                        actions={[
                            {
                                label: "Edit site",
                                handler: () => {
                                    setEditSiteDrawerOpen(true);
                                    setSelectedSiteId(siteId);
                                },
                            },
                        ]}
                    />
                </div>
            ),
        },
    };
});

/**
 * The sites tab content for a company
 *
 * @param {function} setNewSiteDrawerOpen Sets the site drawer open
 *
 * @returns {React.Component} Renders the sites based on their number
 */
const Sites = ({ setNewSiteDrawerOpen }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { app, company: { sites, isLoading } } = useSelector((state) => state);
    const [editSiteDrawerOpen, setEditSiteDrawerOpen] = useState(false);
    const [selectedSiteId, setSelectedSiteId] = useState(null);

    useEffect(() => {
        dispatch(getSites([id]));
    }, [id, dispatch]);

    /**
     * Finds current company and gets relevant permissions
     *
     * @returns {object} selected company and permissions
     */
    const { companyPermissions: { sites: sitePermissions } } = useMemo(() => selectCompany(app, id), [app, id]);

    const renderSites = () => {
        if (sites?.meta?.total > 4) {
            return (
                <LayoutContainer>
                    <Table
                        data={formattedSites(sites.data, navigate, setEditSiteDrawerOpen, setSelectedSiteId)}
                        headings={{
                            name: "Name",
                            address: "Address",
                            employees: "Employees",
                            actions: "Actions",
                        }}
                        withSelectableRows={true}
                    />
                </LayoutContainer>
            );
        }

        if (sites?.meta?.total) {
            return <SitesWithSchemes />;
        }

        return sitePermissions.canCreate
            ? (
                <CreateButton
                    onClick={() => setNewSiteDrawerOpen(true)}
                >
                    Create site
                </CreateButton>
            )
            : <NoDataCard text="No Sites" />;
    };

    return (
        <LoadingMask loading={isLoading} containerClasses={styles.loadingMask}>
            <div className={styles.sitesWrapper}>
                {renderSites()}
                {selectedSiteId && (
                    <EditSiteDrawer
                        site={head(sites?.data?.filter((site) => site.id === selectedSiteId))}
                        companyId={id}
                        isOpen={editSiteDrawerOpen}
                        setIsOpen={setEditSiteDrawerOpen}
                        isLoading={isLoading}
                        onCancel={() => setEditSiteDrawerOpen(false)}
                    />
                )}
            </div>
        </LoadingMask>
    );
};

Sites.propTypes = {
    setNewSiteDrawerOpen: PropTypes.func,
};

Sites.defaultProps = {
    setNewSiteDrawerOpen: noop,
};

export default Sites;
