import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { resetCrumbs, setCrumbs } from 'state/app-slice';
import { Page, PageContent, LayoutContainer, TabSection } from 'components';
import {
    Password,
    Username,
    EmailAddress,
    MobileNumber,
    Authentification,
    SecurityQuestions,
} from './components';

import styles from './my-account.module.scss';

/**
 * Renders the User Account Page
 *
 * @returns {React.Component} MyAccount Page Component
 */
const MyAccount = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState();
    const { tab } = useParams();

    /**
     * Sets custom breadcrumb to be the tab name and resets the crumbs upon exit
     */
    useEffect(() => {
        dispatch(setCrumbs({ myAccount: tab }));

        return () => dispatch(resetCrumbs());
    }, [tab, dispatch]);

    return (
        <Page title="My Account">
            <PageContent>
                <LayoutContainer headerContent={
                    <TabSection
                        data-testid="tab-section"
                        className={styles.tabSection}
                        loading={loading}
                        tabs={[
                            {
                                title: "Password",
                                route: "password",
                                content: <Password />,
                            },
                            {
                                title: "Username",
                                route: "username",
                                content: <Username />,
                            },
                            {
                                title: "Email address",
                                route: "email-address",
                                content: <EmailAddress />,
                            },
                            {
                                title: "Mobile number",
                                route: "mobile-number",
                                content: <MobileNumber />,
                            },
                            {
                                title: "Authentification",
                                route: "authentification",
                                content: <Authentification />,
                            },
                            {
                                title: "Security questions",
                                route: "security-questions",
                                content: <SecurityQuestions setLoading={setLoading} />,
                            },
                        ]}
                    />}
                />
            </PageContent>
        </Page>
    );
};

export default MyAccount;
