import { api } from "lib/api";

export default class AnnouncementsApi {

    /**
     * Gets the list of announcements for the current user
     *
     * @param {object} params Additional query params
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getList = (params = {}) => api({ url: `/announcements`, config: { params }});

    /**
     * Updates a given announcement properties
     *
     * @param {int} id The announcement id
     * @param {object} data The properties of the announcement to change
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static edit = (id, data) => api({ type: 'patch', url: `/announcements/${id}`, data });

    /**
     * Creates an announcement
     *
     * @param {object} data The announcement data
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static create = (data) => api({ type: 'post', url: `/announcements`, data });

}
