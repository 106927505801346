import React, { useState } from "react";
import PropTypes from 'prop-types';
import { useForm, FormProvider } from "react-hook-form";
import { noop } from "lodash";
import { Drawer, DrawerTitle, PrimaryButton } from "components";
import { Button } from "components/forms/form-controls";
import { Form, PasswordInputRow } from "components/forms";

import styles from './components.module.scss';

const ConfirmationDrawer = ({ drawerState, setDrawerState, onConfirm }) => {
    const methods = useForm({
        defaultValues: {
            password: "",
        },
    });
    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, getValues, watch } = methods;
    watch();

    const formData = getValues();

    const onSubmit = () => {
        if (onConfirm) {
            setLoading(true);
            onConfirm(formData)
                .then(() => {
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <Drawer
            isOpen={drawerState.isOpen}
            isLoading={drawerState.isLoading || loading}
            onClose={() => setDrawerState({ isOpen: false, isLoading: true })}
            onOutsideClick={() => setDrawerState({ isOpen: false, isLoading: true })}
            headerContent={<DrawerTitle>Make changes</DrawerTitle>}
            footerContent={
                <div className={styles.footerContainer}>
                    <PrimaryButton
                        className={styles.drawerButton}
                        type="submit"
                        disabled={!formData.password || loading}
                        onClick={onSubmit}
                    >
                        Save
                    </PrimaryButton>
                    <Button
                        className={styles.drawerCancelButton}
                        onClick={() => setDrawerState({...drawerState, isOpen: false, isLoading: true})}
                    >
                        Cancel
                    </Button>
                </div>
            }
            ariaLabel="confirmation drawer"
        >
            <div className={styles.drawerText}>
                In order to confirm your changes, please enter your Tipology account password:
            </div>
            <div className={styles.inputContainer}>
                <FormProvider {...methods}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className={styles.passwordInputContainer}>
                            <PasswordInputRow
                                {...register("password")}
                                name="password"
                                label="Password"
                                aria-label="password"
                            />
                        </div>
                    </Form>
                </FormProvider>
            </div>
        </Drawer>
    );
};

ConfirmationDrawer.propTypes = {
    drawerState: PropTypes.object.isRequired,
    setDrawerState: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
};

ConfirmationDrawer.defaultProps = {
    onConfirm: noop,
};

export default ConfirmationDrawer;
