import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./form-row.module.scss";

/**
 * Renders the form row component
 *
 * @param {object} props The form row properties
 *
 * @return {React.Component} The form row component
 */
const FormRow = ({ className, topMargin, ...otherProps}) => (
    <div className={classNames(topMargin ? styles.row : '', className)} {...otherProps} />
);

FormRow.defaultProps = {
    className: null,
    topMargin: true,
};

FormRow.propTypes = {
    className: PropTypes.string,
    topMargin: PropTypes.bool,
};

export default FormRow;
