import React from "react";
import UserMenu from "./user-menu";
import UpdatesMenu from "./updates-menu";

import styles from "./main-header.module.scss";

/**
 * The Main Header Component for the app responsible for the search bar and user profile icon
 *
 * @returns {React.Component} MainHeader Component
 */
const MainHeader = () => {

    return (
        <div className={styles.container}>
            <div className={styles.contentWrapper}>
                <div className={styles.sideActions}>
                    <UpdatesMenu />
                    <UserMenu />
                </div>
            </div>
        </div>
    );
};

export default MainHeader;
