import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { find, map } from 'lodash';
import useTroncSchemesList from 'lib/api-endpoints/hooks/tronc-schemes-list';
import { resetCrumbs, setCrumbs } from 'state/app-slice';
import { LayoutContainer, Table } from 'components';

const formatSchemes = (schemes, companyId) => map(schemes, ({ id, name, frequency }) => ({
    id,
    frequency: frequency?.display_name,
    name: {
        text: name,
        link: `/files/company/${companyId}/tronc-schemes/${id}/periods`,
    },
}));

const TroncSchemesTable = () => {
    const { company: companyId } = useParams();
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const companies = useSelector((state) => state.app.companies);
    const { schemes, meta, isLoaded } = useTroncSchemesList({ page, companyIds: [companyId] });

    /**
     * Sets the breadcrumb state
     */
    useEffect(() => {
        const crumbCompany = find(companies, { id: Number(companyId) });

        dispatch(setCrumbs({
            company: crumbCompany.name,
        }));

        return () => dispatch(resetCrumbs());

    }, [companyId, companies, dispatch]);

    return (
        <LayoutContainer>
            <Table
                headings={{
                    name: "Name",
                    frequency: "Frequency",
                }}
                skeletonConfig={{
                    rowCount: 6,
                    rowConfig: [
                        { key: 'company-tronc-schemes' },
                    ],
                }}
                dataLoaded={isLoaded}
                noDataText="No Tronc Schemes"
                data={formatSchemes(schemes, companyId)}
                pagination={{
                    currentPage: page,
                    onPageChange: setPage,
                    totalPages: meta?.last_page,
                }}
            />
        </LayoutContainer>
    );
};

export default TroncSchemesTable;
