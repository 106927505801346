import React from "react";
import PropTypes from "prop-types";
import { debounce, parseInt } from "lodash";
import ProfileSection from "./profile-section";
import { TroncSchemesApi } from "lib/api-endpoints";
import { TextInput } from "components/forms";
import { PrimaryButton } from "components/buttons";
import { useNotificationContext, useSnackbarContext } from "state/context";
import useTroncPoints from "hooks/useTroncPoints";
import SkeletonText from "../skeleton-loaders/skeleton-text";

import styles from "./overview-tab.module.scss";

/**
 * Gets the total tronc points for an employee
 *
 * @param {int} jobRole the job role tronc points
 * @param {int} individual the individual tronc points
 * @param {int} LOS the LOS tronc points
 * @param {int} training the training tronc points
 *
 * @returns {int} The total tronc points for an employee
 */
const getTotal = (jobRole, individual, LOS, training) => {
    const total = (jobRole + individual + LOS + training);

    return parseInt(total) ? total : "";
};

/**
 * A component representing the OverviewTab content
 *
 * @param {object} props component props
 *
 * @returns {React.Component} OverviewTab component
 */
const OverviewTab = ({ employee }) => {
    const { id, current_tronc_scheme: currentTroncScheme } = employee;

    const { openNotification } = useNotificationContext();
    const { openSnackbar } = useSnackbarContext();
    const { points, setPoints, isLoaded: pointsLoaded } = useTroncPoints(employee, currentTroncScheme);

    const {
        jobRole,
        individual,
        LOS,
        training,
    } = points;

    const changePoints = React.useMemo(() => debounce((_event) => setPoints({
        ...points,
        individual: _event.target.value ? parseFloat(_event.target.value) : 0,
    }), 100), [points, setPoints]);

    const apiCall = () => {
        if (!currentTroncScheme?.length) {
            openSnackbar({
                message: `Employee needs to be linked to a scheme to update Tronc Points.`,
                type: "error",
            });
        }

        TroncSchemesApi.patchEmployeeTroncPoints(currentTroncScheme[0].id, id, { individual })
            .request
            .then(() => {
                openNotification({
                    message: "Tronc points updated successfully.",
                    type: "success",
                });
            });
    };

    return (
        <ProfileSection>
            <div className={styles.sectionContainer}>
                <div className={styles.title}>
                    Tronc points
                </div>
                {
                    pointsLoaded
                        ? (
                            <div className={styles.inputs}>
                                <div className={styles.pointsButtonConatiner}>
                                    <div>
                                        Job role
                                    </div>
                                    <TextInput className={styles.numberInput} value={jobRole} disabled />
                                </div>
                                <div className={styles.pointsButtonConatiner}>
                                    <div>
                                        Individual
                                    </div>
                                    <TextInput
                                        className={styles.numberInput}
                                        onChange={changePoints}
                                        defaultValue={individual}
                                    />
                                </div>
                                <div className={styles.pointsButtonConatiner}>
                                    <div>
                                        LOS
                                    </div>
                                    <TextInput className={styles.numberInput} value={LOS} disabled />
                                </div>
                                <div className={styles.pointsButtonConatiner}>
                                    <div>
                                        Training
                                    </div>
                                    <TextInput className={styles.numberInput} value={training} disabled />
                                </div>
                                <div className={styles.pointsButtonConatiner}>
                                    <div>
                                        Total
                                    </div>
                                    <TextInput
                                        className={styles.numberInput}
                                        value={getTotal(jobRole, individual, LOS, training)}
                                        disabled
                                    />
                                </div>
                                <PrimaryButton
                                    className={styles.saveButton}
                                    type="submit"
                                    disabled={!parseInt(individual)}
                                    onClick={apiCall}
                                >
                                    Save
                                </PrimaryButton>
                            </div>
                        )
                        : <SkeletonText />
                }
            </div>
        </ProfileSection>
    );
};

OverviewTab.propTypes = {
    employee: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        "current_tronc_scheme": PropTypes.array,
        "tronc_points_job_role": PropTypes.number,
        "individual_tronc_points": PropTypes.number,
        "tronc_points_length_of_service": PropTypes.number,
        "tronc_points_training": PropTypes.number,
    }),
};

OverviewTab.defaultProps = {
    employee: {},
};

export default OverviewTab;
