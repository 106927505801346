import React from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Currency, Drawer, Table } from 'components';

import styles from './starters-leavers-drawer.module.scss';

/**
 * Formats given employee array for use in the table
 *
 * @param {array} employees array of employee objects
 *
 * @returns {array} array of formatted employee data for table use
 */
const formatData = (employees) => map(employees, (employee) => ({
    ...employee,
    'payroll_ref': String(employee.payroll_ref),
    'net_pay': {
        component: <Currency value={employee.net_pay} />,
    },
}));

/**
 * Renders a drawer containing a table to display provided starters/leavers employee data
 *
 * @param {object} props component props
 * @param {array} props.data array of starters or leavers to show
 * @param {boolean} props.visible if the drawer is visible
 * @param {function} props.handleOnClose drawer close handler
 * @param {string} props.type either 'starters' or 'leavers'
 *
 * @returns {React.Component} StartersLeaversDrawer Component
 */
const StartersLeaversDrawer = ({ data, visible, handleOnClose, type }) => {

    /**
     * Gets table headers object with the correct keys in the correct order depending on type
     *
     * @returns {object} table headers
     */
    const getHeaders = () => {
        const headers = {
            'name': 'Name',
            'payroll_ref': 'Payroll Number',
            'ni_number': 'NI Number',
        };

        if (type === "starters") {
            const key = "start_date";
            headers[key] = "Start Date";
        } else if (type === "leavers") {
            const key = "leave_date";
            headers[key] = "Leaver Date";
        }

        const key = "net_pay";
        headers[key] = "Net Pay";

        return headers;
    };

    return (
        <Drawer
            isOpen={visible}
            isLoading={false}
            onClose={handleOnClose}
            onOutsideClick={handleOnClose}
            headerContent={(
                <h2
                    data-testid={`${type}-drawer-title`}
                    className={styles.title}
                >
                    { type }
                </h2>
            )}
            ariaLabel="starters leavers drawer"
        >
            <Table
                headings={getHeaders()}
                data={formatData(data)}
            />
        </Drawer>
    );
};

StartersLeaversDrawer.defaultProps = {
    data: [],
    visible: false,
    handleOnClose: noop,
    type: 'starters',
};

StartersLeaversDrawer.propTypes = {
    data: PropTypes.array,
    visible: PropTypes.bool,
    handleOnClose: PropTypes.func,
    type: PropTypes.oneOf(['starters', 'leavers']),
};

export default StartersLeaversDrawer;
