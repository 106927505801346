import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Drawer, DrawerTitle } from 'components';
import CreateDocumentForm from './create-document-form';
import { noop } from 'lodash';

/**
 * Renders Drawer For Creating Company Documents
 *
 * @param {object} props component props
 * @param {boolean} props.isOpen is drawer open
 * @param {function} props.onClose drawer close handler
 * @param {function} props.onSuccess on success handler
 *
 * @returns {React.Component} CreateDocumentDrawer Component
 */
const CreateDocumentDrawer = ({ isOpen, onClose, onSuccess }) => {
    const [isLoading, setLoading] = useState(false);

    /**
     * Form Success handler - sets loading to false and runs onSuccess prop
     *
     * @param  {...any} args onSuccess args
     */
    const handleOnSuccess = (...args) => {
        setLoading(false);
        onSuccess(args);
    };

    /**
     * Drawer close handler - sets loading to false and runs onClose prop
     */
    const handleOnClose = () => {
        setLoading(false);
        onClose();
    };

    return (
        <Drawer
            data-testid="document-create-drawer"
            isOpen={isOpen}
            isLoading={isLoading}
            onClose={handleOnClose}
            onOutsideClick={handleOnClose}
            headerContent={<DrawerTitle>Add new document</DrawerTitle>}
            ariaLabel="create document drawer"
        >
            <CreateDocumentForm
                onSuccess={handleOnSuccess}
                onClose={handleOnClose}
                onSubmit={() => setLoading(true)}
            />
        </Drawer>
    );
};

CreateDocumentDrawer.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
};

CreateDocumentDrawer.defaultProps = {
    isOpen: false,
    onClose: noop,
    onSuccess: noop,
};

export default CreateDocumentDrawer;
