import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import FocusTrap from "focus-trap-react";
import OutsideClickHandler from "react-outside-click-handler";
import { SearchInput, UnorderedList } from "components";

import styles from "./searchable.module.scss";

const Searchable = ({ options, visible, onOutsideClick, ...props }) => {
    const [currentOptions, setOptions] = useState(options);
    const updateOptions = useCallback((newOptions) => setOptions(newOptions), [setOptions]);

    /**
     * The button used for the fallbackFocus
     *
     * @returns {React.Component} The fallback focus
     */
    const fallbackFocus = () => {
        return <button type="button" tabIndex={-1} />;
    };
    useEffect(() => {
        updateOptions(options);
    }, [options, updateOptions]);

    const handleOnChange = ({ target: { value }}) => {
        const newOptions = (!isEmpty(value))
            ? currentOptions.filter(({ display }) => display.toLowerCase().includes(value.toLowerCase()))
            : options;

        updateOptions(newOptions);
    };

    if (!visible) {
        return null;
    }

    return (
        <OutsideClickHandler onOutsideClick={onOutsideClick}>
            <FocusTrap
                focusTrapOptions={{
                    fallbackFocus,
                    allowOutsideClick: true,
                    initialFocus: false,
                    delayInitialFocus: true,
                }}
            >
                <div className={styles.container}>
                    <div className={styles.filterInput}>
                        <SearchInput onChange={handleOnChange} />
                    </div>
                    <UnorderedList {...props} onClick={props.onChange} options={currentOptions} />
                </div>
            </FocusTrap>
        </OutsideClickHandler>
    );
};

Searchable.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        display: PropTypes.any,
    })),
    visible: PropTypes.bool,
    onChange: PropTypes.func,
    onOutsideClick: PropTypes.func,
};

Searchable.defaultProps = {
    options: [],
    visible: false,
    onChange: () => null,
    onOutsideClick: () => null,
};

export default Searchable;
