import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import useTroncPeriodFiles from 'lib/api-endpoints/hooks/troncs-period-files-list';
import TroncProcessFilesDrawer from 'pages/tronc-schemes/tronc-process-files-drawer';
import TroncSchemeCard from './tronc-scheme-card';

/**
 * Returns array of TroncSchemeCard react elements for the loaded schemes
 *
 * @param {array} schemes The tronc scheme details to render
 * @param {function} setFormState Sets the tronc scheme drawer state
 * @param {function} setEditDrawer Sets the tronc scheme drawer state
 * @param {function} reportDrawer Sets the tronc scheme drawer state
 * @param {function} setReportDrawer Sets the tronc scheme drawer state
 * @param {function} setReprocess Sets the tronc scheme drawer state
 *
 * @returns {React.Component} array of tronc scheme card details
 */
const SchemesCardList = ({ schemes, setFormState, setEditDrawer, reportDrawer, setReportDrawer, setReprocess }) => {
    const [processSchemeFiles, setProcessSchemeFiles] = useState(false);
    const [selectedScheme, setSelectedScheme] = useState(null);
    const periodIds = useMemo(() => schemes.map((scheme) => scheme?.currentPeriod?.id), [schemes]);
    const { files, isLoaded } = useTroncPeriodFiles({ periodIds });

    const handleProcessFiles = (currentScheme) => {
        setProcessSchemeFiles(true);
        setSelectedScheme(currentScheme);
    };

    /**
     * Returns array of TroncSchemeCard react elements for the current scheme period
     *
     * @returns {array} array of tronc scheme cards
     */
    const renderSchemes = () => {
        return schemes.map((scheme) => {
            const schemeFiles = files.filter((file) => (
                file.tronc_period_id === scheme?.currentPeriod?.id
            ));

            return (
                <TroncSchemeCard
                    key={scheme.id}
                    scheme={scheme}
                    handleProcessFiles={handleProcessFiles}
                    schemeFiles={schemeFiles}
                    troncFilesLoaded={isLoaded}
                    reportDrawer={reportDrawer}
                    setReportDrawer={setReportDrawer}
                    actionHandlers={{
                        edit: (eScheme) => {
                            setFormState({ scheme: eScheme, editMode: true });
                            setEditDrawer(true);
                        },
                        reprocess: (eScheme) => {
                            setReprocess(eScheme);
                        },
                    }}
                />
            );
        });
    };

    return (
        <>
            {renderSchemes()}
            <TroncProcessFilesDrawer
                files={files}
                scheme={selectedScheme}
                showForm={processSchemeFiles}
                setShowForm={setProcessSchemeFiles}
            />
        </>
    );
};

SchemesCardList.propTypes = {
    schemes: PropTypes.arrayOf(PropTypes.object),
    setFormState: PropTypes.func,
    setEditDrawer: PropTypes.func,
    reportDrawer: PropTypes.object,
    setReportDrawer: PropTypes.func,
    setReprocess: PropTypes.func,
};

SchemesCardList.defaultProps = {
    schemes: [],
    setFormState: noop,
    setEditDrawer: noop,
    reportDrawer: null,
    setReportDrawer: noop,
    setReprocess: noop,
};

export default SchemesCardList;
