import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectCompany } from 'state/app-slice';
import { Card, LoadingSpinner, MoreActionsButton, TextLink } from 'components';
import AddEditCompanyDrawer from '../../companies/company-forms/add-edit-company-drawer';
import { getSites } from 'state/company-slice';
import { LinkIcon } from 'components/icons';

import styles from './about.module.scss';
import classNames from 'classnames';

/**
 * Shows the linked sites in name badges
 *
 * @param {array} sites The company's sites
 * @param {number} id The company id
 * @param {boolean} isLoading Loading state
 *
 * @returns {React.Component} Renders the linked sites badges else the empty site
 */
const renderLinkedSites = (sites, id, isLoading) => {
    const noSites = (
        <div>
            <div>No linked Site</div>
        </div>
    );

    const hasSites = sites?.data?.map((site) => {
        return (
            <TextLink key={site.id} className={styles.sitesBadge} to={`/companies/${id}/sites`}>
                <div><LinkIcon /></div>
                <div className={styles.sitesText}>{site.name}</div>
            </TextLink>
        );
    });

    const sitesBadge = isEmpty(sites) ? noSites : hasSites;

    return (
        <div className={styles.sites}>
            <div className={styles.linkedSites}>
                {isLoading ? <LoadingSpinner /> : sitesBadge}
            </div>
        </div>
    );
};

/**
 * Renders the company address
 *
 * @param {array} company The company
 *
 * @returns {React.Component} Returns the company address
 */
const renderAddress = (company) => {
    const { address:
        { line_1: line1, line_2: line2, line_3: line3, post_town: town, post_code: postcode },
    } = company;

    return (
        <>
            <div>{line1}</div>
            <div>{line2}</div>
            <div>{line3}</div>
            <div>{town}</div>
            <div>{postcode}</div>
        </>
    );
};

/**
 * Renders the company names column
 *
 * @param {array} company The company
 *
 * @returns {React.Component} Returns the company names
 */
const renderNameColumn = (company) => {
    return (
        <>
            <div>
                <div className={styles.subtitle}>Name</div>
                <div className={styles.title}>{company?.name ?? "-"}</div>
            </div>
            <div>
                <div className={styles.subtitle}>Trading name</div>
                <div className={styles.text}>{company?.trading_as_name ?? "-"}</div>
            </div>
        </>
    );
};

/**
 * Displays the company info
 *
 * @returns {React.Component} Returns the company about overview tab content
 */
const CompanyAbout = () => {
    const { app, user, company: {sites, isLoading} } = useSelector((state) => state);
    const { id } = useParams();
    const dispatch = useDispatch();
    const [editDrawerOpen, setEditDrawerOpen] = useState(false);

    useEffect(() => {
        dispatch(getSites([id]));
    }, [id, dispatch]);

    /**
     * Finds current company
     *
     * @returns {object} selected company
     */
    const company = useMemo(() => selectCompany(app, id), [app, id]);

    /**
     * Renders the company edit button and form drawer
     *
     * @returns {React.Component} Returns the company edit actions
     */
    const renderEditCompany = () => {
        return user?.tronc_master && (
            <>
                <div className={styles.items}>
                    <MoreActionsButton
                        actions={[
                            {
                                label: "Edit",
                                testId: "edit-company",
                                handler: () => setEditDrawerOpen(true),
                            },
                        ]}
                    />
                </div>
                <AddEditCompanyDrawer
                    isOpen={editDrawerOpen}
                    setIsOpen={setEditDrawerOpen}
                    isLoaded={!isEmpty(company)}
                    onCancel={() => setEditDrawerOpen(false)}
                    company={company}
                />
            </>
        );
    };

    return (
        <Card className={styles.cardWrapper} padding={false}>
            <div className={styles.moreActionsAlign}>
                <div className={styles.editCompanyWrapper}>
                    {renderEditCompany()}
                </div>
            </div>
            <div className={styles.companyInfo}>
                {renderNameColumn(company)}
                <div>
                    <div className={styles.subtitle}>Address</div>
                    <div className={styles.text}>{company?.address ? renderAddress(company) : "-"}</div>
                </div>
                <div>
                    <div className={styles.subtitle}>Company No.</div>
                    <div className={styles.text}>{company?.companies_house_number ?? "-"}</div>
                </div>
                <div>
                    <div className={styles.subtitle}>Employees</div>
                    <div className={styles.text}>{company?.employee_stats?.count ?? "-"}</div>
                </div>
            </div>
            <div className={classNames(styles.subtitle, styles.sitesSubtitle)}>Sites</div>
            {renderLinkedSites(sites, id, isLoading)}
        </Card>
    );
};

export default CompanyAbout;
