import React from 'react';
import PropTypes from 'prop-types';

import styles from './card-display.module.scss';

/**
 * Presents a summary of data with a link to view the full data
 *
 * @param {string} fieldName The name describing the number this field shows
 * @param {number} number The summary number to display
 * @param {number} decimalPlaces the amount of decimal places to show
 * @param {string} text The text to show
 *
 * @returns {React.Component} The summary component
 */
const CardDisplay = ({ fieldName, content }) => {
    return (
        <div className={styles.numberSummary}>
            <h3>{fieldName}</h3>
            <div
                className={styles.displayNumbers}
                data-testid="card-display"
            >
                {content}
            </div>
        </div>
    );
};

CardDisplay.propTypes = {
    fieldName: PropTypes.string,
    content: PropTypes.any.isRequired,
};

CardDisplay.defaultProps = {
    fieldName: "",
};

export default CardDisplay;
