import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { SlimArrow } from "components/icons";

import styles from "./switch-navigator.module.scss";

/**
 * Renders the switch navigator
 *
 * @param {string} direction The direction of the switch (left|right)
 * @param {object} props Additional properties for the switch
 *
 * @returns {React.Component} The switch navigator
 */
const SwitchNavigator = ({ direction, ...props }) => {
    const classList = classNames({
        [styles.navigator]: true,
        [styles.left]: (direction === 'left'),
    });

    return (
        <button type="button" className={styles.navigator} {...props}>
            <SlimArrow className={classList} />
        </button>
    );
};

SwitchNavigator.propTypes = {
    direction: PropTypes.string,
};

SwitchNavigator.defaultProps = {
    direction: 'left',
};

export default SwitchNavigator;
