import { api } from "lib/api";

export default class NewsApi {

    /**
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static get = () => api({ url: "/news" });

}
