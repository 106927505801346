import { api } from "lib/api";

export default class UserApi {

    /**
     * Gets the users on a payroll
     *
     * @param {object} params Additional query params
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getUsers = (params = {}) => api({ url: "/users", config: { params } });

    /**
     * Gets an individual user on a payroll
     *
     * @param {number} id Id of the user to get the details for
     * @param {object} params Additional query params
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getUserDetails = (id, params = {}) => (
        api({ url: `/users/${id}`, config: { params } })
    );

    /**
     * Add an individual user
     *
     * @param {object} data Form field to submit for changes
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static addUsers = (data) => api({ url: "/users", type: "post", data });

    /**
     * Edit an individual user
     *
     * @param {number} id Id of the user to edit the details for
     * @param {object} data Form field to submit for changes
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static editUsers = (id, data) => {
        return api({
            url: `/users/${id}`,
            type: "patch",
            data,
        });
    };

    /**
     * Disable an individual user
     *
     * @param {number} id Id of the user to disable
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static disableUser = (id) => {
        return api({
            url: `/users/${id}/disable`,
            type: "post",
        });
    };

}
