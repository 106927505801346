import React from 'react';
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Navigate } from 'react-router-dom';
import { routePaths } from "routes";

/**
 * Gets the protected route component
 *
 * @param {object} props The component properties
 *
 * @returns {React.Element} The protected route component
 */
const ProtectedRoute = (props) => {
    const { loaded } = useSelector((state) => state.app);

    if (!loaded) {
        return <Navigate to={routePaths.login} replace={true} />;
    }

    return props.children;
};

ProtectedRoute.propTypes = {
    children: PropTypes.any.isRequired,
};

export default ProtectedRoute;
