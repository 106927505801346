import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isEmpty } from "lodash";
import Tooltip from "../tooltip/Tooltip";
import { Info } from "components/icons";

import styles from "./search-input.module.scss";

const SearchInput = ({ className, transparent, tooltipText, ...props }) => {
    const classList = classNames({
        [styles.input]: true,
        [styles.transparent]: transparent,
        [className]: !isEmpty(className),
    });

    const tip = (
        <div className={styles.iconContainer}>
            <Tooltip
                text={tooltipText}
                position="bottom"
            >
                <Info className={styles.icon} />
            </Tooltip>
        </div>
    );

    return (
        <div className={styles.container}>
            <input
                {...props}
                className={classList}
                type="text"
                placeholder="Search"
            />
            {!isEmpty(tooltipText) && tip}
        </div>
    );
};

SearchInput.defaultProps = {
    className: "",
    transparent: false,
    tooltipText: "",
};

SearchInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    transparent: PropTypes.bool,
    tooltipText: PropTypes.string,
};

export default SearchInput;
