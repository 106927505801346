import { useState, useEffect } from "react";
import { TroncSchemesApi } from "lib/api-endpoints";

const schemeInitialState = {
    scheme: {},
    isLoaded: false,
};

const periodsInitialState = {
    periods: [],
    meta: {},
    isLoaded: false,
};

const useTroncSchemePeriodsFiles = ({ id, page, perPage, taxYear }) => {
    const [schemeState, setSchemeState] = useState(schemeInitialState);
    const [periodsState, setPeriodsState] = useState(periodsInitialState);
    const combinedState = {schemeState, periodsState};

    useEffect(() => {
        const getTroncSchemesPeriodsList = (schemeData, taxYears) => {
            const { request, abortController } = TroncSchemesApi.getTroncSchemesPeriods({
                "tronc_scheme_ids": [schemeData?.id],
                "per_page": perPage,
                "tax_years": taxYears,
                page,
            });

            request.then(({ data }) => setPeriodsState({
                periods: data?.data,
                meta: data?.meta,
                isLoaded: true,
            }));

            setSchemeState({
                scheme: schemeData,
            });

            return () => abortController?.abort();
        };

        const { request, abortController } = TroncSchemesApi.getTroncScheme(id, {
            "include_current_period": true,
            "include_tax_years": true,
        });

        request.then(({ data: schemeData }) => {
            const taxYearsString = schemeData?.currentPeriod?.tax_year?.toString();
            const taxYearsArray = taxYearsString.split(',');

            getTroncSchemesPeriodsList(
                schemeData,
                taxYear ? [taxYear.toString()] : taxYearsArray,
            );
        });

        return () => abortController?.abort();
    }, [id, page, perPage, taxYear]);

    return combinedState;
};

export default useTroncSchemePeriodsFiles;
