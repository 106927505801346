import React from "react";
import { Link } from "react-router-dom";

import styles from "./text-link.module.scss";

const TextLink = (props) => (
    <Link className={styles.link} {...props} />
);

export default TextLink;
