import _ from "lodash";

/**
 * Helper class for consistently opening files, using the browsers default method
 *
 * @param {string} url The file data
 * @param {object} attributes The anchor tag attributes to be included
 * @param {ref} targetNode The anchor tag attributes to be included
 *
 * @return {void}
 */
const handleFile = (url, attributes = {}, targetNode = document.body) => {
    const link = document.createElement('a');

    const setAttributes = {
        href: url,
        ...attributes,
    };

    if (setAttributes.target === '_blank') {
        setAttributes.rel = 'noopener noreferrer';
    }

    _.forOwn(setAttributes, (value, key) => {
        link.setAttribute(key, value);
    });

    targetNode.appendChild(link);
    link.click();
    targetNode.removeChild(link);

    window.URL.revokeObjectURL(url);
};

const streamFileHandler = (promise, { filename, targetNode = document.body }) => {
    promise.then(({ data }) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(data, filename);
        } else {
            const windowUrl = window.URL.createObjectURL(data);
            handleFile(windowUrl, { download: filename }, targetNode);
        }
    });
};

/**
 * Downloads data and assigns to a blob data type, use for csv files
 *
 * @param {Promise} promise A promise returned from the axios handler
 * @param {object} config Configuration for the filename and target node
 *
 * @return {void}
 */
const downloadBlob = (promise, config) => {
    promise.then(({ data, headers }) => {
        const { "content-type": type } = headers;
        const blob = new Blob([data], { type });

        const dataPromise = new Promise((resolve) => {
            resolve({ data: blob });
        });

        streamFileHandler(dataPromise, config);
    });
};

export { streamFileHandler, handleFile, downloadBlob };
