import React from 'react';
import { useSelector } from "react-redux";

/**
 * Displays a users name from the redux user store
 *
 * @returns {React.Component} UserName Component
 */
const UserName = () => {
    const user = useSelector((state) => state.user);
    const forename = user !== null ? user?.forename : "";
    const surname = user !== null ? user.surname : "";

    return (
        <div data-testid="user-name">
            {forename} {surname}
        </div>
    );
};

export default UserName;
