import { api } from "lib/api";

export default class PeriodsApi {

    /**
     * Gets a list payrolls periods the user has permission to see request promise
     *
     * @param {object} params Additional query params
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getPeriods = (params = {}) => api({ url: "/periods", config: { params }});

    /**
     * Gets the period details
     *
     * @param {int} id The period id
     * @param {object} params Additional query params
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static getPeriod = (id, params = {}) => api({ url: `/periods/${id}`, config: { params }});

    /**
     * Completes the period input
     *
     * @param {int} id The period id
     *
     * @returns {{ request: promise, abortController: AbortController }} The api promise with abort controller
     */
    static completeInput = (id) => api({ url: `/periods/${id}/complete_input`, type: 'post' });

}
