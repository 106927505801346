import React, { forwardRef, useState } from "react";
import PropTypes from 'prop-types';
import { FormRow, FormRowLabel, TextInput } from "./form-controls";
import { Eye } from "components/icons";

import styles from "./password-input-row.module.scss";

/**
 * Renders the file input row
 *
 * @param {object} props The file input row properties
 *
 * @return {React.Component} The file input row
 */
const PasswordInputRow = forwardRef(({ label, required, ...otherProps }, ref) => {
    const [hiddenState, setHiddenState] = useState(true);

    return (
        <div data-testid="password-input" className={styles.container}>
            <FormRow>
                <FormRowLabel label={label} />
                <TextInput
                    required={required}
                    ref={ref}
                    type={hiddenState ? "password" : "text"}
                    {...otherProps}
                />
            </FormRow>
            <button
                type="button"
                className={styles.showPassword}
                onClick={() => setHiddenState(!hiddenState)}
            >
                <Eye
                    className={styles.showPasswordIcon}
                    width={30}
                    height={30}
                    fill={"#68707D"}
                />
                <span className={styles.buttonText}>
                    {(hiddenState) ? 'Show' : 'Hide'}
                </span>
            </button>
        </div>
    );
});

PasswordInputRow.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
};

PasswordInputRow.defaultProps = {
    label: "",
    required: false,
};

export default PasswordInputRow;
