import React from "react";
import PropTypes from 'prop-types';
import Warning from "components/icons/warning";

import styles from './error-frame.module.scss';

/**
 * The card frame for sentry errors with warning icon
 *
 * @param {string} title the text for the header
 * @param {any} content the content for the page
 * @param {element} buttons the button elements to render
 *
 * @returns {React.Component} the error card component
 */
const ErrorFrame = ({ title, content, form }) => {
    return (
        <div className={styles.contentWrapper}>
            <div className={styles.errorCard}>
                <div className={styles.cardHeader}>
                    <div data-testid="error-frame-title" className={styles.cardHeaderInner}>
                        <Warning className={styles.icon} />
                        <div className={styles.iconPadding}>
                            {title}
                        </div>
                    </div>
                </div>
                <div className={styles.cardContent}>
                    <div data-testid="error-frame-content" className={styles.cardText}>
                        {content}
                    </div>
                    <div>
                        {form}
                    </div>
                </div>
            </div>
        </div>
    );
};

ErrorFrame.propTypes = {
    title: PropTypes.string,
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    form: PropTypes.element,
};

ErrorFrame.defaultProps = {
    title: '',
    content: '',
    form: null,
};

export default ErrorFrame;
