import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import classNames from "classnames";

import { Drawer, SecondaryButton, TertiaryButton } from "components";
import AddEditAnnouncement from "./add-edit-announcement";
import Announcement from "./announcement";

import styles from "./announcement-drawer.module.scss";

/**
 * Renders the announcement drawer
 *
 * @param {boolean} isOpen Is the drawer open
 * @param {boolean} isLoading Is the announcement data loading
 * @param {function} onCancel Cancel callback
 * @param {boolean} editable Is announcement editable
 * @param {object|null} announcement The announcement
 * @param {func} setAnnouncement Manage the drawer state
 * @param {object} props Any other props
 *
 * @returns {React.Component} The AnnouncementDrawer component
 */
const AnnouncementDrawer = ({ isOpen, isLoading, onCancel, editable, announcement, setAnnouncement, ...props }) => {
    const viewingAnnouncement = !isEmpty(announcement) && !editable;

    const renderAddEditForm = () => (
        <AddEditAnnouncement
            {...props}
            announcement={announcement}
            onCancel={onCancel}
        />
    );

    const renderDisplay = () => (
        <>
            <Announcement announcement={announcement} {...props} />
            <div className={styles.actions}>
                <SecondaryButton
                    data-testid="edit-announcement"
                    onClick={() => setAnnouncement({ announcement, editable: true, isOpen: true })}
                >
                    Edit
                </SecondaryButton>
                <TertiaryButton onClick={onCancel}>Close</TertiaryButton>
            </div>
        </>
    );

    const renderContent = () => {
        return (viewingAnnouncement)
            ? renderDisplay()
            : renderAddEditForm();
    };

    const classList = classNames({
        [styles.heading]: viewingAnnouncement,
    });

    return (
        <Drawer
            isOpen={isOpen}
            isLoading={isLoading}
            onClose={onCancel}
            onOutsideClick={onCancel}
            headerContent={<h2 className={classList}>Announcements</h2>}
            padding={!viewingAnnouncement}
            ariaLabel="announcement drawer"
        >
            {isOpen && renderContent()}
        </Drawer>
    );
};

AnnouncementDrawer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    setAnnouncement: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    announcement: PropTypes.object,
    editable: PropTypes.bool,
};

AnnouncementDrawer.defaultProps = {
    announcement: null,
    editable: false,
};

export default AnnouncementDrawer;
